import {Card} from 'primereact/card';
import React, {FC} from 'react';
import styles from './ReleaseView.module.scss';
import releaseNotes from '../../assets/files/release.json';

interface ReleaseComponentProps {}

const ReleaseView: FC<ReleaseComponentProps> = () => (
  <div className={styles.ReleaseComponent} data-testid="ReleaseComponent">
     <Card>
      <h1>Release Notes</h1>
      {
        releaseNotes.map(release =>
          <div key={release?.version}>
            <h2>{(release?.version!) + ((release?.name!) ? ("-" + release?.name!) : "")}</h2>
            <ul>
              {
                release?.items.map((note, index) =>
                  <li key={index}>{note}</li>
                )
              }
            </ul>
          </div>
        )
      }
      <div>

      </div>
    </Card>
  </div>
);

export default ReleaseView;
