import { FC, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FeatureDefinition, SpecificationParameterDefinition, SpecificationParameterType } from '../../../data/model/DataModels';
import { sanitizeHTML } from '../../RichTextCustomHeader/RichTextCustomHeaderComponent';
import FeatureSpecificationComponent from '../FeatureSpecificationComponent/FeatureSpecificationComponentInc';
import styles from './SpecificationList.module.scss';
import { Button } from 'primereact/button';
import { cloneDeep } from 'lodash';
import { ReactComponent as FlowLogo } from '../../../assets/icons/maersk/flow.svg';
import { Tooltip } from 'primereact/tooltip';
import EditSpecificationParameterConfiguration from '../../../features/EditSpecificationParameterConfiguration/EditSpecificationParameterConfiguration';
import { Tag } from 'primereact/tag';
import SpecificationParameterOptionality from '../../SpecificationParameterOptionality/SpecificationParameterOptionality';

export const SPDefaultLabel = {
  included: "Core",
  not_included: "Optional",
  none: ""
} as any;

export interface SpecificationListProps {
  specificationParameters: SpecificationParameterDefinition[],
  feature?: FeatureDefinition,
  deleteSpecificationParam?: Function,
  createOrEditSpecificationParameter?: Function,
  addSpecificationParameter?: Function,
  saveSpecificationParameters?: Function,
  isConfigurable: boolean,
  showExcludedOptionsInViewMode?: boolean,
  usedIn?: "product" | "definition" | "solution",
  isLinkedFeatureInDraft?: boolean,
  selectedSpecParamCode?: string
}

interface ChildComponentRef {
  resetWarnings: () => void;
}

const SpecificationList = forwardRef<ChildComponentRef, SpecificationListProps>((
  {
    specificationParameters,
    feature,
    deleteSpecificationParam,
    createOrEditSpecificationParameter,
    addSpecificationParameter,
    isConfigurable,
    saveSpecificationParameters,
    showExcludedOptionsInViewMode = false,
    usedIn = "definition",
    isLinkedFeatureInDraft = false,
    selectedSpecParamCode = ""
  },
  ref
) => {
  const specificationParameterDefinitions = specificationParameters.map(spec => spec.definition);
  const [specificationCodeForConfigurationDialog, setSpecificationCodeForConfigurationDialog] = useState("");

  const [showWarning, setShowWarning] = useState("");

  const specParamRef = useRef() as any;

  // Expose a function to focus the input element
  useImperativeHandle(ref, () => ({
    resetWarnings: () => {
      setShowWarning("");
    }
  }));

  useEffect(() => {
    if (specParamRef && specParamRef.current && selectedSpecParamCode !== "") {
      specParamRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedSpecParamCode]);

  const onlyOneCoreAvailable = () => {
    if (!feature) { return; }
    if (feature.serviceDefault === 'included') {
      const isOnlyCoreAvailable = specificationParameters.filter(sp => sp.serviceDefault === 'included');
      if (isOnlyCoreAvailable.length === 1) { return true; }
    }
    return false;
  }

  const atleastOneSpecAvailable = () => {
    if (specificationParameters.length <= 1) { return true; }
    return false;
  }


  const disableSpecificationDeleteButton = (specification: SpecificationParameterDefinition) => {
    return specification.serviceDefault === 'included' ? atleastOneSpecAvailable() || onlyOneCoreAvailable() : atleastOneSpecAvailable()
  }


  const saveSpecification = (specificationParam: SpecificationParameterDefinition) => {
    let specificationParametersCopy = cloneDeep(specificationParameters);
    let specificationOld = specificationParameters.find(element => element.code === specificationParam.code);
    let updatingParam = specificationParametersCopy.find(element => element.code === specificationParam.code);
    if (updatingParam && specificationOld) {
      updatingParam.configuration.value = specificationParam.configuration.value;
      updatingParam.configurableAtAssociation = specificationParam.configurableAtAssociation;
      updatingParam.configurableAtBooking = specificationParam.configurableAtBooking;
      updatingParam.serviceDefault = specificationParam.serviceDefault;
      updatingParam.configurableAtContracting = {
        enabled: (feature?.service && specificationParam.configurableAtContracting?.enabled) || false,
        choiceRequired: (feature?.service && specificationParam.configurableAtContracting?.choiceRequired) || false,
        allowMultiselect: (feature?.service && specificationParam.configurableAtContracting?.allowMultiselect) || false,
      }
      if (specificationParam.configuration.type === SpecificationParameterType.options && specificationParam.configuration.value.length <= 1) {
        updatingParam.configurableAtContracting = {
          enabled: false,
          choiceRequired: false,
          allowMultiselect: false
        }
      }
      if (specificationOld.configuration.value.length < 2 && (specificationParam.configuration.type === SpecificationParameterType.options && specificationParam.configuration.value.length == 2)) {
        updatingParam.configurableAtContracting = {
          enabled: true,
          choiceRequired: true,
        }
      }
      saveSpecificationParameters && saveSpecificationParameters(specificationParametersCopy);
    }
  }

  const totalCoreSps = useMemo(() => {
    return specificationParameters.filter(sp => sp.serviceDefault === 'included').length;
  }, [specificationParameters]);

  const onOptionalityRadioButtonClick = (specification: SpecificationParameterDefinition, serviceDefault: "included" | "not_included" | "none") => {
    if (totalCoreSps === 1 && serviceDefault === "not_included") {
      setShowWarning && setShowWarning(specification.code);
      return;
    }
    else {
      setShowWarning && setShowWarning("");
      const updatedSpecificationparameters = specificationParameters.map(specParam => {
        if (specParam.code === specification.code) {
          specParam.serviceDefault = serviceDefault;
        }
        return specParam;
      })
      saveSpecificationParameters && saveSpecificationParameters(updatedSpecificationparameters);
    }
  }

  const checkSPOptionalityEnabled = () => {
    let flag = true;
    if (feature?.service) {
      if (usedIn !== 'definition') {
        if (feature.serviceDefault === 'not_included') {
          flag = false;
        }
      }
    }
    return flag;
  }

  const showSpecification = (specification: SpecificationParameterDefinition, index: number) => {
    const specificationParameterDefinition: SpecificationParameterDefinition | undefined = specificationParameterDefinitions?.find(spec => spec.code === specification.code);
    if (!specificationParameterDefinition) {
      return <></>
    }

    return (
      <div key={specification.code} data-testid={`showSpecification${index}`}>
        <div className={styles.specification}>
          <div className={styles.actionButtonsAndHeader} ref={selectedSpecParamCode === specification.code ? specParamRef : null}>
            <div className={styles.specificationHeader}>
              <h4 data-testid="SpecParamName">
                <div className={styles.specNameAndIcon}>
                  <FlowLogo />
                  {specificationParameterDefinition.name}
                </div>
              </h4>
              <div className={styles.actionButtons}>
                {
                  createOrEditSpecificationParameter && isLinkedFeatureInDraft &&
                  <Button icon="pi pi-pencil" data-testid="editSpecification" className={`p-button-outlined p-button-secondary default_button`} onClick={() => createOrEditSpecificationParameter && createOrEditSpecificationParameter(specification)} />
                }
                {
                  <>
                    {
                      feature?.service &&
                      <div className={styles.badgeStyle}>
                        {
                          isConfigurable ?
                            <SpecificationParameterOptionality
                              specificationParameter={specification}
                              optionalityChangeFn={(serviceDefault: "included" | "not_included" | "none") => onOptionalityRadioButtonClick(specification, serviceDefault)}
                              isSPOptionalityEnabled={checkSPOptionalityEnabled()}
                              showWarning={showWarning === specification.code}
                              isFeatureAService={feature.service} />
                            : <Tag className={specification?.serviceDefault === 'included' ? styles.coreServiceTag : specification?.serviceDefault === 'not_included' ? styles.optionalServiceTag : styles.noneServiceTag}
                              rounded>
                              <div className={styles.tagName}>{SPDefaultLabel[specification?.serviceDefault]}</div>
                            </Tag>
                        }
                      </div>
                    }
                    <div className={styles.configurableButtons}>
                      {
                        <Button icon="pi pi-cog" text aria-label="SP Settings"
                          severity="secondary"
                          onClick={() => setSpecificationCodeForConfigurationDialog(specification.code)}
                        />
                      }
                    </div>
                    <div>
                      {
                        isConfigurable &&
                        <Button icon="pi pi-trash" data-testid="deleteSpecification"
                          severity="secondary" text aria-label="SP Delete"
                          disabled={disableSpecificationDeleteButton(specification)}
                          onClick={() => deleteSpecificationParam && deleteSpecificationParam(specification)}
                        />
                      }
                    </div>
                  </>
                }
              </div>

            </div>
          </div>
          <div className={styles.specificationInnerContent}>
            <div className={styles.specificationDescriptionAndConfig}>

              <div data-testid="SpecParamDesc" dangerouslySetInnerHTML={{ __html: sanitizeHTML(specificationParameterDefinition.descriptionRichText?.toString()) }} className={styles.displayDescription} />
              <div className={styles.specificationConfiguration}>
                <FeatureSpecificationComponent specification={specification} saveSpecification={saveSpecification} definition={specificationParameterDefinition} editMode={isConfigurable} showExcludedOptionsInViewMode={showExcludedOptionsInViewMode} />
              </div>
            </div>
          </div>

        </div>
        <Tooltip target={".disabledDeleteSP"} position="left" showEvent='click' content='Specification Parameter is associated with one or more products' />
        {index < (specificationParameters.length - 1) && <div className={styles.divider}><hr /></div>}
        {
          specificationCodeForConfigurationDialog == specification.code &&
          <EditSpecificationParameterConfiguration
            specificationParameter={specification}
            onHide={() => setSpecificationCodeForConfigurationDialog("")}
            saveSpecification={(specificationParam: SpecificationParameterDefinition) => saveSpecification(specificationParam)}
            isFeatureAService={feature?.service}
            isConfigurable={isConfigurable}
            usedIn={usedIn}
            associatedSpecParam={specificationParameters}
          />
        }
      </div>
    )
  }


  return (
    <div className={styles.SpecificationList} data-testid={`specificationList${feature?.code}`}>
      <div className={styles.specificationContent}>
        <div className={styles.specificationList}>
          <div>
            <div className={styles.header}>
              <div className={styles.headerAndButton}>
                <h4 data-testid={`specParamCount${feature?.code}`}>
                  {specificationParameters?.length} specification parameter{specificationParameters?.length > 1 ? 's' : ''}
                </h4>
                <div className={styles.actionButtons}>
                  {
                    addSpecificationParameter && (usedIn == "product") && isConfigurable && feature &&
                    <>
                      <div className={`${specificationParameters.length >= feature.numberOfSpecificationParameters && ("disabledAddSP-" + feature.code)}`}>
                        <Button className={`p-button-outlined p-button-secondary`} icon="pi pi-plus" disabled={(specificationParameters.length >= feature.numberOfSpecificationParameters)}
                          label="Add SP" data-testid="addSPButton" onClick={() => addSpecificationParameter && addSpecificationParameter()}
                        />
                      </div>
                      <Tooltip target={".disabledAddSP-" + feature.code} position="left" content='All specification parameters are already associated to solution'
                        style={{ visibility: (specificationParameters.length >= feature.numberOfSpecificationParameters) ? 'visible' : 'hidden' }} />
                    </>
                  }
                  {
                    createOrEditSpecificationParameter && usedIn == "definition" && isLinkedFeatureInDraft &&
                    <Button className={`p-button-outlined p-button-secondary`} icon="pi pi-plus"
                      label="New SP" data-testid="newSPButton" onClick={() => createOrEditSpecificationParameter && createOrEditSpecificationParameter()} />
                  }
                </div>
              </div>
              {specificationParameters?.length > 0 && <div className={styles.divider}><hr /></div>}
            </div>
          </div>
          {specificationParameters?.map((specification, index) => showSpecification(specification, index))}
        </div>
      </div>
    </div>
  );
}
)

export default SpecificationList;
