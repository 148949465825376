import { FC, useContext, useEffect, useState } from 'react';
import styles from './CriterionComponent.module.scss';
import { Criterion, DimensionType, HeaderTypes, Rule } from '../../data/model/DataModels';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { TreeSelect } from 'primereact/treeselect';
import { useGetCountriesDataQuery, useGetListTypeDimensionDataQuery } from '../../data/api/RefDataApi';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { useGetAllPopUpValuesQuery } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { cloneDeep } from 'lodash';
import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import { MdHeight } from 'react-icons/md';


export interface CriterionComponentProps {
  criteriaDimensionKey: DimensionType | HeaderTypes;
  criteriaDimensionValue: string[];
  allAvailableDimensions: { name: string, code: DimensionType | HeaderTypes }[];
  deleteCriterion: Function;
  index: number;
  rule: Rule;
  setRule: Function;
  criterionValuesValidationFn: Function;
}

const CriterionComponent: FC<CriterionComponentProps> = ({ criteriaDimensionKey, criteriaDimensionValue, allAvailableDimensions, deleteCriterion, index, rule, setRule, criterionValuesValidationFn }) => {

  const [criterionKey, setCriterionKey] = useState<DimensionType | HeaderTypes>(criteriaDimensionKey);
  const toaster = useContext(ToasterContext);
  allAvailableDimensions.sort((a, b) => a.name.localeCompare(b.name));

  const isLocationDimension = () => {
    return criterionKey == DimensionType.Export
      || criterionKey == DimensionType.Import
      || criterionKey == DimensionType.Locations
  }

  const isHeaderType = (key: any): key is HeaderTypes => {
    return Object.values(HeaderTypes).includes(key);
  };

  const { data: countries = [], error: errorCountriesData, isLoading: isLoadingCountriesData } = useGetCountriesDataQuery(undefined, { skip: !isLocationDimension() });
  const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
  const { data: listTypeHeaderData = [], error: errorLoadingHeaderValues, isLoading: isLoadingHeaderValues } = useGetAllPopUpValuesQuery(criterionKey as HeaderTypes, { skip: !isHeaderType(criterionKey) });
  const { data: listTypeDimensionData = [], error: errorLoadingListDimensionValues, isLoading: isLoadingListDimensionValues } = useGetListTypeDimensionDataQuery(criterionKey, { skip: isHeaderType(criterionKey) });

  useEffect(() => {
    setCriterionKey(criteriaDimensionKey);
  }, [criteriaDimensionKey]);

  useEffect(() => {
    if (isLocationDimension()) {
      buildTreeSelection(countries);
    }
  }, [isLoadingCountriesData]);

  const selectPartiallySelect = (processLocations: any[]) => {
    return processLocations.map(location => {
      let locationCopy = cloneDeep(location);
      if (locationCopy.children && locationCopy.children.length > 0) {
        locationCopy.children = selectPartiallySelect(locationCopy.children);
        let isCheckedCount = 0;
        let isPartiallyCheckedCount = 0;
        locationCopy.children.forEach((child: any) => {
          if (child.isChecked == true) {
            isCheckedCount++;
          } else if (child.isPartiallyChecked == true) {
            isPartiallyCheckedCount++;
          }
        });
        if (isCheckedCount === locationCopy.children.length) {
          locationCopy.isChecked = true;
          locationCopy.isPartiallyChecked = false;
        } else if (isCheckedCount > 0) {
          locationCopy.isChecked = false;
          locationCopy.isPartiallyChecked = true;
        } else {
          locationCopy.isChecked = false;
          if (isPartiallyCheckedCount > 0) {
            locationCopy.isPartiallyChecked = true;
          } else {
            locationCopy.isPartiallyChecked = false;
          }
        }
      } else if (criteriaDimensionValue.includes(locationCopy.code)) {
        locationCopy.isChecked = true;
        locationCopy.isPartiallyChecked = false;
      } else {
        locationCopy.isChecked = false;
        locationCopy.isPartiallyChecked = false;
      }
      return locationCopy;
    })

  }

  const buildTreeSelection = (processRegions: any) => {
    let selectedMapping: TreeCheckboxSelectionKeys = {} as TreeCheckboxSelectionKeys;

    let checkedOrPartiallycheckedTree = selectPartiallySelect(processRegions);

    const setMapping = (processRegions: any) => {
      processRegions.forEach((region: any) => {
        selectedMapping[region.key] = { checked: region.isChecked, partialChecked: region.isPartiallyChecked };
        if (region.children) {
          setMapping(region.children);
        }
      }
      )
    }

    setMapping(checkedOrPartiallycheckedTree);
    setSelectedNodeKeys(selectedMapping);
  }


  if (errorCountriesData) {
    toaster.showToast('error', 'Failed to fetch location values');
  }
  if (errorLoadingHeaderValues) {
    toaster.showToast('error', 'Failed to fetch headers values');
  }
  if (errorLoadingListDimensionValues) {
    toaster.showToast('error', 'Failed to fetch dimension values');
  }

  const transformList = (): { name: string, code: string }[] => {
    var dimensionValues: { name: string, code: string }[] = [];
    listTypeDimensionData.forEach(dimensionItem => {
      if (dimensionItem?.children !== undefined) {
        dimensionItem?.children
          .forEach(innerDimensionItem => dimensionValues.push({ name: dimensionItem.name + '-' + innerDimensionItem.name, code: innerDimensionItem.code }));
      }
      else {
        dimensionValues.push({ name: dimensionItem.name, code: dimensionItem.code });
      }
    })
    return dimensionValues;
  }

  const prepareDimensionValues = () => {
    if (isHeaderType(criterionKey)) {
      return listTypeHeaderData;
    } else if (!isLocationDimension()) {
      return transformList();
    }
  }

  const onCriterionKeyChange = (dimensionCode: DimensionType | HeaderTypes) => {
    const clonedCriteria: Criterion[] = rule.criteria.map(criterion => {
      if (criterion.dimension === criterionKey) {
        return {
          dimension: dimensionCode,
          values: []
        };
      }
      else {
        return criterion
      }
    })
    setRule({ ...rule, criteria: clonedCriteria });
    setCriterionKey(dimensionCode);
    setSelectedNodeKeys(null)
  }

  const onCriterionValueChange = (dimensionValue: string[]) => {
    const clonedCriteria: Criterion[] = rule.criteria.map(criterion => {
      if (criterion.dimension === criterionKey) {
        return {
          dimension: criterion.dimension,
          values: dimensionValue
        };
      }
      else {
        return criterion
      }
    })
    setRule({ ...rule, criteria: clonedCriteria });
  }

  const filteredAvailableDimensions = (): { name: string, code: DimensionType | HeaderTypes }[] => {
    return allAvailableDimensions.filter(dimensionItem =>
      !(rule.criteria.some(criterion => criterion.dimension === dimensionItem.code) && dimensionItem.code !== criterionKey)
    );
  }

  const setCountrySelection = (selections: any) => {
    setSelectedNodeKeys(selections);
    let selectedCountries: string[] = [];
    Object.entries(selections)
      .forEach(([key, value]) => {
        const decodedKey = key.toString().split("-");
        if (decodedKey.length === 3) {
          if((value as { checked: boolean }).checked)
          selectedCountries.push(decodedKey[2]);
        }
      });
    const clonedCriteria: Criterion[] = rule.criteria.map(criterion => {
      if (criterion.dimension === criterionKey) {
        return {
          dimension: criterion.dimension,
          values: selectedCountries
        };
      }
      else {
        return criterion
      }
    })
    setRule({ ...rule, criteria: clonedCriteria });
  }


  return (
    <div className={styles.CriterionComponent} data-testid="CriterionComponent">
      {
        (isLoadingCountriesData || isLoadingHeaderValues || isLoadingListDimensionValues) &&
        <SpinnerComponent />
      }
      <div className={styles.criteriaGrid}>
        <div className={styles.gridItemDimensionLabel}>Dimension</div>
        <div className={styles.gridItemValueLabel}>Value</div>
        <div><Dropdown
          optionLabel='name'
          optionValue='code'
          placeholder="Select type"
          value={criterionKey}
          options={filteredAvailableDimensions()}
          onChange={(e) => onCriterionKeyChange(e.target.value)}
          style={{ width: "100%" }} 
          data-testid={`${criterionKey}-dimension`}/>
        </div>
        <div className={styles.gridItemIncludeLabel}>include</div>
        <div className={styles.criteriaValue}>
          {
            !isLocationDimension() ?
              <MultiSelect
                placeholder="Select Values"
                value={criteriaDimensionValue}
                options={prepareDimensionValues()}
                onChange={(e) => onCriterionValueChange(e.value)}
                optionLabel="name"
                optionValue="code"
                display="chip"
                style={{ "width": "100%" }}
                key={index}
                className={criterionValuesValidationFn(criteriaDimensionValue) === "" ? "" : "p-invalid"} 
                data-testid={`${criterionKey}-value`}/>
              :
              <TreeSelect
                style={{ width:"100%", height:"100%" }}
                value={selectedNodeKeys}
                display="chip"
                onChange={(e) => setCountrySelection(e.value)}
                selectionMode={'checkbox'}
                filter
                filterBy='name'
                options={countries} metaKeySelection={false}
                placeholder="Select Items"></TreeSelect>
          }
        </div>
        <div>
          <Button className='p-button-outlined p-button-secondary' icon='pi pi-trash' onClick={() => deleteCriterion(criterionKey)} disabled={rule.criteria.length == 1} data-testid={`${criterionKey}-delete-icon`}/>
        </div>
        <div className={styles.gridItemCriteriaValueValidation}>{criterionValuesValidationFn(criteriaDimensionValue)}</div>
      </div>
    </div>
  )
};

export default CriterionComponent;
