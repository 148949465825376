import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import styles from './SolutionFeatureAssociationsHeader.module.scss';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { filterType } from '../../components/FilterPanel/FilterPanel';
import { ComponentAssociation, FeatureAssociation, FeatureAssociationRequest, SolutionDefinition, SolutionDefnitionRequest, SpecificationParameterAssociation, SpecificationParameterAssociationRequest, SpecificationParameterDefinition, Status } from '../../data/model/DataModels';
import DisplayFeatureCards from '../ProductView/ProductFeatures/DisplayFeatureCards/DisplayFeatureCards';
import { useLocation, useNavigate } from 'react-router-dom';
import { SolutionContext, constructBaseSolutionRequest, solutionTabs } from '../SolutionDetails/SolutionDetails';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Tag } from 'primereact/tag';
import { ClonedSolutionContext } from '../SolutionFeatureAssociations/SolutionFeatureAssociations';

export interface SolutionFeatureAssociationsHeaderProps {
  isEditable?: boolean;
  setFeatureTypeFilterValue: Function;
}

const selectedComponentDefault = {
  componentRef: "all-features",
  definition: {
    name: "All Features"
  }
} as ComponentAssociation;

const SolutionFeatureAssociationsHeader: FC<SolutionFeatureAssociationsHeaderProps> = ({ isEditable = false, setFeatureTypeFilterValue }) => {

  const { hash } = useLocation();
  const navigate = useNavigate();
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [showMultiComponentFilters, setShowMultiComponentFilters] = useState(false);
  const clickedComponentCode = new URLSearchParams(hash).keys().next().value.slice(10);
  let selectedComponentCode = clickedComponentCode ? clickedComponentCode : "all-features";
  let selectedComponentCodes: string[] = [];
  const params = new URLSearchParams(hash.replace(solutionTabs[4].hash, ""));
  let canAddFeature = false;

  const ClonedSolutionState = useContext(ClonedSolutionContext);
  const clonedSolution = ClonedSolutionState.solutionData;
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;


  const toaster = useContext(ToasterContext);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();

  if (params.getAll(filterType.Component).length > 0) {
    selectedComponentCodes = params.getAll(filterType.Component);
    if (params.getAll(filterType.Component).length === 1) {
      selectedComponentCode = selectedComponentCodes[0];
    }
  }

  if (!solution) {
    return <></>
  }

  const components = [selectedComponentDefault, ...solution.components];

  const selectedComponentFromSolution = components.find(component => component.componentRef === selectedComponentCode);

  if (selectedComponentFromSolution) {
    if (selectedComponentCode === selectedComponentDefault.componentRef) {
      canAddFeature = false;
    } else {
      const featuresAssociated = solution.features.filter(({ componentRef }) => componentRef === selectedComponentFromSolution?.definition.code);
      canAddFeature = selectedComponentFromSolution.definition.numberOfFeatures > featuresAssociated.filter(feature => (feature.definition.status == Status.ACTIVE || feature.definition.status == Status.OUTDATED)).length;
    }
  }

  const [featureTypeFilterLabel, setFeatureTypeFilterLabel] = useState(
    {
      name: "All features",
      code: "all",
    }
  );

  const featureTypeFilterLabels = [
    { name: "All features", code: "all" },
    { name: "Core services", code: "included" },
    { name: "Optional services", code: "not_included" },
    { name: "Non services", code: "none" },
  ];

  function getFilterPlaceholder(featureTypeFilterLabel: string): string {
    return "Show: " + featureTypeFilterLabel;
  }


  const selectComponentDropdown = (e: DropdownChangeEvent) => {
    const component = components.find(component => component.componentRef === e.value);
    if (component) {
      if (e.value !== selectedComponentDefault.componentRef) {
        params.set(filterType.Component, component.definition.code);
        navigate(solutionTabs[4].hash + "?" + params.toString());
      } else {
        navigate(solutionTabs[4].hash)
      }
    }
  };

  const componentOptionTemplate = useCallback(
    (option: ComponentAssociation) => {
      return (
        <div className={styles.componentItem}>
          <div className={option.definition?.name === "All Features" ? styles.allfeatures : ""}>{option.definition?.name}</div>
          <div className={styles.dropDownFooter}>
            {option.definition?.name === "All Features" ? solution?.features.length : solution?.features.filter(feature => feature.componentRef === option.componentRef).length} Features
          </div>
        </div>
      );
    }, [solution]
  )

  const selectedComponentTemplate = useCallback(
    (option: ComponentAssociation, props: DropdownProps) => {
      if (option) {
        return (
          <span className={styles.componentName}>
            {option.definition?.name}
          </span>
        );
      }
      return (
        <span>
          {props.placeholder}
        </span>
      );
    }, []
  );

  const getFeaturesCountOfSelectedComponent = (code: string) => {
    if (selectedComponentCode) {
      if (selectedComponentCode === selectedComponentDefault.componentRef) {
        const filteredFeatures = solution?.features?.filter(
          (feature) =>
            code == "all" ||
            feature.serviceDefault === code
        );
        return filteredFeatures?.length === 1 ? "1 Feature" : filteredFeatures?.length + " Features"
      }
      else {
        const featuresByComponent = solution?.features?.filter(f => selectedComponentCodes.includes(f.componentRef));
        const filteredFeaturesByComponentAndServiceOptionality = featuresByComponent?.filter(
          (feature) =>
            code == "all" ||
            feature.serviceDefault === code
        );
        return filteredFeaturesByComponentAndServiceOptionality?.length === 1 ? "1 Feature" : filteredFeaturesByComponentAndServiceOptionality.length + " Features"
      }
    }
    return "0 Features";
  }

  const addFeatures = (features: FeatureAssociation[]) => {

    const featureAssociations: FeatureAssociationRequest[] = [...features,...solution.features];
    const allSpecificationParameters = features.map(feature => feature.definition.specificationParameters).reduce<SpecificationParameterDefinition[]>((accumulator, current) => [...accumulator, ...current], [] as SpecificationParameterDefinition[]);

    const specificationParameterAssociations: SpecificationParameterAssociationRequest[] = [
      ...solution.specificationParameters,
      ...allSpecificationParameters.map(specificationParameter => {
        return {
          featureRef: specificationParameter.featureRef,
          featureVersion: specificationParameter.featureVersion,
          componentRef: specificationParameter.componentRef,
          specificationParamRef: specificationParameter.code,
          configuration: specificationParameter.configuration,
          configurableAtContracting: specificationParameter.configurableAtContracting,
          configurableAtBooking: specificationParameter.configurableAtBooking,
          serviceDefault: specificationParameter.serviceDefault,
          service: specificationParameter.isService
        } as SpecificationParameterAssociationRequest;
      })
    ];

    const specParamAssoc: SpecificationParameterAssociation[] = allSpecificationParameters.map(specParam => {
      return {
        featureRef: specParam.featureRef,
        featureVersion: specParam.featureVersion,
        componentRef: specParam.componentRef,
        definition: specParam,
        specificationParamRef: specParam.code,
        configuration: specParam.configuration,
        configurableAtContracting: specParam.configurableAtContracting,
        configurableAtBooking: specParam.configurableAtBooking,
        note: "",
        serviceDefault: specParam.serviceDefault,
      } as SpecificationParameterAssociation;
    });

    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: featureAssociations,
      specificationParameters: specificationParameterAssociations
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            features: response.features,
            specificationParameters: response.specificationParameters
          }
        );
        if (clonedSolution) {
          ClonedSolutionState.setSolutionData({
            ...clonedSolution,
            lockingVersion: response.lockingVersion,
            features: [...features, ...clonedSolution.features],
            specificationParameters: [...clonedSolution.specificationParameters, ...specParamAssoc]
          });
        }
        toaster.showToast('success', `Successfully added ${features.length > 0 ? "features" : "feature"}`);
        setShowFeatureModal(false);
      },
      () => {
        toaster.showToast('error', `Failed to add ${features.length > 0 ? "features" : "feature"}`);
      }
    );
  }

  const getAssociatedFeatureCodes = useMemo(() => {
    const assocFeatures = solution.features.filter(feature => feature.componentRef == selectedComponentCode);
    return assocFeatures.map(feat => feat.featureRef);
  }, [solution, selectedComponentCode]);


  const MultiComponentFilters = memo(({ urlParams }: { urlParams: URLSearchParams }) => {
    const components = solution.components.filter(component => component.definition !== null).map(component => { return { name: component.definition?.name, code: component.definition?.code }; });

    const selectedComponents = urlParams.getAll(filterType.Component);

    const filterAction = (event: CheckboxChangeEvent) => {
      const localParams = new URLSearchParams(hash.replace(solutionTabs[4].hash, ""));
      localParams.delete(filterType.Component);

      if (event.checked) {
        [...selectedComponents, event.target.value].forEach(componentCode => localParams.append(filterType.Component, componentCode));
      } else {
        selectedComponents.filter(componentCode => componentCode != event.target.value)
          .forEach(component => localParams.append(filterType.Component, component));
      }
      navigate(solutionTabs[4].hash + "?" + localParams.toString());
    }

    return (
      <div className={styles.customFilter}>
        <div className={styles.title}>Components</div>
        <div>
          {
            components.map((component, index) => {
              return (
                <span key={component.code}>
                  <div className="p-field-checkbox">
                    <Checkbox data-testid={`filterCheckBox${index}`} inputId={component.code} name="components" value={component.code} onChange={e => filterAction(e)} checked={selectedComponents.includes(component.code)} />
                    <label data-testid={`filterCheckBoxName${index}`} htmlFor={component.code}>{component.name}</label>
                  </div>
                </span>
              );
            })
          }
        </div>
      </div>)
  })

  const ShowActiveFilters = memo(({ solution }: { solution: SolutionDefinition }) => {
    const selectedCompNames = selectedComponentCodes.map(comp => solution.components.find(c => c.componentRef === comp)?.definition.name);
    return (
      <div className={styles.filtersOverview}>
        <div>
          Active filters:
          {selectedCompNames.length > 0 && <span>
            {
              selectedCompNames.map(componentName =>
                <span key={"component-" + componentName} className={styles.pill}><Tag rounded className="mr-2" severity="info" value={"Component: " + componentName}></Tag></span>
              )
            }
          </span>}
        </div>
      </div>
    );
  })

  return (
    <div className={styles.SolutionFeatureAssociationsHeader} data-testid="SolutionFeatureAssociationsHeader">
      {isLoadingUpdateSolution && <SpinnerComponent />}
      <div className={styles.headerContainer}>
        <span className={styles.spanDropdown}>
          <Dropdown data-testid="componentDropDown" value={selectedComponentCode} options={components.sort((a, b) => a.definition.name !== "All Features" ? 0 : a.definition.name.localeCompare(b.definition.name))}
            optionValue="componentRef"
            className={styles.componentDropdown} onChange={(e) => selectComponentDropdown(e)} optionLabel="definition.name" placeholder="Select a Component"
            valueTemplate={selectedComponentTemplate} itemTemplate={componentOptionTemplate} />
        </span>
        <span className={styles.spanDropdown} data-testid="featuresCount">
          {getFeaturesCountOfSelectedComponent(featureTypeFilterLabel.code)}
        </span>
        <span className={styles.titleButtons}>
          <span>
            {isEditable && <span className={"tooltip"}><Button data-testid="addFeatureButton" label="Add Feature" icon="pi pi-plus" onClick={() => setShowFeatureModal(true)} disabled={!canAddFeature} className="p-button-outlined p-button-secondary" /></span>}
            <span>
              <Button data-testid="filterButton" label="Filters" icon="pi pi-filter" className="p-button-outlined p-button-secondary" onClick={() => setShowMultiComponentFilters(!showMultiComponentFilters)} />
            </span>
            {selectedComponentCodes.length > 0 && <span><Button label="Clear Filters" icon="pi pi-trash" className="p-button-outlined p-button-secondary" onClick={() => navigate(solutionTabs[4].hash)} /></span>}
          </span>
        </span>
      </div>
      {showMultiComponentFilters && <MultiComponentFilters urlParams={params} />}
      <div className={styles.activeFiltersAndServiceModeFilter}>
        {selectedComponentCodes.length > 0 && <ShowActiveFilters solution={solution} />}
        <div className={styles.serviceModelFilter}>
          <Dropdown
            value={featureTypeFilterLabel.code}
            onChange={(e) => {
              setFeatureTypeFilterLabel(e.value);
              setFeatureTypeFilterValue(e.value.code);
            }}
            options={featureTypeFilterLabels}
            optionLabel="name"
            placeholder={getFilterPlaceholder(featureTypeFilterLabel.name)}
            className="w-full md:w-14rem"
          />
        </div>
      </div>
      {
        showFeatureModal &&
        <DisplayFeatureCards header={"Component name"} onHide={() => setShowFeatureModal(false)} activeFeatures={getAssociatedFeatureCodes} componentRef={selectedComponentCode} acceptAction={(features: FeatureAssociation[]) => addFeatures(features)} />
      }
      <Tooltip target=".tooltip" className="general-tooltip" autoHide={true} position="top" disabled={canAddFeature}>
        <div className="p-d-flex p-flex-column">
          {
            selectedComponentCode === selectedComponentDefault.componentRef ? "Select a component to add feature" : selectedComponentFromSolution?.definition.numberOfFeatures === 0 ? "No feature is available to associate with the component" : "All features are already associated with the component"
          }
        </div>
      </Tooltip>
    </div>
  );
}

export default SolutionFeatureAssociationsHeader;
