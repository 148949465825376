import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { LocationData } from '../../data/model/DataModels';
import RegionTree from '../RegionTree/RegionTree';
import { buildTreeFromSelectedValuesAndAddIcons, getLeafValues } from '../ScopeComponents/ScopeHelper';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import styles from './PickListTree.module.scss';

interface PickListTreeProps {
  mainTree: any[];
  mainHeader?: string,
  sourceValues: any[],
  sourceHeader?: string,
  targetHeader?: string,
  searchPlaceholder?: string,
  searchKey: string,
  onChange: Function
}

const PickListTree: FC<PickListTreeProps> = (
  {
    mainTree,
    sourceValues = [],
    sourceHeader = "Source",
    targetHeader = "Target",
    searchPlaceholder = "Search",
    onChange
  }
) => {
  const [searchSource, setSearchSource] = useState("");
  const [searchTarget, setSearchTarget] = useState("");
  const [selectedSource, setSelectedSource] = useState([] as string[]);
  const [selectedTarget, setSelectedTarget] = useState([] as string[]);
  const [sourceTree, setSourceTree] = useState([] as LocationData[]);
  const [targetTree, setTargetTree] = useState([] as LocationData[]);
  const [showSpinner, setShowSpinner] = useState(false);


  useEffect(() => {
    if (mainTree?.length > 0) {
      setSourceTree(buildTreeFromSelectedValuesAndAddIcons(mainTree, sourceValues));
      setTargetTree(buildTreeFromSelectedValuesAndAddIcons(mainTree, sourceValues, "exclusion"));
      setShowSpinner(false);
    }
  }, [sourceValues, mainTree]);

  const processItems = (searchItem: String, treeItems: LocationData[]) => {
    const allValues = getLeafValues(treeItems);
    if(searchItem !== ""){
      return allValues.filter(leafNode => leafNode.toLowerCase().includes(searchItem.toLowerCase()));
    }
    return allValues;
  }

  const processSourceItems = useMemo(() => processItems(searchSource, sourceTree), [searchSource, sourceTree]);
  const processTargetItems = useMemo(() => processItems(searchTarget, targetTree), [searchTarget, targetTree]);

  const getSourceItems = () => {
    return (
      mainTree && sourceTree.length > 0 ?
        <>
          <RegionTree selectedValuesOnTree={selectedSource || []} filterValue={searchSource} enableFilter={true}
            saveSelection={(value: any) => setSelectedSource(value)}
            editMode={true} nodes={sourceTree} />
        </>
        :
        ""
    );
  };

  const getTargetItems = () => {
    return (
      mainTree && targetTree.length > 0 ?
        <>
          <RegionTree selectedValuesOnTree={selectedTarget || []} filterValue={searchTarget} enableFilter={true}
            saveSelection={(value: any) => setSelectedTarget(value)}
            editMode={true} nodes={targetTree} />
        </> :
        ""
    );
  };

  const moveSourceToTarget = () => {
    // getting source tree excluding the moved values
    let nodes = buildTreeFromSelectedValuesAndAddIcons(sourceTree, [...selectedSource], "exclusion");
    setSelectedSource([]);
    const exclusionNodes = ["EXCLUDED"];// dummy value to mark some elements are excluded


    if (!nodes.length) {
      onChange([], exclusionNodes);
      return;
    }

    let newSourceValues: string[] = getLeafValues(nodes);

    onChange(newSourceValues, exclusionNodes);
  };

  const moveTargetToSource = () => {
    let newSource = [...sourceValues, ...selectedTarget];
    const nodes = buildTreeFromSelectedValuesAndAddIcons(mainTree, newSource, "exclusion");
    setSelectedTarget([]);
    if (nodes.length) {
      onChange(newSource, nodes);
    } else {
      onChange(newSource);
    }
  };
  
  const selectAllSource = () => {
    setSelectedSource(processSourceItems);
  };

  const deSelectAllSource = () => {
    setSelectedSource([]);
  };

  const selectAllTarget = () => {
    setSelectedTarget(processTargetItems);
  };

  const deSelectAllTarget = () => {
    setSelectedTarget([]);
  };

  return (
    <>
      {
        showSpinner && <SpinnerComponent />
      }
      {
        <div className={styles.pickListRegionContainer}>
          <div className={styles.columnContent}>
            <div>
              <h3>{sourceHeader}</h3>
            </div>
            <div className={styles.listContent}>
              <div className={styles.searchBox}>
                <span className={`p-input-icon-left ${searchSource !== "" && "p-input-icon-right"}`}>
                  <i className={`pi pi-search ${styles.searchIcon}`} />

                  <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                    value={searchSource}
                    onChange={(e) => setSearchSource(e.target.value)} />
                  {
                    searchSource &&
                    <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchSource("")} />
                  }
                </span>
              </div>
              <div className={`${styles.columnContentBody} noDefaultTreeFilter`}>


                <div className={styles.pickListItems}>
                  <div className={styles.allItemsControl}>
                    <span onClick={() => selectAllSource()}> Select All</span>
                    <span onClick={() => deSelectAllSource()}> Deselect All</span>
                  </div>
                  {getSourceItems()}
                </div>
              </div>
            </div>


          </div>
          <div className={styles.arrowControls}>
            <div>
              <Button icon="pi pi-angle-right" disabled={selectedSource.length === 0} className="p-button-outlined" onClick={() => { setShowSpinner(true); setTimeout(() => moveSourceToTarget(), 0) }} />
            </div>
            <div>
              <Button icon="pi pi-angle-left" disabled={selectedTarget.length === 0} className="p-button-outlined" onClick={() => { setShowSpinner(true); setTimeout(() => moveTargetToSource(), 0) }} />
            </div>
          </div>
          <div className={styles.columnContent}>
            <div>
              <h3>{targetHeader}</h3>
            </div>
            <div className={`p-input-icon-left ${searchTarget !== "" && "p-input-icon-right"}`}>
              <i className={`pi pi-search ${styles.searchIcon}`} />

              <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                value={searchTarget}
                onChange={(e) => setSearchTarget(e.target.value)} />
              {
                searchTarget &&
                <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchTarget("")} />
              }

            </div>
            <div className={`${styles.columnContentBody} noDefaultTreeFilter`}>


              <div className={styles.pickListItems}>
                <div className={styles.allItemsControl}>
                  <span onClick={() => selectAllTarget()}> Select All</span>
                  <span onClick={() => deSelectAllTarget()}> Deselect All</span>
                </div>
                {getTargetItems()}
              </div>
            </div>
          </div>
        </div>
      }
    </>)
}

export default PickListTree;
