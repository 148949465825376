import { FC, useContext } from 'react';
import styles from './FeatureUpgradeDialog.module.scss';
import { FeatureAssociation, FeatureDefinition, ProductInfo, SpecificationParameterDefinition } from '../../data/model/DataModels';
import { Dialog } from 'primereact/dialog';
import DisplayNewFeatureVersion from '../DisplayNewFeatureVersion/DisplayNewFeatureVersion';
import { SolutionContext } from '../../features/SolutionDetails/SolutionDetails';
import { Button } from 'primereact/button';
import { useGetFeatureByRefQuery } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../Spinner/SpinnerComponent';

interface FeatureUpgradeDialogProps {
  feature: FeatureAssociation;
  specificationParameters: SpecificationParameterDefinition[];
  isEditable: boolean;
  onHide: Function;
  upgradeToNewFeature: Function;
}

const FeatureUpgradeDialog: FC<FeatureUpgradeDialogProps> = ({ feature, specificationParameters, isEditable, onHide, upgradeToNewFeature }) => {
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;
  const { data: newActiveFeature, isLoading: isLoadingNewActiveFeature } = useGetFeatureByRefQuery(feature.featureRef);
  if (!solution || !newActiveFeature) {
    return <></>;
  }

  const upgradeFeatureVersion = (feature : FeatureDefinition) => {
    upgradeToNewFeature(feature, newActiveFeature);
    onHide();
  };

  const newCoreSPToBeImportedAsOptional = () => {
    return newActiveFeature?.specificationParameters.find((newFeatureSP) => {
      const specificationParameterList = specificationParameters.map((specParam) => { return { ...specParam.definition, featureServiceDefault: feature.serviceDefault } });
      const existingFeatureSP = specificationParameterList.find(existingSP => {
        return (existingSP.code === newFeatureSP.code)
          && (existingSP.featureRef === newFeatureSP.featureRef)
      }
      );
      return (existingFeatureSP === undefined)
        && (newFeatureSP.serviceDefault === "included")
        && (feature.serviceDefault == "not_included");
    });
  }

  const previewFooter = (
    <div>
      <Button label="Close" className={styles.previewBtn} onClick={() => onHide()} />
    </div>
  );
  
  const upgradeFooter = (featureAssociation : FeatureAssociation) => {

    const featureDefinition =
      {
      ...featureAssociation.definition,
      service: featureAssociation.service,
      associatedProducts: [{ code: solution.code }] as ProductInfo[],
      serviceDefault: featureAssociation.serviceDefault,
      specificationParameters: specificationParameters
         .map((specParam) => { return { ...specParam.definition, featureServiceDefault: featureAssociation.serviceDefault } })
      };
    return (
      <div>
        <Button label="Cancel" className={styles.previewBtn} onClick={() => onHide()} />
        <Button label="Upgrade" className={styles.upgradeBtn} onClick={() => upgradeFeatureVersion(featureDefinition)} />
      </div>
    )
  };

  return (
    <div className={styles.FeatureUpgradeDialog} data-testid="FeatureUpgradeDialog">
      {
        isLoadingNewActiveFeature && <SpinnerComponent/>
      }
      { feature !== undefined && isEditable ?
        <Dialog header="Upgrade feature ?" footer={upgradeFooter(feature)} visible={feature !== undefined} onHide={() => onHide()} style={{ width: '56vw' }}>
          {(feature.service === newActiveFeature?.service) && (feature.serviceDefault !== newActiveFeature?.serviceDefault) && (<div className={styles.optionalityChangeInfoMsg}>
            <i className="pi pi-info-circle" style={{ color: '#0b8ed2' }}></i>
            <label>
              The optionality of the feature changed in the definition. Please, be aware that it will not be reflected if upgraded.
            </label>
          </div>)}
          {
            (newCoreSPToBeImportedAsOptional() !== undefined) && (<div className={styles.optionalityChangeInfoMsg}>
              <i className="pi pi-info-circle" style={{ color: '#0b8ed2' }}></i>
              <label>
                There are new core specification parameters and they will be added as optional.
              </label>
            </div>)
          }
          {newActiveFeature && <DisplayNewFeatureVersion subHeader="You're about to upgrade this product with the latest version of the following feature:"
            feature={newActiveFeature}
            specificationParameters={newActiveFeature.specificationParameters}></DisplayNewFeatureVersion>}
        </Dialog>
        :
        <Dialog header="Preview new feature" footer={previewFooter} visible={feature !== undefined} onHide={() => onHide()} style={{ width: '56vw' }}>
          { newActiveFeature && 
            <DisplayNewFeatureVersion subHeader="Go to your draft product to upgrade with this new version"
            feature={newActiveFeature}
            specificationParameters={newActiveFeature.specificationParameters}></DisplayNewFeatureVersion>
          }
        </Dialog>
      }
    </div>
  )
};

export default FeatureUpgradeDialog;
