declare global {
    interface Window { _def_: any; _env_: any; }
}

const generateProp = (prop: string) => {
	return (window._env_ && (window._env_[prop] || (prop === "RBAC_ENV" && window._env_[prop] == "")) ? window._env_[prop]
		: window._def_ && window._def_[prop] ? window._def_[prop]
			: {});
};

const generatePropMap = (prop: string) => {
	const def = window._def_ && window._def_[prop];
	const env = window._env_ && window._env_[prop];
	return {
		...def,
		...env
	};
};

export const EnvConfig: {[key:string]: any} = {
	ENVIRONMENT: generateProp("ENVIRONMENT"),
	APP_NAME: generateProp("APP_NAME"),
	CLIENT_ID: generateProp("CLIENT_ID"),
	TENANT_ID: generateProp("TENANT_ID"),
	CATALOGUE_BASE_URL: generateProp("CATALOGUE_BASE_URL"),
	MS_GRAPH_BASE_URL: generateProp("MS_GRAPH_BASE_URL"),
	USERS_BASE_URL: generateProp("USERS_BASE_URL"),
	USERS_ROLE_RETRIEVAL_BASE_URL: generateProp("USERS_ROLE_RETRIEVAL_BASE_URL"),
	REFDATA_BASE_URL: generateProp("REFDATA_BASE_URL"),
	TOGGLES: generatePropMap("TOGGLES"),
	CONSUMER_KEY: generateProp("CONSUMER_KEY"),

	//RBAC
	RBAC_ENV: generateProp("RBAC_ENV"),
	SUBSCRIPTION_ID: generateProp("SUBSCRIPTION_ID"),
	SOLUTIONS_ID: generateProp("SOLUTIONS_ID"),
	RBAC_OWNER_ROLE_ID: generateProp("RBAC_OWNER_ROLE_ID"),
  DEPOT: generateProp("DEPOT"),
  LAST_MILE: generateProp("LAST_MILE"),
  LANDSIDE_TRANSPORT: generateProp("LANDSIDE_TRANSPORT"),
  LEAD_LOGISTICS: generateProp("LEAD_LOGISTICS"),
  OCEAN_CONTRACT: generateProp("OCEAN_CONTRACT"),
  OCEAN_FREESALE: generateProp("OCEAN_FREESALE"),
  OCEAN_VAS: generateProp("OCEAN_VAS"),
	OCEAN_SPECIAL_CARGO: generateProp("OCEAN_SPECIAL_CARGO"),
  TRADELENS: generateProp("TRADELENS"),
  MAERSK_PROJECT_LOGISTICS: generateProp("MAERSK_PROJECT_LOGISTICS"),
  AIR: generateProp("AIR"),
  COLD_CHAIN_LOGISTICS: generateProp("COLD_CHAIN_LOGISTICS"),
  CUSTOMS: generateProp("CUSTOMS"),
	LCL: generateProp("LCL"),
	COLD_STORAGE: generateProp("COLD_STORAGE"),
	WAREHOUSING_FULFILMENT: generateProp("WAREHOUSING_FULFILMENT"),
	INSURANCE: generateProp("INSURANCE"),
	CARGO_RISK_MANAGEMENT: generateProp("CARGO_RISK_MANAGEMENT"),
	MIDDLE_MILE: generateProp("MIDDLE_MILE"),
	FEEDBACK_FORM_ID: generateProp("FEEDBACK_FORM_ID"),

	//MS Clarity
	MS_CLARITY_ID: generateProp("MS_CLARITY_ID"),
};
