import React, { FC, useCallback, useState } from 'react';
import styles from './ProductCompatibilities.module.scss';
import { Compatibility, CompatibilityRule, Product, ProductListing, ProductType } from '../../data/model/DataModels';
import { Button } from 'primereact/button';
import ProductSelectionDialog from '../../components/ProductSelectionDialog/ProductSelectionDialog';
import { sectionType } from '../../data/model/SharedDataInterfaces';
import ProductCardMedium from '../ProductCardMedium/ProductCardMedium';
import { ReactComponent as NonCompatibleBannerIcon } from '../../assets/icons/maersk/exclamation-octagon.svg';
import AddOnRules from '../AddOnRules/AddOnRules';
import { ReactComponent as InvalidRuleIcon } from '../../assets/icons/maersk/mi-exclamation-triangle-20px.svg';

export interface ProductCompatibilitiesProps {
  product: Product,
  editMode?: boolean,
  updateCompatibilities?: Function
}

const ProductCompatibilities: FC<ProductCompatibilitiesProps> = ({ product, editMode = false, updateCompatibilities }) => {

  const [showProductSelection, setShowProductSelection] = useState(false);
  
  const addProducts = (productList: ProductListing[]) => {
    if (product.compatibility) {
      updateCompatibilities && updateCompatibilities(sectionType.COMPATIBILITIES, { ...product.compatibility, compatibleItems: [...product.compatibility.compatibleItems, ...productList] } as Compatibility);
    } else {
      updateCompatibilities && updateCompatibilities(sectionType.COMPATIBILITIES, { compatibilityRules: [], compatibleItems: productList } as Compatibility);
    }
    setShowProductSelection(false);
  }

  const isAnyProductIncompatible = () => {
    const incompatibleProduct = product.compatibility?.compatibleItems.find(compatibility => compatibility.isCompatible === false);

    if (incompatibleProduct) {
      const incompatibleProductNames = product.compatibility?.compatibleItems.filter(compatability => compatability.isCompatible === false).map(incompatibleProductName => incompatibleProductName.name);
      return {
        incompatible: true,
        inCompatibleproducts: incompatibleProductNames
      }
    }

  }

  const deleteProductCompatibility = (productCode: string) => {
    updateCompatibilities && updateCompatibilities(sectionType.COMPATIBILITIES, {...product.compatibility , compatibleItems : product.compatibility?.compatibleItems.filter(productCompatibility => productCompatibility.code !== productCode)});
  }

  const saveCompatibilityRules = useCallback(
    (compatibilityRules: CompatibilityRule[]) => {
      if (product.compatibility) {
        updateCompatibilities && updateCompatibilities(sectionType.COMPATIBILITIES, { ...product.compatibility, compatibilityRules: compatibilityRules } as Compatibility);
      } else {
        updateCompatibilities && updateCompatibilities(sectionType.COMPATIBILITIES, { compatibilityRules: compatibilityRules, compatibleItems: [] } as Compatibility);
      }
      setShowProductSelection(false);
    },
    [product.compatibility]
  )

  const getInvalidRules = () => {
    const invalidCrossScopeMsg = "The rule can not be realized because the dimensions are borrowed from different scopes";
    const invalidRules : string[] = [];
    product.compatibility?.compatibilityRules.forEach(compatibilityRule => {
      if(compatibilityRule.rules.length > 0){
        compatibilityRule.rules.forEach(rule => {
          if(rule.validationMsg) {
            if(rule.validationMsg === invalidCrossScopeMsg){
              invalidRules.push(rule.name + " : " + invalidCrossScopeMsg);
            }
          }
          else {
            if(rule?.criteriaValidationResults && rule?.criteriaValidationResults.length > 0) {
              invalidRules.push(rule.name);
            }
          }
        })
      }
    })
    return invalidRules;
  }

  return (
    <div className={styles.ProductCompatibilities} data-testid="ProductCompatibilities">
      { getInvalidRules().length > 0 &&
        <div className={styles.ruleWarningSection}>
          <div className={styles.ruleWarning}>
            <div className={styles.invalidIcon}><InvalidRuleIcon /></div>
            <div>Some rules may not be relevant anymore due to changes in this add-on applicabilities</div>
          </div>
          <ul className={styles.invalidRules}>
            { getInvalidRules().map((invalidRule, index) => <li key={index}>{invalidRule}</li>)}
          </ul>
        </div>
      }
      {
        (product.productType === ProductType.ADDON) &&
        <>
          <AddOnRules editMode={editMode} compatibilityRules={product.compatibility?.compatibilityRules}
            saveCompatibilityRules={saveCompatibilityRules} defaultCompatibilityRuleType={product.addOnRelationDefault}
            productApplicability={product.applicability} productFamily={product.family} productHeaders={product.headersInfo}/>
          <header className={styles.header}>
            <h2>Can be sold with: {product.compatibility?.compatibleItems.length} products</h2>
            {
              editMode &&
              <Button label='Link product' className='p-button-outlined p-button-secondary' icon="pi pi-plus " onClick={() => setShowProductSelection(true)} />
            }
          </header>
        </>
      }

      {
        product.productType === ProductType.PRODUCT && <h2>Can be complemented with</h2>
      }
      {
        isAnyProductIncompatible()?.incompatible &&
        <div className={`${styles.ribbonBar} ${styles.productDraftVersionRibbon}`}>
          <div className={styles.ribbonInfo}>
            <div className={styles.ribbonInfoIcon}><NonCompatibleBannerIcon /></div>
            {product.productType === ProductType.PRODUCT ? <div>Some add-ons don’t have common applicabilities with this product</div> : <div>Some products don’t have common applicabilities with this add-on</div>}
          </div>
          <div className={styles.incompatibleProducts}>
            {isAnyProductIncompatible()?.inCompatibleproducts?.map(incompatibleProduct => <div key={incompatibleProduct} className={styles.listInCompatibleProducts}><div className={styles.incompatibleProductlistItem}>.</div> <div>{incompatibleProduct}</div></div>)}
          </div>
        </div>
      }
      <section className={styles.main}>
        {
          product.compatibility?.compatibleItems.map(compatibleProduct => <ProductCardMedium key={compatibleProduct.code + "-" + compatibleProduct.version} product={compatibleProduct} deleteProduct={() => deleteProductCompatibility(compatibleProduct.code)} editMode={editMode} />)
        }
      </section>
      {
        showProductSelection && <ProductSelectionDialog existingProducts={product.compatibility?.compatibleItems} onHide={() => setShowProductSelection(false)} saveProductSelection={(productList: ProductListing[]) => addProducts(productList)} productType={ProductType.PRODUCT} />
      }
    </div>
  );
}

export default ProductCompatibilities;
