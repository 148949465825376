import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EnvConfig } from "../../EnvConfig";
import { getGraphToken } from "../../authorisationService";
import { User } from "../model/DataModels";

interface UserSearchResponse {
	value: User[];
}


export const GraphApi = createApi({
	reducerPath: "GraphApi",
	baseQuery: fetchBaseQuery({
		baseUrl: EnvConfig.MS_GRAPH_BASE_URL + "/users",
		prepareHeaders: (headers) => {
			return getGraphToken().then(token => {
				headers.set("Authorization", `Bearer ${token}`);
				return headers;
			});
		},
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getUsersBySearch: builder.query<User[], string>({
			query: (searchParam: string) => `?$filter=startswith(displayName,'${searchParam}') OR startswith(mail,'${searchParam}')`,
			transformResponse: (response:UserSearchResponse):User[]=>{
				return response.value;
			}
		})

	}),
});


export const {
	useGetUsersBySearchQuery
} = GraphApi;

