import React, { FC, useContext, useState } from 'react';
import styles from './SolutionComponentAssociation.module.scss';
import { ComponentAssociation, ComponentAssociationRequest, ComponentDefinition, FeatureAssociationRequest, FeatureDefinition, ProductListing, SolutionComponentOrigin, SolutionDefinition, SolutionDefnitionRequest, SpecificationParameterAssociationRequest, SpecificationParameterDefinition } from '../../data/model/DataModels';
import { Button } from 'primereact/button';
import DisplayBigCards, { DisplayCardElement } from '../../components/DisplayBigCards/DisplayBigCards';
import { filterType } from '../../components/FilterPanel/FilterPanel';
import { SolutionContext, constructBaseSolutionRequest, solutionTabs } from '../SolutionDetails/SolutionDetails';
import { useNavigate } from 'react-router-dom';
import SolutionAddComponentDialog from '../SolutionAddComponentDialog/SolutionAddComponentDialog';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import ComponentReorderView from '../ProductView/ProductComponents/ComponentReorderView/ComponentReorderView';
import AddSolutionComponentDialog from '../../components/AddSolutionComponentDialog/AddSolutionComponentDialog';
import SolutionAddComponentFromProductsDialog from '../SolutionAddComponentFromProductsDialog/SolutionAddComponentFromProductsDialog.lazy';

export interface SolutionComponentAssociationProps {
  isEditable?: boolean
}

const SolutionComponentAssociation: FC<SolutionComponentAssociationProps> = ({ isEditable = false }) => {

  const navigate = useNavigate();
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;
  const [reorderEnabled, setReorderEnabled] = useState(false);
  const [reorderedList, setReorderedList] = useState([] as DisplayCardElement[]);
  const [componentOrigin, setComponentOrigin] = useState(SolutionComponentOrigin.NONE);
  const toaster = useContext(ToasterContext);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();

  if (!solution) {
    return <></>
  }


  const mapComponentToElements = (components: ComponentAssociation[]): DisplayCardElement[] => {
    return components.map(component => {
      const cardElement: DisplayCardElement = {
        name: component.definition.name,
        code: component.componentRef,
        description: component.definition.description,
        descriptionRichText: component.definition.descriptionRichText,
        tooltipElements: solution?.features.filter(feature => feature.componentRef === component.componentRef).map(feature => feature?.definition ? feature?.definition.name : ""),
        latestVersionFeatures: component.upgradedFeatureCode?.map(featureCode => featureCode.code)
      };
      return cardElement;
    });
  }

  const selectComponent = (element: DisplayCardElement) => {
    const params = new URLSearchParams();
    params.append(filterType.Component.toString(), element.code);
    navigate(solutionTabs[4].hash + "?" + params.toString());
  };

  const showEnhancedComponentAssociationPage = () => {
    if (reorderEnabled) {
      return <ComponentReorderView elementList={mapComponentToElements(solution.components)} saveOrdering={(components: DisplayCardElement[]) => setReorderedList(components)} />
    }
    return <DisplayBigCards elementList={mapComponentToElements(solution.components)} deleteAction={(componentRef: string) => deleteComponent(componentRef)} dialogView={false} editMode={isEditable} isAuthorisedToEdit={isEditable} action={(element: DisplayCardElement) => selectComponent(element)} />
  }

  const deleteComponent = (componentRef: string) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: solution.components.filter(component => component.componentRef !== componentRef),
      features: solution.features.filter(feature => feature.componentRef !== componentRef),
      specificationParameters: solution.specificationParameters.filter(specParam => specParam.componentRef !== componentRef)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            components: response.components,
            features: response.features,
            specificationParameters: response.specificationParameters
          }
        );
        toaster.showToast('success', 'Successfully deleted component');
        setComponentOrigin(SolutionComponentOrigin.NONE);
      },
      () => {
        toaster.showToast('error', 'Failed to delete component');
      }
    );
  }

  const addComponentsAndFeaturesToSolution = (components: ComponentDefinition[], features: FeatureDefinition[]) => {
    if (!solution) {
      return;
    }
    const componentAssociations: ComponentAssociationRequest[] = [
      ...components.map((component) => {
        return {
          componentRef: component.code
        } as ComponentAssociationRequest
      }),
      ...solution.components
    ];

    const featureAssociations: FeatureAssociationRequest[] = [
      ...solution.features,
      ...features.map(feature => {
        return {
          featureRef: feature.code,
          definition: feature,
          componentRef: feature.componentRef,
          featureVersion: feature.version,
          serviceDefault: feature.serviceDefault,
          service: feature.service
        } as FeatureAssociationRequest;
      })
    ];

    const allSpecificationParameters = features.reduce<SpecificationParameterDefinition[]>((accumulator, current) => [...accumulator, ...current.specificationParameters], [] as SpecificationParameterDefinition[]);

    const specificationParameterAssociations: SpecificationParameterAssociationRequest[] = [
      ...solution.specificationParameters,
      ...allSpecificationParameters.map(specificationParameter => {
        return {
          featureRef: specificationParameter.featureRef,
          featureVersion: specificationParameter.featureVersion,
          componentRef: specificationParameter.componentRef,
          specificationParamRef: specificationParameter.code,
          configuration: specificationParameter.configuration,
          configurableAtContracting: specificationParameter.configurableAtContracting,
          configurableAtBooking: specificationParameter.configurableAtBooking,
          serviceDefault: specificationParameter.serviceDefault
        } as SpecificationParameterAssociationRequest;
      })
    ]

    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: componentAssociations,
      features: featureAssociations,
      specificationParameters: specificationParameterAssociations
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            components: response.components,
            features: response.features,
            specificationParameters: response.specificationParameters
          }
        );
        toaster.showToast('success', 'Successfully added components');
        setComponentOrigin(SolutionComponentOrigin.NONE);
      },
      (error) => {
        let errorMsg: string = error.data.message;
        errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to add components';
        toaster.showToast('error', errorMsg);
      }
    );
  }

  const reOrderComponentsAccordingToList = (orderedList: DisplayCardElement[]): ComponentAssociation[] => {
    let orderedComponentList = [] as ComponentAssociation[];
    orderedList.forEach(listItem => {
      const component = solution.components.find(component => component.componentRef === listItem.code);
      if (component) {
        orderedComponentList.push(component);
      }
    });
    return orderedComponentList;
  }

  const saveOrdering = (elements: DisplayCardElement[]) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: reOrderComponentsAccordingToList(elements)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            components: response.components
          }
        );
        toaster.showToast('success', 'Successfully reordered components');
        setReorderEnabled(false)
      },
      () => {
        toaster.showToast('error', 'Failed to reorder components');
      }
    );
  }

  const showComponentSelection = () => {
    if (componentOrigin === SolutionComponentOrigin.COMPONENT_FROM_PRODUCT) {
      return <SolutionAddComponentFromProductsDialog onHide={() => setComponentOrigin(SolutionComponentOrigin.NONE)}
        existingComponents={solution.components}
        saveProps={{save:addComponentsAndFeaturesToSolution,isLoadingSave:isLoadingUpdateSolution}}
         />
    } else if (componentOrigin === SolutionComponentOrigin.COMPONENT_LIBRARY) {
      return <SolutionAddComponentDialog onHide={() => setComponentOrigin(SolutionComponentOrigin.NONE)}
        existingComponents={solution.components}
        saveProps={{save:addComponentsAndFeaturesToSolution,isLoadingSave:isLoadingUpdateSolution}}
         />
    }
    return <></>
  }

  return (
    <div className={styles.SolutionComponentAssociation} data-testid="SolutionComponentAssociation">
      {isLoadingUpdateSolution && <SpinnerComponent />}
      <div className={styles.header} data-testid="SolutionComponentHeader">
        <div>
          {
            solution.components.length > 1 ?
              <h2 data-testid="multipleComponents">{solution.components.length} Components</h2>
              :
              <h2 data-testid="singleComponent">{solution.components.length} Component</h2>
          }
        </div>
        {
          isEditable &&
          <>
            {
              reorderEnabled ?
                <>
                  <div className={styles.leftAlignedButtons}>
                    <Button label="Cancel" data-testid="cancelReorderingButton" icon="pi pi-times" className="p-button-outlined p-button-secondary"
                      onClick={() => setReorderEnabled(false)}
                    />
                    <Button label="Done reordering" data-testid="doneReorderingButton" icon="pi pi-check"
                      onClick={() => saveOrdering(reorderedList)}
                    />
                  </div>
                </>
                :
                <>
                  <Button data-testid='addComponentButton' label="Add Component" icon="pi pi-plus" className={`p-button-outlined p-button-secondary ${styles.leftAlignedButtons}`}
                    onClick={() => setComponentOrigin(SolutionComponentOrigin.GENERAL)}
                  />
                  <Button data-testid='reOrderButton' label="Reorder" icon="pi pi-bars"
                    onClick={() => setReorderEnabled(true)}
                  />
                </>
            }

          </>
        }
      </div>
      {
        componentOrigin === SolutionComponentOrigin.GENERAL &&
        <AddSolutionComponentDialog setDialogVisibleFn={(componentType: SolutionComponentOrigin) => setComponentOrigin(componentType)} />
      }
      {showComponentSelection()}
      {
        solution.components && showEnhancedComponentAssociationPage()
      }
    </div>
  );
}
export default SolutionComponentAssociation;
