import React, { FC, useRef, useState } from 'react';
import styles from './ApproversDialog.module.scss';
import { getResourceNameFromProductCodeAndVersion, mapAnchorUserToGraphUser, sortApproverList } from '../utils/userUtils';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { Dialog } from 'primereact/dialog';
import ViewOrAddUsersDialog from '../../features/ViewOrAddUsersDialog/ViewOrAddUsersDialog';
import { Button } from 'primereact/button';
import { ROLES, User, approverDesignationEmailMap } from '../../data/model/DataModels';
import { useGetAllUsersForProductQuery, ProductAndResources, useGetAllRolesForProductQuery } from '../../data/api/CatalogueApi';

export interface ApproversDialogProps {
  header?: string,
  name: string;
  productCode: string;
  productVersion: number;
  rbacResourceCode: string;
  rbacResourceChildCode: string;
  roleName: ROLES;
  showParentDialog: boolean;
  setShowParentDialog: Function;
  sendForApproval: Function;
  postAssignRoleMutation: Function;
}

const ApproversDialog: FC<ApproversDialogProps> = ({ header = '', name, productCode, productVersion, roleName, rbacResourceCode, rbacResourceChildCode, showParentDialog, setShowParentDialog, sendForApproval, postAssignRoleMutation }) => {

  const [showManageApproverDialog, setShowManageApproverDialog] = useState(false);
  const areRbacResourcesReady = (rbacResourceCode && rbacResourceChildCode && rbacResourceCode != "" && rbacResourceChildCode != '' && rbacResourceCode != "error" && rbacResourceChildCode != "error");
  const { data: allUsers, isLoading: isLoadingUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(productCode, productVersion), resourceCode: rbacResourceCode, resourceChildCode: rbacResourceChildCode } as ProductAndResources, { skip: !areRbacResourcesReady });
  const { data: allRoles, isLoading: isLoadingRoles } = useGetAllRolesForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(productCode, productVersion), resourceCode: rbacResourceCode, resourceChildCode: rbacResourceChildCode } as ProductAndResources, { skip: !areRbacResourcesReady });

  const getRoleId = (roleName: ROLES): string => {
    if (allRoles) {
      return allRoles[roleName].roles_id;
    }
    return "";
  }

  const closeChildAndParentDialog = () => {
    setShowManageApproverDialog(false);
    setShowParentDialog(false);
  }

  const sendForApprovalFooter = (
    <div>
      <Button label="No, I want to edit more" className={"p-button-outlined  p-button-secondary"} onClick={() => setShowParentDialog(false)} />
      <Button label="Send for Approval" className={styles.btnApproval} onClick={() => {setShowParentDialog(false); sendForApproval()}} />
    </div>
  );

  const getApproverDesignation = (email: string) => {
    return <div className={styles.approverDesignation}>{approverDesignationEmailMap[email]}</div>
  }

  const sortApprovers = (users : User[]) => {
    const sortApprovers = sortApproverList(users)
    return sortApprovers ? sortApprovers : [];
  }

  return (
    <div className={styles.approversDialog} data-testid="ApproversDialog">
      <Dialog header={header} footer={sendForApprovalFooter} visible={showParentDialog} onHide={() => setShowParentDialog(false)}>
        {
          (isLoadingUsers || isLoadingRoles) && <SpinnerComponent />
        }
        <div className={styles.dialogContent}>
          <div className={styles.subHeader}>{name} has {allUsers?.approvers?.length} Approvers</div>
          <div className={styles.manageApproversLink}><span onClick={() => setShowManageApproverDialog(true)}>Click here to manage approvers.</span></div>
          <div className={styles.approverList}>
            {sortApprovers(allUsers ? allUsers.approvers?.map(approver => mapAnchorUserToGraphUser(approver)) : []).map(approver =>
              <div className={`${styles.approversInfo} ${Object.keys(approverDesignationEmailMap).includes(approver.mail) ? styles.defaultApproversInfo : ''}`} key={approver.id}>
                <div className={styles.approverNameEmail}>
                  <div className={styles.approverName}>{approver.displayName}</div>
                  <div className={styles.approverEmail}>{approver.mail}</div>
                </div>
                {Object.keys(approverDesignationEmailMap).includes(approver.mail) && getApproverDesignation(approver.mail)}
              </div>
            )
            }
          </div>
          {
            showManageApproverDialog &&
              <ViewOrAddUsersDialog productCode={productCode}
                                    roleName={roleName}
                                    roleId={getRoleId(roleName)}
                                    resourceCode={rbacResourceCode}
                                    resourceChildCode={rbacResourceChildCode}
                                    onHide={() => closeChildAndParentDialog()}
                                    users={sortApprovers(allUsers ? allUsers.approvers?.map(approver => mapAnchorUserToGraphUser(approver)) : [])}
                                    header={"Manage Approvers"}
                                    subHeader={`Here you can add or remove approvers to product  ${name}`}
                                    editMode={showManageApproverDialog}
                                    postAssignRoleMutation={postAssignRoleMutation}
                                    />
          }
        </div>
      </Dialog>
    </div>
  )
};

export default ApproversDialog;
