
export interface AttributeCollection {
    [key: string]: boolean | number | string | boolean[] | number[] | string[];
}


export enum sectionType {
	HEADER, SALES_MARKETING, APPLICABILITY, COMPONENT,
	PRODUCT_HEADERS, COMPATIBILITIES
}
