import React, {FC, useState} from 'react';
import styles from './SideBarNavigation.module.scss';
import Logo from "../../assets/images/Maersk_logoonly.svg";
import logoText from "../../assets/images/Maersk_logo_text.svg";
import {MenuItem} from "../../features/AppLayoutView/AppLayoutView";


export interface SideBarNavigationProps {
  menuItems: MenuItem[];
  onItemSelection: Function;
}

const SideBarNavigation: FC<SideBarNavigationProps> = ({ menuItems, onItemSelection }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.SideBarNavigation} ${isOpen && styles.isOpen}`} data-testid="SideBarNavigation">

      <div className={`${styles.sideBarContent} ${!isOpen ? styles.sideBarCollapsed: styles.sideBarOpen}`}>

        <div className={styles.sideBarHeader}>

          <div className={styles.sideBarLogoIcon}>
            <a href='/'>
              <img alt='Maersk Logo' className="app-logo" src={Logo} />
            </a>
          </div>
          {
            isOpen &&
            <div className={styles.sideBarLogoText}>
              <a href='/'>
                <img alt='Maersk Logo' style={{ height: "40px" }} src={logoText} />
              </a>
            </div>
          }
        </div>
        {
          menuItems.map(item =>
            <div key={item.path} className={`${styles.activeSelectionMask} ${item.isSelected ? styles.activeSelection : ""}`}>
              <div className={styles.menuItem} onClick={() => { setIsOpen(false); onItemSelection(item) }}>

                <div className={`${styles.menuItemLogo}`}>
                  <i className={item.icon} />
                  {!isOpen && <div className={styles.iconText}>{item.text}</div>}

                </div>
                {isOpen && <div className={styles.menuItemText}>{item.text}</div>}

              </div>
            </div>

          )
        }

        <div className={`${styles.menuItem} ${styles.menuOpenArrow}`}>

          <div className={styles.menuItemLogo} onClick={() => setIsOpen(!isOpen)}>
            <i className={`${'pi pi-arrow-right'} ${isOpen && styles.menuCloseArrow}`} />
          </div>
        </div>





      </div>


    </div>
  )
};

export default SideBarNavigation;
