import React, { FC, useContext, useState } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Product, ProductListing, ProductType, Status } from "../../data/model/DataModels";
import styles from "./ProductSelectionDialog.module.scss";
import { useGetFamiliesQuery, useGetProductListByStatusQuery } from "../../data/api/CatalogueApi";
import SpinnerComponent from "../Spinner/SpinnerComponent";
import { ToasterContext } from "../../features/AppLayoutView/AppLayoutView";
import ProductCardMinimal from '../../features/ProductCardMinimal/ProductCardMinimal';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';



interface ProductSelectionDialogProps {
	onHide: Function;
	saveProductSelection: Function;
	existingProducts?: ProductListing[];
	isLoadingUpdateSelection?: boolean;
	productType?: ProductType;
	searchBy?: ("name" | "family")[];
}

const ProductSelectionDialog: FC<ProductSelectionDialogProps> = ({ onHide, saveProductSelection, existingProducts = [], isLoadingUpdateSelection, productType, searchBy = ["name"] }) => {
	const toaster = useContext(ToasterContext);
	const { data: products = [], error: errorProducts, isLoading: isLoadingProducts } = useGetProductListByStatusQuery(Status.ACTIVE);
	const { data: families = [], error: errorfamilies, isLoading: isLoadingfamilies } = useGetFamiliesQuery();

	const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
	const selectedProductCodes = selectedProducts.map(product => product.code);
	const [searchByName, setSearchByName] = useState("");
	const [searchByFamily, setSearchByFamily] = useState("");

	const mapProductListingToProduct = (productListing: ProductListing) => {
		return {
			code: productListing.code,
			family: productListing.family,
			name: productListing.name,
			productType: productListing.productType,
			rbacResourceCode: productListing.rbacResourceCode,
			version: productListing.version,
			header: {
				description: productListing.description
			},
			status: productListing.status
		} as Product;
	}

	let filteredProducts = [...products].map(product => mapProductListingToProduct(product))
		.sort((a, b) => a.name.localeCompare(b.name));

	if (productType) {
		filteredProducts = filteredProducts.filter(product => productType == product.productType);
	}

	if (existingProducts.length > 0) {
		filteredProducts = filteredProducts.filter(product => !existingProducts.some(associatedProduct => associatedProduct.code === product.code));
	}

	if (searchByName != "") {
		filteredProducts = filteredProducts.filter(product => product.name.toLowerCase().includes(searchByName.toLowerCase()));
	}

	if (searchByFamily != "") {
		filteredProducts = filteredProducts.filter(product => product.family?.code == searchByFamily);
	}

	if (errorProducts) {
		toaster.showToast("error", 'Failed to load Active Products');
	}

	if (errorfamilies) {
		toaster.showToast("error", 'Failed to fetch families');
	}


	const onCreate = () => {
		const activeProductsToSave = selectedProducts.map((product) => ({
			code: product.code,
			version: product.version,
			rbacResourceCode: product.rbacResourceCode,
			name: product.name,
			description: product.header.description,
			productType: product.productType,
			status: product.status,
			family: product.family,
		})) as ProductListing[];

		saveProductSelection(activeProductsToSave);
	}

	const footer = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => { onHide(false); }} />
			<Button label={"Add"} icon="pi pi-check" onClick={() => onCreate()} disabled={selectedProducts.length === 0} />
		</div>
	);

	const productClickAction = (productClicked: Product) => {
		if (selectedProductCodes?.includes(productClicked.code)) {
			setSelectedProducts(selectedProducts.filter(product => product.code !== productClicked.code));
		} else {
			setSelectedProducts(selectedProducts.concat(productClicked));
		}
	}

    const selectAllFilteredProducts = () => {
        const productsToAdd = filteredProducts.filter(product => !selectedProductCodes?.includes(product.code));
        setSelectedProducts([...selectedProducts, ...productsToAdd])
    }
    const deselectAllFilteredProducts = () => {
        const filteredProductCodes = filteredProducts.map(product => product.code);
        const productsAfterRemoval = selectedProducts.filter(product => !filteredProductCodes?.includes(product.code));
        setSelectedProducts(productsAfterRemoval);
    }

	return (
		<Dialog footer={footer} header="Select Products To Add" visible={true} style={{ width: '90vw', height: '85vh' }} onHide={() => onHide(false)}>
			<div className={styles.ProductSelectionDialog} data-testid="ProductSelectionDialog">
				{
					(isLoadingProducts || isLoadingfamilies || isLoadingUpdateSelection) && <SpinnerComponent />
				}
				<section className={styles.search}>
					<div className={`p-input-icon-left`}>
						<i className="pi pi-search" />
						<InputText
							className={styles.inputField}
							onChange={(e) => setSearchByName(e.target.value)}
							placeholder={`Search by name`}
							data-testid="searchComponentFilter"
							value={searchByName}
						/>
					</div>
					<Dropdown value={searchByFamily} onChange={(e) => setSearchByFamily(e.value)} options={[{ name: "", code: "" }, ...families]} optionLabel="name" optionValue='code'
						placeholder="Select a family" />
				</section>
				<section className={styles.bulkSelectionActions}>
					<span onClick={() => selectAllFilteredProducts()}>Select all</span>
					<span onClick={() => deselectAllFilteredProducts()}>Deselect all</span>
				</section>
				<section className={styles.productsView}>
					{
						filteredProducts.map(product => <ProductCardMinimal key={product.code} product={product} isSelected={selectedProductCodes.includes(product.code)} onProductClick={(selectedProduct: Product) => productClickAction(selectedProduct)} />)
					}
				</section>
			</div>

		</Dialog>
	);
}


export default ProductSelectionDialog;