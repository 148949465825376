import { AllRoleData, AllUserData, AnchorUserAssignment, ApprovalInfo, ApproverStatus, AssignUsersRequest, AssignUsersResponse, FeatureNameAndVersions, Header, ResourceApproversInfo, SortFeaturesRequest, SortSpecificationParameterRequest, HeaderTypes, SolutionDefinition, SolutionCreation, SolutionDefnitionRequest, ProductListing, SolutionProductUpgradeDTO, ResourceNameAndVersions, Family, SolutionProductRelationTypeChangeDTO } from './../model/DataModels';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	Activity,
	ComponentDefinition,
	DimensionDefinition,
	FamilyDimensionAssociation,
	FeatureDefinition,
	Product,
	ProductInfo,
	SelectedDimensions,
	SpecificationParameterDefinition
} from "../model/DataModels";
import { EnvConfig } from "../../EnvConfig";
import { getToken } from "../../authorisationService";


export interface ProductAndResources {
	productCode: string;
	resourceCode: string;
	resourceChildCode: string;
}

export interface FeatureAndResources {
	featureCode: string;
	resourceCode: string;
	resourceChildCode: string;
}

export interface ActivitiesQuery {
	type: "products" | "components" | "specificationParameters" | "solutions" | "all"
	code?: string
	version?: number
}
export interface ProductQuery {
	code: string
	version?: string,
}
export interface SolutionQuery {
	code: string
	version: string,
	uuid: string
}
export interface FeatureVersionQuery {
	featureRef: string
	version?: string
}

export const CatalogueApi = createApi({
	reducerPath: "CatalogueApi",
	baseQuery: fetchBaseQuery({
		baseUrl: EnvConfig.CATALOGUE_BASE_URL + "/api4ui",
		prepareHeaders: (headers) => {
			return getToken().then(token => {
				headers.set("Authorization", `Bearer ${token}`);
				headers.set("Consumer-Key", EnvConfig.CONSUMER_KEY);
				return headers;
			});
		},
	}),
	tagTypes: [
		"ProductList", "ProductDraft", "SingleProduct", "SingleProductVersion",
		"ComponentList", "ComponentDefinition",
		"FeatureList", "FeatureDefinition", "FeaturesByComponentRef",
		"SpecificationParameterDefinition", "DefaultDimension", "FeatureVersion", "ComponentAssociationList",
		"ProductActivity", "AllActivities",
		"AllUsers",
		"AllBpos",
		"AllRoles",
		"AllGo",
		// Tags for Solutions
		"SolutionList",
		"SolutionVersion",
		"SingleSolution",
		"AllSolutionOwners",
		"ActiveProductList",
		"ProductListByStatus",
		"ProductInfoListByStatus",
		"AllProductVersions"
	],
	endpoints: (builder) => ({

		// Families
		getFamilies: builder.query<Family[], void>({
			query: () => `families`,
			transformResponse: (response: Family[]) => {
				//sorts family by name
				return response.sort((a, b) => a.name.localeCompare(b.name));
			},
			providesTags: ["ProductList"]
		}),
		// getProducts: builder.query<Product[], void>({
		// 	query: () => "products",

		// }),
		// getProductsByFamily: builder.query<Product[], string>({
		// 	query: (familyCode) => `products?family=${familyCode}`,
		// }),

		// Products
		getProductByCode: builder.query<Product, string>({
			query: (productCode) => `products/${productCode}`,
			providesTags: ["SingleProduct"]
		}),

		retireProduct: builder.mutation<void, string>({
			query: (productCode) => ({
				url: `products/${productCode}/retire`,
				method: "PUT"
			}),
			invalidatesTags: ["ProductList", "SingleProduct", "SingleProductVersion", "FeatureDefinition", "SingleSolution", "ProductListByStatus", "AllActivities", "ProductActivity", "AllProductVersions"]
		}),

		getProductByCodeAndVersion: builder.query<Product, ProductQuery>({
			query: ({ code, version }) => version !== undefined ? `products/${code}/version/${Number(version)}` : `products/${code}`,
			providesTags: ["SingleProductVersion"]
		}),
		getProductByCodeAndVersionForGettingResourceCode: builder.query<Product, ProductQuery>({
			query: ({ code, version }) => version !== undefined ? `products/${code}/version/${Number(version)}` : `products/${code}`,
		}),
		getProductByCodeAsDraft: builder.query<Product, string>({
			query: (productCode) => `products/${productCode}/draft`,
			keepUnusedDataFor: 1,
			providesTags: ["ProductDraft"]
		}),
		postProduct: builder.mutation<Product, Product>({
			query: (product) => ({
				url: `/products/update/${product.code}/version/${product.version}`,
				method: "POST",
				body: product
			}),
			invalidatesTags: ["ProductDraft", "ProductList", "ComponentDefinition", "SpecificationParameterDefinition", "SingleProductVersion", "ComponentDefinition", "FeatureList", "ComponentList", "ComponentAssociationList", "AllProductVersions"]
		}),
		postDraftProduct: builder.mutation<Product, String>({
			query: (productCode) => ({
				url: `products/${productCode}/draft`,
				method: "POST"
			}),
			invalidatesTags: ["ProductDraft", "ProductList", "ComponentDefinition", "SpecificationParameterDefinition", "SingleProductVersion", "ComponentDefinition", "FeatureList", "ComponentList", "ComponentAssociationList", "AllProductVersions"]
		}),
		deleteProductDraft: builder.mutation<void, string>({
			query: (productCode) => ({
				url: `/products/${productCode}/draft`,
				method: "DELETE"
			}),
			invalidatesTags: ["ProductList", "AllUsers", "AllProductVersions"],
		}),
		createNewProduct: builder.mutation<void, ProductInfo>({
			query: (product) => ({
				url: `/products/`,
				method: "POST",
				body: product
			}),
			invalidatesTags: ["ProductDraft", "ProductList", "ProductInfoListByStatus"],
		}),
		activateDraftProduct: builder.mutation<void, string>({
			query: (productCode) => ({
				url: `/products/activate/${productCode}`,
				method: "PUT"
			}),
			invalidatesTags: ["ProductList", "SingleProduct", "AllProductVersions"],
		}),
		getAllPopUpValues: builder.query<Header[], HeaderTypes>({
			query: (headerType: HeaderTypes) => `headers/${headerType}`,
		}),
		getAllProductVersions: builder.query<ResourceNameAndVersions, string>({
			query: (productCode) => `/products/${productCode}/versions`,
			providesTags: ['AllProductVersions']
		}),

		// Activities
		getEntityActivities: builder.query<Activity[], ActivitiesQuery>({
			query: ({ type, code }) => `activities/${type}/${code}`,
			providesTags: ["ProductDraft", "ProductActivity"] // This makes no sense
		}),
		getAllActivities: builder.query<Activity[], ActivitiesQuery>({
			query: ({ type, code }) => `activities`,
			providesTags: ["ProductDraft", "AllActivities"]
		}),

		// Components
		getComponents: builder.query<ComponentDefinition[], void>({// TODO: should maybe move to it's own API def
			query: () => "components",
			providesTags: ["ComponentList"]
		}),
		getComponentsForAssociation: builder.query<ComponentDefinition[], void>({// TODO: should maybe move to it's own API def
			query: () => "components/componentAssociation",
			providesTags: ["ComponentAssociationList"]
		}),
		getComponentsByProduct: builder.query<ComponentDefinition[], string[]>({// TODO: should maybe move to it's own API def
			query: (productCodes) => `components?productCodes=${productCodes}`,
			providesTags: ["ComponentAssociationList"]
		}),
		getComponentByRef: builder.query<ComponentDefinition, string>({// TODO: should maybe move to it's own API def
			query: (componentRef) => `components/${componentRef}`,
			providesTags: (result): any[] => {
				if (result) {
					return [{ type: "ComponentDefinition", id: result.code }, "ComponentDefinition"]
				}
				return ["ComponentDefinition"];
			}
		}),
		getComponentByRefForFeatureAssociation: builder.query<ComponentDefinition, string>({// TODO: should maybe move to it's own API def
			query: (componentRef) => `components/${componentRef}/featureAssociation`,
			providesTags: (result): any[] => {
				if (result) {
					return [{ type: "ComponentDefinition", id: result.code }, "ComponentDefinition"]
				}
				return ["ComponentDefinition"];
			}
		}),
		createComponent: builder.mutation<ComponentDefinition, ComponentDefinition>({
			query: (component) => ({
				url: `/components`,
				method: "POST",
				body: component
			}),
			invalidatesTags: ["ComponentList", "ComponentAssociationList"]
		}),
		updateComponent: builder.mutation<ComponentDefinition, ComponentDefinition>({
			query: (component) => ({
				url: `/components/${component.code}`,
				method: "PUT",
				body: component
			}),
			invalidatesTags: (result, error, arg): any[] => [
				"ComponentList", "ComponentAssociationList",
				{ type: "ComponentDefinition", id: arg.code },
				...arg.features.map(feature => { return { type: "FeatureDefinition", id: feature.code } }),
				"ProductDraft", "SingleProduct", "SingleProductVersion"
			]
		}),
		deleteComponent: builder.mutation<void, ComponentDefinition>({
			query: (component) => ({
				url: `/components/${component.code}`,
				method: "DELETE"
			}),
			invalidatesTags: (result, error, arg): any[] => [
				"ComponentList",
				"ComponentAssociationList",
				"FeatureList",
				...arg.features.map(feature => {
					return {
						type: "FeatureDefnition",
						id: feature.code
					}
				})
			]
		}),

		// Incremental Edit
		updateSolution: builder.mutation<SolutionDefinition, SolutionDefnitionRequest>({
			query: (solutionDefnitionRequest) => ({
				url: `/solutions/${solutionDefnitionRequest.code}/version/${solutionDefnitionRequest.version}`,
				method: "PUT",
				body: solutionDefnitionRequest
			}),
			invalidatesTags: (arg, error, request) => {
				if (request.actionType === "addProduct") {
					return ["AllUsers"]
				}
				else if(request.actionType === "updateName" || request.actionType === "updateTagline"){
					return ["SolutionList"];
				}
				return [];
			}
		}),

		updateSolutionProduct: builder.mutation<ProductListing, SolutionProductRelationTypeChangeDTO>({
			query: (solutionProductUpdateRequest) => ({
				url: `/solutions/update-product-relation`,
				method: "PUT",
				body: solutionProductUpdateRequest
			})
		}),

		upgradeSolutionProduct: builder.mutation<ProductListing, SolutionProductUpgradeDTO>({
			query: (solutionProductUpgradeRequest) => ({
				url: `/solutions/update-product`,
				method: "PUT",
				body: solutionProductUpgradeRequest
			})
		}),

		// Features
		getFeatures: builder.query<FeatureDefinition[], string>({// TODO: should maybe move to it's own API def
			query: (status) => status.length > 0 ? `/features?status=${status}` : `/features?status=''`,
			providesTags: ["FeatureList"]
		}),
		getFeatureByComponentsRef: builder.query<FeatureDefinition[], string[]>({
			query: (componentRefs) => componentRefs.length > 0 ? `features?componentRefs=${componentRefs}` : `features?componentRefs=''`,
			providesTags: ["FeaturesByComponentRef"]
		}),
		getFeatureByRef: builder.query<FeatureDefinition, string>({// TODO: should maybe move to it's own API def
			query: (featureRef) => `features/${featureRef}`,
			providesTags: (result, error, arg) =>
				result ?
					[{ type: 'FeatureDefinition', id: result.code }, 'FeatureDefinition']
					: ['FeatureDefinition'],
		}),
		getFeatureByRefAndVersion: builder.query<FeatureDefinition, FeatureVersionQuery>({// TODO: should maybe move to it's own API def
			query: ({ featureRef, version }) => version !== undefined ? `features/${featureRef}/version/${version}` : `features/${featureRef}`,
			providesTags: (result, error, arg) =>
				result ?
					[{ type: 'FeatureDefinition', id: result.code }, 'FeatureDefinition']
					: ['FeatureDefinition'],
		}),
		getFeatureByCodeAndVersionForGettingResourceCode: builder.query<FeatureDefinition, FeatureVersionQuery>({// TODO: should maybe move to it's own API def
			query: ({ featureRef, version }) => version !== undefined ? `features/${featureRef}/version/${Number(version)}` : `features/${featureRef}`,
		}),
		getFeatureVersions: builder.query<FeatureNameAndVersions, string>({
			query: (featureRef) => `/features/${featureRef}/versions`,
			providesTags: ['FeatureVersion']
		}),
		getFeatureByDraft: builder.query<FeatureDefinition, string>({
			query: (featureRef) => `/features/${featureRef}/draft`,
			providesTags: ['FeatureVersion']
		}),
		createFeatureDraft: builder.mutation<FeatureDefinition, FeatureVersionQuery>({
			query: ({ featureRef, version }) => ({
				url: version !== undefined ? `/features/${featureRef}/version/${version}/draft` : `features/${featureRef}`,
				method: "POST",
				body: {}
			}),
			invalidatesTags: (arg) => ["FeatureList", "ComponentList", "ComponentAssociationList", "FeaturesByComponentRef", { type: "ComponentDefinition", id: arg?.componentRef }, "FeatureVersion"]
		}),
		updateFeatureDraft: builder.mutation<FeatureDefinition, FeatureDefinition>({
			query: (feature) => ({
				url: `/features/${feature.code}`,
				method: "PUT",
				body: feature
			}),
			invalidatesTags: (result, error, arg) => {
				const defaultInvalidTags: any[] = ["FeatureList", "SpecificationParameterDefinition", "ComponentList", "ComponentAssociationList", "SingleProduct", "FeaturesByComponentRef"];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.code }, { type: 'ComponentDefinition', id: arg.componentRef }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", "ComponentDefinition", ...defaultInvalidTags];
			}
		}),
		activateDraftFeature: builder.mutation<FeatureDefinition[], string>({
			query: (featureRef) => ({
				url: `/features/activate/${featureRef}`,
				method: "PUT",
				body: {}
			}),
			invalidatesTags: (arg) => ["FeatureVersion", "SingleProduct", "SingleProductVersion", "ProductDraft", "ComponentDefinition"]
		}),
		deprecateActiveFeature: builder.mutation<FeatureDefinition[], string>({
			query: (featureRef) => ({
				url: `/features/deprecate/${featureRef}`,
				method: "PUT",
				body: {}
			}),
			invalidatesTags: (arg) => ["FeatureVersion"]
		}),
		deleteFeatureDraft: builder.mutation<void, FeatureDefinition>({
			query: (feature) => ({
				url: `/features/${feature.code}/draft`,
				method: "DELETE"
			}),
			invalidatesTags: (result, error, arg) => ["FeatureList", "ComponentList", "ComponentAssociationList", { type: "ComponentDefinition", id: arg.componentRef }, "FeaturesByComponentRef", "FeatureVersion"]
		}),
		createFeature: builder.mutation<FeatureDefinition, any>({
			query: (feature) => ({
				url: `/features`,
				method: "POST",
				body: feature
			}),
			invalidatesTags: (arg) => ["FeatureList", "ComponentList", "ComponentAssociationList", "FeaturesByComponentRef", { type: "ComponentDefinition", id: arg?.componentRef }, "ProductDraft", "SingleProduct", "SingleProductVersion"]
		}),
		updateFeature: builder.mutation<FeatureDefinition, FeatureDefinition>({
			query: (feature) => ({
				url: `/features/${feature.code}`,
				method: "PUT",
				body: feature
			}),
			invalidatesTags: (result, error, arg) => {
				const defaultInvalidTags: any[] = ["FeatureList", "SpecificationParameterDefinition", "ComponentList", "ComponentAssociationList", "ProductDraft", "SingleProduct", "SingleProductVersion", "FeaturesByComponentRef"];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.code }, { type: 'ComponentDefinition', id: arg.componentRef }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", "ComponentDefinition", ...defaultInvalidTags];
			}
		}),
		sortComponentFeatures: builder.mutation<ComponentDefinition, SortFeaturesRequest>({
			query: (orderFeatures) => ({
				url: `/components/sort`,
				method: "POST",
				body: orderFeatures
			}),
			invalidatesTags: ["ComponentDefinition", "FeatureDefinition", "SingleProduct", "ProductDraft"]
		}),
		sortFeatureSpecificationParams: builder.mutation<FeatureDefinition, SortSpecificationParameterRequest>({
			query: (orderSpecificationParameters) => ({
				url: `/features/sort`,
				method: "POST",
				body: orderSpecificationParameters
			}),
			invalidatesTags: ["ComponentDefinition", "FeatureDefinition", "SingleProduct", "ProductDraft"]
		}),
		// Specification Parameters
		createSpecificationParameter: builder.mutation<SpecificationParameterDefinition, SpecificationParameterDefinition>({
			query: (specificationParameter) => ({
				url: `/specificationParameters`,
				method: "POST",
				body: specificationParameter
			}),
			invalidatesTags: (arg) => [{ type: "FeatureDefinition", id: arg?.featureRef }, { type: "ComponentDefinition", id: arg?.componentRef }, "ProductDraft", "SingleProduct", "SingleProductVersion"]
		}),
		updateSpecificationParameter: builder.mutation<SpecificationParameterDefinition, SpecificationParameterDefinition>({
			query: (specificationParameter) => ({
				url: `/specificationParameters/${specificationParameter.code}`,
				method: "PUT",
				body: specificationParameter
			}),
			invalidatesTags: (result, arg) => {
				if (result) {
					return [
						{ type: "FeatureDefinition", id: result.featureRef },
						{ type: "SpecificationParameterDefinition", id: result.code },
						{ type: "ComponentDefinition", id: result.componentRef },
						"SingleProduct"
					];
				}
				return ["FeatureDefinition", "SpecificationParameterDefinition", "ComponentDefinition", "SingleProduct"];
			}
		}),
		deleteSpecificationParameter: builder.mutation<SpecificationParameterDefinition, SpecificationParameterDefinition>({
			query: (specificationParameter) => ({
				url: `/specificationParameters/${specificationParameter.code}/version/${specificationParameter.featureVersion}`,
				method: "DELETE"
			}),
			invalidatesTags: (result, error, arg) => [{ type: "FeatureDefinition", id: arg?.featureRef }, { type: "ComponentDefinition", id: arg?.componentRef }]
		}),

		//Solutions
		getSolutions: builder.query<SolutionDefinition[], string[]>({
			query: (solutionStatuses) => `solutions?status=${solutionStatuses.join(",")}`,
			providesTags: ["SolutionList"]
		}),
		getSolutionByCodeAndVersion: builder.query<SolutionDefinition, SolutionQuery>({
			query: ({ code, version }) => `solutions/${code}/version/${Number(version)}`
		}),
		createNewSolution: builder.mutation<SolutionDefinition, SolutionCreation>({
			query: (solution) => ({
				url: `/solutions/`,
				method: "POST",
				body: solution
			}),
			invalidatesTags: ["SolutionList"],
		}),
		postDraftSolution: builder.mutation<SolutionDefinition, String>({
			query: (solutionCode) => ({
				url: `solutions/${solutionCode}/draft`,
				method: "POST"
			}),
			invalidatesTags: ["SolutionList", "SolutionVersion"],
		}),
		createSolutionApprover: builder.mutation<ApproverStatus[], ResourceApproversInfo>({
			query: (solutionApprovers) => ({
				url: `/solutions/${solutionApprovers.productCode}/version/${solutionApprovers.version}/solution-approval`,
				method: "POST",
				body: solutionApprovers
			}),
			invalidatesTags: ["SolutionVersion"]
		}),
		approveRejectSolution: builder.mutation<void, ApprovalInfo>({
			query: (approvalInfo) => ({
				url: `/solutions/approver-remark`,
				method: "PUT",
				body: approvalInfo
			}),
			invalidatesTags: ["SolutionVersion"],
		}),
		cancelSolutionApproval: builder.mutation<SolutionDefinition, string>({
			query: (solutionCode) => ({
				url: `/solutions/cancel-approval/${solutionCode}`,
				method: "PUT"
			}),
			invalidatesTags: ["SolutionVersion"]
		}),
		activateDraftSolution: builder.mutation<void, string>({
			query: (solutionCode) => ({
				url: `/solutions/activate/${solutionCode}`,
				method: "POST"
			}),
			invalidatesTags: ["SolutionList", "SolutionVersion"]
		}),
		deleteSolutionDraft: builder.mutation<void, string>({
			query: (solutionCode) => ({
				url: `/solutions/${solutionCode}/draft`,
				method: "DELETE"
			}),
			invalidatesTags: ["SolutionList"],
		}),
		getProductListByStatus: builder.query<ProductListing[], string>({
			query: (status) => `/products?status=${status}`,
			providesTags: ["ProductListByStatus"],
			transformResponse:(response: ProductListing[])=>{
				return response.sort((a, b)=>a.name.localeCompare(b.name));
			}
		}),
		getSolutionVersions: builder.query<ResourceNameAndVersions, string>({
			query: (solutionRef) => `/solutions/${solutionRef}/versions`,
			providesTags: ['SolutionVersion']
		}),
		getActiveProductsWithFeatures: builder.query<ProductListing[], void>({
			query: () => `/products/active`,
			providesTags: ["ActiveProductList"]
		}),
		getProductInfoListByStatus: builder.query<ProductInfo[], string>({
			query: (status) => `/products?filterStatus=${status}`,
			transformResponse: (response: ProductInfo[]) => {
        //sort products first by family name and within same family by product name
        return response.sort((a, b) => a.family.name.localeCompare(b.family.name)===0 ? a.name.localeCompare(b.name) : a.family.name.localeCompare(b.family.name));
      },
			providesTags: ["ProductInfoListByStatus"]
		}),
		retireSolution: builder.mutation<void, string>({
			query: (solutionCode) => ({
				url: `solutions/${solutionCode}/retire`,
				method: "PUT"
			}),
			invalidatesTags: ["SolutionList",
			"SolutionVersion",
			"SingleSolution",
			"AllSolutionOwners", "SingleProduct", "SingleProductVersion", "FeatureDefinition", "AllActivities"]
		}),


		//Dimensions
		getSelectedDefaultDimensions: builder.query<FamilyDimensionAssociation[], string>({
			query: (familyCode) => `dimensions/${familyCode}`,
			providesTags: ["DefaultDimension"]
		}),
		postSelectedDefaultDimensions: builder.mutation<DimensionDefinition[], SelectedDimensions>({
			query: (selectedDimensions) => ({
				url: `/dimensions/${selectedDimensions.familyCode}`,
				method: "POST",
				body: selectedDimensions.dimensionCodes
			}),
			invalidatesTags: (result, error, arg) => ["DefaultDimension"]
		}),
		createProductApprover: builder.mutation<ApproverStatus[], ResourceApproversInfo>({
			query: (productApprovers) => ({
				url: `/products/${productApprovers.productCode}/version/${productApprovers.version}/product-approval`,
				method: "POST",
				body: productApprovers
			}),
			invalidatesTags: ["SingleProductVersion", "AllProductVersions"]
		}),
		createFeatureApprover: builder.mutation<ApproverStatus[], ResourceApproversInfo>({
			query: (featureApprovers) => ({
				url: `/features/feature-approval`,
				method: "POST",
				body: featureApprovers
			}),
			invalidatesTags: (result, error, arg): any[] => {
				const defaultInvalidTags: any[] = ["FeatureVersion", "AllActivities", "AllUsers"];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.productCode }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", ...defaultInvalidTags];
			},
		}),
		cancelProductApproval: builder.mutation<Product, string>({
			query: (productCode) => ({
				url: `/products/cancel-approval/${productCode}`,
				method: "PUT"
			}),
			invalidatesTags: ["ProductDraft", "SingleProduct", "SingleProductVersion", "ProductActivity", "AllProductVersions"],
		}),
		cancelFeatureApproval: builder.mutation<FeatureDefinition, FeatureVersionQuery>({
			query: ({ featureRef, version }) => ({
				url: version !== undefined ? `/features/cancel-approval/${featureRef}/version/${version}` : `features/${featureRef}`,
				method: "PUT"
			}),
			invalidatesTags: (result, error, arg): any[] => {
				const defaultInvalidTags: any[] = ["AllActivities", 'FeatureVersion'];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.featureRef }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", ...defaultInvalidTags];
			},
		}),
		approveRejectProduct: builder.mutation<void, ApprovalInfo>({
			query: (approvalInfo) => ({
				url: `/products/approver-remark`,
				method: "PUT",
				body: approvalInfo
			}),
			invalidatesTags: ["SingleProductVersion", "AllProductVersions"],
		}),
		approveRejectFeature: builder.mutation<void, ApprovalInfo>({
			query: (approvalInfo) => ({
				url: `/features/approver-remark`,
				method: "PUT",
				body: approvalInfo
			}),
			invalidatesTags: (result, error, arg): any[] => {
				const defaultInvalidTags: any[] = ["FeatureVersion", "AllActivities", "AllUsers"];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.productCode }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", ...defaultInvalidTags];
			},
		}),
		createFeatureRoles: builder.mutation<FeatureDefinition, any>({
			query: (feature) => ({
				url: `/features/role`,
				method: "POST",
				body: feature
			}),
			invalidatesTags: (result, error, arg): any[] => {
				const defaultInvalidTags: any[] = ["AllActivities", "AllUsers", 'AllBpos'];
				if (result == null && !error) {
					return [{ type: 'FeatureDefinition', id: arg.productCode }, ...defaultInvalidTags]
				}
				return ["FeatureDefinition", ...defaultInvalidTags];
			},
		}),


		// CATALOGUE USER API SPLIT
		getAllUsersForProduct: builder.query<AllUserData, ProductAndResources>({
			query: (request) => `/user/all/${request.productCode}/${request.resourceCode}` + (request.resourceChildCode ? `?resourceChildCode=${request.resourceChildCode}` : ""),
			providesTags: ["AllUsers"]
		}),
		getAllBpoForAllFamilies: builder.query<AnchorUserAssignment[], void>({
			query: () => `/user/all/families`,
			providesTags: ["AllBpos"]
		}),
		getAllSolutionOwners: builder.query<AnchorUserAssignment[], void>({
			query: () => `/user/all/solutions`,
			providesTags: ["AllSolutionOwners"]
		}),
		getAllGlobalOwners: builder.query<AnchorUserAssignment[], void>({
			query: () => `/user/all/subscriptions`,
			providesTags: ["AllGo"]
		}),
		postAddBpo: builder.mutation<AssignUsersResponse, AssignUsersRequest>({
			query: (roleAssignments) => ({
				url: "/user/all",
				method: "POST",
				body: roleAssignments
			}),
			invalidatesTags: ["AllBpos", "AllSolutionOwners"]
		}),
		getAllRolesForProduct: builder.query<AllRoleData, ProductAndResources>({
			query: (request) => `/roles/${request.productCode}/${request.resourceCode}/${request.resourceChildCode}`,
			providesTags: ["AllRoles"]
		}),
		postRetryGettingUsersForProduct: builder.mutation<void, string>({
			query: (productCode) => ({
				url: `/user/${productCode}/retry`,
				method: "POST",
				body: ""
			}),
			invalidatesTags: ["ProductDraft", "SingleProductVersion", "SingleProduct", "SingleSolution"]
		}),
		postRetryGettingUsersForSolution: builder.mutation<void, string>({
			query: (solutionCode) => ({
				url: `/user/solutions/${solutionCode}/retry`,
				method: "POST",
				body: ""
			}),
			invalidatesTags: ["SingleSolution"]
		}),
		postRoleAssignments: builder.mutation<AssignUsersResponse, AssignUsersRequest>({
			query: (roleAssignments) => ({
				url: "/user",
				method: "POST",
				body: roleAssignments
			}),
			invalidatesTags: ["ProductActivity", "AllActivities", "AllUsers", 'AllBpos'],
		}),
		postBPORoleAssignments: builder.mutation<AssignUsersResponse[], AssignUsersRequest>({
			query: (roleAssignments) => ({
				url: "/user/bpo",
				method: "POST",
				body: roleAssignments
			}),
			invalidatesTags: ["ProductActivity", "AllActivities", "AllUsers", 'AllBpos'],
		}),
		postFeatureRoleAssignments: builder.mutation<AssignUsersResponse, AssignUsersRequest>({
			query: (roleAssignments) => ({
				url: "/features/user",
				method: "POST",
				body: roleAssignments
			}),
			invalidatesTags: ["AllUsers", 'AllBpos']
		})
	}),
});


export const {
	useGetFamiliesQuery, useGetProductByCodeQuery, useRetireProductMutation, useGetProductByCodeAndVersionQuery, useGetProductByCodeAndVersionForGettingResourceCodeQuery,
	useGetComponentsQuery, useGetComponentsForAssociationQuery, useGetComponentByRefQuery, useGetComponentByRefForFeatureAssociationQuery, useGetFeatureByRefQuery, useGetFeatureByDraftQuery, useGetFeatureByRefAndVersionQuery,
	useGetFeatureByComponentsRefQuery, useGetComponentsByProductQuery, useLazyGetFeatureByComponentsRefQuery, useGetFeaturesQuery, useGetFeatureVersionsQuery, useCreateFeatureDraftMutation, useGetFeatureByCodeAndVersionForGettingResourceCodeQuery,
	useUpdateFeatureDraftMutation, useActivateDraftFeatureMutation, useDeprecateActiveFeatureMutation, useDeleteFeatureDraftMutation,
	useGetProductByCodeAsDraftQuery, usePostProductMutation, usePostDraftProductMutation, useGetAllProductVersionsQuery,
	useDeleteProductDraftMutation, useCreateNewProductMutation, useCreateNewSolutionMutation,
	useGetEntityActivitiesQuery, useGetAllActivitiesQuery,
	useCreateComponentMutation, useCreateSpecificationParameterMutation,
	useUpdateComponentMutation, useDeleteComponentMutation,
	useUpdateSpecificationParameterMutation, useDeleteSpecificationParameterMutation, useActivateDraftProductMutation, useApproveRejectProductMutation,
	useCreateFeatureMutation, useUpdateFeatureMutation, useGetSelectedDefaultDimensionsQuery, usePostSelectedDefaultDimensionsMutation, useSortComponentFeaturesMutation, useSortFeatureSpecificationParamsMutation,
	useCreateProductApproverMutation, useCancelProductApprovalMutation, useCreateFeatureApproverMutation, useCancelFeatureApprovalMutation,
	useApproveRejectFeatureMutation, useGetAllPopUpValuesQuery, useLazyGetAllPopUpValuesQuery, useGetSolutionsQuery, useUpdateSolutionMutation, 
	useUpdateSolutionProductMutation, useUpgradeSolutionProductMutation, useRetireSolutionMutation,

	useLazyGetSolutionByCodeAndVersionQuery, useActivateDraftSolutionMutation, usePostDraftSolutionMutation, useDeleteSolutionDraftMutation,
	useGetProductListByStatusQuery, useGetProductInfoListByStatusQuery, useCreateSolutionApproverMutation, useApproveRejectSolutionMutation, useCancelSolutionApprovalMutation, useGetActiveProductsWithFeaturesQuery, useGetSolutionVersionsQuery,

	// CATALOGUE USER API SPLIT
	useGetAllUsersForProductQuery,
	useGetAllRolesForProductQuery,
	usePostFeatureRoleAssignmentsMutation,
	usePostRoleAssignmentsMutation,
	useGetAllBpoForAllFamiliesQuery,
	useGetAllSolutionOwnersQuery,
	usePostAddBpoMutation,
	usePostBPORoleAssignmentsMutation,
	usePostRetryGettingUsersForProductMutation,
	usePostRetryGettingUsersForSolutionMutation,
	useGetAllGlobalOwnersQuery,
	useCreateFeatureRolesMutation,
} = CatalogueApi;

