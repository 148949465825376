import { FC, useEffect, useState } from 'react';
import styles from './ProductAccordion.module.scss';
import { PackagedSolutionProductRelationType, ProductListing, USER_ACTION } from '../../data/model/DataModels';
import ComponentAccordion from '../ComponentAccordion/ComponentAccordion';
import { Card } from 'primereact/card';
import { cloneDeep, truncate } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './overrides.scss'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate } from "react-router-dom";
import PATHS from '../../Paths';
import { FieldIdentifiers, formValidation } from '../../utils/validationUtil';


interface ProductAccordionProps {
  productList: ProductListing[];
  isEditable: boolean;
  removeProduct: Function;
  previewUpgradeProduct?: Function;
  onFeatureTitleClick?: Function;
  onSpecParamTitleClick?: Function;
  updateProduct: Function;
  accordionAction: USER_ACTION;
  setAccordionAction: Function;
}

const ProductAccordion: FC<ProductAccordionProps> = ({ productList, accordionAction, setAccordionAction, isEditable, removeProduct, previewUpgradeProduct, onFeatureTitleClick, onSpecParamTitleClick, updateProduct }) => {
  let index = 0;
  const [selectedOptions, setSelectedOptions] = useState<{ [associatedProductCode: string]: { relationType: string } }>({});
  const [activeIndex, setActiveIndex] = useState(index === 0 ? [index] : []);
  const navigate = useNavigate();
  let invalidFields: string[] = [];
  const [tooltipDescription, setTooltipDescription] = useState(" ");


  useEffect(() => {
    productList.forEach(product => {
      setSelectedOptions(prevState => ({
        ...prevState,
        [product.code]: { relationType: product.relationType }
      }));
    });
  }, [productList]);

  useEffect(() => {
    if (accordionAction === USER_ACTION.EXPAND_ALL) {
      setActiveIndex(productList.map((product, index) => index));
    }
    else if(accordionAction === USER_ACTION.COLLAPSE_ALL) {
      setActiveIndex([]);
    }
  }, [accordionAction]);

  const selectedTypeTemplate = (option: any) => {
    return (
      <div className={styles.customDropdownItem}>
        <span className={styles.optionName} >{option.name}</span>
        <div className={styles.optionDescription}>{option.description}</div>
      </div>
    );
  };

  const goToProduct = (product: ProductListing) => {
    let url = PATHS.PRODUCT_BY_CODE.replace(":code", product.code);
    window.open(url);
  }

  const getRelationTypeNameFromCode = (relationTypeCode: string) => {
    return PackagedSolutionProductRelationType.filter(relationTypeObj => relationTypeObj.code === relationTypeCode).map(matchedObj => matchedObj.name);
  }

  const constructProductHeaderForAccordion = (productAssociation: ProductListing, index: number) => {

    const handleDropdownChange = (relationType: string, associatedProductCode: any) => {
      setSelectedOptions(prevState => ({
        ...prevState,
        [associatedProductCode]: { relationType: relationType },
      }));
      updateProduct(relationType, associatedProductCode);
    };

    const checkFormValidity = (value: any, fieldIdentifier: FieldIdentifiers, fieldName: string, isRequiredField: boolean = false, requiredFieldValidatioError: string = "") => {

      const validityResult = formValidation(value, fieldIdentifier, isRequiredField, requiredFieldValidatioError);
      if (validityResult !== "") {
        if (!invalidFields.includes(fieldName)) {
          invalidFields = [...invalidFields, fieldName];
        }
        return (
        <div className={styles.productTypeError}>
        <span className={styles.errorIcon}><i className="pi pi-exclamation-triangle"></i></span><div className={styles.errorMessage}>{requiredFieldValidatioError}</div></div>);
      } else {
        if (invalidFields.length > 0) {
          invalidFields = invalidFields.filter(field => field !== fieldName);
        }

      }
      return validityResult;
    }

    const getDescription = (relationType: any) => {
      const relation = PackagedSolutionProductRelationType.find(item => item.name === relationType);
      return relation ? relation.description : "Description not found";
    };


    return (
      <div className={styles.productNameSection}>
        <span className={styles.productNameAndUpgrade}>
          <div>
            <span className={styles.productName}>{productAssociation.name}  -  v{productAssociation.version}</span>
            {productAssociation.latestActiveVersion > productAssociation.version! ?
              <span>
                <span className={styles.featureVersionAvailable}>New version available</span>
                <span className={styles.productUpgrade} onClick={(e) => previewUpgradeProduct && previewUpgradeProduct(e, productAssociation)} data-testid={`previewOrUpgradeLink${index + 1}`}>
                  Preview & Update
                </span>
              </span>
              : ""
            }
          </div>
          {
            (productAssociation.description === "" || productAssociation.description === null) ? (<div className={styles.productDescription}>No description Available !! <span className={styles.link}><i
              className="pi pi-external-link"
              onClick={() => goToProduct(productAssociation)}
              data-testid="featureCodeClick"
            /></span></div>) :
              <div className={styles.productDescription}>
                {truncate(productAssociation.description, { length: 100, omission: "..." })}<span className={styles.link}><i
                  className="pi pi-external-link"
                  onClick={() => goToProduct(productAssociation)}
                  data-testid="featureCodeClick"
                /></span>
              </div>
          }
        </span>
        <span className={styles.selectProductType} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
          {isEditable ?
            (
              <div>
                <Dropdown
                  className={`${styles.relationType} ${formValidation(productAssociation.relationType === "EMPTY" ? null : productAssociation.relationType, FieldIdentifiers.SOLUTION_RELATION_TYPE, true, "Please select product type") !== "" && "invalid"}`}
                  value={selectedOptions[productAssociation.code]?.relationType || productAssociation.relationType}
                  options={PackagedSolutionProductRelationType}
                  onChange={(e: any) => {
                    handleDropdownChange(e.value, productAssociation.code);
                  }}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Product Type"
                  itemTemplate={selectedTypeTemplate}
                  data-testid="ProductRelation"
                  scrollHeight="18rem"
                />
                {
                  checkFormValidity(productAssociation.relationType === "EMPTY" ? null : productAssociation.relationType, FieldIdentifiers.SOLUTION_RELATION_TYPE, "relationTypeTitle", true, "Please select product type")
                }
              </div>)
            :
            <Button className={styles.relationTypeViewTag} onMouseEnter={(e: any) => {
              setTooltipDescription(getDescription(e.target.innerText));
            }}
              tooltip={tooltipDescription} tooltipOptions={{ showOnDisabled: false, position: "left" , className: `general-tooltip custom-tooltip`}}  >
              <span className={`${styles.relationTypeViewText} ${productAssociation.relationType === 'CORE' ? `${styles.relationTypeViewTextCore}` : `${styles.relationTypeViewTextOptional}`}`} >
                {getRelationTypeNameFromCode(productAssociation.relationType)}
              </span>
            </Button>
          }
          {isEditable &&
            <Button icon="pi pi-trash" className={`p-button-outlined  p-button-secondary ${styles.deleteProductIcon}`} onClick={(e) => removeProduct(e, productAssociation)} data-testid={`deleteProduct-${index + 1}`}></Button>
          }
        </span>
      </div>
    )
  }

  return (
    <Accordion className={`${styles.productAccordion} productAccordion`} multiple activeIndex={activeIndex} onTabChange={(e: any) => { setActiveIndex(e.index); setAccordionAction(USER_ACTION.NONE)}}>
      {cloneDeep(productList).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())).map((productAssociation, index) => {
        return (
          <AccordionTab key={index} header={constructProductHeaderForAccordion(productAssociation, index)}>
            <Card className={styles.productCard} key={index} >
              <div>
                <ComponentAccordion
                  componentList={productAssociation.components}
                  featureList={productAssociation.features}
                  specificationParameterList={productAssociation.specificationParameters}
                  index={index === 0 ? 0 : -1}
                  productAssociation={productAssociation}
                  isEditable={false}
                  accordionAction={accordionAction}
                  setAccordionAction={setAccordionAction}
                  onFeatureTitleClick={(featureCode: string) => onFeatureTitleClick && onFeatureTitleClick(featureCode, productAssociation.code)}
                  onSpecParamTitleClick={(featureCode: string, specParamCode: string) => onSpecParamTitleClick && onSpecParamTitleClick(featureCode, specParamCode, productAssociation.code)}
                />
              </div>
            </Card>

          </AccordionTab>

        )
      })
      }
    </Accordion>

  );
}

export default ProductAccordion;
