import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { FC, useContext, useEffect, useState } from 'react';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { AssignUsersRequest, ROLES, RoleAssign, User, UserAssignWithUserName, approverDesignationEmailMap, AllUserData } from '../../data/model/DataModels';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import UserSearchWithinOrganisation from '../UserSearchWithinOrganisation/UserSearchWithinOrganisation';
import styles from './ViewOrAddUsersDialog.module.scss';
import './overrides.scss';
import { useAnchor } from '../../authorisationService';

interface ViewOrAddUsersDialogProps {
  header?: string;
  subHeader?: string;
  users?: User[];
  productCode?: string;
  resourceCode?: string;
  resourceChildCode?: string;
  roleName: string;
  roleId?: string;
  editMode?: boolean;
  onHide: Function;
  approversApproved?: string[],
  approversRejected?: { [key: string]: string }
  allUsers?: AllUserData,
  postAssignRoleMutation: Function;
  feature?: boolean,
  footer?: any
}

const ViewOrAddUsersDialog: FC<ViewOrAddUsersDialogProps> = ({ header = "", subHeader = "", users = [], productCode, resourceCode, resourceChildCode, roleId, roleName, onHide, editMode = false, approversApproved, approversRejected, allUsers, feature, footer, postAssignRoleMutation }) => {

  const [selectedUsers, setSelectedUsers] = useState([] as User[]);

  const [existingUsers, setExistingUsers] = useState(users);

  const [searchTextForExistingUsers, setSearchTextForExistingUsers] = useState("");

  const { refreshAnchorToken } = useAnchor();

  useEffect(() => {
    if (searchTextForExistingUsers == "") {
      setExistingUsers(users);
    } else {
      setExistingUsers(users.filter(user => user.displayName.includes(searchTextForExistingUsers) || user.mail.includes(searchTextForExistingUsers)))
    }
  }, [searchTextForExistingUsers]);

  useEffect(() => {
    setExistingUsers(users);
  }, [users]);

  const [postRoleAssignment, { isLoading: isLoadingRoleAssignment }] = postAssignRoleMutation();

  const toaster = useContext(ToasterContext);

  const assignUsers = () => {
    const userList = selectedUsers.map(user => {
      return {
        user_name: user.displayName,
        user_email: user.mail,
        user_idp: user.id
      } as UserAssignWithUserName;
    })

    const assignUsersRequest: AssignUsersRequest = {
      productCode: productCode,
      resourceCode: resourceCode,
      resourceChildCode: resourceChildCode,
      shouldInheritRoleAssignment: false,
      roles: [{
        role_name: roleName,
        role_id: roleId,
        users: userList
      } as RoleAssign]
    } as AssignUsersRequest;

    postRoleAssignment(assignUsersRequest).unwrap().then(
      () => {
        toaster.showToast('success', `Successfully added users to ${roleName}`);
        refreshAnchorToken();
        setExistingUsers([...existingUsers, ...selectedUsers]);
        setSelectedUsers([]);
      },
      (e: any) => {
        toaster.showToast('error', `Failed to add users to ${roleName}`);
      }
    )

  }

  const confirmUnassignUser = (user: User) => {
    confirmDialog({
      message: `Are you sure you want to remove ${user.displayName}?`,
      header: `Remove ${roleName.charAt(0).toUpperCase() + roleName.slice(1)}?`,
      acceptLabel: "Remove",
      rejectLabel: "Cancel",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        unassignUser(user);
      },
    });
  }


  const unassignUser = (user: User) => {
    const userList = [
      {
        user_name: user.displayName,
        user_email: user.mail,
        user_idp: user.id
      } as UserAssignWithUserName
    ];
    const assignUsersRequest: AssignUsersRequest = {
      productCode: productCode,
      resourceCode: resourceCode,
      resourceChildCode: resourceChildCode,
      shouldInheritRoleAssignment: false,
      roles: [{
        role_name: roleName,
        role_id: roleId,
        users: userList
      } as RoleAssign]
    } as AssignUsersRequest;

    postRoleAssignment(assignUsersRequest).unwrap().then(
      () => {
        toaster.showToast('success', `Successfully removed ${user.displayName} from ${roleName}`);
        setSelectedUsers([]);
      },
      (e: any) => {
        toaster.showToast('error', `Failed to remove ${user.displayName} from ${roleName}`);
      }
    )

  }

  const isApproverApproved = (email: string) => {
    return approversApproved?.includes(email);
  }

  const isApproverRejected = (email: string) => {
    return approversRejected && Object.keys(approversRejected).includes(email);
  }

  const getApproverDesignation = (email: string) => {
    return <div className={styles.approverDesignation}>{approverDesignationEmailMap[email]}</div>
  }

  const checkAndSetSelectedUsers = (users: User[]) => {
    if (users.length > 20) {
      toaster.showToast('warn', 'Only 20 users can be added at once');
      return;
    }



    const checkforContributorAndApproverSelection = () => {
      let cloneSelectedUsers = cloneDeep(users);
      if (allUsers) {
        Object.keys(allUsers).forEach((key) => {
          if (key == "approvers" || key == "contributors") {
            return;
          }
          const userList = allUsers[key];
          if (userList && userList.length) {
            const userListEmails = userList.map(user => user.email);

            cloneSelectedUsers = cloneSelectedUsers.filter(user => {
              if (userListEmails.includes(user.mail)) {
                toaster.showToast('warn', `${user.displayName} is already assigned as ${key.slice(0, -1)}`);
                return false;
              }
              return true;
            })
          }

        })
      }
      return cloneSelectedUsers;
    }

    const checkforReviewerSelection = () => {
      let cloneSelectedUsers = cloneDeep(users);
      if (allUsers) {
        Object.keys(allUsers).forEach((key) => {
          const userList = allUsers[key];
          if (userList && userList.length) {
            const userListEmails = userList.map(user => user.email);

            cloneSelectedUsers = cloneSelectedUsers.filter(user => {
              if (userListEmails.includes(user.mail)) {
                toaster.showToast('warn', `${user.displayName} is already assigned as ${key.slice(0, -1)}`);
                return false;
              }
              return true;
            })
          }

        })
      }
      return cloneSelectedUsers;
    }

    switch (roleName) {
      case "contributor":
      case "approver":
        setSelectedUsers(checkforContributorAndApproverSelection());
        break;
      case "reviewer":
        setSelectedUsers(checkforReviewerSelection());
        break;
    }
  }

  return (
    <div className={`${styles.ViewOrAddUsersDialog}`} data-testid="ViewOrAddUsersDialog">
      <Dialog className={'FooterStyling'} visible={true} header={header} footer={footer} onHide={() => onHide()} style={{ width: "40vw" }}>
        {
          isLoadingRoleAssignment && <SpinnerComponent />
        }
        <div>
          {subHeader}
        </div>
        <div className={styles.dialogContent}>
          <div className={styles.searchBarAndButton}>
            {
              editMode ?
                <>
                  <UserSearchWithinOrganisation selectedUsers={selectedUsers} setSelectedUsers={checkAndSetSelectedUsers} existingUsers={existingUsers} />
                  <div>
                    <Button label='Add' disabled={!selectedUsers?.length} onClick={() => assignUsers()} />
                  </div>
                </>
                :
                <span style={{ width: "100%" }} className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText style={{ width: "100%" }} placeholder="Search" onChange={(e) => setSearchTextForExistingUsers(e.target.value)} />
                </span>
            }

          </div>
          <div className={styles.existingUsers}>
            {
              existingUsers.map((user) =>
                <div className={`${styles.userInfoWrapper} ${Object.keys(approverDesignationEmailMap).includes(user.mail) ? styles.userInfoDefaultApprover : ''}`} key={user.id}>
                  <div className={styles.userInfo}>
                    <div className={styles.user} key={user.mail}>
                      <div>
                        <div className={styles.displayName}>
                          {user.displayName}
                        </div>
                        <div className={styles.email}>
                          {user.mail}
                        </div>
                      </div>

                    </div>
                    <div className={styles.statusImg}>
                      <div>
                        {
                          editMode && !Object.keys(approverDesignationEmailMap).includes(user.mail) && <i className={`pi pi-trash ${styles.deleteIcon}`} data-testid="delete-icon" onClick={() => confirmUnassignUser(user)} />
                        }
                      </div>
                      <div>
                        {
                          Object.keys(approverDesignationEmailMap).includes(user.mail) && getApproverDesignation(user.mail)
                        }
                      </div>
                      <div className={styles.statusIcon}>
                        {!editMode && roleName === ROLES.APPROVER ?
                          isApproverApproved(user.mail) ? <i className={`pi pi-check-circle ${styles.approvedColor}`} /> :
                            isApproverRejected(user.mail) ? <i className={`pi pi-times-circle ${styles.rejectedColor}`} /> : <></> : <></>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={styles.userComment}>
                    {approversRejected && approversRejected[user.mail]}
                  </div>
                </div>
              )
            }
          </div>

        </div>
      </Dialog>
    </div>
  );
}

export default ViewOrAddUsersDialog;
