import { Tree, TreeCheckboxSelectionKeys, TreeNodeTemplateOptions } from 'primereact/tree';
import React, { FC, memo, useEffect, useState } from 'react';
import styles from './RegionTree.module.scss';
import { cloneDeep } from 'lodash';
import { LocationData } from '../../data/model/DataModels';
import { getLeafValues } from '../ScopeComponents/ScopeHelper';
import { TreeNode } from 'primereact/treenode';
import './overrides.scss';

export interface RegionTreeProps {
  nodes: LocationData[];
  selectedValuesOnTree: string[];
  editMode?: boolean;
  enableFilter?: boolean;
  filterValue?: string;
  saveSelection?: Function;
}


// This region tree is configured to process and give out leaf elements as selected values.
const RegionTree: FC<RegionTreeProps> = ({ nodes, selectedValuesOnTree, editMode = false, saveSelection, enableFilter = false, filterValue = "" }) => {
  const [treeMapping, setTreeMapping] = useState({} as any);
  const [lazyFilterValue, setLazyFilterValue] = useState("");
  const [processedRegions, setProcessedRegions] = useState([] as any[]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLazyFilterValue(filterValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [filterValue]);

  useEffect(() => {
    if (nodes) {
      buildTreeSelection(nodes);
    }
  }, [selectedValuesOnTree, nodes])



  const calculateAndShowPartialSelection = () => {

    // using document because we dont have control over prime react classes per tree node.

    // Step 1: Adding border to partially selected
    let elements = document.getElementsByClassName("p-indeterminate");
    if (elements) {
      Array.from(elements).forEach(element => {
        let parent = element.parentElement?.parentElement;
        if (parent) {
          parent.classList.add("partial-selection");
        }
      })
    }

    // Step 2: removing borders if there is border from step 1 even if item is not partially selected.
    let elementsWithPartialSelectionBorder = document.getElementsByClassName("partial-selection");
    if (elementsWithPartialSelectionBorder) {
      Array.from(elementsWithPartialSelectionBorder).forEach(element => {
        //we take the 0th element because there should be 1 checkbox associated with each tree element.
        let checkBoxElement = element.getElementsByClassName("p-checkbox-icon")[0];
        if (checkBoxElement) {
          if (!checkBoxElement.classList.contains("pi-minus")) {
            element.classList.remove("partial-selection");
          }
        }
      })
    }
  }

  useEffect(() => calculateAndShowPartialSelection());


  const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
    let colorClass = "";
    if (node.icon == "pi pi-check-circle") {
      colorClass = styles.checkIcon;
    }
    if (node.icon == "pi pi-ban") {
      colorClass = styles.banIcon;
    }
    if (node.icon == "pi pi-circle") {
      colorClass = styles.partialCheckIcon;
    }
    return (
      <>
        <div className={styles.treeItemTemplate}>
          <span className={styles.scopeTypeIcon}>
            <span className={`${colorClass} ${node.icon ? node.icon : ""}`}></span>
          </span>
          <span>{node.label}</span>
        </div>

      </>
    );
  }



  // this is for buttom up approach
  const selectPartiallySelect = (processLocations: any[]) => {
    return processLocations.map(location => {
      let locationCopy = cloneDeep(location);
      if (locationCopy.children && locationCopy.children.length > 0) {
        locationCopy.children = selectPartiallySelect(locationCopy.children);
        let isCheckedCount = 0;
        let isPartiallyCheckedCount = 0;
        locationCopy.children.forEach((child: any) => {
          if (child.isChecked == true) {
            isCheckedCount++;
          } else if (child.isPartiallyChecked == true) {
            isPartiallyCheckedCount++;
          }
        });
        if (isCheckedCount === locationCopy.children.length) {
          locationCopy.isChecked = true;
          locationCopy.isPartiallyChecked = false;
        } else if (isCheckedCount > 0) {
          locationCopy.isChecked = false;
          locationCopy.isPartiallyChecked = true;
        } else {
          locationCopy.isChecked = false;
          if (isPartiallyCheckedCount > 0) {
            locationCopy.isPartiallyChecked = true;
          } else {
            locationCopy.isPartiallyChecked = false;
          }
        }
      } else if (selectedValuesOnTree.includes(locationCopy.code)) {
        locationCopy.isChecked = true;
        locationCopy.isPartiallyChecked = false;
      } else {
        locationCopy.isChecked = false;
        locationCopy.isPartiallyChecked = false;
      }
      return locationCopy;
    })

  }

  const buildTreeSelection = (processRegions: any) => {
    let selectedMapping: TreeCheckboxSelectionKeys = {} as TreeCheckboxSelectionKeys;

    let checkedOrPartiallycheckedTree = selectPartiallySelect(processRegions);

    const setMapping = (processRegions: any) => {
      processRegions.forEach((region: any) => {
        selectedMapping[region.key] = { checked: region.isChecked, partialChecked: region.isPartiallyChecked };
        if (region.children) {
          setMapping(region.children);
        }
      }
      )
    }

    setMapping(checkedOrPartiallycheckedTree);
    setTreeMapping(selectedMapping);
    setProcessedRegions(checkedOrPartiallycheckedTree);
  }

  const pushSelectedValue = (event: any) => {
    let children = event.node.children;
    if (children) {
      saveSelection && saveSelection([...selectedValuesOnTree, ...getLeafValues(children)]);
    } else {
      saveSelection && saveSelection([...selectedValuesOnTree, event.node.code]);
    }
  }

  const popSelectedValue = (event: any) => {
    let children = event.node.children;
    if (children) {
      const leafSelections = getLeafValues(children);
      saveSelection && saveSelection(selectedValuesOnTree.filter(value => !leafSelections.includes(value)));
    } else {
      saveSelection && saveSelection(selectedValuesOnTree.filter(value => value !== event.node.code));
    }
  }

  return (
    <div className={`${styles.RegionTree} RegionTree`} data-testid="RegionTree">
      {
        <div>
          {
            processedRegions.length && editMode ?
              <Tree value={processedRegions}
                filter={enableFilter}
                onFilterValueChange={(e) => { return }}
                filterPlaceholder="Search"
                selectionMode="checkbox"
                filterMode="strict"
                filterBy='label'
                filterValue={lazyFilterValue}
                selectionKeys={treeMapping}
                nodeTemplate={nodeTemplate}
                onSelect={(event) => pushSelectedValue(event)}
                onUnselect={(event) => popSelectedValue(event)}
                onExpand={(e) => { 
                  setTimeout(() => calculateAndShowPartialSelection(), 0) }}
              />
              :
              <div className="noEdit">
                <Tree value={processedRegions}
                  filter={enableFilter}
                  onFilterValueChange={(e) => { return }}
                  filterPlaceholder="Search"
                  selectionMode="checkbox"
                  filterMode="strict"
                  filterBy="label"
                  filterValue={lazyFilterValue}
                  nodeTemplate={nodeTemplate}
                />
              </div>
          }
        </div>
      }
    </div>
  );
}

export default memo(RegionTree);


