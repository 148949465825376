import type { UniqueIdentifier } from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';
import {SpecificationParameterConfig} from '../../data/model/DataModels';
import {FlattenedItem, TreeItem} from './types';


export const getDragDepth = (offset: number, indentationWidth: number) => {
  return Math.round(offset / indentationWidth);
}

export const getProjection = (
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number
) => {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    activeItem,
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;
  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  const parentId =  getParentId();
  
  if(depth === 1 && previousItem.type === "child" && previousItem.parentId === null){
    return;
  }

  if(depth === 1 && activeItem.type === "child" && previousItem.type === "child" && activeItem.parentId !== previousItem.parentId)
    return;
  
  if(depth === 1 && activeItem.type === "child" && previousItem.type === "parent" && activeItem.parentId !== previousItem.id)
    return;

  if(activeItem.type === "child" && depth === 0)
    return;
  
  return { depth, maxDepth, minDepth, parentId: parentId };

   function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;
    return newParent ?? null;
  }
}

export const getMaxDepth = ({ activeItem, previousItem }: { activeItem: FlattenedItem, previousItem: FlattenedItem }) => {
  if(activeItem.isFeatureGroup)
    return 0;
  if (previousItem) {
    
    if((activeItem.depth === 0 || activeItem.depth === 1) && previousItem.depth === 0 && previousItem.isFeatureGroup === false){
      return 0;
    }
    
    return previousItem.depth + 1 >= 1 ? 1 : 0;
  }

  return 0;
}

export const getMinDepth = ({ nextItem }: { nextItem: FlattenedItem }) => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

export const buildTree = (flattenedItems: FlattenedItem[]): TreeItem[] => {
  const root: TreeItem = { id: 'root', name: '', children: [], type: '', description: '', specification: {} as SpecificationParameterConfig, code: '', isFeatureGroup: false, disableEdit: false, featureVersion: 0 };
  const nodes: Record<string, TreeItem> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [], type: item.type, isFeatureGroup: item.type === "parent" }));
  
  for (const item of items) {
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);
    
    parent.children.push(item);
  }
  
  return root.children;
}

export const findItem = (items: TreeItem[], itemId: UniqueIdentifier) => {
  return items.find(({ id }) => id === itemId);
}
