import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { FC, useContext, useEffect, useState } from 'react';
import SpinnerComponent from '../../../../../components/Spinner/SpinnerComponent';
import { AssignUsersRequest, RoleAssign, User, UserAssignWithUserName } from '../../../../../data/model/DataModels';
import { EnvConfig } from '../../../../../EnvConfig';
import { ToasterContext } from '../../../../AppLayoutView/AppLayoutView';
import styles from './ManageBpo.module.scss';
import {
  useGetAllUsersForProductQuery,
  ProductAndResources,
  usePostBPORoleAssignmentsMutation,
  usePostFeatureRoleAssignmentsMutation, useGetAllBpoForAllFamiliesQuery, useCreateFeatureRolesMutation
} from '../../../../../data/api/CatalogueApi';
import { useAnchor } from '../../../../../authorisationService';

export interface ManageBpoProps {
  header?: string;
  subHeader?: string;
  user?: User;
  productFamilyCode: string;
  productCode?: string;
  resourceCode: string;
  resourceChildCode: string;
  roleName: string;
  roleId: string;
  onHide: Function;
  feature?: boolean
}

const ManageBpo: FC<ManageBpoProps> = ({ user, productFamilyCode, productCode, resourceCode, resourceChildCode, roleName, roleId, subHeader, onHide, feature }) => {
  const [selectedUser, setSelectedUser] = useState<User>();

  const { refreshAnchorToken } = useAnchor();

  const [searchedUsers, setSearchedUsers] = useState([] as User[]);

  const resourceRoleName = productFamilyCode === "solutions_id" ? `Solution Owner`: `BPO`

  // get by resource
  // const familyResourceCode = getRbacResourceCodeForFamily(productFamilyCode);

  const areRbacResourcesReady = (resourceCode && resourceChildCode && resourceCode != "" && resourceChildCode != '' && resourceCode != "error" && resourceChildCode != "error");
  const getAllUsersData = feature ?
    {
      productCode: productCode,
      resourceCode: resourceCode,
      resourceChildCode: resourceChildCode
    } as ProductAndResources
    : {
      productCode: productFamilyCode.replaceAll("_", " "),
      resourceCode: EnvConfig[productFamilyCode.toUpperCase()],
      resourceChildCode: ""
    } as ProductAndResources
  const { data: userOptions, isLoading: isLoadingUserOptions } = useGetAllUsersForProductQuery(getAllUsersData, { skip: !areRbacResourcesReady });
  const { data: allBpos = [], error: errorAllBpos, isLoading: isLoadingAllBpos } = useGetAllBpoForAllFamiliesQuery();
  const [postBPORoleAssignment, { isLoading: isLoadingRoleBPOAssignment }] = !feature ? usePostBPORoleAssignmentsMutation() : (!roleId ? useCreateFeatureRolesMutation() : usePostFeatureRoleAssignmentsMutation());

  const getMappedOptions = () => {
    if (!feature && userOptions?.owners) {
      return userOptions?.owners?.filter(owner => owner.email != user?.mail).map(user => {
        return {
          displayName: user.name,
          mail: user.email,
          id: user.user_idp
        } as User
      });
    } else if (feature) {
      return allBpos.filter(owner => owner.email != user?.mail).map(user => {
        return {
          displayName: user.name,
          mail: user.email,
          id: user.user_idp
        } as User
      }).filter(
          (user, i, arr) => arr.findIndex(element => element.mail === user.mail) === i
      );
    }
    return [] as User[];
  }

  useEffect(() => {
    setSearchedUsers(getMappedOptions())
  }, [userOptions]);


  const toaster = useContext(ToasterContext);


  const assignUser = () => {
    let userList = [
      {
        user_name: selectedUser?.displayName,
        user_email: selectedUser?.mail,
        user_idp: selectedUser?.id
      } as UserAssignWithUserName
    ];

    const getRequestToAssignUser = (): AssignUsersRequest => {
      return {
        productCode: productCode,
        resourceCode: resourceCode,
        resourceChildCode: resourceChildCode,
        shouldInheritRoleAssignment: true,
        roles: [{
          role_name: roleName,
          role_id: roleId,
          users: userList
        } as RoleAssign]
      } as AssignUsersRequest;
    }

    const getRequestToReplaceUser = (): AssignUsersRequest => {
      return {
        productCode: productCode,
        resourceCode: resourceCode,
        resourceChildCode: resourceChildCode,
        roles: [{
          role_name: roleName,
          role_id: roleId,
          users: userList
        } as RoleAssign,
        {
          role_name: roleName,
          role_id: "",
          users: [
            {
              user_name: user?.displayName,
              user_email: user?.mail,
              user_idp: user?.id
            } as UserAssignWithUserName
          ]
        } as RoleAssign]
      } as AssignUsersRequest;
    }

    const request = user ? getRequestToReplaceUser() : getRequestToAssignUser();

    postBPORoleAssignment(request).unwrap().then(
      () => {
        refreshAnchorToken();
        toaster.showToast('success', `Successfully replaced ${resourceRoleName} to: ${roleName}`);
        onHide();
      },
      () => {
        toaster.showToast('error', `Failed to replace ${resourceRoleName}`);
      }
    )


  }

  const confirmReplaceBpo = () => {
    const message = user ?
      <div>
        Are you sure you want to replace <b>{user.displayName}</b> as {resourceRoleName} of {productCode} with <b>{selectedUser?.displayName}</b>?
      </div>
      :
      <div>
        Are you sure you want to assign <b>{selectedUser?.displayName}</b> as {resourceRoleName} of {productCode}?
      </div>
    confirmDialog({
      message: message,
      header: `Replace ${resourceRoleName}?`,
      acceptLabel: "Yes, replace",
      rejectLabel: "No",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        assignUser();
      },
    });
  }

  const itemTemplate = (item: User) => {
    return (
      <div className={styles.user}>
        <div>
          {item.displayName}
        </div>
        <div className={styles.email}>
          {item.mail}
        </div>
      </div>
    )
  }

  const search = (event: AutoCompleteCompleteEvent) => {
    let _items = getMappedOptions();
    setSearchedUsers(event.query != "" ? _items.filter(option => option.displayName.toLowerCase().includes(event.query.toLowerCase())) : _items);
  }


  return (
    <Dialog visible={true} header={`Manage ${resourceRoleName}`} onHide={() => onHide()}>
      <div className={styles.ManageBpo} data-testid="ManageBpo">

        {
          (isLoadingUserOptions || isLoadingRoleBPOAssignment || isLoadingAllBpos) && <SpinnerComponent />
        }
        <div>
          {subHeader}
        </div>
        <div className={styles.dialogContent}>
          <div className={styles.searchBarAndButton}>


            <AutoComplete style={{ width: "100%" }} placeholder="Search by name"
              itemTemplate={itemTemplate}
              field='displayName'
              value={selectedUser}
              suggestions={searchedUsers}
              completeMethod={search}
              onChange={(e) => setSelectedUser(e.value)} dropdown />


            <div>
              <Button disabled={!selectedUser} label='REPLACE' data-testid="replace-bpo-button" onClick={() => confirmReplaceBpo()} />
            </div>

          </div>
          {
            !feature &&
            <div className={styles.subheader}>
              A product can have only 1 {resourceRoleName}
            </div>
          }
          {
            feature &&
            <div className={styles.subheader}>
              A feature can have only 1 {resourceRoleName}
            </div>
          }
          {
            user &&
            <div className={styles.existingUsers}>
              {
                <div className={styles.user} key={user.mail}>
                  <div>
                    <div>
                      {user.displayName}
                    </div>
                    <div className={styles.email}>
                      {user.mail}
                    </div>
                  </div>
                </div>
              }
            </div>
          }


        </div>
      </div>

    </Dialog>
  );
}

export default ManageBpo;

