import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { User, ROLES, AllUserData } from '../../data/model/DataModels';
import ManageBpo from '../ProductView/ProductOverview/OverviewForm/ManageBpo/ManageBpo';
import ViewOrAddUsersDialog from '../ViewOrAddUsersDialog/ViewOrAddUsersDialog';
import styles from './UserListForRoleV2.module.scss';
import { usePostRoleAssignmentsMutation } from '../../data/api/CatalogueApi';

export interface UserListForRoleProps {
  displayRoleName: string,
  roleName: string,
  userList?: User[],
  resourceCode: string,
  resourceChildCode?: string,
  productName: string,
  productVersion: number,
  productCode: string,
  productFamilyCode?: string,
  roleId: string,
  editMode?: boolean,
  canEdit: boolean,
  approversApproved?: string[],
  approversRejected?: { [key: string]: string },
  allUsers?: AllUserData,
  triggerApproverStatus?: number,
  setTriggerApproverStatus?: Function
}

const UserListForRoleV2: FC<UserListForRoleProps> = ({ displayRoleName, userList = [], resourceCode, resourceChildCode, productName, productVersion, productCode, productFamilyCode, roleId, roleName, approversApproved, approversRejected, canEdit, allUsers, triggerApproverStatus = 0, setTriggerApproverStatus }) => {
  const [showRoles, setShowRoles] = useState(false);
  const [editRoles, setEditRoles] = useState(false);

  useEffect(() => {
    if (triggerApproverStatus) {
      setShowRoles(true);
      setEditRoles(false);
    }
  }, [triggerApproverStatus])


  const isApproverApproved = (email: string) => {
    return approversApproved?.includes(email);
  }

  const isApproverRejected = (email: string) => {
    return approversRejected && Object.keys(approversRejected).includes(email);
  }

  const getRoleInformation = () => {
    switch (roleName) {
      case "contributor":
        return <div className={styles.footer}>
          <div>They can configure components & features of a draft version of this product.</div>
        </div>

      case "reviewer":
        return <div className={styles.footer}>
          <div>They can review the draft version of this product.</div>
        </div>
      case "approver":
        return <div className={styles.footer}>
          <div>They can view & approve the draft version of this product.</div>
        </div>
    }
  }

  const manageUsers = () => {
    if (roleName === "owner" && productFamilyCode) {
      return <ManageBpo user={userList[0]} productCode={productCode} productFamilyCode={productFamilyCode} resourceCode={resourceCode} resourceChildCode={resourceChildCode || ""} roleId={roleId} roleName={roleName} onHide={() => setShowRoles(false)} />
    }
    return <ViewOrAddUsersDialog
      postAssignRoleMutation={usePostRoleAssignmentsMutation}
      footer={getRoleInformation()} editMode={editRoles}
      resourceCode={resourceCode}
      resourceChildCode={resourceChildCode}
      roleId={roleId}
      roleName={roleName}
      productCode={productCode}
      users={userList}
      header={'Manage ' + displayRoleName}
      subHeader={`Search and add ${displayRoleName.toLowerCase()} for product ${productName}, version ${productVersion}`}
      onHide={() => { setShowRoles(false); setTriggerApproverStatus && setTriggerApproverStatus(0) }}
      approversApproved={approversApproved}
      approversRejected={approversRejected}
      allUsers={allUsers} />
  }

  const showAssignment = () => {
    if (userList.length == 0) {
      return (
        <div className={styles.yetToBeAssigned}>Yet to be assigned</div>
      );
    }
    if (userList.length == 1) {
      const user = userList[0];
      return (
        <div key={user.id} className={`${styles.userInfo} ${user.me && styles.highlightMe}`}>
          <div key={user.mail} className={styles.user}>
            <div className={styles.username}>
              {user.displayName}
            </div>
            <div className={styles.mail}>
              {user.mail}
            </div>
          </div>
          <div className={styles.statusImg}>
            {roleName === ROLES.APPROVER ?
              isApproverApproved(user.mail) ? <i data-testid="approvedIcon" className={`pi pi-check-circle ${styles.approvedColor}`} /> : isApproverRejected(user.mail) ? <i data-testid="rejectedIcon" className={`pi pi-times-circle ${styles.rejectedColor}`} /> : <></> : <></>}
          </div>
        </div>
      );

    }
    if (userList.length > 1) {
      return (
        <div className={styles.showAll} data-testid="viewAll" onClick={() => { setShowRoles(true); setEditRoles(false) }}>
          View All ({userList.length})
        </div>
      )
    }

  }

  const showEditText = () => {
    if (userList.length > 0) {
      return "Manage";
    } else {
      return "Assign";
    }
  }

  return (
    <div className={styles.UserListForRoleV2} data-testid="userListForRoleCard">
      <div className={styles.title}>
        {displayRoleName}
      </div>
      {showAssignment()}
      {
        canEdit &&
        <div className={styles.editSection} onClick={() => { setShowRoles(true); setEditRoles(true) }} data-testid={`edit-${roleName}-icon`}>
          {showEditText()}
        </div>
      }
      {
        showRoles &&
        manageUsers()
      }
    </div>
  )

}

export default UserListForRoleV2;
