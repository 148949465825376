import { FC, useEffect, useState } from 'react';
import styles from './EditSpecificationParameterConfiguration.module.scss';
import { Dialog } from 'primereact/dialog';
import SpecificationParameterConfiguration
    from '../../components/SpecificationParameterConfiguration/SpecificationParameterConfiguration';
import { Button } from 'primereact/button';
import { SpecificationParameterDefinition } from '../../data/model/DataModels';

export interface EditSpecificationParameterConfigurationProps {
    specificationParameter: SpecificationParameterDefinition;
    onHide: Function;
    saveSpecification: Function;
    isConfigurable: boolean;
    usedIn: string;
    associatedSpecParam?: SpecificationParameterDefinition[];
    isFeatureAService?: boolean;
}

const EditSpecificationParameterConfiguration: FC<EditSpecificationParameterConfigurationProps> = ({ specificationParameter, onHide, isConfigurable, saveSpecification, isFeatureAService = false }) => {
    const [specificationToSave, setSpecificationToSave] = useState(specificationParameter);

    useEffect(() => {
        setSpecificationToSave(specificationParameter);
    }, [specificationParameter]);

    const updateSpecificationDialogFooter = () => (
        <div>
            <Button
                label="Close"
                className={styles.cancelBtn}
                onClick={(e) => { onHide(); e.stopPropagation(); e.preventDefault() }}
            />
            {

                isConfigurable &&
                <Button
                    className={styles.saveButton}
                    label="Apply"
                    onClick={() => {
                        saveSpecification(specificationToSave);
                        onHide();
                    }} />
            }
        </div>
    )

    return (
        <div className={styles.EditSpecificationParameterConfiguration}
            data-testid="EditSpecificationParameterConfiguration">
            <Dialog
                visible={true}
                header="Specification parameter configuration"
                onHide={() => onHide()}
                footer={updateSpecificationDialogFooter()}
                closable={false}
            >
                <SpecificationParameterConfiguration
                    specificationParameter={specificationToSave}
                    setSpecificationParameter={(specParam: SpecificationParameterDefinition) => setSpecificationToSave(specParam)}
                    isDraft={isConfigurable}
                    isFeatureAService={isFeatureAService}
                />
            </Dialog>
        </div>
    )
};

export default EditSpecificationParameterConfiguration;
