import React, {FC, useEffect, useState} from 'react';
import styles from './InputTextWithSave.module.scss';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import { FieldIdentifiers, formValidation } from '../../utils/validationUtil';

export interface InputTextWithSaveProps {
    value: string,
    saveText: Function,
    cancel: Function,
    fieldName: FieldIdentifiers,
    isRequired: boolean,
    validationMessage?: string,
    dataTestIds?: string[]
}

const InputTextWithSave: FC<InputTextWithSaveProps> = ({value, saveText, cancel, fieldName, isRequired, validationMessage, dataTestIds = ['inputTextWithSave','inputText','saveBtn','cancelBtn']}) => {

    const [inputValue, setInputValue] = useState(value);

    return (
        <div className={styles.InputTextWithSave} data-testid={dataTestIds[0]}>
            <div className={styles.InputText}>
                <InputText className={styles.Input} value={inputValue} onChange={(e) => setInputValue(e.target.value)} data-testid={dataTestIds[1]} />
                
            </div>
            <div className={styles.InputActionButtons}>
                <div>
                    {   validationMessage !=="" ?
                        formValidation(inputValue, fieldName, isRequired, validationMessage) :
                        formValidation(inputValue, fieldName, isRequired)
                    }
                </div>
                <div>
                    <Button icon="pi pi-check"
                            className={`p-button-outlined p-button-secondary`}
                            onClick={() => saveText(inputValue)}
                            disabled={formValidation(inputValue, fieldName, isRequired) != ""} data-testid={dataTestIds[2]}/>
                    <Button icon="pi pi-times"
                            className={`p-button-outlined p-button-secondary`}
                            onClick={e => cancel(e)} data-testid={dataTestIds[3]}/>
                </div>
            </div>
        </div>
    );
}

export default InputTextWithSave;