import React, { lazy, Suspense } from 'react';

const LazyUsersView = lazy(() => import('./UsersView'));

const UsersView = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyUsersView {...props} />
  </Suspense>
);

export default UsersView;
