import React, { lazy, Suspense } from 'react';
import { ComponentDetailProps } from './ComponentDetail';

const LazyComponentDetail = lazy(() => import('./ComponentDetail'));

const ComponentDetail = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyComponentDetail {...props} />
  </Suspense>
);

export default ComponentDetail;
