import { FeatureDefinition, SpecificationParameterDefinition } from "../../../data/model/DataModels";
import styles from "./ShowSpecification.module.scss";
import EditSpecificationParameterConfiguration from "../../../features/EditSpecificationParameterConfiguration/EditSpecificationParameterConfiguration";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { sanitizeHTML } from "../../RichTextCustomHeader/RichTextCustomHeaderComponent";
import FeatureSpecificationComponent from "../FeatureSpecificationComponent/FeatureSpecificationComponent2";
import { FC, useMemo, useState } from "react";
import { ReactComponent as FlowLogo } from '../../../assets/icons/maersk/flow.svg';
import { ReactComponent as PricingIcon } from '../../../assets/icons/maersk/dollar-circle.svg';
import ViewOrEditSpecConfig from "../../ViewOrEditSpecConfig/ViewOrEditSpecConfig";
import SpecificationParameterOptionality from "../../SpecificationParameterOptionality/SpecificationParameterOptionality";
import { cloneDeep } from "lodash";


export interface ShowSpecificationProps {
    associatedSpecifications: SpecificationParameterDefinition[],
    specification: SpecificationParameterDefinition,
    index: number,
    isConfigurable: boolean,
    isAuthorisedToEdit: boolean,
    usedIn: string,
    feature?: FeatureDefinition,
    saveSpecification: Function,
    createOrEditSpecificationParameter?: Function,
    isLinkedFeatureInDraft?: boolean,
    deleteSpecificationParam?: Function,
    showExcludedOptionsInViewMode?: boolean,
    sourceComponent?: string
    isFeatureSidePanel?: boolean
    saveSpecificationParameterOptionality: Function
}

const SPDefaultLabel = {
    included: "Core",
    not_included: "Optional",
    none: ""
} as any;


const ShowSpecification: FC<ShowSpecificationProps> = ({
    associatedSpecifications,
    specification,
    index,
    isConfigurable = false,
    isAuthorisedToEdit = false,
    usedIn,
    feature,
    saveSpecification,
    createOrEditSpecificationParameter,
    isLinkedFeatureInDraft,
    deleteSpecificationParam,
    showExcludedOptionsInViewMode,
    sourceComponent,
    isFeatureSidePanel = false,
    saveSpecificationParameterOptionality
}) => {
    const [specificationCodeForConfigurationDialog, setSpecificationCodeForConfigurationDialog] = useState("");
    const serviceDefault: string = specification?.serviceDefault || specification.definition?.serviceDefault || "none";
    const [showWarning, setShowWarning] = useState(false);

    if(!feature){
        return <></>
    }

    const generateDisabledDeleteButtonMessage = (specificationParameter: SpecificationParameterDefinition) => {
        if (associatedSpecifications.length <= 1) {
            return "Feature should have at least one specification parameter";
        }
        if (usedIn == "definition" && specificationParameter?.associatedProducts && specificationParameter.associatedProducts.length > 0) {
            return "Specification Parameter is associated with one or more products";
        }
        if (specificationParameter.serviceDefault === "included") {
            if (!associatedSpecifications.some(parameter => parameter.code !== specificationParameter.code && parameter.serviceDefault === "included")) {
                return "This is the only core specification parameter for the core feature";
            }
        }
        return "";
    }

    const checkEditSpecificationParameter = () => {
        return ((createOrEditSpecificationParameter && isLinkedFeatureInDraft)
            || (isAuthorisedToEdit && ((usedIn == "definition" && isLinkedFeatureInDraft && deleteSpecificationParam) || (usedIn == "product" && isConfigurable)))
            || feature?.status === 'active'
            || feature?.status === 'outdated'
            || sourceComponent === 'componentDetail'
            )
    }

    const getSpecificationParameterAssociationForSpecDef = () => {
        const specificationParamDef = associatedSpecifications.find(spec => specification.code === spec.code);
        return specificationParamDef ? specificationParamDef : {} as SpecificationParameterDefinition;
    }

    const totalCoreSps = useMemo(() => {
        return associatedSpecifications?.filter(sp => sp.serviceDefault === 'included').length;
    }, [associatedSpecifications]);

    const onOptionalityRadioButtonClick = (serviceDefault: "included" | "not_included" | "none") => {
        if (totalCoreSps === 1 && serviceDefault === "not_included") {
            setShowWarning(true);
            return;
        }
        else {
            setShowWarning(false);
            let clonedSP = cloneDeep(specification);
            clonedSP.serviceDefault = serviceDefault;
            saveSpecificationParameterOptionality(clonedSP);
        }
    }

    return (
        <div key={specification.code}>
            <div className={styles.specification}>
                <div className={styles.specificationHeader}>
                    <div className={styles.dollarWithSpecName}>
                        <div>
                            <h4 data-testid="SpecParamName" className={styles.specNameAndIcon}>
                                <FlowLogo />
                                {specification.name}
                            </h4>
                        </div>
                        {specification.isPriceNegotiable &&  (<div className={styles.dollarLabelBox}><PricingIcon /></div>)}
                    </div>
                    {
                        specificationCodeForConfigurationDialog == specification.code &&
                        <EditSpecificationParameterConfiguration
                            specificationParameter={specification}
                            onHide={() => setSpecificationCodeForConfigurationDialog("")}
                            saveSpecification={(specificationParam: SpecificationParameterDefinition) => saveSpecification(specificationParam)}
                            isFeatureAService={feature.service}
                            isConfigurable={isConfigurable}
                            usedIn={usedIn}
                            associatedSpecParam={associatedSpecifications}
                        />
                    }

                    <div className={styles.actionButtons}>
                        {
                            feature?.service &&
                            <div className={styles.badgeStyle}>
                                { isConfigurable ?
                                    <SpecificationParameterOptionality 
                                        specificationParameter={specification}
                                        optionalityChangeFn={onOptionalityRadioButtonClick}
                                        isSPOptionalityEnabled={true} 
                                        showWarning={showWarning}
                                        isFeatureAService={feature?.service}/>
                                    :
                                    <Tag className={serviceDefault === 'included' ? styles.coreServiceTag : serviceDefault === 'not_included' ? styles.optionalServiceTag : styles.noneServiceTag}
                                        rounded>
                                        <div className={styles.tagName}>{SPDefaultLabel[serviceDefault]}</div>
                                    </Tag>
                                }
                            </div>
                        }
                        {
                            checkEditSpecificationParameter() &&
                            <div className={styles.actionButtons}>
                                {
                                    createOrEditSpecificationParameter && isLinkedFeatureInDraft &&
                                    <Button icon="pi pi-pencil" data-testid="editSpecification" text aria-label="Filter"
                                        severity="secondary"
                                        onClick={() => createOrEditSpecificationParameter && createOrEditSpecificationParameter(specification)}
                                        disabled={!isAuthorisedToEdit} />
                                }
                                {
                                    (usedIn === 'product' || (feature?.status === 'active') || (sourceComponent === 'componentDetail')) && (usedIn !== "featureCompare") && !isFeatureSidePanel &&
                                    <Button icon="pi pi-cog" text aria-label="SP Settings"
                                        severity="secondary"
                                        onClick={(e) => {e.stopPropagation(); e.preventDefault(); setSpecificationCodeForConfigurationDialog(specification.code)}}
                                    />
                                }
                                {
                                    isAuthorisedToEdit && ((usedIn == "definition" && isLinkedFeatureInDraft && deleteSpecificationParam) || (usedIn == "product" && isConfigurable)) &&
                                    <div>
                                        <Button icon="pi pi-trash" data-testid="deleteSpecification"
                                            severity="secondary" text aria-label="Filter"
                                            disabled={generateDisabledDeleteButtonMessage(specification) != "" || !isAuthorisedToEdit}
                                            onClick={() => deleteSpecificationParam && deleteSpecificationParam(specification)}
                                            tooltip={generateDisabledDeleteButtonMessage(specification)} tooltipOptions={{ showOnDisabled: true }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>

                </div>
                <div className={styles.specificationInnerContent}>
                    <div className={styles.specificationDescriptionAndConfig}>

                        <div data-testid="SpecParamDesc"
                            dangerouslySetInnerHTML={{ __html: sanitizeHTML(specification.descriptionRichText?.toString()) }}
                            className={styles.displayDescription} />
                        <div className={styles.specificationConfiguration}>
                            <FeatureSpecificationComponent specification={specification}
                                saveSpecification={saveSpecification}
                                definition={specification.definition}
                                editMode={isConfigurable}
                                showExcludedOptionsInViewMode={showExcludedOptionsInViewMode} />
                        </div>
                    </div>
                </div>

            </div>
            {isFeatureSidePanel && <ViewOrEditSpecConfig specificationParameter={getSpecificationParameterAssociationForSpecDef()} setSpecificationParameter={() => {}} isDraft={false} isFeatureAService={feature?.service} /> }
            {index < (associatedSpecifications.length - 1) && <div className={styles.divider}>
            <hr />
            </div>}
        </div>
    )
}

export default ShowSpecification;
