import { cloneDeep } from "lodash";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import React, { FC } from "react";
import { OptionValue, SpecificationParameterDefinition } from "../../../data/model/DataModels";
import styles from "./FeatureSpecificationComponent.module.scss";
import { InputSwitch } from "primereact/inputswitch";

interface FeatureSpecificationComponentProps {
	specification: SpecificationParameterDefinition;
	definition?: SpecificationParameterDefinition;
	editMode?: boolean;
	showExcludedOptionsInViewMode?: boolean;
	saveSpecification?: Function;
}

const generateOptionsOptions = (specValues: OptionValue[] = [], definitionValues: OptionValue[] = [], handleInput: Function, editMode: boolean = false, showExcludedOptionsInViewMode = false) => {

	const allValues = (editMode || showExcludedOptionsInViewMode) ? definitionValues : specValues;
	const selectedOptionCodes = specValues.map(value => value.code);
	const defaultOptionCodes = specValues.filter(value => value.isDefault).map(value => value.code);

	const onOptionChange = (e: CheckboxChangeEvent) => {
		let updatedSpecValues = cloneDeep(specValues);
		if (e.checked) {
			updatedSpecValues.push(e.value);
		} else {
			updatedSpecValues = updatedSpecValues.filter(item => e.value.code !== item.code);
		}

		handleInput(updatedSpecValues);
	};

	const onOptionDefaultChange = (code: string, e: CheckboxChangeEvent) => {
    let updatedSpecValues: OptionValue[] = [];
    let clonedSpecValues = cloneDeep(specValues);
    clonedSpecValues.forEach((specValue) => {
      specValue.isDefault = false;
      if (specValue.code === code) {
        specValue.isDefault = e.target.value;
      }
      updatedSpecValues.push(specValue);
    }
    )
    handleInput(updatedSpecValues);
  };

	const doesSpContainsOneOption = (code: string) => {
		if(specValues.length === 1 && selectedOptionCodes.includes(code))
			return { disabled : true, message : "There should be at least one option within a Specification Parameter of config type option" }
		else
			return { disabled : false, message : "" }
	}

	return (
		<span>
			{
				allValues?.map((value, index) => {
					return <div key={index} className={styles.specValueItem}>
						<div className={styles.itemNameWithSwitches}>
							{
								editMode ?
									<Checkbox data-testid="optionconfigwrite" name={"option-" + index} value={value}
										checked={selectedOptionCodes?.length ? selectedOptionCodes.includes(value.code) : false}
										onChange={(e) => onOptionChange(e)}
										className="p-mr-2" style={{ verticalAlign: "text-bottom" }}
										disabled={doesSpContainsOneOption(value.code!).disabled}
										tooltip={doesSpContainsOneOption(value.code!).message} />
									:
									selectedOptionCodes.includes(value.code) ? <div data-testid="optionconfigread" className={styles.Check + " pi pi-check "} /> : <div data-testid="optionconfigread" className={styles.Cross + " pi pi-times "} />
							}

							<span className="p-mr-2">{value.option}</span>
							{
                !editMode ?
                  value.isDefault && <span className={"p-ml-3 " + styles.label + " " + styles.grayLabel}>Default</span>
                  :
                  <div className={styles.defaultSwitch}>
                    <span>Default</span>
                    <InputSwitch data-testid={`defaultOptionsButton${index}`} disabled={!(selectedOptionCodes?.length ? selectedOptionCodes.includes(value.code) : false)} checked={defaultOptionCodes.includes(value.code)} name='isDefault' onChange={(e) => onOptionDefaultChange(value.code || "", e)} />
                  </div>
              }
						</div>
						<div className={styles.specValueDescription}>
							<span>{value.description}</span>
						</div>
					</div>
				})
			}
		</span>
	);
};

// const generateSelectionOptions = (specValues: any[] = [], definitionValues: any[] = [], activeSpecificationValue: any[], handleInput: Function) => {

// 	const onOptionChange = (e: CheckboxChangeEvent) => {
// 		const updatedSpecValues = cloneDeep(specValues);
// 		if (e.checked)
// 			updatedSpecValues.push(e.value);
// 		else
// 			updatedSpecValues.splice(updatedSpecValues.indexOf(e.value), 1);

// 		handleInput(updatedSpecValues);
// 	};
// 	let changedValues: any = [];
// 	if (activeSpecificationValue) {
// 		const allValues = specValues?.concat(activeSpecificationValue);
// 		changedValues = allValues?.filter(element => !(specValues.includes(element) && activeSpecificationValue?.includes(element)));
// 	}

// 	return (
// 		<span className="p-d-inline-flex p-flex-wrap p-ai-center">
// 			{
// 				definitionValues?.map((value, index) =>
// 					<span key={index} className={styles.specValueItem}>
// 						<Checkbox name={value} value={value}
// 							checked={specValues ? specValues.includes(value) : false} onChange={(e) => onOptionChange(e)}
// 							className="p-mr-2" style={{ verticalAlign: "text-bottom" }} />
// 						<span className="p-mr-3">{casingForTitle(value, changedValues?.includes(value))}</span>
// 					</span>
// 				)
// 			}
// 		</span>
// 	);
// };

const FeatureSpecificationComponent: FC<FeatureSpecificationComponentProps> = ({ specification, definition, saveSpecification, editMode = false, showExcludedOptionsInViewMode = false }) => {
	if (!specification) return null;

	const handleInput = (featureValue: any) => {
		const updatedSpecification = cloneDeep(specification);
		updatedSpecification.configuration.value = featureValue;
		// updatedSpecifications.configuration.valueRepresentation = featureValue.toString();
		saveSpecification && saveSpecification(updatedSpecification);
	};

	const isConfigMissing = (specificationValue: any): boolean =>
		(specificationValue === undefined || specificationValue === "" || specificationValue === null)

	const renderSpecificationBasedOnType = () => {
		if (isConfigMissing(specification.configuration.value) && !editMode) {
			return <span className={styles.isMissing}>(configuration is missing)</span>
		}
		switch (specification.configuration.type) {
			case "text":
				if (editMode) {
					return (
						<span data-testid="textconfigwrite">
							<InputTextarea value={specification.configuration.value} onChange={(e) => handleInput(e.target.value)} style={{ width: "100%" }} className={styles.textAreaSize} rows={5} cols={50} autoResize />
						</span>
					);
				} else {
					return <span data-testid="textconfigreadonly">{specification.configuration.value}</span>
				}
			// case "selection":
			// 	return generateSelectionOptions(specification.configuration.value as any[], definition?.configuration.value as any[], activeSpecificationValue, handleInput);
			case "options":
				return generateOptionsOptions(specification.configuration.value as any[], definition?.configuration.value as any[], handleInput, editMode, showExcludedOptionsInViewMode);
			default:
				return "";
		}
	};

	return (
		<div data-testid="FeatureSpecificationComponent" className={styles.FeatureSpecificationComponent}>
			{renderSpecificationBasedOnType()}
		</div>
	);

};

export default FeatureSpecificationComponent;
