import { FeatureAssociationRequest, FeatureDefinition, SpecificationParameterAssociationRequest, SpecificationParameterDefinition } from "./data/model/DataModels";

export const getFeatureAssociationRequestsFromFeatureDefinitions = (features: FeatureDefinition[]) => {
	const featureAssociations: FeatureAssociationRequest[] = [
		...features.map(feature => {
			return {
				featureRef: feature.code,
				definition: feature,
				componentRef: feature.componentRef,
				featureVersion: feature.version,
				serviceDefault: feature.serviceDefault,
				service: feature.service
			} as FeatureAssociationRequest;
		})
	];

	return featureAssociations;
}

export const getSpecParamAssociationRequestsFromSpecParamDefinitions = (specParams: SpecificationParameterDefinition[]) => {
	if(!specParams){
		return [];
	}
	return specParams.map(specificationParameter => {
		return {
			featureRef: specificationParameter.featureRef,
			featureVersion: specificationParameter.featureVersion,
			componentRef: specificationParameter.componentRef,
			specificationParamRef: specificationParameter.code,
			configuration: specificationParameter.configuration,
			configurableAtContracting: specificationParameter.configurableAtContracting,
			configurableAtBooking: specificationParameter.configurableAtBooking,
			serviceDefault: specificationParameter.serviceDefault
		} as SpecificationParameterAssociationRequest;
	}
	);
}