import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import SpinnerComponent from "../../../../components/Spinner/SpinnerComponent";
import { ComponentDefinition, FeatureAssociation, FeatureDefinition, } from "../../../../data/model/DataModels";
import { useGetComponentByRefForFeatureAssociationQuery } from "../../../../data/api/CatalogueApi";
import styles from "./DisplayFeatureCards.module.scss";
import DisplayCardsComponent, { DisplayCardElement } from "../../../../components/DisplayCards/DisplayCardsComponent";

interface DisplayFeatureCardsProps {
	componentRef: string,
	header?: string,
	onHide: Function,
	acceptAction: Function,
	activeFeatures: string[];
}

const DisplayFeatureCards: React.FC<DisplayFeatureCardsProps> = ({ componentRef, header = "", onHide, acceptAction, activeFeatures }) => {
	const [featuresToAdd, setFeaturesToAdd] = useState([] as FeatureAssociation[]);
	const featuresToAddCodes = featuresToAdd.map(feature => feature.featureRef);

	const { data: componentDetails = {} as ComponentDefinition, error: errorComponent, isLoading: isLoadingComponent } = useGetComponentByRefForFeatureAssociationQuery(componentRef);
	const toast = useRef<any>();

	if (errorComponent) {
		toast?.current?.show({ severity: 'error', summary: 'Failed to fetch Features', detail: "Please try reloading", life: 10000 });
	}

	if (isLoadingComponent) {
		return <SpinnerComponent></SpinnerComponent>;
	}

	const getSpecParamByCode = (code: string): FeatureDefinition => {
		return componentDetails.features?.find(feature => feature.code === code)!;
	}


	const selectFeature = (element: DisplayCardElement) => {
		let feature = getSpecParamByCode(element.code);
		let featureAssociation = {
			productId: "",
			featureRef: feature.code,
			componentRef: feature.componentRef,
			definition: feature,
			serviceDefault: feature.serviceDefault,
			service: feature.service,
			featureVersion: feature.version
		} as FeatureAssociation;
		if (featuresToAdd.map(feature => feature.featureRef).includes(featureAssociation.featureRef)) {
			setFeaturesToAdd(featuresToAdd.filter(featureToAdd => feature.code !== featureToAdd.featureRef))
		} else {
			setFeaturesToAdd([...featuresToAdd, featureAssociation])
		}
	}

	const cancelAction = () => {
		onHide();
	}

	const mapElementList = (): DisplayCardElement[] => {
		return componentDetails?.features?.filter(feature => !activeFeatures.includes(feature.code) && feature.status === "active").map(feature => {
			return { code: feature.code, name: feature.name, specParamElements: feature.specificationParameters.map(specParam => specParam.name) }
		});
	}

	const footer = (
		<div className={styles.dialogFooter}>
			<Button label="Cancel" icon="pi pi-times" onClick={() => cancelAction()} />
			<Button label="Add" data-testid="addFeaturesButton" icon="pi pi-check" onClick={() => { acceptAction(featuresToAdd) }} disabled={featuresToAdd.length === 0} />
		</div>
	);

	return (
		<Dialog footer={footer} header={header + " Features"} visible className={styles.dialogSize} onHide={() => onHide(false)}>
			<div data-testid="featureCardDilog" className={styles.dialogContent}>
				<DisplayCardsComponent action={selectFeature}
					selectedCodes={featuresToAddCodes}
					dialogView={true} editMode={true} elementList={mapElementList()}></DisplayCardsComponent>
			</div>
		</Dialog>
	)
}

export default DisplayFeatureCards;
