import { Dialog } from "primereact/dialog";
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "./CreateSolutionDialog.module.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FieldIdentifiers, formValidation } from "../../utils/validationUtil";
import { Header, HeaderTypes, ProductListing, SOLUTION_DEFAULT_HEADER_NAMES, SolutionCreation, SolutionType, SolutionTypes, serviceModelTypeDropDownList } from "../../data/model/DataModels";
import { MultiSelect } from "primereact/multiselect";
import ProductSelectionDialog from "../ProductSelectionDialog/ProductSelectionDialog";
import { useCreateNewSolutionMutation } from "../../data/api/CatalogueApi";
import { ToasterContext } from "../../features/AppLayoutView/AppLayoutView";
import SpinnerComponent from "../Spinner/SpinnerComponent";
import { useAnchor } from "../../authorisationService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import PATHS from "../../Paths";


interface CreateSolutionProps {
	onHide: Function;
}

const CreateSolutionDialog: FunctionComponent<CreateSolutionProps> = ({ onHide }) => {
	const [createNewSolution, { isLoading: isLoadingCreateNewSolution }] = useCreateNewSolutionMutation();
	let invalidFields: string[] = [];
	const [isFormValid, setIsFormValid] = useState(true);
	const [solutionData, setSolutionData] = useState<SolutionCreation>({} as SolutionCreation);
	const [showProductSelectionDialog, setShowProductSelectionDialog] = useState(false);
	const toaster = useContext(ToasterContext);
	const { refreshAnchorToken } = useAnchor();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();


	const createSolution = (solutionData: SolutionCreation) => {
		createNewSolution(solutionData).unwrap().then(async (solutionState) => {
			toaster.showToast("success", solutionState.productType === SolutionType.PACKAGED_SOLUTION ? "Products are added and solution creation is successful" : "Successfully created solution");
			refreshAnchorToken();
			onHide();
			solutionState.productType === SolutionType.PACKAGED_SOLUTION
				? navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", solutionState.code).replace(":version", (solutionState.version).toString() + "#products"))
				: navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", solutionState.code).replace(":version", (solutionState.version).toString()));
		},
			() => {
				toaster.showToast("error", solutionData.productType === SolutionType.PACKAGED_SOLUTION ? "Failed to add products and create solution" : "Failed to create solution");
			}
		)
	}


	const updateSelectedProducts = (updatedProducts: ProductListing[]) => {
		const updatedSolutionDTO = { ...solutionData, products: updatedProducts };
		createSolution(updatedSolutionDTO);
	};


	useEffect(() => {
		setIsFormValid(invalidFields.length == 0 && checkRequiredFieldValidation());
	})



	const checkFormValidity = (value: string, fieldIdentifier: FieldIdentifiers, fieldName: string) => {
		const validityResult = formValidation(value, fieldIdentifier);

		if (validityResult !== "") {
			if (!invalidFields.includes(fieldName)) {
				invalidFields = [...invalidFields, fieldName];
			}
		} else {
			if (invalidFields.length > 0) {
				invalidFields = invalidFields.filter(field => field !== fieldName);
			}

		}
		return validityResult;
	}

	const checkRequiredFieldValidation = () => {
		if (
			(solutionData?.productType && solutionData.productType?.length > 0) &&
			(solutionData.name?.length > 0) && solutionData.headersInfo?.serviceModel?.length! > 0
		) {
			return true;
		}
		else {
			return false;
		}

	};

	const SelectionOrCreationButtons = () => {
		if (solutionData?.productType === SolutionType.PACKAGED_SOLUTION) {
			return <Button disabled={!isFormValid} label={"Next"} icon="pi pi-check" onClick={() => setShowProductSelectionDialog(true)}
				tooltip={!isFormValid ? "Please enter required/valid solution information" : ""} tooltipOptions={{ appendTo: "self", showOnDisabled: true }} data-testid="createSolutionButton" />
		}
		return <Button disabled={!isFormValid} label={"Create"} icon="pi pi-check" onClick={() => createSolution(solutionData)}
			tooltip={!isFormValid ? "Please enter required/valid solution information" : ""} tooltipOptions={{ appendTo: "self", showOnDisabled: true }} data-testid="createSolutionButton" />
	}

	const footer = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => { onHide() }} />
			{
				SelectionOrCreationButtons()
			}
		</div>
	);

	const setSolutionTagLine = (e: ChangeEvent<HTMLInputElement>) => {
		// const setDescription = { ...solutionData.header, description: e.target.value, descriptionRichText: e.target.value }
		const setSalesInfo = { ... solutionData.salesInfo, tagline: e.target.value }
		const updateSolutionDTO = { ...solutionData , salesInfo: setSalesInfo }
		setSolutionData(updateSolutionDTO);
	}



	return (
		<>
			{
				!showProductSelectionDialog &&
				<Dialog visible={true} onHide={() => { onHide(false) }}
					header={"Create new solution"} footer={footer}
					className={styles.createSolutionDialog} data-testid="addNewSolutiontDialog">
					{
						isLoadingCreateNewSolution && <SpinnerComponent></SpinnerComponent>
					}
					<div className={styles.sectionBlock}>
						<div className={styles.inputGroup}>
							<span className={styles.label}>Solution Name<label className={styles.optionLabel}>*</label></span>
							<span className={styles.valueField}>
								<InputText className={checkFormValidity(solutionData.name, FieldIdentifiers.SOLUTION_TITLE, "solutionTitle") === "" ? "" : "p-invalid"} value={solutionData.name || ''}
									onChange={(e) => setSolutionData({ ...solutionData, name: e.target.value.trimStart() })} placeholder="Enter name" />
								{checkFormValidity(solutionData.name, FieldIdentifiers.SOLUTION_TITLE, "solutionTitle")}
							</span>
						</div>
						<div className={styles.inputGroup}>
							<span className={styles.label}>Solution type<label className={styles.optionLabel}>*</label></span>
							<span className={styles.valueField}>
								<Dropdown className={styles.dropdown}
									optionLabel="name"
									optionValue="code"
									placeholder="Select type"
									options={SolutionTypes}
									value={solutionData.productType}
									onChange={(e) => {
										setSolutionData({ ...solutionData, productType: e.target.value as SolutionType })
									}
									} />
							</span>
						</div>
						<div className={styles.inputGroup}>
							<span className={styles.label}>Short Description</span>
							<span className={styles.valueField}>
								<InputText className={checkFormValidity(solutionData.salesInfo?.tagline, FieldIdentifiers.PRODUCT_TAGLINE, "productTagline") === "" ? "" : "p-invalid"} value={solutionData.salesInfo?.tagline || ''}
									onChange={(e) => setSolutionTagLine(e)} />
								{checkFormValidity(solutionData.salesInfo?.tagline, FieldIdentifiers.PRODUCT_TAGLINE, "productTagline")}
							</span>
						</div>
						<div className={styles.inputGroup}>
							<span className={styles.label}>Service model<label className={styles.optionLabel}>*</label></span>
							<MultiSelect
								panelHeaderTemplate={(<></>)}
								optionLabel="name"
								placeholder="Select service model"
								value={solutionData?.headersInfo?.serviceModel}
								options={serviceModelTypeDropDownList}
								onChange={(e) => {
									const defaultHeaders = {};
									//Since serviceModel is mandatory, so filtering that
									Object.values(SOLUTION_DEFAULT_HEADER_NAMES).filter((header: string) => header !== HeaderTypes.SERVICE_MODEL).forEach(value => Object.assign(defaultHeaders, { [value]: [] }))
									setSolutionData({ ...solutionData, headersInfo: { ...solutionData.headersInfo, ...defaultHeaders, serviceModel: e.target.value as Header[] } })
								}}
								display="chip"
							/>
						</div>
					</div>
				</Dialog>
			}
			{
				(showProductSelectionDialog) &&
				<ProductSelectionDialog onHide={() => setShowProductSelectionDialog(false)} saveProductSelection={updateSelectedProducts} isLoadingUpdateSelection={isLoadingCreateNewSolution}></ProductSelectionDialog>
			}
		</>
	)

}

export default CreateSolutionDialog;

