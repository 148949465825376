import React, {FunctionComponent} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import "./SortableItem.scss"

interface OwnProps {
  id: string | number
  content: React.ReactNode,
  dragEnable?: boolean
}

type Props = OwnProps;

const SortableItem: FunctionComponent<Props> = (props) => {

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.4 : 1,
  };

  return (
      <div ref={setNodeRef} style={style} className={isDragging ? "ghostElement" : ""} {...(props.dragEnable ? attributes : {})} {...(props.dragEnable ? listeners : {})}>
        {props.content}
      </div>
  );
};

export default SortableItem;
