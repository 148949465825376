import { FC, useRef, useState } from 'react';
import styles from './FeatureDetailPanel.module.scss';
import './overrides.scss'
import { ConfigurableControl, FeatureAssociation, ServiceDefaultLabel, SpecificationParameterDefinition } from '../../data/model/DataModels';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../Paths';
import SpecificationList from '../FeatureCardIncremental/SpecificationList/SpecificationList';
import FeatureAsServiceDefaultOptions from '../FeatureAsService/FeatureAsServiceDefaultOptions/FeatureServiceDefaultOptions';
import { cloneDeep } from 'lodash';
import DisplaySpecificationParameterCards from '../../features/ProductView/ProductFeatures/DisplaySpecificationParameterCards/DisplaySpecificationParameterCards';
import { Button } from 'primereact/button';

interface FeatureDetailPanelProps {
  onHide: Function;
  feature: FeatureAssociation;
  specificationParameters: SpecificationParameterDefinition[];
  isEditable?: boolean;
  selectedSpecParamCode?: string;
  saveChanges?: Function;
  deleteSpecification?: Function
}

const FeatureDetailPanel: FC<FeatureDetailPanelProps> = ({ feature, specificationParameters, onHide, isEditable = false, selectedSpecParamCode = "", saveChanges, deleteSpecification }) => {

  const navigate = useNavigate();
  const childRef = useRef<any>(null);
  const [showSpecificationParamModal, setShowSpecificationParamModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [featureLocal, setFeatureLocal] = useState(feature);
  const [specificationParametersLocal, setSpecificationParametersLocal] = useState(specificationParameters);

  const saveFeatureOptionality = (featureServiceValue: "included" | "not_included" | "none", isChangedFromCoreToOptional: boolean) => {
    let updatedFeature = { ...featureLocal, serviceDefault: featureServiceValue }

    let updatedSPs;
    if (isChangedFromCoreToOptional) {
      updatedSPs = specificationParametersLocal.map((sp) => {
        let updatedSP = sp.featureRef === featureLocal.featureRef;
        if (updatedSP) {
          let config: ConfigurableControl = cloneDeep(sp.configurableAtContracting!);
          config.enabled = false;
          config.allowMultiselect = false;
          config.choiceRequired = false;
          return {
            ...sp,
            serviceDefault: "not_included",
            configurableAtContracting: config
          } as SpecificationParameterDefinition;
        }
        return sp;
      });
      setSpecificationParametersLocal(updatedSPs);
    }
    setFeatureLocal(updatedFeature);
    // saveChanges && saveChanges(updatedFeature, updatedSPs);
  };

  const showServiceDefault = () => {
    if (editMode) {
      return <FeatureAsServiceDefaultOptions
        serviceDefaultFromParent={featureLocal.serviceDefault}
        saveServiceDefault={(featureServiceValue: "included" | "not_included" | "none", isChangedFromCoreToOptional: boolean) => saveFeatureOptionality(featureServiceValue, isChangedFromCoreToOptional)}
      />
    }
    return (
      <div className={styles.serviceDefaultAndDollar}>
        <div className={`${styles.labelBox} 
                        ${featureLocal.serviceDefault === "included" ? styles.labelBoxCore : featureLocal.serviceDefault === "not_included" ? styles.labelBoxOptional : styles.labelBoxNonService}`}>
          <div className={styles.label}>
            {ServiceDefaultLabel[featureLocal.serviceDefault]}
          </div>
        </div>
      </div>
    )
  }

  const addSpecificationParameter = (specParams: SpecificationParameterDefinition[]) => {

    // const mapSpecParamDefinitionToAssociation = (): SpecificationParameterAssociation[] => {
    //   return specParams.map(specParam => {
    //     return {
    //       featureRef: specParam.featureRef,
    //       featureVersion: specParam.featureVersion,
    //       componentRef: specParam.componentRef,
    //       definition: specParam,
    //       specificationParamRef: specParam.code,
    //       configuration: specParam.configuration,
    //       configurableAtContracting: specParam.configurableAtContracting,
    //       configurableAtBooking: specParam.configurableAtBooking,
    //       note: "",
    //       serviceDefault: featureLocal.serviceDefault === 'not_included' ? 'not_included' : specParam.serviceDefault
    //     } as SpecificationParameterAssociation
    //   })
    // }

    setSpecificationParametersLocal([...specificationParametersLocal, ...specParams.map(param => { return { ...param, definition: param } })]);

    // saveChanges && saveChanges(null, [...specificationParametersLocal, ...mapSpecParamDefinitionToAssociation()]);

  }

  return (
    <Sidebar visible={true} showCloseIcon={false} position="right" onHide={() => onHide()}>
      <div className={styles.featureDetailPanel}>
        <div className={`${styles.featureHeaderSection} ${styles.section}`}>
          <div className={styles.featureHeader}>
            <h2>{featureLocal.definition.name}</h2>
            <i className={`pi pi-times ${styles.closeIcon}`} onClick={() => onHide()} />
          </div>
          <div className={styles.featureVersionServiceType}>
            <div className={styles.featureVersion}>
              v{featureLocal.definition.version} - {featureLocal.definition.status}
              <i
                className={`pi pi-arrow-up-right ${styles.arrowUp}`}
                onClick={() =>
                  navigate(
                    PATHS.FEATURES_BY_CODE_VERSION.replace(
                      ":featureCode",
                      featureLocal.definition.code
                    ).replace(":version", featureLocal.definition.version!.toString())
                  )
                }
                data-testid="featureCodeClick"
              />
            </div>
            <div className={styles.serviceType}>
              {
                showServiceDefault()
              }
            </div>
            {
              isEditable &&
              <div className={styles.actionButtons}>
                {
                  !editMode ?
                    <Button icon="pi pi-pencil" className="p-button-outlined p-button-secondary" onClick={() => setEditMode(true)} />
                    :
                    <>
                      <Button icon="pi pi-check" className="p-button-outlined p-button-secondary" onClick={() => { saveChanges && saveChanges(featureLocal, specificationParametersLocal); setEditMode(false); childRef?.current?.resetWarnings() }} />
                      <Button icon="pi pi-times" className="p-button-outlined p-button-secondary" onClick={() => { setEditMode(false); setFeatureLocal(feature); setSpecificationParametersLocal(specificationParameters); childRef?.current?.resetWarnings() }} />
                    </>
                }
              </div>
            }
          </div>
        </div>
        <hr className={styles.seperator} />
        <div className={`${styles.featureDescriptionSection} ${styles.section}`}>
          <div className={styles.featureDescriptionLabel}>Feature Description</div>
          <p>{featureLocal.definition.description}</p>
        </div>
        <div className={styles.specificationParamSection}>
          <SpecificationList
            specificationParameters={specificationParametersLocal || []}
            saveSpecificationParameters={(specificationParams: SpecificationParameterDefinition[]) => { setSpecificationParametersLocal(specificationParams) }}
            feature={featureLocal.definition}
            deleteSpecificationParam={(specParam: SpecificationParameterDefinition) => { setSpecificationParametersLocal(specificationParametersLocal.filter(param => param.code !== specParam.code)) }}
            addSpecificationParameter={() => setShowSpecificationParamModal(true)}
            isConfigurable={editMode}
            showExcludedOptionsInViewMode={false}
            usedIn={"product"}
            selectedSpecParamCode={selectedSpecParamCode}
            ref={childRef}
          />
        </div>
      </div>
      {
        showSpecificationParamModal &&
        <DisplaySpecificationParameterCards header={featureLocal.definition.name} actionShowDialog={() => setShowSpecificationParamModal(false)} activeSpecificationParameters={specificationParametersLocal.map(sp => sp.code)} componentRef={featureLocal.componentRef} featureRef={featureLocal.featureRef} acceptAction={(specParams: SpecificationParameterDefinition[]) => addSpecificationParameter(specParams)} />
      }

    </Sidebar>

  )

};

export default FeatureDetailPanel;
