import React, { FC, useContext, useMemo, useState } from 'react';
import styles from './SolutionProductAssociation.module.scss';
import { SolutionContext, constructBaseSolutionRequest, solutionTabs } from '../SolutionDetails/SolutionDetails';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { Button } from 'primereact/button';
import { ProductListing, SolutionDefnitionRequest, SolutionProductUpgradeDTO } from '../../data/model/DataModels';
import ProductSelectionDialog from '../../components/ProductSelectionDialog/ProductSelectionDialog';
import { useUpdateSolutionMutation, useUpgradeSolutionProductMutation } from '../../data/api/CatalogueApi';
import PATHS from '../../Paths';
import { cloneDeep } from 'lodash';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { Dialog } from 'primereact/dialog';
import UpgradeSolutionProductView from '../SolutionDetails/UpgradeSolutionProductView/UpgradeSolutionProductView';
import { useNavigate } from 'react-router-dom';
import TruncatedText from '../../utils/TruncatedText';
import ProductCardMedium from '../ProductCardMedium/ProductCardMedium';

export interface SolutionProductAssociationProps {
  isEditable?: boolean
  isSolutionOwner?: boolean
}

const SolutionProductAssociation: FC<SolutionProductAssociationProps> = ({ isEditable = false, isSolutionOwner = false }) => {
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;
  const toaster = useContext(ToasterContext);
  const [showProductSelectionDialog, setShowProductSelectionDialog] = useState(false);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const [showUpgradeProductDialogueFor, setShowUpgradeProductDialogueFor] = useState<ProductListing>();
  const [upgradeSolutionProduct, { isLoading: isLoadingUpgradeSolutionProduct }] = useUpgradeSolutionProductMutation();
  const navigate = useNavigate();

  if (!solution) {
    return <></>
  }

  const sharedComponentsData = useMemo(() => {
    const componentOccurrences = new Map<string, string[]>();
    const componentCodeAndNameMap = new Map<string, string>();
    solution.products?.forEach((product) => {
      product.components?.forEach((component) => {
        const componentCode = component.componentRef;
        if (componentOccurrences.has(componentCode)) {
          componentOccurrences.get(componentCode)?.push(product.name);
        } else {
          componentOccurrences.set(componentCode, [product.name]);
        }
        componentCodeAndNameMap.set(componentCode, component.definition.name);
      });
    });
    const sharedComponentMap = new Map<string, string[]>();
    componentOccurrences.forEach((products, componentCode) => {
      if (products && products.length > 1) {
        sharedComponentMap.set(componentCode, products);
      }
    });
    return { "componentCodeAndProductListMap": sharedComponentMap, "componentCodeAndNameMap": componentCodeAndNameMap };
  }, [solution.products])

  const goToProduct = (product: ProductListing) => {
    let url = PATHS.PRODUCT_BY_CODE.replace(":code", product.code);
    window.open(url);
  }


  const addProductsToSolution = (productsToBeAdded: ProductListing[]) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData = { ...solutionBaseRequest, products: [...solution?.products, ...productsToBeAdded], actionType: "addProduct" } as SolutionDefnitionRequest;
    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            products: response?.products
          });
        toaster.showToast('success', 'Products are added successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to add products');
      }
    )
    setShowProductSelectionDialog(false);
  }

  const removeProductFromSolution = (productToBeRemoved: ProductListing) => {
    const updatedProducts = solution?.products.filter(product => product.code != productToBeRemoved.code);
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData = {
      ...solutionBaseRequest, products: cloneDeep(updatedProducts).map(product => {
        product.rbacResourceCode = "";
        return product;
      })
    } as SolutionDefnitionRequest;
    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            products: response?.products
          });
        toaster.showToast('success', 'Product is removed successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to remove product');
      }
    )
  }

  const upgradeProductVersion = (product: ProductListing) => {
    const solutionProductUpgradeRequestData: SolutionProductUpgradeDTO = { solutionId: solution.id, productCode: product.code, productVersion: product.latestActiveVersion };
    upgradeSolutionProduct(solutionProductUpgradeRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            products: solution.products.map(product => (product.code === response.code) ? response : product)
          });
        toaster.showToast('success', 'Product is upgraded successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to upgrade the product');
      }
    )
    setShowUpgradeProductDialogueFor(undefined);
  }

  const closeDialogueAndNavigateToProductTab = () => {
    setShowUpgradeProductDialogueFor(undefined);
    navigate(solutionTabs[2].hash); //Why?
  }

  const upgradeOrPreviewFooter = (product: ProductListing) => {
    return (
      <div>
        {(isEditable && isSolutionOwner) ?
          (
            <>
              <Button
                label={"Cancel"}
                className={styles.previewBtn}
                onClick={() => closeDialogueAndNavigateToProductTab()}
              />
              <Button
                label="Upgrade"
                className={styles.upgradeOrCloseBtn}
                onClick={() => {
                  upgradeProductVersion(product)
                  navigate(solutionTabs[2].hash);
                }}
              />
            </>)
          :
          (<Button
            label={"Close"}
            className={styles.upgradeOrCloseBtn}
            onClick={() => closeDialogueAndNavigateToProductTab()}
          />)
        }
      </div>
    );
  }

  const upgradeOrPreviewProduct = (product: ProductListing) => {
    setShowUpgradeProductDialogueFor(product);
  }

  return (
    <div className={styles.SolutionProductAssociation} data-testid="SolutionProductAssociation">
      {(isLoadingUpdateSolution || isLoadingUpgradeSolutionProduct) &&
        <SpinnerComponent />
      }
      <h2 data-testid="sharedComponents">Shared Components</h2>
      {
        sharedComponentsData.componentCodeAndProductListMap && sharedComponentsData.componentCodeAndProductListMap.size > 0 ? (<div >
          <div className={styles.sharedComponent}>
            {Array.from(sharedComponentsData.componentCodeAndProductListMap).map(([componentCode, products], index) => (
              <div key={index} className={styles.blockComponent}>
                <div>
                  {
                    <TruncatedText text={sharedComponentsData.componentCodeAndNameMap.get(componentCode)} maxLength={50} />
                  }
                </div>
                <div className={styles.productList}>
                  <span>in</span><span className={styles.products}>
                    {
                      <TruncatedText text={products.join(", ")} maxLength={100} />}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>) : <div className={styles.noSharedComponent}> - No shared components</div>
      }
      <div className={styles.productsHeader}>
        <div className={styles.productCountDropdown}>
          <h2 className={styles.productCount} data-testid="totalproduct">Products ({solution?.products.length})</h2>
        </div>
        {
          isEditable &&
          <div className={styles.productAddBtn}><Button label="Add Product" icon="pi pi-plus" className="p-button-outlined p-button-secondary" onClick={() => setShowProductSelectionDialog(true)} /></div>
        }
      </div>
      <div className={styles.productGrid}>
        {solution?.products.map((product) => <ProductCardMedium key={product.code} editMode={isEditable} onProductClick={() => goToProduct(product)} product={product} upgradeOrPreviewProduct={() => { upgradeOrPreviewProduct(product) }} deleteProduct={() => removeProductFromSolution(product)} />)}
      </div>
      {
        showProductSelectionDialog &&
        <ProductSelectionDialog onHide={() => setShowProductSelectionDialog(false)} saveProductSelection={addProductsToSolution} existingProducts={solution?.products}></ProductSelectionDialog>
      }
      {
        showUpgradeProductDialogueFor && <Dialog
          header="Upgrade new product version?"
          footer={upgradeOrPreviewFooter(showUpgradeProductDialogueFor)}
          visible={true}
          onHide={() => closeDialogueAndNavigateToProductTab()}
          style={{ minHeight: "90%", width: "90%", maxHeight: "90%" }}
          data-testid="upgradeDialogue"
        >
          <UpgradeSolutionProductView
            subHeader={isEditable && isSolutionOwner ? "You're about to upgrade this solution with the latest version of the product:" : "Latest version of the product:"}
            code={showUpgradeProductDialogueFor.code}
            version={showUpgradeProductDialogueFor.latestActiveVersion}
          />
        </Dialog>
      }
    </div >
  )
};

export default SolutionProductAssociation;
