export const enum FieldIdentifiers {
	//Solution
	SOLUTION_TITLE,
	SOLUTION_DESCRIPTION,
	SOLUTION_KEYCONTACTS,
	SOLUTION_LIMITATION,
	SOLUTION_DEPENDENCY,
	SOLUTION_CUSTOMER_COMMITMENTS,
	SOLUTION_HEADER_SERVICE_MODEL,
	SOLUTION_HEADER_VERTICAL,
	SOLUTION_HEADER_VALUE_PROPOSITION,
	SOLUTION_TAGLINE,
	SOLUTION_TWOWAY_COMMITMENT_TAGLINE,
	SOLUTION_CUSTOMER_NEED,
	SOLUTION_TARGET_CUSTOMER_SEGMENTS,
	SOLUTION_TARGET_CUSTOMER_VERTICALS,
	SOLUTION_SALES_CHANNELS,
	SOLUTION_BOOKING_CHANNELS,
	SOLUTION_COMPATIBLE_PRODUCTS,
	SOLUTION_RELATION_TYPE,

	//Product
	PRODUCT_TITLE,
	PRODUCT_DESCRIPTION,
	PRODUCT_FAMILY,
	PRODUCT_CODE,
	PRODUCT_TAGLINE,
	PRODUCT_CUSTOMERNEED,

	ATTRIBUTES,
	ATTRIBUTES_DESCRIPTION,
	ATTRIBUTE_KEY_CONTACTS,

	//Components
	COMPONENT_TITLE,
	COMPONENT_DESCRIPTION,

	//Features
	FEATURE_TITLE,
	FEATURE_DESCRIPTION,

	//Specification Prameter
	SPECIFICATION_PARAMETER_TITLE,
	SPECIFICATION_PARAMETER_DESCRIPTION,
	SPECIFICATION_PARAMETER_SPEC_TEXT,
	SPECIFICATION_PARAMETER_SPEC_OPTION,
	SPECIFICATION_PARAMETER_SPEC_OPTION_DESCRIPTION,

	SPECIFICATION_PARAMETER_CONFIGURATION_OPTIONALITY,

	//Applicability
	APPLICABILITY_TITLE,

	//Variance
	VARIANCE_TITLE,

	//Compatibility
	RULE_NAME,
	CRITERION_VALUES

}

export const getInputLengthRestriction = (fieldIdentifier: FieldIdentifiers): number => {
	switch (fieldIdentifier) {
		case FieldIdentifiers.PRODUCT_TITLE:
		case FieldIdentifiers.SOLUTION_TITLE:
			return 100;
		case FieldIdentifiers.PRODUCT_FAMILY:
		case FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION:
		case FieldIdentifiers.APPLICABILITY_TITLE:
			return 50;
		case FieldIdentifiers.COMPONENT_TITLE:
		case FieldIdentifiers.FEATURE_TITLE:
		case FieldIdentifiers.SPECIFICATION_PARAMETER_TITLE:
			return 80;
		case FieldIdentifiers.VARIANCE_TITLE:
		case FieldIdentifiers.PRODUCT_CODE:
		case FieldIdentifiers.PRODUCT_TAGLINE:
		case FieldIdentifiers.SOLUTION_TAGLINE:
			return 100;
		case FieldIdentifiers.COMPONENT_DESCRIPTION:
		case FieldIdentifiers.FEATURE_DESCRIPTION:
		case FieldIdentifiers.SPECIFICATION_PARAMETER_DESCRIPTION:
			return 1500;
		case FieldIdentifiers.ATTRIBUTES_DESCRIPTION:  //PRODUCT_DESCRIPTION & SALES_DESCRIPTION is part of ATTRIBUTE_DESCRIPTION
		case FieldIdentifiers.PRODUCT_DESCRIPTION:
			return 2000;
		// case FieldIdentifiers.RESTRICTION:
		// case FieldIdentifiers.LIMITATION:
		// case FieldIdentifiers.DEPENDENCY:
		// case FieldIdentifiers.CUSTOMER_COMMITMENTS:
		case FieldIdentifiers.SOLUTION_TWOWAY_COMMITMENT_TAGLINE:
		case FieldIdentifiers.SOLUTION_CUSTOMER_NEED:
		case FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_SEGMENTS:
		case FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_VERTICALS:
		case FieldIdentifiers.SOLUTION_SALES_CHANNELS:
		case FieldIdentifiers.SOLUTION_BOOKING_CHANNELS:
		case FieldIdentifiers.SOLUTION_COMPATIBLE_PRODUCTS:		
		case FieldIdentifiers.ATTRIBUTES:
			return 1000;
		// case FieldIdentifiers.KEY_CONTACTS:
		case FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_TEXT:
		case FieldIdentifiers.PRODUCT_CUSTOMERNEED:
		case FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION_DESCRIPTION:
		case FieldIdentifiers.ATTRIBUTE_KEY_CONTACTS:
			return 500;
		default:
			return 2000; //TODO: why so high default?
	}
}


export const formValidation = (value: any, fieldIdentifier: FieldIdentifiers, isRequired: boolean = false, validationMessage: string ="") => {
	if (isRequired) {
		if (!(value?.length > 0 && value !== null)) {
			return (<div style={{ fontSize: "smaller", color: "red", margin: "0.25rem 0" }}>
				{
					validationMessage !=="" ?
					<>
					{validationMessage}
					</>
					:
					<>Input Required</>
				}
			</div>);
		}
	}
	const fieldLengthRestriction = getInputLengthRestriction(fieldIdentifier);
	if (value?.length > fieldLengthRestriction) {
		return (<div style={{ fontSize: "smaller", color: "red", margin: "0.25rem 0" }}>
			Input length for field exceeded ({value.length}/{fieldLengthRestriction} characters).
		</div>);
	}

	return "";
}
