import React, { FunctionComponent } from "react";
import styles from "./NotAuthenticatedView.module.scss";


interface OwnProps {}

type Props = OwnProps;

const NotAuthenticatedView: FunctionComponent<Props> = (props) => {

	return (
		<div className={styles.no_aut_container} data-testid="notAuthenticatedView">
			<div >
				<i className="pi pi-lock" style={{"fontSize": "2em"}}></i>
			</div>
			<div className={styles.text}>
          Looks like you do not have access to see this content
			</div>
		</div>

	);
};

export default NotAuthenticatedView;
