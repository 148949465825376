// Import order is very important for css styles to apply correctly.
// Imagine two css selectors with same specificity eg. '.p-component' in two different files, then the last will win.
// Import default styles from react components, then other default stuff in order we want to override,
// and then our default index.scss and the components which will automatically their styles.
// Component styles should only specify styles specific for their component, or overrides applicable for their subtree

import React from "react";

import "../src/assets/fonts/maeu/fonts.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";

import "./index.scss";
import "./PrimeReactOverrides.scss"

import App from "./App";
import { setupStore } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { pca } from "./authorisationService";
import ReactDOM from "react-dom/client";


// remove anchor token on refresh
sessionStorage.removeItem("anchorToken");

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<BrowserRouter>
		<React.StrictMode>
			<Provider store={setupStore({})}>
				<MsalProvider instance={pca}>
					<App />
				</MsalProvider>
			</Provider>
		</React.StrictMode>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
