import { ReactComponent as ServiceModel } from '../../assets/icons/maersk/flow.svg';
import { ReactComponent as Vertical } from '../../assets/icons/maersk/shopping_cart.svg';
import { ReactComponent as Attractivness } from '../../assets/icons/maersk/star.svg';
import { ReactComponent as Carrier } from '../../assets/icons/maersk/vessel-front.svg';
import { ReactComponent as Incoterms } from '../../assets/icons/maersk/container-hook.svg';
import { ReactComponent as ContractValidity } from '../../assets/icons/maersk/clock.svg';
import { ReactComponent as PackagingType } from '../../assets/icons/maersk/mi-package-24px.svg';
import { ReactComponent as CustomerType } from '../../assets/icons/maersk/mi-medal-20px.svg';
import { ReactComponent as ValueProposition } from '../../assets/icons/maersk/people.svg';
import { ReactComponent as LoadType } from '../../assets/icons/maersk/container-stacked-20px.svg';
import { ReactComponent as Direction } from '../../assets/icons/maersk/arrow-all-directions-20px.svg';
import styles from "./HeaderView.module.scss";
import { HeaderTypes } from '../../data/model/DataModels';



/* Function to return BackGround Color for each icon  */
export const getIconColor = (type: HeaderTypes) => {
	switch (type) {
		case HeaderTypes.SERVICE_MODEL:
			return styles.customerType
		case HeaderTypes.VERTICAL:
			return styles.vertical
		case HeaderTypes.ATTRACTIVENESS:
			return styles.attractiveness
		case HeaderTypes.CARRIER:
			return styles.carrier
		case HeaderTypes.INCOTERM:
			return styles.incoterm
		case HeaderTypes.CUSTOMER_TYPE:
			return styles.customerType
		// case Headers.PACKAGING_TYPE:
		// 	return styles.packagingType
		case HeaderTypes.CONTRACT_VALIDITY:
			return styles.customerType
		case HeaderTypes.LOAD_TYPE:
			return styles.loadType
		case HeaderTypes.DIRECTION:
			return styles.direction
		default:
			<></>
	}
}

/* Function to return icon for its respective type */
export const getHeaderIcon = (type: HeaderTypes | string) => {
		switch (type) {
			case 'serviceModel':
				return <ServiceModel />
			case HeaderTypes.VERTICAL:
				return <Vertical />
			case HeaderTypes.ATTRACTIVENESS:
				return <Attractivness />
			case HeaderTypes.CARRIER:
				return <Carrier />
			case HeaderTypes.INCOTERM:
				return <Incoterms />
			case HeaderTypes.CONTRACT_VALIDITY:
				return <ContractValidity />
			// case Headers.PACKAGING_TYPE:
			// 	return <PackagingType />
			case HeaderTypes.VALUE_PROPOSITION:
				return <ValueProposition />
			case HeaderTypes.CUSTOMER_TYPE:
					return <CustomerType />
			case HeaderTypes.DIRECTION:
					return <Direction />
			case HeaderTypes.LOAD_TYPE:
					return <LoadType />
			default:
				<></>
		}
	}
