import { ConfigurableControl, FeatureAssociation, FeatureDefinition, ProductHeaders, Optionality, Scope, ProductListing, Compatibility } from "./../model/DataModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	ComponentAssociation,
	ComponentDefinition,
	Product,
	Overview,
	SalesInfo,
	SpecificationParameterAssociation
} from "../model/DataModels";
import { selectedComponentDefault } from "../../features/ProductView/ProductView";

interface ProductState {
  selectedComponent: ComponentAssociation;
  features: FeatureDefinition[];
  editMode: boolean;
  isReordering: boolean;
  editableProduct: Product;
  editableProductCopy: Product;
  localDraftChanged: boolean;
  showVarianceDetails: boolean;
  selectedVariance: any;
  isProductFormValid: boolean;
}

const initialState: ProductState = {
  selectedComponent: selectedComponentDefault,
  features: [],
  editMode: false,
  isReordering: false,
  editableProduct: {} as Product,
  editableProductCopy: {} as Product,
  localDraftChanged: false,
  showVarianceDetails: false,
  selectedVariance: {},
  isProductFormValid: true,
};

interface ComponentsAndFeatureAssociation {
  components: ComponentAssociation[];
  specificationParameters: SpecificationParameterAssociation[];
}

export const productSlice = createSlice({
	name: "product",
	initialState,
	reducers: {
		setComponentReordering: (state, action: PayloadAction<boolean>) => {
			state.isReordering = action.payload;
		},
		setComponent: (state, action: PayloadAction<ComponentAssociation>) => {
			state.selectedComponent = action.payload;
		},
		setIsProductFormValid: (state, action: PayloadAction<boolean>) => {
			state.isProductFormValid = action.payload;
		},
		setEditMode: (state, action: PayloadAction<boolean>) => {
			state.editMode = action.payload;
			if (!state.editMode) {
				state.selectedComponent = selectedComponentDefault;
				state.showVarianceDetails = false;
				state.localDraftChanged = false;
			}
		},
		setLocalDraftChanged: (state, action: PayloadAction<boolean>) => {
			state.localDraftChanged = action.payload;
		},
		setFeaturesInProduct: (state, action: PayloadAction<FeatureDefinition[]>) => {
			state.features = action.payload.map(feature => feature)
			state.localDraftChanged = true;
		},
		updateFeatureInProduct: (state, action: PayloadAction<FeatureAssociation[]>) => {
			state.editableProduct.features = [...action.payload];
			state.localDraftChanged = true;
		},
		updateEditableProduct: (state, action: PayloadAction<Product>) => {
			state.editableProduct = action.payload;
			state.localDraftChanged = true;
		},
		updateEditableProductOnSave: (state, action: PayloadAction<Product>) => {
			state.editableProduct = action.payload;
			state.localDraftChanged = false;
		},
		loadEditableProduct: (state, action: PayloadAction<Product>) => {
			state.editableProduct = action.payload;
			state.editableProductCopy = action.payload;
		},
		updateResourceCodes: (state, action: PayloadAction<Product>) => {
			if (state.editableProduct.code === action.payload.code && state.editableProduct.version === action.payload.version) {
				state.editableProduct.rbacResourceCode = action.payload.rbacResourceCode;
				state.editableProduct.rbacChildResourceCode = action.payload.rbacChildResourceCode;
			}
		},
		addUpdateSpecificationParameterInProduct: (state, action: PayloadAction<SpecificationParameterAssociation>) => {
			// todo : check for datastructuer conversion changes
			const index = state.editableProduct?.specificationParameters.findIndex(specParam => specParam.specificationParamRef === action.payload.specificationParamRef);
			if (index !== -1) {
				state.editableProduct.specificationParameters[index!] = action.payload;
			} else {
				state.editableProduct.specificationParameters.push(action.payload);
			}
			//add add specificationParameter related component if it is deleted
			if (!state.editableProduct.components.map(component => component.componentRef).includes(action.payload.componentRef)) {
				const component = state.editableProductCopy?.components.find(component => component.componentRef === action.payload.componentRef);
				if (component) {
					state.editableProduct.components = state.editableProduct.components.concat(component);
				}
			}
			state.localDraftChanged = true;
		},
		updateAllSpecificationParameterInProduct: (state, action: PayloadAction<SpecificationParameterAssociation[]>) => {
			state.editableProduct.specificationParameters = action.payload;
			state.localDraftChanged = true;
		},
		addFeatureInProduct: (state, action: PayloadAction<FeatureAssociation[]>) => {
			state.editableProduct.features = [...action.payload,...state.editableProduct.features];
		},
		deleteFeatureInProduct: (state, action: PayloadAction<string>) => {
			state.editableProduct.specificationParameters = state.editableProduct?.specificationParameters.filter(specParam => specParam.featureRef !== action.payload);
			state.editableProduct?.features.splice(state.editableProduct.features.findIndex(feature => feature.featureRef === action.payload), 1);
			state.localDraftChanged = true;
		},
		deleteSpecificationParameterInProduct: (state, action: PayloadAction<string>) => {
			state.editableProduct?.specificationParameters.splice(state.editableProduct?.specificationParameters.findIndex(specParam => specParam.specificationParamRef === action.payload), 1);
			state.localDraftChanged = true;
		},
		addSpecificationParameterInProduct: (state, action: PayloadAction<SpecificationParameterAssociation[]>) => {
			state.editableProduct.specificationParameters = [...state.editableProduct.specificationParameters, ...action.payload];
			state.localDraftChanged = true;
		},
		updateSalesInfoInProduct: (state, action: PayloadAction<SalesInfo>) => {
			state.editableProduct.salesInfo = action.payload;
			state.localDraftChanged = true;
		},
		updateApplicabilityInProduct: (state, action: PayloadAction<Scope[]>) => {
			state.editableProduct.applicability = action.payload;
			state.localDraftChanged = true;
		},
		updateComponentAssociationInProduct: (state, action: PayloadAction<ComponentAssociation[]>) => {
			state.editableProduct.components = action.payload;
			let sortedFeatures = [] as FeatureAssociation[];
			state.editableProduct.components.forEach(component => {
				const featuresForComponent = state.editableProduct.features.filter(feature => feature.componentRef === component.componentRef);
				const sortedFeaturesForComponent = featuresForComponent.sort((a, b) => a.definition.associationIndex - b.definition.associationIndex);
				sortedFeatures = [...sortedFeatures, ...sortedFeaturesForComponent];
			});
			state.editableProduct.features = sortedFeatures
			state.localDraftChanged = true;
		},
		updateHeaderInProduct: (state, action: PayloadAction<Overview>) => {
			state.editableProduct.header = action.payload;
			state.localDraftChanged = true;
		},
		updateProductCompatibilitiesInProduct: (state, action: PayloadAction<Compatibility>) => {
			if (state.editableProduct.compatibility) {
				state.editableProduct.compatibility.compatibleItems = action.payload.compatibleItems;
				state.editableProduct.compatibility.compatibilityRules = action.payload.compatibilityRules;
			} else{
				state.editableProduct.compatibility = {compatibleItems: action.payload.compatibleItems, compatibilityRules:  action.payload.compatibilityRules}
			}
			state.localDraftChanged = true;
		},
		updateproductHeadersInProduct: (state, action: PayloadAction<ProductHeaders>) => {
			state.editableProduct.headersInfo = { ...state.editableProduct.headersInfo, ...action.payload };
			state.localDraftChanged = true;
		},
		updateContractingInProduct: (state, action: PayloadAction<string>) => {
			state.editableProduct.contracting = action.payload === "mandatory" ? Optionality.mandatory
				: action.payload === "restricted" ? Optionality.restricted
					: Optionality.optional;
			state.localDraftChanged = true;
		},
		addFeaturesToDraft: (state, action: PayloadAction<FeatureAssociation[]>) => {
			let specificationParameters = action.payload?.flatMap(feature => feature?.definition?.specificationParameters).map(sp => {
				return {
					featureRef: sp?.featureRef,
					componentRef: sp?.componentRef,
					productId: 0,
					specificationParamRef: sp?.code,
					definition: sp,
					configuration: sp?.configuration,
					configurableAtContracting: sp?.configurableAtContracting,
					configurableAtBooking: sp?.configurableAtBooking,
					note: "",
					serviceDefault: sp?.serviceDefault
				} as SpecificationParameterAssociation;
			})
			state.editableProduct.specificationParameters = [...state.editableProduct.specificationParameters, ...specificationParameters]
			state.editableProduct.features = [...state.editableProduct.features, ...action.payload]
			state.features = [...state.features, ...action.payload.map(feature => feature.definition)]
			state.localDraftChanged = true;
		},
		addComponentsToDraft: (state, action: PayloadAction<ComponentDefinition[]>) => {
			let componentsAssociation: ComponentAssociation[] = action.payload.map(componentDef => {
				return { componentRef: componentDef.code, definition: { ...componentDef, associatedProducts: [] } };
			});
			state.editableProduct.components =  [...componentsAssociation, ...state.editableProduct.components];
			state.localDraftChanged = true;
		},
		removeComponentInDraft: (state, action: PayloadAction<string>) => {
			state.editableProduct.components = state.editableProduct.components.filter(component => component.componentRef !== action.payload);
			state.editableProduct.features = state.editableProduct.features.filter(feature => feature.componentRef !== action.payload);
			state.editableProduct.specificationParameters = state.editableProduct.specificationParameters.filter(specParam => specParam.definition.componentRef !== action.payload);
			state.features = state.editableProduct.features.filter(feature => feature.componentRef !== action.payload).map(feature => feature.definition);
			state.localDraftChanged = true;
		},
		addRemovedComponentsToDraft: (state, action: PayloadAction<ComponentsAndFeatureAssociation>) => {
			state.editableProduct.components = state.editableProduct.components.concat(action.payload.components);
			const existingFeatureRefs = state.editableProduct.specificationParameters.map(feature => feature.featureRef);
			// todo : check for datastructuer conversion changes

      // action.payload.specificationParameters.forEach(feature => {
      // 	if (existingFeatureRefs.includes(feature.featureRef)) {
      // 		return;
      // 	}
      // 	state.editableProduct.specificationParameters = state.editableProduct.specificationParameters.concat(feature);
      // })
      state.localDraftChanged = true;
    },
    setShowVarianceDetails: (state, action: PayloadAction<boolean>) => {
      state.showVarianceDetails = action.payload;
    },
    setSelectedVariance: (state, action: PayloadAction<Scope>) => {
      state.selectedVariance = action.payload;
    },
    addUpdateVarianceInProduct: (state, action: PayloadAction<Scope>) => {
      const index = state.editableProduct?.variances.findIndex(
        (variance) => variance.code === action.payload.code
      );
      if (index != undefined && index > -1) {
        state.editableProduct.variances[index!] = action.payload;
      } else {
        state.editableProduct.variances.push(action?.payload);
      }
      state.localDraftChanged = true;
    },
    deleteVarianceInProduct: (state, action: PayloadAction<string>) => {
      state.editableProduct.variances = state.editableProduct.variances.filter(
        (variance) => variance.code !== action.payload
      );
      state.localDraftChanged = true;
    },
    updateConfigurability: (
      state,
      action: PayloadAction<{
        contracting: ConfigurableControl;
        booking: ConfigurableControl;
        featureIndex: number;
      }>
    ) => {
      state.editableProduct.specificationParameters[
        action.payload.featureIndex
      ].configurableAtContracting = action.payload.contracting;
      state.editableProduct.specificationParameters[
        action.payload.featureIndex
      ].configurableAtBooking = action.payload.booking;
      state.localDraftChanged = true;
    },
  },
});

export const { setComponentReordering, setComponent, setIsProductFormValid, setEditMode, setLocalDraftChanged, setFeaturesInProduct, updateEditableProduct, updateEditableProductOnSave, loadEditableProduct, updateResourceCodes,
	addUpdateSpecificationParameterInProduct, updateAllSpecificationParameterInProduct, addFeatureInProduct, deleteFeatureInProduct, deleteSpecificationParameterInProduct, addSpecificationParameterInProduct,
	updateSalesInfoInProduct, updateComponentAssociationInProduct, updateHeaderInProduct, addFeaturesToDraft, addComponentsToDraft, removeComponentInDraft, addRemovedComponentsToDraft,
	setShowVarianceDetails, setSelectedVariance, addUpdateVarianceInProduct, deleteVarianceInProduct,
	updateApplicabilityInProduct, updateContractingInProduct, updateConfigurability, updateFeatureInProduct, updateproductHeadersInProduct, updateProductCompatibilitiesInProduct } = productSlice.actions;

export default productSlice.reducer;
