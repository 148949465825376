import React, { FC, useEffect, useState } from 'react';
import styles from './FeatureVersionCompare.module.scss';
import { Dialog } from 'primereact/dialog';
import { FeatureDefinition, SpecificationParameterDefinition } from '../../data/model/DataModels';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { useGetFeatureByRefQuery } from '../../data/api/CatalogueApi';
import SpecificationList from '../../components/FeatureCard2/SpecificationList/SpecificationList';
import { sanitizeHTML } from '../../components/RichTextCustomHeader/RichTextCustomHeaderComponent';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

export interface FeatureVersionCompareProps {
  onHide: Function,
  oldFeatureDefinition: FeatureDefinition;
  oldSpecificationParameterAssociations: SpecificationParameterDefinition[];
  upgradeFeature: Function;
  canUpgrade?: boolean;
}

const FeatureVersionCompare: FC<FeatureVersionCompareProps> = ({ onHide, oldFeatureDefinition, oldSpecificationParameterAssociations, upgradeFeature, canUpgrade=false }) => {


  const [updatedFeature, setupdatedFeature] = useState<FeatureDefinition>();


  const { data: updatedFeatureFetch, isLoading: isLoadingUpdatedFeature } =
    useGetFeatureByRefQuery(oldFeatureDefinition.code);

  useEffect(() => {
    setupdatedFeature(matchFeatureValues() || {} as FeatureDefinition);
  }, [updatedFeatureFetch])


  const featureContent = (compareBoxTitle: string, feature: FeatureDefinition, isOld: boolean = false) => {
    return (
      <div className={`${styles.compareBox} `}>
        <div>{compareBoxTitle}</div>
        <div className={`${styles.featureContent} ${isOld && styles.oldFeature}`}>
          <div className={styles.featureHeader}>
            <div>{feature?.name}</div>
            <div></div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(feature?.descriptionRichText?.toString()) }} />
          <SpecificationList isConfigurable={!isOld && canUpgrade}
            saveSpecificationParameters={(specs: any) => setupdatedFeature({ ...updatedFeature!, specificationParameters: specs } as FeatureDefinition)}
            usedIn='featureCompare' feature={feature} specificationParameters={feature?.specificationParameters || []} isAuthorisedToEdit={true} />
        </div>

      </div>
    )
  }

  const confirmUpgrade = (updatedFeature: FeatureDefinition) =>{
    confirmDialog({
      message: 'Do you want to proceed with upgrade?',
      header: 'Upgrade Confirmation',
      accept: () => {
        upgradeFeature(updatedFeature);
      }
    });
  }

  const footer = () => {
    if(!canUpgrade){
      return <></>
    }
    return <>
      <Button className='p-button-outlined p-button-secondary' label='Cancel' onClick={() => onHide()} />
      <Button label='Update' onClick={() => updatedFeature && confirmUpgrade(updatedFeature)} />
    </>
  }


  const matchFeatureValues = (): FeatureDefinition => {
    const returnFeature: FeatureDefinition = {
      ...updatedFeatureFetch!,
      specificationParameters: updatedFeatureFetch!.specificationParameters.map(spec => {
        const specificationOldConfigValue = oldSpecificationParameterAssociations.find(old => old.code === spec.code);

        let returnValue: SpecificationParameterDefinition = JSON.parse(JSON.stringify({
          ...spec,
          definition: spec
        }));

        if(!canUpgrade){
          return returnValue;
        }

        if (
          specificationOldConfigValue &&
          specificationOldConfigValue.configuration.type === spec.configuration.type
        ) {
          returnValue.configurableAtContracting = specificationOldConfigValue.configurableAtContracting;
          returnValue.configurableAtBooking = specificationOldConfigValue.configurableAtBooking;
          returnValue.configurableAtAssociation = specificationOldConfigValue.configurableAtAssociation;

          if (spec.configuration.type === 'options') {
            const oldConfigOptionValueCodes = specificationOldConfigValue.configuration.value.map(
              (val: any) => val.code
            );
            const newConfigurationValues = spec.configuration.value.filter((val: any) =>
              oldConfigOptionValueCodes.includes(val.code)
            );
            returnValue.configuration.value = newConfigurationValues;

            if (returnValue.definition.configuration.value.length === 1) {
              returnValue.configurableAtContracting = spec.configurableAtContracting;
              returnValue.configurableAtBooking = spec.configurableAtBooking;
              returnValue.configurableAtAssociation = spec.configurableAtAssociation;
            }
          }

          return returnValue;
        }

        return returnValue;
      }),
    };

    return returnFeature;
  };


  if (isLoadingUpdatedFeature || !updatedFeature) {
    return <SpinnerComponent />
  }

  const showAssociationsWhenInEdit = () =>{
    return {
      ...oldFeatureDefinition,
      specificationParameters: oldSpecificationParameterAssociations
    } as FeatureDefinition
  }

  return (
    <Dialog
      header="Compare Feature"
      footer={footer}
      visible={true}
      onHide={() => onHide()}
    >
      <div className={styles.FeatureVersionCompare} data-testid="FeatureVersionCompare">
        {
          featureContent("Original feature", showAssociationsWhenInEdit(), true)
        }
        {
          featureContent("Updated feature", updatedFeature)
        }
      </div>

    </Dialog>
  );
}

export default FeatureVersionCompare;
