import { FC, useState } from "react";
import styles from "../FeatureAsServiceEdit/FeatureAsServiceEdit.module.scss";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { confirmDialog } from "primereact/confirmdialog";

export interface FeatureAsServiceDefaultOptionsProps {
  serviceDefaultFromParent: "included" | "not_included" | "none";
  saveServiceDefault?: Function;
}

const FeatureAsServiceDefaultOptions: FC<
  FeatureAsServiceDefaultOptionsProps
> = ({ serviceDefaultFromParent, saveServiceDefault: saveServiceDefault }) => {
  const [serviceDefaultValue, setServiceDefaultValue] = useState(serviceDefaultFromParent);
  const handleInput = (featureServiceValue: any, isChangedFromCoreToOptional: boolean) => {
    saveServiceDefault && saveServiceDefault(featureServiceValue, isChangedFromCoreToOptional);
  };

  const coreToOptionalConfirmationDialogue = (e: RadioButtonChangeEvent) => {
    confirmDialog({
      message: 'You are about to automatically change all the specification parameters to be optional . Do you want to proceed?',
      header: 'Change Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setServiceDefaultValue(e.target.value);
        handleInput(e.target.value, true);
      }
    });
  }

  return (
    <div className={styles.FeatureAsServiceEdit} data-testid="FeatureAsServiceEdit">
      <div className={styles.featureInnerRadioGroup}>
        <div className={styles.featureTypeInnerRadioGroupRadioButtons}>
          <div className={styles.featureTypeRadioButton}>
            <RadioButton
              id="radio1"
              data-testid="standardRadioButton"
              name="serviceValue"
              value="included"
              onChange={(e) => {
                handleInput(e.target.value, false);
                setServiceDefaultValue(e.target.value);
              }}
              checked={serviceDefaultValue === "included"}
            />
            <label className={styles.featureTypeRadioButtonLabel}>
              Core
            </label>
          </div>
          <div className={styles.featureTypeRadioButton}>
            <RadioButton
              id="radio1"
              data-testid="optionalRadioButton"
              name="serviceValue"
              value="not_included"
              onChange={(e) => {
                coreToOptionalConfirmationDialogue(e);
              }}
              checked={serviceDefaultValue === "not_included"}
            />
            <label className={styles.featureTypeRadioButtonLabel}>
              Optional
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureAsServiceDefaultOptions;
