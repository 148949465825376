import React, { FC, useContext, useMemo, useState } from 'react';
import styles from './SolutionAddComponentDialog.module.scss';
import { Dialog } from 'primereact/dialog';
import DisplayCardsComponent, { DisplayCardElement } from '../../components/DisplayCards/DisplayCardsComponent';
import { Button } from 'primereact/button';
import { useGetComponentsForAssociationQuery, useLazyGetFeatureByComponentsRefQuery } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ComponentAssociation, ComponentDefinition, FeatureDefinition } from '../../data/model/DataModels';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';

export interface SolutionAddComponentDialogProps {
  existingComponents: ComponentAssociation[],
  onHide: Function
  saveProps: { save: Function, isLoadingSave: boolean }
}


const SolutionAddComponentDialog: FC<SolutionAddComponentDialogProps> = ({ existingComponents, onHide, saveProps }) => {

  const toaster = useContext(ToasterContext);

  const { data: componentList, error: errorComponents, isLoading: isLoadingComponents } = useGetComponentsForAssociationQuery();
  const [getFeaturesForSelectedComponents, { isLoading: isLoadingFeaturesForSelectedComponents }] = useLazyGetFeatureByComponentsRefQuery();
  const { save, isLoadingSave } = saveProps;

  const [componentsSelected, setComponentsSelected] = useState<ComponentDefinition[]>([]);

  if (errorComponents) {
    toaster.showToast("error", 'Failed to load components');
  }

  const anyFeatureWithZeroSP = (features: FeatureDefinition[]) => {
    return features.find(feature => feature.numberOfSpecificationParameters === 0);
  }

  const acceptAction = () => {
    getFeaturesForSelectedComponents(componentsSelected.map(comp => comp.code))
      .unwrap()
      .then(features => {
        const featureWithZeroSp = anyFeatureWithZeroSP(features);
        if (featureWithZeroSp) {
          const componentName = componentList?.find(component => component.code === featureWithZeroSp.componentRef)?.name;
          toaster.showToast("error", "Component(s) cannot be added due to missing specification parameter(s): " + componentName);
        }
        else {
          save(componentsSelected, features)
        }
      },
        () => {
          toaster.showToast("error", "Failed to fetch features for components");
        }
      )
  }

  const sortedAndFilteredComponentsList = useMemo((): { [key: string]: ComponentDefinition } => {
    return componentList ?
      [...componentList].sort((a, b) => a.name.localeCompare(b.name))
        .reduce((accumulator, current) => { return { ...accumulator, [current.code]: current } }, {})
      : {}
  }
    ,
    [componentList]);

  const generatedElementList = useMemo(() => {
    return Object.values(sortedAndFilteredComponentsList).map(comp => {
      return {
        code: comp.code, name: comp.name, tooltipElements: comp.features.map(feature => feature.name),
        duplicateElement: existingComponents.some(componentInDraft => componentInDraft.componentRef === comp.code)
      }
    })

  }, [sortedAndFilteredComponentsList])


  const addComponentAction = (elementClicked: DisplayCardElement) => {
    let componentClicked = sortedAndFilteredComponentsList[elementClicked.code];
    if (componentsSelected.includes(componentClicked)) {
      setComponentsSelected(componentsSelected.filter(component => component.code !== componentClicked.code));
    } else {
      if(componentClicked.features.length == 0){
        toaster.showToast("warn", "Component cannot be added because it does not contain any active features");
        return;
      }
      setComponentsSelected([componentClicked, ...componentsSelected]);
    }
  }

  const footer = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} />
      <Button data-testid="addButton" label="Add" icon="pi pi-check" onClick={() => acceptAction()} disabled={componentsSelected.length === 0} />
    </div>
  );


  return (
    <div className={styles.SolutionAddComponentDialog} data-testid="SolutionAddComponentDialog">
      <Dialog footer={footer} header="Select Components To Add" visible={true} style={{ width: '90vw', height: '85vh' }} onHide={() => onHide()}>
        {
          (isLoadingComponents || isLoadingFeaturesForSelectedComponents || isLoadingSave) && <SpinnerComponent />
        }
        <div className={styles.componentsView}>
          <DisplayCardsComponent action={(elementClicked: DisplayCardElement) => addComponentAction(elementClicked)}
            selectedCodes={componentsSelected.map(comp => comp.code)}
            dialogView={true}
            editMode={true}
            elementList={generatedElementList}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default SolutionAddComponentDialog;