import React, { FC, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbContext } from '../../../App';
import CreateOrEditSpecificationParameter
  from '../../../components/CreateOrEditSpecificationParameter/CreateOrEditSpecificationParameter';
import styles from './WrapperCreateOrEditSpecificationParameter.module.scss';
import { SpecificationParameterDefinition } from '../../../data/model/DataModels';
import { addCrumb } from '../../Breadcrumbs/Breadcrumbs';

interface WrapperCreateOrEditSpecificationParameterProps { }

const WrapperCreateOrEditSpecificationParameter: FC<WrapperCreateOrEditSpecificationParameterProps> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { featureCode, componentCode } = useParams();
  const location: any = useLocation();

  const specificationParamForEdit = location?.state?.specificationParameter;


  const pageLabel = specificationParamForEdit?.code ? "Edit Specification Parameter" : "Create Specification Parameter";


  const breadcrumbs = useContext(BreadCrumbContext);
  useEffect(() => {
    addCrumb({ label: pageLabel, url: pathname }, breadcrumbs);
  });

  const saveOrCancelSpecificationParamCreation = () => {
    navigate(-1);
  }

  const getSpecificationParam = () => {
    if (specificationParamForEdit?.code) {
      return specificationParamForEdit;
    }
    return { serviceDefault: specificationParamForEdit?.serviceDefault, featureRef: featureCode, componentRef: componentCode, isService: specificationParamForEdit?.isService, featureServiceDefault: specificationParamForEdit?.featureServiceDefault } as SpecificationParameterDefinition;
  }

  return (
    <div className={styles.WrapperCreateSpecificationParameter} data-testid="WrapperCreateSpecificationParameter">
      <h1>{pageLabel}</h1>

      <div className="mainDisplayCard">
        <h2>In feature: {specificationParamForEdit?.featureName}</h2>
        <CreateOrEditSpecificationParameter specificationParameter={getSpecificationParam()} onClose={() => saveOrCancelSpecificationParamCreation()} />

      </div>
    </div>
  );
}

export default WrapperCreateOrEditSpecificationParameter;

