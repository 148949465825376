import { AutoComplete } from 'primereact/autocomplete';
import React, { FC, useContext, useState } from 'react';
import { useGetUsersBySearchQuery } from '../../data/api/GraphApi';
import { User } from '../../data/model/DataModels';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import styles from './UserSearchWithinOrganisation.module.scss';
import { useGetAllGlobalOwnersQuery } from "../../data/api/CatalogueApi";

interface UserSearchWithinOrganisationProps {
  selectedUsers: User[];
  existingUsers: User[]
  setSelectedUsers: Function;
  disabled?: boolean;
  feature?: boolean;
}

const UserSearchWithinOrganisation: FC<UserSearchWithinOrganisationProps> = ({ selectedUsers, existingUsers, setSelectedUsers, disabled = false, feature = false }) => {

  const [searchParameter, setSearchParameter] = useState("");

  const mailsOfExistingUsers = existingUsers.map(item => item.mail);

  const { data: searchedUsers = [], isError: isErrorSearchedUsers } = feature ? useGetAllGlobalOwnersQuery() : useGetUsersBySearchQuery(searchParameter, { skip: searchParameter === "" || feature });

  const toaster = useContext(ToasterContext);

  if (isErrorSearchedUsers) {
    toaster.showToast('error', 'Failed to search for user');
  }


  const onSelectingUsers = (user: User) => {
    if (!user.mail) {
      toaster.showToast('warn', 'Select user with valid user email');
    } else {
      const mailsOfSelectedUsers = selectedUsers.map(item => item.mail);
      if (!mailsOfExistingUsers.includes(user.mail) && !mailsOfSelectedUsers.includes(user.mail)) {
        setSelectedUsers([...selectedUsers, user]);
      } else {
        if (mailsOfExistingUsers.includes(user.mail)) {
          toaster.showToast('warn', 'User already part of owner list');
        }
        if(mailsOfSelectedUsers.includes(user.mail)){
          toaster.showToast('warn', 'User already selected');
        }
      }
    }
  }


  const itemTemplate = (item: User) => {
    return (
      <div className={styles.user}>
        <div>
          {item.displayName}
        </div>
        <div className={styles.email}>
          {item.mail}
        </div>
      </div>
    )
  }

  return (
    <AutoComplete placeholder='Search by name or email' delay={100} multiple itemTemplate={itemTemplate} value={selectedUsers} field='displayName' suggestions={searchedUsers || []}
      completeMethod={(event) => setSearchParameter(event.query.toLowerCase())}
      onUnselect={(e) => { setSelectedUsers(selectedUsers.filter(user => user.mail != e.value.mail)) }}
      onSelect={(e) => { onSelectingUsers(e.value) }}
      disabled={disabled} data-testid="UserSearchInput"
    />
  );
}
export default UserSearchWithinOrganisation;
