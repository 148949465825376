import React, { lazy, Suspense } from 'react';

const LazyResourceDetailsAllVersions = lazy(() => import('./ResourceAllVersions'));

const ResourceAllVersions = (props: JSX.IntrinsicAttributes &{ children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyResourceDetailsAllVersions {...props}/>
  </Suspense>
);

export default ResourceAllVersions;
