import React, { lazy, Suspense } from 'react';
import { ActivityComponentProps } from "./ActivityComponent";

const LazyActivityComponent = lazy(() => import('./ActivityComponent'));

const ActivityComponent = (props: ActivityComponentProps & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyActivityComponent {...props} />
  </Suspense>
);

export default ActivityComponent;
