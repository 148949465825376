import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import RegionTree from '../../components/RegionTree/RegionTree';
import { asyncBuildTreeFromSelectedValuesAndAddIcons } from '../../components/ScopeComponents/ScopeHelper';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ScopeType } from '../../data/model/DataModels';
import styles from './ShowMultiTreeTypeDimension.module.scss';

interface ShowMultiTreeTypeDimensionProps {
  nodes: any[];
  dimensionValues: string[];
  type: ScopeType;
  selectedRegion: string;
}

const ShowMultiTreeTypeDimension: FC<ShowMultiTreeTypeDimensionProps> = ({ nodes, dimensionValues, type, selectedRegion }) => {

  const [processedNodes, setProcessedNodes] = useState([] as any[]);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => asyncBuildTreeFromSelectedValuesAndAddIcons(nodes, dimensionValues, type).then(tree => {
      setProcessedNodes(tree);
      setShowSpinner(false);
    }), 0);

  }, [nodes, dimensionValues, type, selectedRegion]);

  useMemo(() => {
    setShowSpinner(true);
    setProcessedNodes([]);
  }, [selectedRegion])
  


  return (
    <div className={styles.ShowMultiTreeTypeDimension} data-testid="ShowMultiTreeTypeDimension">
      {showSpinner && <SpinnerComponent />}
      {
        processedNodes.length > 0 ?
          <div className={styles.regionTreeContent}>
            <RegionTree nodes={processedNodes} selectedValuesOnTree={[]} editMode={false} />
          </div>
          :
          <div className={styles.listItem}>
            <span
              className={type == "inclusion" ? `pi pi-check-circle ${styles.checkIcon}` : `pi pi-ban ${styles.banIcon}`} />
            <span>-</span>
          </div>

      }
    </div>
  );
}


export default memo(ShowMultiTreeTypeDimension);
