import React, { FC } from 'react';
import styles from './ProductCardMinimal.module.scss';
import { RiPlaneLine, RiTempColdLine, RiBuilding4Line, RiArchiveDrawerLine, RiShoppingBag3Line, RiTruckLine, RiBuildingLine, RiLinksFill, RiShipFill, RiShipLine, RiArchiveLine, RiArchiveFill } from 'react-icons/ri';
import { Product } from '../../data/model/DataModels';

export interface ProductCardMinimalProps {
  product: Product;
  isSelected?: boolean;
  onProductClick?: Function;
}

// This card is meant for minimal product content. Generally used for product listing while selecting product

const ProductCardMinimal: FC<ProductCardMinimalProps> = ({ product, isSelected, onProductClick }) => {
  const resolveFamiliesIcon = (family: string) => {
    switch (family.toLowerCase()) {
      case "air_n_lcl": return <RiPlaneLine />;
      case "cold_chain_logistics": return <RiTempColdLine />;
      case "contract_logistics": return <RiBuilding4Line />;
      case "customs_services": return <RiArchiveDrawerLine />;
      case "ecommerce_logistics": return <RiShoppingBag3Line />;
      case "landside_transport": return <RiTruckLine />;
      case "lead_logistics": return <RiBuildingLine />;
      case "tradelens": return <RiLinksFill />;
      case "ocean_contract": return <RiShipFill />;
      case "ocean_freesale": return <RiShipLine />;
      case "ocean_vas": return <RiArchiveLine />;
      default: return <RiArchiveFill />;
    }
  };

  return (
    <div className={`${styles.ProductCardMinimal} ${isSelected && styles.selected}`} data-testid="ProductCardMinimal" onClick={() => { onProductClick && onProductClick(product) }}>
      {product.family && <div className={styles.productFamily}>{resolveFamiliesIcon(product.family?.code || '')} {product.family?.name}</div>}
      <div className={styles.title}>{product.name}</div>
      {
        product.header.description != "" &&
        <div className={styles.description}>
          {product.header.description}
        </div>
      }
    </div>
  );
}

export default ProductCardMinimal;
