import { Button } from 'primereact/button';
import React, { FC } from 'react';
import { FeatureDefinition, SpecificationParameterDefinition } from '../../data/model/DataModels';
import FeatureCard2 from '../FeatureCard2/FeatureCard2';
import styles from './DisplayNewFeatureVersion.module.scss';

interface DisplayNewFeatureVersionProps {
  subHeader : string;
  feature : FeatureDefinition;
  specificationParameters : SpecificationParameterDefinition[];
  isAuthorisedToEdit?: boolean;
}

const DisplayNewFeatureVersion: FC<DisplayNewFeatureVersionProps> = ({ subHeader, feature, specificationParameters, isAuthorisedToEdit=true }) => {
  
  return (
  <div className={styles.DisplayNewFeatureVersion} data-testid="DisplayFeatureDialog">
    <div className={styles.subHeader}>{ subHeader }</div>
    <FeatureCard2 feature={feature} specificationParameterAssociations={specificationParameters} usedIn="featureupgradedialog" isAuthorisedToEdit={isAuthorisedToEdit}></FeatureCard2>
  </div>
)};

export default DisplayNewFeatureVersion;
