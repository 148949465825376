import {
    ConfigurableControl,
    SpecificationParameterConfig,
    SpecificationParameterDefinition,
    SpecificationParameterType
} from "../../data/model/DataModels";
import { FC } from "react";
import styles from "./SpecificationParameterConfiguration.module.scss";
import "primeicons/primeicons.css";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

export interface SpecificationParameterConfigurationProps {
    specificationParameter: SpecificationParameterDefinition;
    setSpecificationParameter: Function;
    isDraft?: boolean;
    isFeatureAService?: boolean;
}

const SpecificationParameterConfiguration: FC<SpecificationParameterConfigurationProps> = ({
    specificationParameter, // this is the actual spec param to be worked upon
    setSpecificationParameter,
    isDraft = false,
    isFeatureAService = false,
}) => {

    const isVisibleInSFDC = (specification: SpecificationParameterDefinition) => {
        if(isFeatureAService && specification.configuration?.type === SpecificationParameterType.options && specification.configuration?.value.length <= 1)
            return false;
        return (isFeatureAService && specificationParameter.configurableAtContracting?.enabled) ? true : false;
    }

    const viewOrEditConfigurableVisibleInSFDC = (specification: SpecificationParameterDefinition) => {
        const checkBoxOrTick = () => {
            if (isDraft) {
                return <Checkbox inputId="sfdcCheckbox" checked={isVisibleInSFDC(specification)} disabled={true} />
            }
            return <div className={`pi ${isVisibleInSFDC(specification) ? "pi-check" : "pi-times"} `} />
        }

        return (
            <div className={styles.configurationOption}>
                {checkBoxOrTick()}
                <label htmlFor="sfdcCheckbox">Visible in SFDC</label>
            </div>
        )
    }

    const checkIfConfigurationIsOfTypeOptionAndLengthIsOne = (configuration: SpecificationParameterConfig) => {
        return configuration?.type == SpecificationParameterType.options && configuration.value.length <= 1;
    }

    const isConfigurableAtContractingEnabled = (specification: SpecificationParameterDefinition) => {
        return specification.configurableAtContracting?.enabled;
    }

    const viewConfigurableAtContracting = (specification: SpecificationParameterDefinition) => {
        const checkBoxOrTick = () => {
            if (isDraft) {
                return <Checkbox inputId="configurableCheckbox"
                    checked={(isConfigurableAtContractingEnabled(specification)) || false}
                    disabled={!isFeatureAService || specificationParameter.configuration?.type !== SpecificationParameterType.options || checkIfConfigurationIsOfTypeOptionAndLengthIsOne(specificationParameter.configuration)}
                    onChange={(e) => onSelectOfContracting(e)} data-testid="configurableCheckbox"
                />
            }
            return <div className={`pi ${isConfigurableAtContractingEnabled(specification) ? "pi-check" : "pi-times"} `} />;
        }

        return (
            <div className={styles.configurationOption}>
                {checkBoxOrTick()}
                <label htmlFor="configurableCheckbox">Configurable</label>
            </div>
        )
    }

    const viewConfigurableAtContractingMultiselect = (specification: SpecificationParameterDefinition) => {
        const checkBoxOrTick = () => {
            if (isDraft) {
                return <Checkbox inputId="multiselectCheckbox"
                    checked={specification.configurableAtContracting?.allowMultiselect || false}
                    onChange={(e) => onSelectOfMultiselect(e)}
                    disabled={
                        !isConfigurableAtContractingEnabled(specification) ||
                        (specificationParameter.configuration?.type !== SpecificationParameterType.options || checkIfConfigurationIsOfTypeOptionAndLengthIsOne(specificationParameter.configuration))
                    }
                    data-testid="multiSelectCheckbox"
                />

            }
            return specification.configurableAtContracting?.allowMultiselect ?
                <div data-testid="editmodeicons" className={"pi pi-check "} /> :
                <div data-testid="editmodeicons" className={"pi pi-times "} style={{ fontSize: "0.8rem" }} />
        }
        return (
            <div className={styles.configurationOption}>

                {checkBoxOrTick()}
                <label htmlFor="multiselectCheckbox"> Can multiselect</label>
            </div>
        )
    }

    const viewConfigurableAtContractingMandatory = (specification: SpecificationParameterDefinition) => {
        const checkBoxOrTick = () => {
            if (isDraft) {
                return <Checkbox inputId="mandatoryCheckbox"
                    disabled={
                        !isConfigurableAtContractingEnabled(specification) ||
                        (specificationParameter.configuration?.type !== SpecificationParameterType.options || checkIfConfigurationIsOfTypeOptionAndLengthIsOne(specificationParameter.configuration))
                    }
                    checked={specificationParameter?.configurableAtContracting?.choiceRequired || false}
                    onChange={(e) => onSelectOfMandatory(e)}
                    data-testid="mandatoryCheckbox"
                />

            }
            return specification.configurableAtContracting?.choiceRequired ?
                <div data-testid="editmodeicons" className={"pi pi-check "} /> :
                <div data-testid="editmodeicons" className={"pi pi-times "} style={{ fontSize: "0.8rem" }} />
        }
        return (
            <div className={styles.configurationOption}>

                {checkBoxOrTick()}
                <label htmlFor="mandatoryCheckbox"> Mandatory</label>
            </div>
        )
    }

    const onSelectOfContracting = (e: CheckboxChangeEvent) => {
        let clonedContracting: ConfigurableControl = JSON.parse(JSON.stringify(specificationParameter?.configurableAtContracting || {}));
        clonedContracting.enabled = e.checked || false;
        if (!e.checked) {
            clonedContracting.allowMultiselect = false;
            clonedContracting.choiceRequired = false;
        }
        setSpecificationParameter({ ...specificationParameter, configurableAtContracting: clonedContracting });
    }

    const onSelectOfMandatory = (e: CheckboxChangeEvent) => {
        setSpecificationParameter({ ...specificationParameter, configurableAtContracting: { ...specificationParameter.configurableAtContracting, choiceRequired: e.checked } });
    }

    const onSelectOfMultiselect = (e: CheckboxChangeEvent) => {
        setSpecificationParameter({ ...specificationParameter, configurableAtContracting: { ...specificationParameter.configurableAtContracting, allowMultiselect: e.checked } });
    }

    return (
        <div data-testid={"SpecificationParameterConfiguration"} className={styles.SpecificationParameterConfiguration}>
            <div className={styles.configurableAt}>
                <div className={styles.contractingTitle}>
                    Contracting
                </div>
                <div className={styles.configurations}>
                    <div className={styles.configuration}>
                        {viewOrEditConfigurableVisibleInSFDC(specificationParameter)}
                    </div>
                    <div className={styles.configuration}>
                        {viewConfigurableAtContracting(specificationParameter)}
                        <div className={styles.subConfiguration}>
                            {viewConfigurableAtContractingMultiselect(specificationParameter)}
                            {viewConfigurableAtContractingMandatory(specificationParameter)}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SpecificationParameterConfiguration;
