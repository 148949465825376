import React, { lazy, Suspense } from 'react';
import { ApplicabilitiesViewProps } from './ApplicabilitiesView';

const LazyApplicabilitiesView = lazy(() => import('./ApplicabilitiesView'));

const ApplicabilitiesView = (props: ApplicabilitiesViewProps & JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyApplicabilitiesView {...props} />
  </Suspense>
);

export default ApplicabilitiesView;
