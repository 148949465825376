import React, { FC } from 'react';
import { dimensionIncludeValues } from '../../components/utils/dimensionData';
import styles from './RegionSelection.module.scss';

interface RegionSelectionProps {
  regionOptions: { name: string, code: string, numberOfLeaves: number }[];
  selectedValues: { code: string, values: string[] }[];
  selectedRegionCode: string;
  onRegionSelection: Function;
}

const RegionSelection: FC<RegionSelectionProps> = ({ regionOptions, selectedValues, selectedRegionCode, onRegionSelection }) => {
  const getIncludedExcludedStatusForRegion = (option: any) => {
    const regionValues = selectedValues?.find((region: any) => region.code === option.code)?.values;
    if (regionValues) {
      if (!regionValues.length) {
        return <><i className={"pi pi-times-circle " + styles.banIcon} /> All excluded</>
      }
      if (regionValues[0] === dimensionIncludeValues.ALL_INCLUDED) {
        return <><i className={"pi pi-check-circle " + styles.checkIcon} /> All included</>
      }
      return <><i className={"pi pi-circle " + styles.partialCheckIcon} /> {regionValues.length} included</>
    }
  }

  return (
    <div className={styles.RegionSelection} data-testid="RegionSelection">
      {
        regionOptions.map(option =>
          <div key={option.code} className={`${styles.regionName} ${selectedRegionCode === option.code && styles.selectedRegion}`}
            onClick={() => onRegionSelection(option.code)}>
            <div className={styles.name}>
              {option.name}
            </div>
            <div className={styles.status}>
              {getIncludedExcludedStatusForRegion(option)}
            </div>
          </div>
        )
      }
    </div>
  );
}

export default RegionSelection;
