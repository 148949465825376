import React, {lazy, Suspense} from "react";

const LazyProductComponent = lazy(() => import("./ProductView"));

const ProductView = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
	<Suspense fallback={null}>
		<LazyProductComponent  {...props} />
	</Suspense>
);

export default ProductView;
