import React from "react";
import styles from "./FilterPanel.module.scss";
import { Product } from "../../data/model/DataModels";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { useAppSelector } from "../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { productTabs } from "../../features/ProductView/ProductView";
import { solutionTabs } from "../../features/SolutionDetails/SolutionDetails";


interface FilterProperties {
	product: Product;
	isInsideSolutionProductUpgradePopup?: boolean;
}

const tags = [{ name: "Impacts Pricing", key: "ImpactsPricing" }, { name: "Impacts Operations", key: "ImpactsOperations" }, { name: "Systems Controlled", key: "SystemsControlled" }];
export const controls = [{ name: "Configurable at Contracting", key: "configurableAtContracting" }, { name: "Selectable at Booking", key: "selectableAtBooking" }];
export const enum filterType { Tag = "tag", Control = "control", Component = "component" }

const FilterPanel: React.FC<FilterProperties> = ({ product, isInsideSolutionProductUpgradePopup = false }) => {

	const components = product.components.filter(component => component.definition !== null).map(component => { return { "name": component.definition?.name, "key": component.definition?.code }; });

	const checkedTags = useAppSelector((state) => state.featureFilter.checkedTags);
	// const checkedControls = useAppSelector((state) => state.featureFilter.checkedControls); // TODO: MVP re-enable process control filters when we are ready
	const checkedComponents = useAppSelector((state) => state.featureFilter.checkedComponents);
	const { hash } = useLocation();
	const navigate = useNavigate();
	const params = isInsideSolutionProductUpgradePopup ? new URLSearchParams(hash.replace(solutionTabs[2].hash, "")) : new URLSearchParams(hash.replace(productTabs[3].hash, ""));

	const filterAction = (e: CheckboxChangeEvent, filterType: filterType) => {
		if (e.checked) {
			params.append(e.value, filterType.toString());
		} else {
			params.delete(e.value);
		}
		isInsideSolutionProductUpgradePopup ? navigate(solutionTabs[2].hash + "?" + params.toString()) : navigate(productTabs[3].hash + "?" + params.toString());
	};

	return (
		<div className={styles.filterPanel}>
			{/* <div className={styles.div_inline}>
				<p>Tags</p>
				{
					tags.map(tag => {
						return (
							<span key={tag.key}>
								<div className="p-field-checkbox">
									<Checkbox inputId={tag.key} name="tags" value={tag.key} onChange={e => filterAction(e, filterType.Tag)} checked={checkedTags.indexOf(tag.key) !== -1} />
									<label htmlFor={tag.key}>{tag.name}</label>
								</div>
							</span>
						);
					})
				}
			</div> */}
			{/*<div className={styles.div_inline}> // TODO: MVP re-enable process control filters when we are ready*/}
			{/*	<p>Controls</p>*/}
			{/*	{*/}
			{/*		controls.map(control => {*/}
			{/*			return (*/}
			{/*				<div key={control.key} className={styles.controlOption}>*/}
			{/*					<p>{control.name}</p>*/}
			{/*					{*/}
			{/*						Object.values(Optionality).map(controlOption => {*/}
			{/*							return (*/}
			{/*								<div key={control.key + controlOption}  className="p-field-checkbox" >*/}
			{/*									<Checkbox  inputId={control.key + controlOption} name={control.key + controlOption} value={control.key + "-" + controlOption} onChange={ e=> filterAction(e, filterType.Control)} checked={checkedControls.indexOf(control.key + "-" + controlOption) !== -1} />*/}
			{/*									<label className={styles.margin_controls}  htmlFor={control.key + controlOption}>{startCase(controlOption)}</label>*/}
			{/*								</div>*/}
			{/*							);*/}
			{/*						})*/}

			{/*					}*/}
			{/*				</div>*/}
			{/*			);*/}
			{/*		})*/}
			{/*	}*/}
			{/*</div>*/}
			<div className={styles.div_inline}>
				<p>Components</p>
				<div>
					{
						components.map((component, index) => {
							return (
								<span key={component.key}>
									<div className="p-field-checkbox">
										<Checkbox data-testid={`filterCheckBox${index}`} inputId={component.key} name="components" value={component.key} onChange={e => filterAction(e, filterType.Component)} checked={checkedComponents.indexOf(component.key!) !== -1} />
										<label data-testid={`filterCheckBoxName${index}`} htmlFor={component.key}>{component.name}</label>
									</div>
								</span>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};

export default FilterPanel;
