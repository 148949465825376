import React, { memo, useContext, useState } from "react";
import styles from './SolutionOverview.module.scss';
import { Header, OverviewIncrementalEdit, ROLES, SOLUTION_DEFAULT_HEADER_NAMES, SOLUTION_HEADERS_DEFINITION_DATA, SOLUTION_HEADER_NAMES, SolutionDefnitionRequest, SolutionsId, Status, User } from "../../../data/model/DataModels";
import HeaderView from "../../../components/HeaderSection/HeaderView";
import InputRichTextWithSave, { RichTextDescription } from "../../../components/InputRichTextWithSave/InputRichTextWithSave";
import { FieldIdentifiers } from "../../../utils/validationUtil";
import InputTextWithSave from "../../../components/InputTextWithSave/InputTextWithSave";
import { isEqual, startCase } from "lodash";
import { SolutionContext, constructBaseSolutionRequest } from "../SolutionDetails";
import { ProductAndResources, useGetAllRolesForProductQuery, useGetAllUsersForProductQuery, usePostRetryGettingUsersForSolutionMutation, useUpdateSolutionMutation } from "../../../data/api/CatalogueApi";
import { ToasterContext } from "../../AppLayoutView/AppLayoutView";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import { getResourceNameFromProductCodeAndVersion, mapAnchorUserToGraphUser, sortApproverList } from "../../../components/utils/userUtils";
import UserListForRoleV2 from "../../UserListForRoleV2/UserListForRoleV2";
import { hasGlobalOwnerRole, hasRole, useAnchor } from "../../../authorisationService";
import { sanitizeHTML } from "../../../components/RichTextCustomHeader/RichTextCustomHeaderComponent";

export interface SolutionOverviewProps {
  isEditable: boolean
  triggerApproverStatus?: number;
  setTriggerApproverStatus?: Function;
  refetchSolution?: Function,
  isSolutionInEditMode?: Function
}

const SolutionOverview: React.FC<SolutionOverviewProps> = ({ isEditable, triggerApproverStatus = 0, setTriggerApproverStatus, refetchSolution, isSolutionInEditMode }) => {
  const [editableFields, setEditableFields] = useState({ name: false, description: false, keyContacts: false, limitation: false, dependency: false, customerCommitments: false, serviceModel: false, vertical: false, valueProposition: false, customerType: false, attractiveness: false, incoterm: false } as OverviewIncrementalEdit);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const toaster = useContext(ToasterContext);
  const solutionContext = useContext(SolutionContext);
  const solution = solutionContext?.solutionData;
  const isGlobalOwner = hasGlobalOwnerRole();
  const isSolutionOwner = hasRole([solution?.code + "-" + solution?.version], ROLES.OWNER);
  const { refreshAnchorToken } = useAnchor();


  if (!solution) {
    return <></>
  }

  const areRbacResourcesReady = (solution?.rbacResourceCode && solution?.rbacChildResourceCode && solution?.rbacResourceCode != "" && solution?.rbacChildResourceCode != '' && solution?.rbacResourceCode != "error" && solution?.rbacChildResourceCode != "error") || false;
  const isRbacInErrorState = (solution?.rbacResourceCode == "error" || solution?.rbacChildResourceCode == "error") || false;
  const { data: allUsers, isLoading: isLoadingUsers, isError: isErrorUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(solution?.code, solution?.version), resourceCode: solution?.rbacResourceCode, resourceChildCode: solution?.rbacChildResourceCode } as ProductAndResources, { skip: !areRbacResourcesReady });
  const { data: allRoles, isLoading: isLoadingRoles, isError: isErrorRoles } = useGetAllRolesForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(solution?.code, solution?.version), resourceCode: solution?.rbacResourceCode, resourceChildCode: solution?.rbacChildResourceCode } as ProductAndResources, { skip: !areRbacResourcesReady });
  const isApprovalFlow = solution?.status === Status.REVIEW;
  const approversApproved = solution?.approversApproved;
  const approversRejected = solution?.approversRejected;
  const [postRetryGettingUsersForSolution, { isLoading: isLoadingRetryGettingUsersForProduct }] = usePostRetryGettingUsersForSolutionMutation();

  const editOverviewField = (fieldName: string, flag: boolean) => {
    setEditableFields({ ...editableFields, [fieldName]: flag });
    isSolutionInEditMode && isSolutionInEditMode({ ...editableFields, [fieldName]: flag });
  }

  const saveOverviewDescriptionField = (fieldName: string, value: RichTextDescription) => {
    setEditableFields({ ...editableFields, [fieldName]: false });
    isSolutionInEditMode && isSolutionInEditMode({ ...editableFields, [fieldName]: false });
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData = { ...solutionBaseRequest, header: { ...solution?.header, description: value.textValue, descriptionRichText: value.htmlValue } } as SolutionDefnitionRequest;

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionContext.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            header: { ...solution.header, description: value.textValue, descriptionRichText: value.htmlValue }
          });
        toaster.showToast('success', 'Solution description is successfully updated');
      },
      () => {
        toaster.showToast('error', 'Failed to update solution description');
      }
    )
  }

  const saveOverviewField = (fieldName: string, value: string) => {
    setEditableFields({ ...editableFields, [fieldName]: false });
    const solutionBaseRequest = solutionContext && constructBaseSolutionRequest(solution);
    const solutionRequestData = { ...solutionBaseRequest, header: { ...solution.header, [fieldName]: value } } as SolutionDefnitionRequest;

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionContext.setSolutionData({ ...solution, lockingVersion: response.lockingVersion, header: { ...solution.header, [fieldName]: value } });
        toaster.showToast('success', 'Solution ' + startCase(fieldName) + ' is successfully updated');
      },
      () => {
        toaster.showToast('error', 'Failed to update solution ' + [fieldName]);
      }
    )
  }

  const saveSolutionHeaders = (options: Header[]) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const modifiedHeaderObject = Object.fromEntries(Object.entries(options).filter(header => {
      return Object.values(SOLUTION_HEADER_NAMES).find(val => val.code === header[0]) !== undefined;
    }).map(entry => {
      return entry[1] === null ? [entry[0], null] : entry;
    }))
    const solutionRequestData = { ...solutionBaseRequest, headersInfo: { ...solution.headersInfo, ...modifiedHeaderObject } } as SolutionDefnitionRequest;
    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionContext.setSolutionData({ ...solution, "lockingVersion": response.lockingVersion, headersInfo: response.headersInfo });
        toaster.showToast('success', 'Solution ' + startCase(Object.keys(options)[0]) + ' header is successfully updated');
      },
      (error) => {
        let errorMsg: string = error.data.message;
        errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to update solution ' + startCase(Object.keys(options)[0]) + ' header';
        toaster.showToast('error', errorMsg);
      }
    )
  }

  const retryCreatingAnchorResources = () => {
    postRetryGettingUsersForSolution(solution?.code)
      .unwrap()
      .then(() => {
        refreshAnchorToken();
        refetchSolution && refetchSolution();
        toaster.showToast('success', 'Successfully created solution resources');
      },
        () => {
          toaster.showToast('error', 'Failed create solution resources. Please try again');
        }
      )
  }

  const rbacResourceReadinessBanner = () => {
    if (isRbacInErrorState) {
      return (
        <div className={styles.redRibbon}>
          <i className={`pi pi-times-circle ${styles.icon}`} />
          <div>
            We failed to retrieve the users.
            {
              (hasGlobalOwnerRole() || hasRole([solution?.code + "-" + (solution?.version - 1)], ROLES.OWNER)) &&
              <>
                &nbsp;<span onClick={() => retryCreatingAnchorResources()} className={styles.link}>Click here</span> to try again.
              </>

            }
          </div>

        </div>
      );
    } else if (!areRbacResourcesReady) {
      return (
        <div className={styles.yellowRibbon}>
          <span className={styles.spin} />
          We are currently setting up the role based access for you.
        </div>
      );
    } else {
      return <></>
    }
  }

  const getRoleId = (roleName: ROLES): string => {
    if (allRoles) {
      return allRoles[roleName]?.roles_id;
    }
    return "";
  }

  const sortApprovers = (users: User[]) => {
    const sortApprovers = sortApproverList(users)
    return sortApprovers ? sortApprovers : [];
  }

  return (
    <div className={styles.SolutionOverview} data-testid="SolutionOverview">
      {(isLoadingUpdateSolution || isLoadingRetryGettingUsersForProduct) && <SpinnerComponent />}
      <div className={styles.formFieldList} data-testid="FormFields">
        <div className={styles.field}>
          <div className={styles.fieldTitle}>
            <div className={styles.label}>
              <h3>Description</h3>
            </div>
            {isEditable && !editableFields.description &&
              <div className={`pi pi-pencil ${styles.editField}`} onClick={() => editOverviewField('description', true)} data-testid="solOverDescEditIcon" />
            }
          </div>
          {editableFields.description ?
            <div className={styles.displayDescription}>
              <InputRichTextWithSave richTextDesc={{ htmlValue: solution.header.descriptionRichText, textValue: solution.header.description }}
                saveText={(input: RichTextDescription) => saveOverviewDescriptionField('description', input)} cancel={() => editOverviewField('description', false)}
                fieldName={FieldIdentifiers.SOLUTION_DESCRIPTION} isRequired={false} dataTestIds={['solOverDescInputWithSave', 'solOverDescInputText', 'solOverDescSaveBtn']} />
            </div>
            :
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(solution.header?.descriptionRichText) }} className={styles.fieldTextValue}></div>
          }
        </div>
        <div>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Key Contacts</h3>
              </span>
            </div>
            <div className={styles.keyContactsUserLists}>
              {rbacResourceReadinessBanner()}
              <UserListForRoleV2 allUsers={allUsers} displayRoleName='Solution Owner' canEdit={isGlobalOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers} resourceCode={solution?.rbacResourceCode} resourceChildCode={solution?.rbacChildResourceCode} roleName={ROLES.OWNER} roleId={getRoleId(ROLES.OWNER)} userList={allUsers?.owners?.map(owner => mapAnchorUserToGraphUser(owner))} productName={solution?.name} productFamilyCode={SolutionsId} productVersion={solution?.version} productCode={solution?.code} editMode={isEditable} />
              {
                (solution?.status === Status.DRAFT || solution?.status === Status.REVIEW) &&
                <>
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Contributors' canEdit={isSolutionOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={solution?.rbacResourceCode} resourceChildCode={solution?.rbacChildResourceCode} roleName={ROLES.CONTRIBUTOR} roleId={getRoleId(ROLES.CONTRIBUTOR)} userList={allUsers?.contributors?.map(contributor => mapAnchorUserToGraphUser(contributor))} productName={solution?.name} productFamilyCode={SolutionsId} productVersion={solution?.version} productCode={solution?.code} editMode={isEditable} />
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Reviewers' canEdit={isSolutionOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={solution?.rbacResourceCode} resourceChildCode={solution?.rbacChildResourceCode} roleName={ROLES.REVIEWER} roleId={getRoleId(ROLES.REVIEWER)} userList={allUsers?.reviewers?.map(reviewer => mapAnchorUserToGraphUser(reviewer))} productName={solution?.name} productFamilyCode={SolutionsId} productVersion={solution?.version} productCode={solution?.code} editMode={isEditable} />
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Approvers' canEdit={isSolutionOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={solution?.rbacResourceCode} resourceChildCode={solution?.rbacChildResourceCode} roleName={ROLES.APPROVER} roleId={getRoleId(ROLES.APPROVER)} userList={sortApprovers(allUsers ? allUsers?.approvers?.map(approver => mapAnchorUserToGraphUser(approver)) : [])} productName={solution?.name} productFamilyCode={SolutionsId} productVersion={solution?.version} productCode={solution?.code} editMode={isEditable} approversApproved={approversApproved} approversRejected={approversRejected} triggerApproverStatus={triggerApproverStatus} setTriggerApproverStatus={setTriggerApproverStatus} />
                </>
              }
            </div>
            <div className={styles.fieldTextValue}>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <div className={styles.label}>
                <h3>Limitation</h3>
              </div>
              {isEditable && !editableFields.limitation &&
                <div className={`pi pi-pencil ${styles.editField}`} onClick={() => editOverviewField('limitation', true)} data-testid="solOverLimitationEditIcon" />
              }
            </div>
            {editableFields.limitation ?
              <div className={styles.displayDescription}>
                <InputTextWithSave value={solution.header.limitation} saveText={(input: string) => saveOverviewField('limitation', input)} cancel={() => editOverviewField('limitation', false)}
                  fieldName={FieldIdentifiers.SOLUTION_LIMITATION} isRequired={false} dataTestIds={['solLimitationInputWithSave', 'solLimitationInputText', 'solLimitationSaveBtn', 'solLimitationCancelBtn']} />
              </div>
              :
              <div className={styles.fieldTextValue}>
                {solution.header.limitation}
              </div>
            }
          </div>
        </div>
        <div>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <div className={styles.label}>
                <h3>Dependency</h3>
              </div>
              {isEditable && !editableFields.dependency &&
                <div className={`pi pi-pencil ${styles.editField}`} onClick={() => editOverviewField('dependency', true)} data-testid="solOverDependencyEditIcon" />
              }
            </div>
            {editableFields.dependency ?
              <div className={styles.displayDescription}>
                <InputTextWithSave value={solution.header.dependency} saveText={(input: string) => saveOverviewField('dependency', input)} cancel={() => editOverviewField('dependency', false)}
                  fieldName={FieldIdentifiers.SOLUTION_DEPENDENCY} isRequired={false} dataTestIds={['solDependencyInputWithSave', 'solDependencyInputText', 'solDependencySaveBtn', 'solDependencyCancelBtn']} />
              </div>
              :
              <div className={styles.fieldTextValue}>
                {solution.header.dependency}
              </div>
            }
          </div>
        </div>
        <div>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <div className={styles.label}>
                <h3>Customer Commitments</h3>
              </div>
              {isEditable && !editableFields.customerCommitments &&
                <div className={`pi pi-pencil ${styles.editField}`} onClick={() => editOverviewField('customerCommitments', true)} data-testid="solOverCustomerCommitEditIcon" />
              }
            </div>
            {editableFields.customerCommitments ?
              <div className={styles.displayDescription}>
                <InputTextWithSave value={solution.header.customerCommitments} saveText={(input: string) => saveOverviewField('customerCommitments', input)} cancel={() => editOverviewField('customerCommitments', false)}
                  fieldName={FieldIdentifiers.SOLUTION_CUSTOMER_COMMITMENTS} isRequired={false} dataTestIds={['solCustomerCommitInputWithSave', 'solCustomerCommitInputText', 'solCustomerCommitSaveBtn', 'solCustomerCommitCancelBtn']} />
              </div>
              :
              <div className={styles.fieldTextValue}>
                {solution.header.customerCommitments}
              </div>
            }
          </div>
        </div>
      </div>

      <div data-testid="HeaderView">
        <HeaderView productHeaders={solution.headersInfo} editMode={isEditable} saveHeaders={saveSolutionHeaders} headerList={SOLUTION_HEADER_NAMES} allHeadersDefinitions={SOLUTION_HEADERS_DEFINITION_DATA} defaultHeaders={Object.values(SOLUTION_DEFAULT_HEADER_NAMES) as string[]} isSolution={true}></HeaderView>
      </div>
    </div>
  )
};

export default memo(SolutionOverview, isEqual);

