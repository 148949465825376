import React, { lazy, Suspense } from 'react';
import { RibbonProps } from './Ribbon';

const LazyRibbon = lazy(() => import('./Ribbon'));

const Ribbon = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; } & RibbonProps) => (
  <Suspense fallback={null}>
    <LazyRibbon {...props} />
  </Suspense>
);

export default Ribbon;
