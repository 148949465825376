import React, { useContext, useState } from 'react';
import styles from './CommercialInfo.module.scss';
import { AttributeCollection } from '../../data/model/SharedDataInterfaces';
import { startCase } from 'lodash';
import { CommercialInfoIncrementalEdit, SolutionDefnitionRequest, Status } from '../../data/model/DataModels';
import InputTextWithSave from '../InputTextWithSave/InputTextWithSave';
import { FieldIdentifiers } from '../../utils/validationUtil';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../../features/AppLayoutView/AppLayoutView';
import { SolutionContext, constructBaseSolutionRequest } from '../../features/SolutionDetails/SolutionDetails';
import SpinnerComponent from '../Spinner/SpinnerComponent';

interface CommercialInfoProps {
	attributes: AttributeCollection,
	isEditable: boolean,
	isSolutionInEditMode?: Function
}

const CommercialInfo: React.FC<CommercialInfoProps> = ({attributes, isEditable, isSolutionInEditMode}) => {

	const [editableFields, setEditableFields] = useState({tagline: false, twowayCommitmentTagline: false, customerNeed : false, targetCustomerSegments : false, targetCustomerVerticals: false, customerCommitments : false, salesChannels : false, bookingChannels : false, compatibleProducts : false} as CommercialInfoIncrementalEdit);
	const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
	const toaster = useContext(ToasterContext);
	const solutionState = useContext(SolutionContext);
	const solution = solutionState.solutionData;

	if(!solution){
		return <></>
	}

	const editCommercialInfoAttribute = (attrKey : string, flag: boolean) => {
    setEditableFields({...editableFields, [attrKey] : flag});
		isSolutionInEditMode && isSolutionInEditMode({...editableFields, [attrKey] : flag});
  }

	const saveAttribute = (attrKey : string, attrValue: string) => {
		setEditableFields({ ...editableFields, [attrKey]: false });
		isSolutionInEditMode && isSolutionInEditMode({ ...editableFields, [attrKey]: false });
    const solutionBaseRequest = solutionState && constructBaseSolutionRequest(solution);
		let solutionRequestData;
		if(attrKey === "tagline") {
			solutionRequestData = {...solutionBaseRequest, salesInfo: {...solution.salesInfo, [attrKey] : attrValue}, actionType: "updateTagline"} as SolutionDefnitionRequest;
		}
		else {
    	solutionRequestData = {...solutionBaseRequest, salesInfo: {...solution.salesInfo, [attrKey] : attrValue }} as SolutionDefnitionRequest;
		}

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData({...solution, "lockingVersion" : response.lockingVersion, salesInfo : {...solution.salesInfo, [attrKey] : attrValue }});
        toaster.showToast('success', 'Solution ' + startCase(attrKey) + ' is successfully updated');
      },
      () => {
        toaster.showToast('error', 'Failed to update solution ' + startCase(attrKey) );
      }
    )
  }

	const resolveAttributeIconFn = (key: string) => {
    switch (key) {
      case "tagline": return FieldIdentifiers.SOLUTION_TAGLINE;
      case "twowayCommitmentTagline": return FieldIdentifiers.SOLUTION_TWOWAY_COMMITMENT_TAGLINE;
      case "customerNeed": return FieldIdentifiers.SOLUTION_CUSTOMER_NEED;
      case "targetCustomerSegments": return FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_SEGMENTS;
      case "targetCustomerVerticals": return FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_VERTICALS;
      case "salesChannels": return FieldIdentifiers.SOLUTION_SALES_CHANNELS;
			case "bookingChannels": return FieldIdentifiers.SOLUTION_BOOKING_CHANNELS;
			case "compatibleProducts": return FieldIdentifiers.SOLUTION_COMPATIBLE_PRODUCTS;
    }
  };

	interface AttributeIdentifier {
		[key: string]: FieldIdentifiers
	}

	const attributeIdentifierMap = {
		"tagline": FieldIdentifiers.SOLUTION_TAGLINE,
		"twowayCommitmentTagline" : FieldIdentifiers.SOLUTION_TWOWAY_COMMITMENT_TAGLINE,
		"customerNeed" : FieldIdentifiers.SOLUTION_CUSTOMER_NEED,
		"targetCustomerSegments" : FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_SEGMENTS,
		"targetCustomerVerticals" : FieldIdentifiers.SOLUTION_TARGET_CUSTOMER_VERTICALS,
		"salesChannels" : FieldIdentifiers.SOLUTION_SALES_CHANNELS,
		"bookingChannels" : FieldIdentifiers.SOLUTION_BOOKING_CHANNELS,
		"compatibleProducts" : FieldIdentifiers.SOLUTION_COMPATIBLE_PRODUCTS
	} as AttributeIdentifier

	const attributeInputByType = (attrKey: string, attrValue: string) => {
		const editMode = editableFields[attrKey as keyof CommercialInfoIncrementalEdit];
		return (
			<div className={styles.attributeText}>
				{ editMode ?
					<div className={styles.displayDescription}>
						<InputTextWithSave value={attrValue} saveText={(input : string) => saveAttribute(attrKey, input)}  cancel={() => editCommercialInfoAttribute(attrKey, false)} 
							fieldName={attributeIdentifierMap[attrKey as keyof AttributeIdentifier]} isRequired={false} dataTestIds={[`sol${attrKey}InputWithSave`, `sol${attrKey}InputText`,`sol${attrKey}SaveBtn`,`sol${attrKey}CancelBtn`]}/>
					</div>
					:
					<div className={styles.fieldTextValue}>
						{attrValue}
					</div>
				}

				{/* <InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
					className={`${editMode ? styles.edit_mode : styles.view_mode} ${containsChanges(attrKey) ? "isChanged" : ""} ${editMode && formValidation(attrValue, FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
					value={attrValue} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, attrKey)} />
				{
					editMode && checkFormValidity(attrValue, attrKey)
				} */}
			</div>
		);
	}

  return (
    <div className={styles.CommercialInfo} data-testid="CommercialInfo">
			{ isLoadingUpdateSolution && <SpinnerComponent /> } 
      <div className={styles.attributes}>
				{Object.keys(attributes)
					.map((entry) => {
						const attrKey = entry;
						const attrValue = attributes[entry]?.toString() || "";
						return (
							<div key={"attribute-" + attrKey} className={styles.attribute}>
								<div className={styles.attributeHeader}>
									<div className={`${resolveAttributeIconFn(attrKey)} ${styles.attributeIcon}`} />
									<div className={styles.label}><h3>{startCase(attrKey)}</h3></div>
									{isEditable && !editableFields[attrKey as keyof CommercialInfoIncrementalEdit] && 
                		<div className={`pi pi-pencil ${styles.editAttribute}`} onClick={() => editCommercialInfoAttribute(attrKey, true)} data-testid={`sol${attrKey}EditIcon`}/>
									}
								</div>
								{attributeInputByType(attrKey, attrValue)}
							</div>
						);
					})}
			</div>
    </div>
  )
};

export default CommercialInfo;