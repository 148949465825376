import React, { FC, useContext, useMemo, useState } from 'react';
import styles from './SolutionAddComponentFromProductsDialog.module.scss';
import { ComponentAssociation, ComponentDefinition, Status } from '../../data/model/DataModels';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DisplayCardsComponent, { DisplayCardElement } from '../../components/DisplayCards/DisplayCardsComponent';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { useGetProductListByStatusQuery, useGetComponentsByProductQuery, useLazyGetFeatureByComponentsRefQuery } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { Dropdown } from 'primereact/dropdown';

export interface SolutionAddComponentFromProductsDialogProps {
  existingComponents: ComponentAssociation[],
  saveProps: { save: Function, isLoadingSave: boolean },
  onHide: Function
}

const SolutionAddComponentFromProductsDialog: FC<SolutionAddComponentFromProductsDialogProps> = ({ existingComponents, onHide, saveProps }) => {
  const { data: activeProductList, error: errorActiveProductList, isLoading: isLoadingActiveProductList } = useGetProductListByStatusQuery(Status.ACTIVE);

  const toaster = useContext(ToasterContext);
  const [selectedProduct, setSelectedProduct] = useState("");
  const { save, isLoadingSave } = saveProps;
  const { data: componentList, error: errorComponents, isLoading: isLoadingComponents } = useGetComponentsByProductQuery([selectedProduct], { skip: selectedProduct === "" });

  const [getFeaturesForSelectedComponents, { isLoading: isLoadingFeaturesForSelectedComponents }] = useLazyGetFeatureByComponentsRefQuery();

  const [componentsSelected, setComponentsSelected] = useState<ComponentDefinition[]>([]);
  const componentsSelectedCodes = componentsSelected.map(component => component.code);

  if (errorComponents) {
    toaster.showToast("error", 'Failed to load components');
  }
  if (errorActiveProductList) {
    toaster.showToast("error", 'Failed to load products');
  }

  const filterTemplate = (option: any) => {
    return (
      <div>
        <div>{option.name}</div>
      </div>
    );
  };

  const selectedComponentTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="component-item component-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const acceptAction = () => {
    getFeaturesForSelectedComponents(componentsSelected.map(comp => comp.code))
      .unwrap()
      .then(features => {
        save(componentsSelected, features)
      },
        () => {
          toaster.showToast("error", "Failed to fetch features for components");
        }
      )
  }

  const sortedAndFilteredComponentsList = useMemo((): { [key: string]: ComponentDefinition } => {
    return componentList ?
      [...componentList].sort((a, b) => a.name.localeCompare(b.name))
        .reduce((accumulator, current) => { return { ...accumulator, [current.code]: current } }, {})
      : {}
  }
    ,
    [componentList]);


  const addComponentAction = (elementClicked: DisplayCardElement) => {
    let componentClicked = sortedAndFilteredComponentsList[elementClicked.code];
    if (componentsSelected.includes(componentClicked)) {
      setComponentsSelected(componentsSelected.filter(component => component.code !== componentClicked.code));
    } else {
      setComponentsSelected(componentsSelected.concat(componentClicked));
    }
  }

  const footer = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} />
      <Button data-testid="addButton" label="Add" icon="pi pi-check" onClick={() => acceptAction()} disabled={componentsSelected.length === 0} />
    </div>
  );

  return (
    <div className={styles.SolutionAddComponentFromProductsDialog} data-testid="SolutionAddComponentFromProductsDialog">
      <Dialog footer={footer} header="Select Components To Add" visible={true} style={{ width: '90vw', height: '85vh' }} onHide={() => onHide()}>
        {
          (isLoadingComponents || isLoadingFeaturesForSelectedComponents || isLoadingActiveProductList || isLoadingSave) && <SpinnerComponent />
        }
        <Dropdown style={{ minWidth: '48rem' }} optionLabel="name" optionValue="code" placeholder="Search product"
          options={activeProductList} value={selectedProduct} onChange={(e) => setSelectedProduct(e.value)}
          filter itemTemplate={filterTemplate} valueTemplate={selectedComponentTemplate} />
        <div className={styles.componentsView}>
          <DisplayCardsComponent action={(elementClicked: DisplayCardElement) => addComponentAction(elementClicked)}
            selectedCodes={componentsSelectedCodes}
            dialogView={true}
            editMode={true}
            elementList={
              Object.values(sortedAndFilteredComponentsList).map(comp => {
                return {
                  code: comp.code, name: comp.name, duplicateElement: existingComponents.some(componentInDraft => componentInDraft.componentRef === comp.code),
                  tooltipElements: comp.features.map(feature => feature.name)
                }
              })}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default SolutionAddComponentFromProductsDialog;
