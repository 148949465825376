import { Card } from "primereact/card";
import React, { FC } from "react";
import styles from "./ErrorHandlerComponent.module.scss";
import NotAuthenticatedView from "./NotAuthenticated/NotAuthenticatedView";

interface ApiErrorComponentProps {
  error: any
}

const ErrorHandlerComponent: FC<ApiErrorComponentProps> = ({ error }) => {
	console.log(error);


	const DefaultError = () => {

		return (
			<div className={styles.ApiError} data-testid="ApiError">
				<div className={styles.cardStyle}>
					<Card>
						<div className={styles.header}>Failed to fetch data</div>
						<div className={styles.content}>
							<div className={styles.container}>
								<div className={styles.row}>
									<div className={styles.column}>Error:</div>
									<div className={styles.column}>{error?.data?.status} {error?.data?.error}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.column}>Message:</div>
									<div className={styles.column}>{error?.data?.message}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.column}>Trace Id:</div>
									<div className={styles.column}>{error?.data?.traceId}</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
			</div>
		);

	};

	switch (error?.status) {
	case 403:
		return <NotAuthenticatedView></NotAuthenticatedView>;

	default: return <DefaultError></DefaultError>;
	}

};

export default ErrorHandlerComponent;
