import { Checkbox } from 'primereact/checkbox';
import { FC, useState } from 'react';
import { useGetFamiliesQuery } from '../../../data/api/CatalogueApi';
import styles from './FamilyFilter.module.scss';
import { ProductType, productTypeFilterList, serviceModelTypeDropDownList } from '../../../data/model/DataModels';
import { familyFilterQueryParams } from '../../ProductFamiliesView/ProductFamilies';

export interface FamilyFilterProps {
  familyNameFilters: string[];
  setSelectedFilters: Function;
  changeProductTypeDropDown?: Function;
  onClose: Function;
  productTypeFilters?: string[];
  serviceModelFilters?: string[];
}

const FamilyFilter: FC<FamilyFilterProps> = ({ familyNameFilters, setSelectedFilters, changeProductTypeDropDown, onClose, productTypeFilters , serviceModelFilters }) => {
  const { data: familyListFromApi = [], error: errorFamily, isLoading: isLoadingFamily } = useGetFamiliesQuery();
  const familyList = familyListFromApi.map(family => { return { name: family.name, code: family.code }; });
  const [showFilter, setShowFilter] = useState({ family: true, productType: true, serviceModal: true } as { family: boolean, productType: boolean, serviceModal: boolean });
  const serviceModalList = serviceModelTypeDropDownList;
  const selectAllFamilies = () => {
    setSelectedFilters(familyList.map(family => family.code), familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY)
  }

  const deSelectAllFamilies = () => {
    setSelectedFilters([], familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY);
  }

  const onCheck = (familyCode: string, isChecked: boolean) => {
    if (!isChecked) {
      setSelectedFilters(familyNameFilters.filter(familyFilterCode => familyFilterCode !== familyCode), familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY)
      return;
    }
    setSelectedFilters([...familyNameFilters, familyCode], familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY)
  }

  const onProductTypeCheck = (productType: ProductType, isChecked: boolean) => {
    if (!isChecked) {
      setSelectedFilters(productTypeFilters && productTypeFilters.filter(prodType => prodType !== productType), familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
      changeProductTypeDropDown && changeProductTypeDropDown();
      return;
    }
    setSelectedFilters(productTypeFilters && [...productTypeFilters, productType], familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
    changeProductTypeDropDown && changeProductTypeDropDown();
  }

  const toggleFamilyFilter = () => {
    setShowFilter({ ...showFilter, family: !showFilter.family });
  }

  const toggleProductFilter = () => {
    setShowFilter({ ...showFilter, productType: !showFilter.productType });
  }

  const selectAllServiceModels = () => {
		setSelectedFilters(serviceModalList.map(service => service.code), familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
	}

	const deselectAllServiceModels = () => {
		setSelectedFilters([], familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	}

  const onServiceModelCheck = (serviceModelCode: string, isChecked: boolean) => {
		if (!isChecked) {
			setSelectedFilters(serviceModelFilters && serviceModelFilters.filter(serviceFilterCode => serviceFilterCode !== serviceModelCode), familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
		}
    else setSelectedFilters(serviceModelFilters && [...serviceModelFilters, serviceModelCode], familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
	}

  const toggleServiceModelFilter = () => {
		setShowFilter({ ...showFilter, serviceModal: !showFilter.serviceModal });
	}

  const toggleFilter = (filter: boolean) => {
		return `pi ${filter ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`
	}

  return (
    <div className={styles.FamilyFilter} data-testid="FamilyFilter">
      <div>
        <div className={styles.header}>
          <h2>Filters</h2>
          <div onClick={() => onClose()} className={`${"pi pi-times"} ${styles.closeIcon}`}></div>
        </div>
        <hr />
        <div className={styles.subHeader}>
          <h3>Families ({familyNameFilters.length})</h3>
          <div onClick={() => toggleFamilyFilter()} className={`pi ${showFilter.family ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`}></div>
          {/* <i className={`pi ${showFamilyFilter ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`} onClick={toggleFamilyFilter}/> */}
        </div>
        <div className={` ${styles.filterSection} ${!showFilter.family ? styles.hidden : ''}`}>
          <div className={styles.filterActions}>
            <div className={styles.filterAction} onClick={() => selectAllFamilies()}>Select All</div>
            <div className={styles.filterAction} onClick={() => deSelectAllFamilies()}>Deselect All</div>
          </div>


          {
            familyList.map(family =>
              <div key={family.code} className={styles.family}>
                <span className={styles.checkbox}>
                  <Checkbox inputId={family.code} checked={familyNameFilters.includes(family.code)} onChange={(e) => onCheck(family.code, (e.checked || false))} />
                </span>
                <label htmlFor={family.code}>{family.name}</label>
              </div>
            )
          }
        </div>
      </div>
      { productTypeFilters &&
      <>
      <hr/>
      <div>
        <div className={styles.subHeader}>
          <h3>Product Type ({productTypeFilters.length})</h3>
          <div onClick={()=>toggleProductFilter()} className={`pi ${showFilter.productType ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`}></div>
        </div>
        <div className={` ${styles.filterSection} ${!showFilter.productType ? styles.hidden : ''}`}>
          {
            productTypeFilterList.map(productType =>
              <div key={productType.type} className={styles.family}>
                <span className={styles.checkbox}>
                  <Checkbox inputId={productType.type} checked={productTypeFilters.includes(productType.type)} onChange={(e) => onProductTypeCheck(productType.type, (e.checked || false))} />
                </span>
                <label htmlFor={productType.type}>{productType.name}</label>
              </div>
            )
          }
        </div>
      </div>
      <hr/>
      <div>
        <div className={styles.subHeader}>
          <h3>Service model</h3>
          <div onClick={()=>toggleServiceModelFilter()} className={`pi ${showFilter.serviceModal ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`}></div>
        </div>
        <div className={` ${styles.filterSection} ${!showFilter.serviceModal ? styles.hidden : ''}`}>
          <div className={styles.filterActions}>
            <div className={styles.filterAction} onClick={() => selectAllServiceModels()}>Select All</div>
            <div className={styles.filterAction} onClick={() => deselectAllServiceModels()}>Deselect All</div>
          </div>
          {
            serviceModalList.map(serviceModel =>
              <div key={serviceModel.code} className={styles.family}>
                <span className={styles.checkbox}>
                  <Checkbox inputId={serviceModel.code} checked={serviceModelFilters!.includes(serviceModel.code)} onChange={(e) => onServiceModelCheck(serviceModel.code, (e.checked || false))} />
                </span>
                <label htmlFor={serviceModel.code}>{serviceModel.name}</label>
              </div>
            )
          }
        </div>
      </div>
      </>
      }
    </div>
  )
};

export const setSelectedFilters = (selectedFilters: string[], searchParams: URLSearchParams, setSearchParams: Function, filterType: string) => {
  if (!selectedFilters?.length) {
    searchParams?.delete(filterType)
    setSearchParams(searchParams);
    return;
  }
  searchParams.set(filterType, selectedFilters?.join(','));
  setSearchParams(searchParams);
}

export default FamilyFilter;
