import {Header, HeaderOptions, Optionality, ProductHeaders, ProductType} from "../model/DataModels";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface NewProductDTO {
	name: string,
	code: string,
	productType: string,
	family: string,
	tagline: string,
	customerNeed: string,
	contracting: "" | Optionality,
	headersInfo: ProductHeaders,
	errorText: string
}

export const initialState: NewProductDTO = {
	name: "",
	code: "",
	productType: "",
	family: "",
	tagline: "",
	customerNeed: "",
	contracting: "",
	errorText: "Please enter required information",
	headersInfo: {
		serviceModel: [],
		carrier: [],
		incoterm: [],
		contractValidity: [],
		// packagingType: [],
		attractiveness: [],
		customerType: [],
		vertical: [],
		valueProposition: [],
		loadType: [],
		direction: []
	},
};
const validateProduct = (product : any) : string => {
	if (product.name === "") return "Please enter a product name";
	if (product.code === "") return "Please enter a product code";
	if (!(/^[a-z0-9\-]+$/i.test(product.code))) return "Please only use letters, digits and hyphens for product code";
	if (product.code.length<3) return "Please make product code at least 3 characters long";
	if (product.family === "") return "Please select a product family";
	if (Object.values(Optionality).indexOf(product.contracting) === -1) return "Please select a correct product type";
	if (product.contracting !== "mandatory" && product.contracting !== "optional" && product.contracting !== "restricted") return "Please select a product type";
	return "";
}

export const newProductSlice = createSlice({
	name: "newProduct",
	initialState,
	reducers: {
		setProductName: (state, action: PayloadAction<string>)=>{
			state.name = action.payload;
			state.errorText = validateProduct(state)
		},
		setProductCode: (state, action: PayloadAction<string>)=>{
			state.code = action.payload;
			state.errorText = validateProduct(state)
		},
		setProductTagline: (state, action: PayloadAction<string>)=>{
			state.tagline = action.payload;
			state.errorText = validateProduct(state)
		},
		setProductCustomerNeed: (state, action: PayloadAction<string>)=>{
			state.customerNeed = action.payload;
			state.errorText = validateProduct(state)
		},
		setProductFamily: (state, action: PayloadAction<string>)=>{
			state.family = action.payload;
			state.errorText = validateProduct(state)
		},
		setProductContracting: (state, action: PayloadAction<string>)=>{
			state.contracting = action.payload === "mandatory" ? Optionality.mandatory
				: action.payload === "restricted" ? Optionality.restricted
					: action.payload === "optional" ? Optionality.optional : "";
			state.errorText = validateProduct(state)
		},
		setProductType: (state, action: PayloadAction<string>)=>{
			state.productType = action.payload;
			state.errorText = validateProduct(state)
		},
		setServiceModel: (state, action: PayloadAction<HeaderOptions>)=>{
			state.headersInfo.serviceModel = [{code: action.payload.code, name: action.payload.name}] as Header[]
			state.errorText = validateProduct(state)
		},
		clearProduct: (state, action: PayloadAction<void>)=>{
			state.code = "";
			state.name = "";
			state.family = "";
			state.contracting = "";
			state.tagline = "";
			state.customerNeed = "";
			state.errorText = "Please enter required information";
		},
	}
});

export const {
	setProductName, setProductCode,
	setProductFamily, setProductContracting,
	setProductTagline, setProductCustomerNeed,
	setProductType, setServiceModel, clearProduct
} = newProductSlice.actions;

export default newProductSlice.reducer;
