import React, {forwardRef} from 'react';

import {GiHamburgerMenu} from 'react-icons/gi'
import { ActionProps, Action } from '../Action';

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    
    return (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
      >
      </Action>
    );
  }
);