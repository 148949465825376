import React, { useMemo, useState } from "react";
import styles from "./PickList.module.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export interface CodeNamePair {
  code: string,
  name: string
}
export interface PickListProperties {
  mainHeader?: string,
  source: CodeNamePair[],
  target: CodeNamePair[],
  sourceHeader?: string,
  targetHeader?: string,
  searchPlaceholder?: string,
  sortingKey: string,
  sourceItemTemplate: Function,
  targetItemTemplate: Function,
  onChange: Function
}

export const PickList: React.FC<PickListProperties> =
  ({
    mainHeader,
    source,
    target,
    sourceHeader = "Source",
    targetHeader = "Target",
    searchPlaceholder = "Search",
    sortingKey,
    sourceItemTemplate,
    targetItemTemplate,
    onChange,
  }) => {
    const [updatedSource, setUpdatedSource] = useState(source);
    const [updatedTarget, setUpdatedTarget] = useState(target);
    const [searchSource, setSearchSource] = useState("");
    const [searchTarget, setSearchTarget] = useState("");
    const [selectedSourceItems, setSelectedSourceItems] = useState([] as string[]);
    const [selectedTargetItems, setSelectedTargetItems] = useState([] as string[]);

    const processItems = (searchItem: String, updatedSourceOrTarget: CodeNamePair[]) => {
      if(searchItem !== ""){
        return updatedSourceOrTarget.filter(item => item.name.toLowerCase().includes(searchItem.toLowerCase())).map(item => item.code);
      }
      return updatedSourceOrTarget.map(item => item.code);
    }

    const processSourceItems = useMemo(() => processItems(searchSource, updatedSource), [searchSource]);
    const processTargetItems = useMemo(() => processItems(searchTarget, updatedTarget), [searchTarget]);

    const includeOrRemoveFromSelectedItems = (value: string, selectionList: string[], setSelectedItems: Function) => {
      if (selectionList.includes(value)) {
        setSelectedItems(selectionList.filter(item => item !== value));
      } else {
        setSelectedItems([...selectionList, value]);
      }
    }

    const getSourceItems = () => {
      return (
        <>
          {
            updatedSource
              .filter((value: any) => value.name?.toLowerCase().includes(searchSource?.toLowerCase()))
              .map((element: any, index: number) =>
                <div key={"item-" + index}
                  className={`${styles.lineItems} ${selectedSourceItems.includes(element.code) ? styles.itemSelected : ""}`}
                  onClick={(e) => includeOrRemoveFromSelectedItems(element.code, selectedSourceItems, setSelectedSourceItems)}> {sourceItemTemplate(element)} </div>)
          }
        </>
      );
    };
    
    const getTargetItems = () => {
      return (
        <>
          {
            updatedTarget
              .filter((value: any) => value.name?.toLowerCase().includes(searchTarget?.toLowerCase()))
              .map((element: any, index: number) =>
                <div key={"item-" + index}
                  className={`${styles.lineItems} ${selectedTargetItems.includes(element.code) ? styles.itemSelected : ""}`}
                  onClick={(e) => includeOrRemoveFromSelectedItems(element.code, selectedTargetItems, setSelectedTargetItems)}> {targetItemTemplate(element)} </div>)
          }
        </>
      );
    };

    const moveSourceToTarget = (e: any) => {
      const selectedSourceItemsDefs: CodeNamePair[] = updatedSource.filter((value: any) => selectedSourceItems.includes(value.code));
      let newSource: CodeNamePair[] = updatedSource.filter((value: any) => !selectedSourceItems.includes(value.code));

      const newTarget = sortData([...updatedTarget, ...selectedSourceItemsDefs]);
      setUpdatedTarget(newTarget);
      setUpdatedSource(newSource);
      onChange(newSource, newTarget, e);
      setSelectedSourceItems([]);
    };

    const moveTargetToSource = (e: any) => {
      const selectedTargetItemsDefs: CodeNamePair[] = updatedTarget.filter((value: any) => selectedTargetItems.includes(value.code));
      let newTarget: CodeNamePair[] = updatedTarget.filter((value: any) => !selectedTargetItems.includes(value.code));

      const newSource = sortData([...updatedSource, ...selectedTargetItemsDefs]);
      setUpdatedTarget(newTarget);
      setUpdatedSource(newSource);
      onChange(newSource, newTarget, e);
      setSelectedTargetItems([]);
    };

    const sortData = (data: any[]) => {
      return data.sort((prev: any, next: any) => prev[sortingKey] > next[sortingKey] ? 1 : -1)
    };

    const selectAllSource = () => {
      setSelectedSourceItems(processSourceItems);
    };

    const deSelectAllSource = () => {
      setSelectedSourceItems([]);
    };

    const selectAllTarget = () => {
      setSelectedTargetItems(processTargetItems);
    };

    const deSelectAllTarget = () => {
      setSelectedTargetItems([]);
    };

    return (
      <>
        {
          <div className={styles.pickListContainer}>
            {mainHeader && <h2>{mainHeader}</h2>}

            <div className={styles.columnContent}>
              <div>
                <h3>{sourceHeader}</h3>
              </div>
              <div className={`p-input-icon-left ${searchSource !== "" && "p-input-icon-right"}`}>
                <i className={`pi pi-search ${styles.searchIcon}`} />
                <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                  value={searchSource}
                  onChange={(e) => setSearchSource(e.target.value)} />
                {
                  searchSource !== "" &&
                  <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchSource("")} />

                }
              </div>
              <div className={styles.columnContentBody}>


                <div className={styles.pickListItems}>
                  <div className={styles.allItemsControl}>
                    <span onClick={() => selectAllSource()}> Select All</span>
                    <span onClick={() => deSelectAllSource()}> Deselect All</span>
                  </div>
                  {getSourceItems()}
                </div>
              </div>
            </div>
            <div className={styles.arrowControls}>
              <div>
                <Button icon="pi pi-angle-right" disabled={selectedSourceItems.length===0} className="p-button-outlined" onClick={(e) => moveSourceToTarget(e)} />
              </div>
              <div>
                <Button icon="pi pi-angle-left" disabled={selectedTargetItems.length===0} className="p-button-outlined" onClick={(e) => moveTargetToSource(e)} />
              </div>
            </div>
            <div className={styles.columnContent}>
              <div>
                <h3>{targetHeader}</h3>
              </div>
              <div className={`p-input-icon-left ${searchTarget !== "" && "p-input-icon-right"}`}>
                <i className={`pi pi-search ${styles.searchIcon}`} />
                <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                  value={searchTarget}
                  onChange={(e) => setSearchTarget(e.target.value)} />
                {
                  searchTarget &&
                  <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchTarget("")} />
                }
              </div>
              <div className={styles.columnContentBody}>


                <div className={styles.pickListItems}>
                  <div className={styles.allItemsControl}>
                    <span onClick={() => selectAllTarget()}> Select All</span>
                    <span onClick={() => deSelectAllTarget()}> Deselect All</span>
                  </div>
                  {getTargetItems()}
                </div>
              </div>
            </div>

          </div>
        }
      </>)
  };
