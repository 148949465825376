import React, { lazy, Suspense } from 'react';
import { SolutionAddComponentFromProductsDialogProps } from './SolutionAddComponentFromProductsDialog';

const LazySolutionAddComponentFromProductsDialog = lazy(() => import('./SolutionAddComponentFromProductsDialog'));

const SolutionAddComponentFromProductsDialog = (props: JSX.IntrinsicAttributes & SolutionAddComponentFromProductsDialogProps & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazySolutionAddComponentFromProductsDialog {...props} />
  </Suspense>
);

export default SolutionAddComponentFromProductsDialog;
