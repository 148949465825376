import React, {FC} from 'react';
import {EnvConfig} from '../../EnvConfig';
import styles from './HelpView.module.scss';


interface HelpComponentProps { }

const HelpView: FC<HelpComponentProps> = () => {

  return (
    <div className={styles.HelpComponent} data-testid="HelpComponent">
      <iframe
        src={`${EnvConfig.CATALOGUE_BASE_URL}/docs/index.html`}
        style={{ height: '92vh', width: '100%' }}
      />
    </div>
  )
};

export default HelpView;
