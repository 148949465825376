import React, { lazy, Suspense } from 'react';
import { ApplicabilitiesViewIncrementalProps } from './ApplicabilitiesViewIncremental';

const LazyApplicabilitiesViewIncremental = lazy(() => import('./ApplicabilitiesViewIncremental'));

const ApplicabilitiesViewIncremental = (props: JSX.IntrinsicAttributes & ApplicabilitiesViewIncrementalProps & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyApplicabilitiesViewIncremental {...props} />
  </Suspense>
);

export default ApplicabilitiesViewIncremental;
