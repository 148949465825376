import {
	Dimension,
	FamilyDimensionAssociation,
} from "../../data/model/DataModels";


export const enum dimensionIncludeValues { ALL_INCLUDED = "ALL" };
export interface Option {
	label: string,
	subLabel: string,
	value: string,
	scopeType: string
}


