import React from "react";
import { sectionType } from "../../../data/model/SharedDataInterfaces";
import { useAppSelector } from "../../../app/hooks";
import OverviewForm from "./OverviewForm/OverviewForm";
import { AllUserData, ProductHeaders, Overview, HEADER_NAMES, Header, UsedInSolution, Dimension, Scope, PRODUCT_HEADERS_DEFINITION_DATA, PRODUCT_DEFAULT_HEADER_NAMES } from "../../../data/model/DataModels";
import styles from "./ProductOverview.module.scss";
import HeaderView from "../../../components/HeaderSection/HeaderView";
import UsedInSolutions from "./UsedInSolutions/UsedInSolutions";
import { EnvConfig } from "../../../EnvConfig";

export interface ProductOverviewProps {
	shownVersionHeader: Overview,
	activeVersionHeader: Overview,
	saveStateFn: (section: sectionType, value: any) => void,
	isFormAttibutesValidFn: Function,
	productName: string,
	productFamilyCode: string,
	productVersion: number,
	rbacResourceCode: string,
	rbacChildResourceCode: string,
	productCode: string,
	isAuthorisedToEdit: boolean,
	allUsers: AllUserData
	approversApproved: string[],
	approversRejected: { [key: string]: string },
	productStatus: string,
	triggerApproverStatus?: number,
	setTriggerApproverStatus?: Function,
	refetchProduct?: Function,
	isApprovalFlow: boolean,
	productHeaders: ProductHeaders,
	usedInSolutions: UsedInSolution[],
	isInsideSolutionProductUpgradePopup?: boolean
	productModconStatus?: boolean,
	applicabilityScope?: Scope[];
}

const ProductOverview: React.FC<ProductOverviewProps> = ({ shownVersionHeader,
	activeVersionHeader,
	productName,
	productVersion,
	productCode,
	productFamilyCode,
	rbacResourceCode,
	rbacChildResourceCode,
	saveStateFn,
	isFormAttibutesValidFn,
	isAuthorisedToEdit,
	allUsers,
	approversApproved,
	approversRejected,
	productStatus,
	triggerApproverStatus = 0,
	setTriggerApproverStatus,
	refetchProduct,
	isApprovalFlow,
	productHeaders,
	usedInSolutions,
	isInsideSolutionProductUpgradePopup,
	productModconStatus = false,
	applicabilityScope = []
}) => {
	const editMode: boolean = useAppSelector((state) => state.product.editMode);

	const attributeValidityCheck = (isValid: boolean) => {
		isFormAttibutesValidFn(isValid);
	}

	const saveProductHeaders = (options: Header[]) => {
		saveStateFn(sectionType.PRODUCT_HEADERS, options);
	}

	const showModConStatus = () => {
		const modconStatusDisplay = () => {
			if (productModconStatus) {
				return <span className={`${styles.modConStatus} ${styles.modConStatusActive}`}>Available</span>
			}
			return <span className={styles.modConStatus}>Not Available</span>
		}

		return (
			<div className={styles.field}>
				<div className={styles.fieldTitle}>
					<span className={styles.label}>
						<h3>Standard Terms & Conditions</h3>
					</span>
					{
						modconStatusDisplay()
					}
				</div>
			</div>
		)
	}

	return (
		<div className={styles.ProductOverview} data-testid="ProductOverview">
			<div>
				<OverviewForm rbacResourceCode={rbacResourceCode} rbacResourceChildCode={rbacChildResourceCode} productName={productName} productVersion={productVersion} productCode={productCode} productFamilyCode={productFamilyCode}
					productHeaders={productHeaders}
					formFields={shownVersionHeader} activeVersionFormFields={activeVersionHeader} saveState={saveStateFn} section={sectionType.HEADER} editMode={editMode} isFormValid={(isValid: boolean) => attributeValidityCheck(isValid)} data-testid="OverviewForm"
					isAuthorisedToEdit={isAuthorisedToEdit} allUsers={allUsers} approversApproved={approversApproved} approversRejected={approversRejected} productStatus={productStatus} triggerApproverStatus={triggerApproverStatus} setTriggerApproverStatus={setTriggerApproverStatus} refetchProduct={refetchProduct} isApprovalFlow={isApprovalFlow} isInsideSolutionProductUpgradePopup={isInsideSolutionProductUpgradePopup}
				/>
				<UsedInSolutions solutions={usedInSolutions} isInsideSolutionProductUpgradePopup={isInsideSolutionProductUpgradePopup}></UsedInSolutions>
				{showModConStatus()}
			</div>
			<div data-testid="HeaderView">
				<HeaderView productHeaders={productHeaders} editMode={editMode && isAuthorisedToEdit} saveHeaders={saveProductHeaders} headerList={HEADER_NAMES} familyCode={productFamilyCode}
							applicabilityScope={applicabilityScope} allHeadersDefinitions={PRODUCT_HEADERS_DEFINITION_DATA} defaultHeaders={Object.values(PRODUCT_DEFAULT_HEADER_NAMES)}></HeaderView>
			</div>
		</div>
	);
}

export default ProductOverview;
