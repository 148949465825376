import styles from "./SolutionsView.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CreateSolutionDialog from "../../components/CreateSolutionDialog/CreateSolutionDialog";
import {
	SolutionDefinition,
	serviceModelTypeDropDownList,
	ROLES,
	statusDropDownList,
	SolutionType
} from "../../data/model/DataModels";
import { useGetSolutionsQuery, useGetAllPopUpValuesQuery } from "../../data/api/CatalogueApi";
import { ToasterContext } from "../AppLayoutView/AppLayoutView";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import { BreadCrumbContext } from "../../App";
import PATHS from "../../Paths";
import { Card } from "primereact/card";
import { hasAnyRoleForAnySolution, hasGlobalOwnerRole, hasRole } from "../../authorisationService";
import { Sidebar } from "primereact/sidebar";
import SolutionFilter, { setSelectedFilters, solutionFilterQueryParams } from "./SolutionFilter";
import ErrorHandlerComponent from "../../components/ErrorHandler/ErrorHandlerComponent";
import { HeaderTypes } from "../../data/model/DataModels";
import TruncatedText from "../../utils/TruncatedText";
import { Dropdown } from "primereact/dropdown";
import './overrides.scss';

export const DRAFT_VIEW_PATH = "draftView";

export function SolutionsView() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const solutionStatusFilterValue: string | null = searchParams.get(solutionFilterQueryParams.SOLUTION_STATUS_FILTER_QUERY_KEY);

	const { data: solutions, error: errorSolutions, isLoading: isLoadingSolutions, isFetching: isFetchingSolutions } = useGetSolutionsQuery([solutionStatusFilterValue!], { skip: !solutionStatusFilterValue });
	const { data: allVerticalValues = [], isLoading: isLoadingAllVerticalValues, error: errorAllVerticalValues } = useGetAllPopUpValuesQuery(HeaderTypes.VERTICAL);
	const { data: allValuePropositionValues = [], isLoading: isLoadingAllValuePropositionValues, error: errorAllValuePropositionValues } = useGetAllPopUpValuesQuery(HeaderTypes.VALUE_PROPOSITION);
	const queryVerticalFilterValue = searchParams.get(solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY);
	const verticalNameFilters: string[] = queryVerticalFilterValue ? queryVerticalFilterValue.split(',') : [];
	const queryValuePropositionFilterValue = searchParams.get(solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY);
	const valuePropositionNameFilters: string[] = queryValuePropositionFilterValue ? queryValuePropositionFilterValue.split(',') : [];

	const queryProductFilterValue = searchParams.get(solutionFilterQueryParams.PRODUCT_FILTER_QUERY_KEY);
	const productNameFilters: string[] = queryProductFilterValue ? queryProductFilterValue.split(',') : [];

	const [searchText, setSearchText] = useState<string>("");
	const [dialogVisible, setDialogVisible] = useState(false);
	const toaster = useContext(ToasterContext);
	const breadcrumbs = useContext(BreadCrumbContext);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const queryServiceFilterValue = searchParams.get(solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	const serviceFilters: string[] = queryServiceFilterValue ? queryServiceFilterValue.split(',') : [];
	const isSolutionDirectoryOwner = hasRole(["solutions"], ROLES.OWNER);

	const solutionProductArr = solutions ? solutions.filter(sol => sol.productType === SolutionType.PACKAGED_SOLUTION).map(sol => sol.products).flat() : [];
	const solutionProducts = solutionProductArr.filter((element, index) => index === solutionProductArr.findIndex(ele => element.code === ele.code)).flat();
	if (solutionStatusFilterValue == null || !(hasGlobalOwnerRole() || hasAnyRoleForAnySolution())) {
		searchParams.set("status", statusDropDownList[0].code);
		setSearchParams(searchParams);
	}

	useEffect(() => {
		breadcrumbs.setData([{ label: "Solutions", url: PATHS.SOLUTIONS }]);
		if (solutionStatusFilterValue == null) {
			searchParams.set("status", statusDropDownList[0].code);
			setSearchParams(searchParams);
		}
	}, [])

	if (errorSolutions) {
		return (<ErrorHandlerComponent error={errorSolutions} />);
	}

	if (errorAllVerticalValues) {
		toaster.showToast('error', 'Failed to fetch Vertical header data');
	}
	if (errorAllValuePropositionValues) {
		toaster.showToast('error', 'Failed to fetch Value Proposition header data');
	}


	const filterSearchText = (solution: SolutionDefinition): boolean => {
		if (searchText.length > 0) {
			return solution.name.toLowerCase().includes(searchText.toLowerCase());
		}
		return true;
	}

	const goToSolution = (solution: SolutionDefinition) => {
		navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", solution.code).replace(":version", solution.version.toString()));
	}


	const removeServiceModelFromFilter = (serviceModelCode: string) => {
		setSelectedFilters(serviceFilters?.filter((value: string) => value != serviceModelCode), searchParams, setSearchParams, solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	}

	const filterServiceModel = (solution: SolutionDefinition): boolean => {
		const serviceModel = solution.headersInfo.serviceModel;
		if (serviceFilters.length == 0 || !serviceModel) {
			return true;
		}
		return serviceModel.some(item => serviceFilters.includes(item.code.toString()));
	}

	const filterVertical = (solution: SolutionDefinition): boolean => {
		const vertical = solution.headersInfo.vertical;
		if (verticalNameFilters.length == 0 || !vertical) {
			return true;
		}
		return vertical.some(item => verticalNameFilters.includes(item.code.toString()));
	}

	const filterValueProposition = (solution: SolutionDefinition): boolean => {
		const valueProposition = solution.headersInfo.valueProposition;
		if (valuePropositionNameFilters.length == 0 || !valueProposition) {
			return true;
		}
		return valueProposition.some(item => valuePropositionNameFilters.includes(item.code.toString()));
	}

	const filterProduct = (solution: SolutionDefinition): boolean => {
		return productNameFilters.length > 0 ?
			(productNameFilters.length == 1 ?
				solution.products.some(product => productNameFilters.includes(product.code.toString()))
				: productNameFilters.every(filter => solution.products.map(product => product.code).includes(filter)))
			: true;
	}

	const removeVerticalFromFilter = (verticalCode: string) => {
		setSelectedFilters(verticalNameFilters?.filter((value: string) => value != verticalCode), searchParams, setSearchParams, solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY);
	}

	const removeValuePropositionFromFilter = (valuePropositionCode: string) => {
		setSelectedFilters(valuePropositionNameFilters?.filter((value: string) => value != valuePropositionCode), searchParams, setSearchParams, solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY);
	}

	const removeProductFromFilter = (productCode: string) => {
		setSelectedFilters(productNameFilters?.filter((value: string) => value != productCode), searchParams, setSearchParams, solutionFilterQueryParams.PRODUCT_FILTER_QUERY_KEY);
	}


	const showFilterValues = () => {
		return (
			<>
				{
					serviceFilters?.length > 0 &&
					serviceFilters.map((serviceModelCode: string) =>
						<span key={serviceModelCode} >
							<span data-testid="serviceModelFilter">
								Service Model: {serviceModelTypeDropDownList.find(service => service?.code == serviceModelCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeServiceModelFromFilter(serviceModelCode)}></span>
						</span>
					)
				}
			</>

		)
	}


	const showVerticalFilterValues = () => {
		return (
			<>
				{
					verticalNameFilters?.length > 0 &&
					verticalNameFilters.map((verticalCode: string) =>
						<span key={verticalCode} >
							<span>
								Vertical: {allVerticalValues.find(vertical => vertical?.code === verticalCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeVerticalFromFilter(verticalCode)}></span>
						</span>
					)
				}
			</>

		)
	}

	const showValuePropositionValues = () => {
		return (
			<>
				{
					valuePropositionNameFilters?.length > 0 &&
					valuePropositionNameFilters.map((verticalCode: string) =>
						<span key={verticalCode} >
							<span>
								Value Proposition: {allValuePropositionValues.find(vertical => vertical?.code === verticalCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeValuePropositionFromFilter(verticalCode)}></span>
						</span>
					)
				}
			</>

		)
	}

	const showProductFilterValues = () => {
		return (
			<>
				{
					productNameFilters?.length > 0 &&
					productNameFilters.map((productCode: string) =>
						<span key={productCode} >
							<span>
								Product: {solutionProducts.find(product => product.code === productCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeProductFromFilter(productCode)}></span>
						</span>
					)
				}
			</>

		)
	}

	const filterBySolutionStatus = (status: string) => {
		searchParams.set("status", status);
		setSearchParams(searchParams);
	}

	function getServiceModels(solution: SolutionDefinition): React.ReactNode {
		const serviceModelNameArr = solution?.headersInfo?.serviceModel?.map(serviceModel => serviceModel.name);
		const serviceModelsStr = serviceModelNameArr?.join(", ");
		return serviceModelsStr;
	}

	const getSolutions = () => {
		return solutions ? solutions.filter(solution =>
			filterSearchText(solution)
			&& filterServiceModel(solution)
			&& filterVertical(solution)
			&& filterValueProposition(solution)
			&& filterProduct(solution))
			: [];
	}

	const getFilterLabelWithAppliedFilterCount = () => {
		const noOfAppliedFilters = verticalNameFilters?.length + valuePropositionNameFilters.length + serviceFilters.length + productNameFilters.length;
		return `Filter${noOfAppliedFilters > 0 ? "(" + noOfAppliedFilters + ")" : ""}`;
	}

	return (
		<div className={styles.container}>
			{
				(isLoadingSolutions || isFetchingSolutions || isLoadingAllVerticalValues || isLoadingAllValuePropositionValues) && <SpinnerComponent />
			}
			<div className={styles.header} data-testid='SolutionsView'>
				<h1>Solutions</h1>
				<div className={styles.actionButtons}>
					{
						isSolutionDirectoryOwner &&
						<Button label="Add new solution"
							icon="pi pi-plus"
							className="p-button-outlined p-button-secondary"
							onClick={() => setDialogVisible(true)} data-testid="addSolutionButton" />
					}
				</div>
			</div>
			<div className={`mainDisplayCard ${styles.solutionsGrid}`}>
				<div >
					<div className={styles.solutionActions}>
						<div>
							<span className="p-input-icon-left">
								<i className="pi pi-search" />
								<InputText className={styles.searchInput} placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
							</span>
							<span className={styles.solutionsLength}>
								{getSolutions().length} Solutions
							</span>
						</div>
						<span className={styles.alignRight}>
							<span className={styles.haveGap}>
								<span>Show</span>
								<Dropdown className={styles.solutionStatusDropDown} disabled={!(hasGlobalOwnerRole() || hasAnyRoleForAnySolution())} value={solutionStatusFilterValue} onChange={(e) => { filterBySolutionStatus(e.value) }} options={statusDropDownList.filter(status => status.code != "outdated")} optionLabel="name" optionValue="code" />
							</span>
							<Button icon={"pi pi-sliders-h"} className={`p-button-outlined p-button-secondary`} onClick={() => setIsFilterOpen(true)} data-testid="headerSliderButton" label={getFilterLabelWithAppliedFilterCount()} />
						</span>
					</div>
					<div className={styles.filterValues}>
						{showFilterValues()}
						{
							serviceFilters?.length > 0 &&
							<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)}>Clear all</div>
						}
					</div>
					<div className={styles.filterValues}>
						{showProductFilterValues()}
						{
							productNameFilters?.length > 0 &&
							<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, solutionFilterQueryParams.PRODUCT_FILTER_QUERY_KEY)}>Clear all</div>
						}
					</div>
					<div className={styles.filterValues}>
						{showVerticalFilterValues()}
						{
							verticalNameFilters?.length > 0 &&
							<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY)}>Clear all</div>
						}
					</div>
					<div className={styles.filterValues}>
						{showValuePropositionValues()}
						{
							valuePropositionNameFilters?.length > 0 &&
							<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY)}>Clear all</div>
						}
					</div>
				</div>
				<div className={"p-d-flex p-flex-row p-flex-wrap "}>
					{
						getSolutions()
							.map(solution =>
								<div key={`${solution.code}-${solution.version}-${solution.status}`} data-testid="solutionList">
									<Card className={styles.card}>
										<div className={styles.solutionContent} onClick={() => goToSolution(solution)}>
											<div className={`${"p-d-flex p-flex-row p-jc-between "} ${styles.solutionFamilyAndStatus}`}>
												<span className={styles.solutionFamily}>
													{
														<TruncatedText text={getServiceModels(solution)} maxLength={50} />}
												</span>
											</div>
											<div className={styles.cardTitles}>
												<div>
													<p className={styles.solutionTitle}>{solution.name}</p>
												</div>
											</div>
											<div className={styles.cardContent}>
												<span
													className={styles.teaser}>{solution.salesInfo.tagline}</span>
											</div>
											<div className={styles.cardFooter}>
												<span className={styles.productVersion}>{solution.status !== "active" && "v" + solution.version}</span>
											</div>
										</div>
									</Card>
								</div>
							)
					}
				</div>
			</div>
			{
				dialogVisible && <CreateSolutionDialog onHide={() => setDialogVisible(false)} />
			}
			<Sidebar visible={isFilterOpen} showCloseIcon={false} position="right" onHide={() => setIsFilterOpen(false)}>
				<SolutionFilter onClose={() => setIsFilterOpen(false)} setSelectedFilters={(selectedFilters: string[], filterType: string) => setSelectedFilters(selectedFilters, searchParams, setSearchParams, filterType)}
					serviceNameFilters={serviceFilters} verticalNameFilters={verticalNameFilters} valuePropositionNameFilters={valuePropositionNameFilters} productNameFilters={productNameFilters} solutionProducts={solutionProducts} />
			</Sidebar>
		</div>
	)

}


