import React, { FC, useState } from 'react';
import styles from './InputRichTextWithSave.module.scss';
import { Button } from "primereact/button";
import RichTextCustomHeaderComponent from "../RichTextCustomHeader/RichTextCustomHeaderComponent";
import { Editor } from "primereact/editor";
import { FieldIdentifiers, formValidation } from '../../utils/validationUtil';

export interface RichTextDescription {
    htmlValue: string,
    textValue: string
}

export interface InputRichTextWithSaveProps {
    richTextDesc: RichTextDescription,
    saveText: Function,
    cancel: Function,
    fieldName: FieldIdentifiers,
    isRequired: boolean,
    validationMessage?: string,
    dataTestIds?: string[]
}

const InputRichTextWithSave: FC<InputRichTextWithSaveProps> = ({ richTextDesc, saveText, cancel, fieldName, isRequired, validationMessage, dataTestIds = ['inputRichTextWithSave', 'inputDesc', 'richTextSaveBtn'] }) => {
    const [description, setDescription] = useState(richTextDesc);
    return (
        <div className={styles.InputRichTextWithSave} data-testid={dataTestIds[0]}>
            <div className={styles.InputText}>
                <Editor style={{ height: '7rem', fontSize: "1rem" }} placeholder="Enter description"
                    name="description"
                    headerTemplate={RichTextCustomHeaderComponent()}
                    value={description.htmlValue?.toString()} onTextChange={(e) => setDescription({
                        textValue: e.textValue,
                        htmlValue: e.htmlValue || ""
                    })} data-testid={dataTestIds[1]} />
            </div>
            <div className={styles.InputActionButtons}>
                <div>
                    {validationMessage !== "" ?
                        formValidation(description.htmlValue, fieldName, isRequired, validationMessage) :
                        formValidation(description.htmlValue, fieldName, isRequired)
                    }
                </div>
                <div>
                    <Button icon="pi pi-check"
                        className={`p-button-outlined p-button-secondary`}
                        onClick={() => saveText(description)}
                        disabled={formValidation(description.htmlValue, fieldName, isRequired) !== ""} data-testid={dataTestIds[2]} />
                    <Button icon="pi pi-times"
                        className={`p-button-outlined p-button-secondary`}
                        onClick={e => cancel(e)} />
                </div>
            </div>
        </div>
    );
}

export default InputRichTextWithSave;
