import { startCase } from "lodash";
import { Button } from "primereact/button";
import {
  Dropdown,
  DropdownChangeEvent,
  DropdownProps,
} from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { FeatureFilterState } from "../../../../components/FilterPanel/featureFilterSlice";
import FilterPanel, {
  filterType,
} from "../../../../components/FilterPanel/FilterPanel";
import {
  ComponentAssociation,
  FeatureAssociation,
  Status,
  Product,
  SpecificationParameterAssociation,
} from "../../../../data/model/DataModels";
import { addFeatureInProduct, addSpecificationParameterInProduct } from "../../../../data/slices/productSlice";
import { selectedComponentDefault, productTabs } from "../../ProductView";
import DisplayFeatureCards from "../DisplayFeatureCards/DisplayFeatureCards";
import styles from "./ProductFeaturesViewHeader.module.scss";
import { solutionTabs } from "../../../SolutionDetails/SolutionDetails";

interface ProductFeaturesViewHeaderProps {
  product: Product;
  isAuthorisedToEdit: boolean;
  setFeatureTypeFilterValue: Function;
  isInsideSolutionProductUpgradePopup?: boolean;
}

const ProductFeaturesFilterOverview: React.FC<{
  filtersApplied: FeatureFilterState;
  components: ComponentAssociation[];
}> = ({ filtersApplied, components }) => {
  return (
    <div className={styles.filtersOverview}>
      <div>
        Active filters:
        {filtersApplied.checkedTags.length > 0 && (
          <span>
            {filtersApplied.checkedTags.map((tag) => (
              <span key={"tag-" + tag} className={styles.pill}>
                <Tag
                  rounded
                  className="mr-2"
                  severity="info"
                  value={"Tag: " + startCase(tag)}
                ></Tag>
              </span>
            ))}
          </span>
        )}
        {filtersApplied.checkedControls.length > 0 && (
          <span>
            {filtersApplied.checkedControls.map((control) => (
              <span key={"control-" + control} className={styles.pill}>
                <Tag
                  rounded
                  className="mr-2"
                  severity="info"
                  value={"Controls: " + startCase(control)}
                ></Tag>
              </span>
            ))}
          </span>
        )}
        {filtersApplied.checkedComponents.length > 0 && (
          <span>
            {components
              .filter(
                (component) =>
                  filtersApplied.checkedComponents.indexOf(
                    component.componentRef
                  ) !== -1
              )
              .map((component) => (
                <span
                  key={"component-" + component.componentRef}
                  className={styles.pill}
                >
                  <Tag
                    rounded
                    className="mr-2"
                    severity="info"
                    value={"Component: " + component.definition?.name}
                  ></Tag>
                </span>
              ))}
          </span>
        )}
      </div>
    </div>
  );
};

const emptyFilters = (filtersApplied: FeatureFilterState) => {
  return !(
    filtersApplied.checkedComponents.length > 0 ||
    filtersApplied.checkedTags.length > 0 ||
    filtersApplied.checkedControls.length > 0
  );
};

const ProductFeaturesViewHeader: React.FC<ProductFeaturesViewHeaderProps> = ({
  product,
  isAuthorisedToEdit = false,
  setFeatureTypeFilterValue,
  isInsideSolutionProductUpgradePopup = false
}) => {
  const filtersApplied: FeatureFilterState = useAppSelector(
    (state) => state.featureFilter
  );
  const component: ComponentAssociation = useAppSelector(
    (state) => state.product.selectedComponent
  );
  const associatedFeaturesInEdit: FeatureAssociation[] = useAppSelector(
    (state) => state.product.editableProduct?.features
  );
  const editMode: boolean = useAppSelector((state) => state.product.editMode);

  const associatedFeatures = editMode
    ? associatedFeaturesInEdit
    : product.features;

  const dispatch = useAppDispatch();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [canAddFeature, setCanAddFeature] = useState(true);

  const featureTypeFilterLabelDefault = {
    name: "All features",
    code: "all",
  };

  const [featureTypeFilterLabel, setFeatureTypeFilterLabel] = useState(
    featureTypeFilterLabelDefault
  );

  const featureTypeFilterLabels = [
    { name: "All features", code: "all" },
    { name: "Core services", code: "included" },
    { name: "Optional services", code: "not_included" },
    { name: "Non services", code: "none" },
  ];

  const components = [selectedComponentDefault, ...product.components];

  const navigateToProductOrFeatureTab = () => {
    isInsideSolutionProductUpgradePopup ? navigate(solutionTabs[2].hash) : navigate(productTabs[3].hash)
  }

  const selectComponentDropdown = (e: DropdownChangeEvent) => {
    const component = components.find(component => component.componentRef === e.value.componentRef);
    const params = isInsideSolutionProductUpgradePopup ? new URLSearchParams(hash.replace(solutionTabs[2].hash, "")) : new URLSearchParams(hash.replace(productTabs[3].hash, ""));
    filtersApplied.checkedComponents.forEach(component => params.delete(component));
    if (e.value.componentRef !== selectedComponentDefault.componentRef) {
      params.append(component?.definition?.code!, filterType.Component);
    }
    isInsideSolutionProductUpgradePopup ? navigate(solutionTabs[2].hash + "?" + params.toString()) : navigate(productTabs[3].hash + "?" + params.toString());
  };
  // below code is to preserve mapping of the dropdown. As dropdown selection is done on basis of reference mapping
  const doesComponentExist = components.find(
    (element) => component?.componentRef === element.componentRef
  );
  const selectedComponent: ComponentAssociation = doesComponentExist
    ? doesComponentExist
    : selectedComponentDefault;

  useEffect(() => {
    if (selectedComponent.componentRef === "all-features") {
      setCanAddFeature(false);
    } else {
      const featuresAssociated =
        component?.componentRef !== selectedComponentDefault.componentRef
          ? associatedFeatures?.filter(
            ({ componentRef }) => componentRef === component?.definition.code
          )
          : associatedFeatures;
      setCanAddFeature(
        component?.definition.numberOfFeatures >
        featuresAssociated?.filter(
          (feature) =>
            feature.definition.status == Status.ACTIVE ||
            feature.definition.status == Status.OUTDATED
        ).length
      );
    }
  }, [selectedComponent]);

  const componentOptionTemplate = (option: ComponentAssociation) => {
    return (
      <div className={styles.componentItem}>
        <div
          className={
            option.definition?.name === "All Features" ? styles.allfeatures : ""
          }
        >
          {option.definition?.name}
        </div>
        <div className={styles.dropDownFooter}>
          {option.definition?.name === "All Features"
            ? product?.features.length
            : product?.features.filter(
              (feature) => feature.componentRef === option.componentRef
            ).length}{" "}
          Features
        </div>
      </div>
    );
  };

  const addFeatures = (features: FeatureAssociation[]) => {
    let specificationParameterAssociations = features
      .map((feature) => feature.definition.specificationParameters)
      .reduce((accumulator, current) => [...accumulator, ...current])
      .map((specParam) => {
        return {
          featureRef: specParam.featureRef,
          componentRef: specParam.componentRef,
          specificationParamRef: specParam.code,
          configuration: specParam.configuration,
          productId: product.id,
          definition: specParam,
          configurableAtContracting: specParam.configurableAtContracting,
          configurableAtBooking: specParam.configurableAtBooking,
          featureVersion: specParam.featureVersion,
          serviceDefault: specParam.serviceDefault
        } as SpecificationParameterAssociation;
      });
    let featureAssociations = features.map(
      (feature) =>
      ({
        ...feature,
        featureVersion: feature.definition.version,
        service: !feature.service
          ? feature.definition.service
          : feature.service,
        serviceDefault: !feature.serviceDefault
          ? feature.definition.serviceDefault
          : feature.serviceDefault,
      } as FeatureAssociation)
    );
    dispatch(addFeatureInProduct(featureAssociations));
    dispatch(
      addSpecificationParameterInProduct(specificationParameterAssociations)
    );

    setShowFeatureModal(false);
  };

  const selectedComponentTemplate = (
    option: ComponentAssociation,
    props: DropdownProps
  ) => {
    if (option) {
      return (
        <span className={styles.componentName}>{option.definition?.name}</span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const getAssociatedFeatureCodes = () => {
    const assocFeatures = product.features.filter(
      (feature) => feature.componentRef == component.componentRef
    );
    return assocFeatures.map((feat) => feat.featureRef);
  };

  const getFeaturesCountOfSelectedComponent = () => {
    if (component?.componentRef) {
      if (component?.componentRef === selectedComponentDefault?.componentRef)
        return product?.features?.length === 1
          ? "1 Feature"
          : product?.features?.length + " Features";
      else {
        const featuresByComponent = product?.features?.filter(
          (f) => f.componentRef === component.componentRef
        );
        return featuresByComponent?.length === 1
          ? "1 Feature"
          : featuresByComponent.length + " Features";
      }
    }
    return "0 Features";
  };

  function getFilterPlaceholder(featureTypeFilterLabel: string): string {
    return "Show: " + featureTypeFilterLabel;
  }

  const getFeaturesCountOfSelectedComponentAndFeatureTypeFilter = (
    code: string
  ) => {
    if (component?.componentRef) {
      if (component?.componentRef === selectedComponentDefault?.componentRef) {
        const filteredFeatures = product?.features?.filter(
          (feature) =>
            code == "all" ||
            feature.serviceDefault === code
        );
        return filteredFeatures.length === 1
          ? "1 Feature"
          : filteredFeatures.length + " Features";
      } else {
        const featuresByComponent = product?.features?.filter(
          (f) => f.componentRef === component.componentRef
        );
        const filteredFeaturesByComponent = featuresByComponent.filter(
          (feature) =>
            code == "all" ||
            feature.serviceDefault === code
        );
        return filteredFeaturesByComponent?.length === 1
          ? "1 Feature"
          : filteredFeaturesByComponent.length + " Features";
      }
    }
    return "0 Features";
  };

  return (
    <div className={styles.featureViewHeaderComponent}>
      <div className={styles.headerContainer}>
        <div>
          <span className={styles.spanDropdown}>
            <Dropdown
              data-testid="componentDropDown"
              value={selectedComponent}
              options={components}
              className={styles.componentDropdown}
              onChange={selectComponentDropdown}
              optionLabel="definition.name"
              placeholder="Select a Component"
              valueTemplate={selectedComponentTemplate}
              itemTemplate={componentOptionTemplate}
            />
          </span>
          <div className={styles.spanDropdown} data-testid="featuresCount">
            {getFeaturesCountOfSelectedComponentAndFeatureTypeFilter(
              featureTypeFilterLabel.code
            )}
          </div>
          <span className={styles.titleButtons}>
            <span>
              {editMode && isAuthorisedToEdit && (
                <span className={"tooltip"}>
                  <Button
                    data-testid="addFeatureButton"
                    label="Add Feature"
                    icon="pi pi-plus"
                    onClick={() => setShowFeatureModal(true)}
                    disabled={!canAddFeature}
                    className="p-button-outlined p-button-secondary"
                  />
                </span>
              )}
              <span>
                <Button
                  data-testid="filterButton"
                  label="Filters"
                  icon="pi pi-filter"
                  className="p-button-outlined p-button-secondary"
                  onClick={() => setFilterPanelOpen(!filterPanelOpen)}
                />
              </span>
              {!emptyFilters(filtersApplied) && (
                <span>
                  <Button
                    label="Clear Filters"
                    icon="pi pi-trash"
                    className="p-button-outlined p-button-secondary"
                    onClick={() => navigateToProductOrFeatureTab()}
                  />
                </span>
              )}
            </span>
          </span>
        </div>
        {filterPanelOpen && <FilterPanel product={product} isInsideSolutionProductUpgradePopup={isInsideSolutionProductUpgradePopup} ></FilterPanel>}
        <div className={styles.featureTypeFilters}>
          <div className={styles.displayFlex}>
            {!emptyFilters(filtersApplied) && (
              <ProductFeaturesFilterOverview
                filtersApplied={filtersApplied}
                components={product.components}
              />
            )}
          </div>
          <div className={styles.serviceModelFilter}>
            <Dropdown
              value={featureTypeFilterLabel.code}
              onChange={(e) => {
                setFeatureTypeFilterLabel(e.value);
                setFeatureTypeFilterValue(e.value.code);
              }}
              options={featureTypeFilterLabels}
              optionLabel="name"
              placeholder={getFilterPlaceholder(featureTypeFilterLabel.name)}
              className="w-full md:w-14rem"
            />
          </div>
        </div>
        {showFeatureModal && (
          <DisplayFeatureCards
            header={selectedComponent?.definition?.name}
            onHide={() => setShowFeatureModal(false)}
            activeFeatures={getAssociatedFeatureCodes()}
            componentRef={selectedComponent?.componentRef}
            acceptAction={(features: FeatureAssociation[]) =>
              addFeatures(features)
            }
          />
        )}
        <Tooltip
          target=".tooltip"
          className="general-tooltip"
          autoHide={false}
          position="top"
          disabled={canAddFeature}
        >
          <div className="p-d-flex p-flex-column">
            {selectedComponent?.componentRef ===
              selectedComponentDefault.componentRef
              ? "Select a component to add feature"
              : selectedComponent.definition.numberOfFeatures === 0
                ? "No feature is available to associate with the component"
                : "All features are already associated with the component"}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ProductFeaturesViewHeader;
