import { Dialog } from "primereact/dialog";
import { FC, ReactNode } from "react";

export interface ShowListElementsInPopUpProps {
	errorMsgArr: string[],
	onClose: Function,
	footer: ReactNode,
	header: string
}

const ShowListElementsInPopUp: FC<ShowListElementsInPopUpProps> = ({ errorMsgArr, onClose, footer, header }) => {

	return (

		<div>
			<Dialog style={{ width: "45vw", height: "40vh" }} footer={footer} header={header} visible={true} onHide={() => onClose()}>
				<p>In Order to Send this add-on for approval, you need to enter the following information: </p>
				<ul>
					{errorMsgArr.map((error, index) => (
						<li key={index} style={{marginBottom : '0.5rem'}}>{error}</li>
					))}
				</ul>
			</Dialog>
		</div>
	)
}

export default ShowListElementsInPopUp;