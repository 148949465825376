import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasGlobalOwnerRole, hasRole } from "../../authorisationService";
import { useGetFeatureByRefQuery } from "../../data/api/CatalogueApi";
import {
  FeatureDefinition,
  FAMILIES_CODES,
  ROLES,
  SpecificationParameterDefinition,
} from "../../data/model/DataModels";
import { ToasterContext } from "../../features/AppLayoutView/AppLayoutView";
import PATHS from "../../Paths";
import DisplayNewFeatureVersion from "../DisplayNewFeatureVersion/DisplayNewFeatureVersion";
import { sanitizeHTML } from "../RichTextCustomHeader/RichTextCustomHeaderComponent";
import styles from "./FeatureCard2.module.scss";
import SpecificationList from "./SpecificationList/SpecificationList";
import FeatureAsServiceDefaultOptions from "../FeatureAsService/FeatureAsServiceDefaultOptions/FeatureServiceDefaultOptions";
import { cloneDeep } from "lodash";
import { ReactComponent as PricingIcon } from '../../assets/icons/maersk/dollar-circle.svg';
import FeatureVersionCompare from "../../features/FeatureVersionCompare/FeatureVersionCompare";


export const ServiceDefaultLabel = {
  included: "Core Service",
  not_included: "Optional Service",
  none: "Non Service",
} as any;

export interface FeatureCard2Props {
  feature: FeatureDefinition;
  specificationParameterAssociations: SpecificationParameterDefinition[];
  deleteFeature?: Function;
  deleteSpecification?: Function;
  addSpecificationParameter?: Function;
  saveSpecificationParameterConfigs?: Function;
  isConfigurable?: boolean;
  showExcludedOptionsInViewMode?: boolean;
  usedIn?: "product" | "definition" | "featureupgradedialog";
  isNewVersionAvailable?: Function;
  upgradeToNewFeature?: Function;
  isAuthorisedToEdit: boolean;
  saveFeatureConfigs?: Function;
  sourceComponent?: string;
  chargeTypeCode?: string;
}

const FeatureCard2: FC<FeatureCard2Props> = ({
  feature,
  specificationParameterAssociations,
  deleteFeature,
  deleteSpecification,
  addSpecificationParameter,
  saveSpecificationParameterConfigs,
  isConfigurable = false,
  showExcludedOptionsInViewMode = false,
  usedIn = "definition",
  isNewVersionAvailable,
  upgradeToNewFeature,
  isAuthorisedToEdit = false,
  saveFeatureConfigs,
  sourceComponent,
  chargeTypeCode
}) => {
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState(false);
  const [showFeatureCompare, setShowFeatureCompare] = useState(false);
  const isNewVersionPresent: boolean = isNewVersionAvailable
    ? isNewVersionAvailable(feature)
    : false;
  const { data: newActiveFeature, isLoading: isLoadingNewActiveFeature } =
    useGetFeatureByRefQuery(feature.code!, { skip: !isNewVersionPresent });

  const isSolutionOwner = hasRole(["solutions"], ROLES.OWNER);

  const disableDeleteFeatureButton =
    feature?.associatedProducts?.length > 0 && usedIn == "definition";

  const toaster = useContext(ToasterContext);

  const areNewCoreSPToBeImportedAsOptionalMsgVisible = () => {
    return newCoreSPToBeImportedAsOptional() !== undefined;
  };

  const newCoreSPToBeImportedAsOptional = () => {
    return newActiveFeature?.specificationParameters.find((newFeatureSP) => {
      const existingFeatureSP = feature.specificationParameters.find(existingSP => {
        return (existingSP.code === newFeatureSP.code)
          && (existingSP.featureRef === newFeatureSP.featureRef)
      }
      );
      return (existingFeatureSP === undefined)
        && (newFeatureSP.serviceDefault === "included")
        && (feature.serviceDefault == "not_included");
    });
  }

  const upgradeFeatureVersion = () => {
    setShowPreview(false);
    upgradeToNewFeature && upgradeToNewFeature(feature, newActiveFeature);
    toaster.showToast(
      "success",
      "Feature upgraded",
      "Product has been successfully updated with the new feature version"
    );
  };

  const upgradeFeatureVersion2 = (newFeature: FeatureDefinition) => {
    setShowFeatureCompare(false);
    upgradeToNewFeature && upgradeToNewFeature(feature, newFeature);
    toaster.showToast(
      "success",
      "Feature upgraded",
      "Product has been successfully updated with the new feature version"
    );
  };

  const previewFooter = (
    <div>
      <Button
        label="Close"
        className={styles.previewBtn}
        onClick={() => setShowPreview(false)}
      />
    </div>
  );

  const upgradeFooter = (
    <div>
      <Button
        label="Cancel"
        className={styles.previewBtn}
        onClick={() => setShowPreview(false)}
      />
      <Button
        label="Upgrade"
        className={styles.upgradeBtn}
        onClick={() => upgradeFeatureVersion()}
      />
    </div>
  );

  const saveFeature = (serviceDefault: "included" | "not_included" | "none", isChangedFromCoreToOptional: boolean) => {
    saveFeatureConfigs && saveFeatureConfigs({...feature, serviceDefault: serviceDefault}, isChangedFromCoreToOptional);
  };

  return (
    <div className={styles.FeatureCard2} data-testid="FeatureCard2">
      <div className={styles.header}>
        <div className={styles.headerTitleAndVersion}>
          <div className={styles.featureNameAndVersion}>
            <h2 data-testid="FeatureName">{feature.name}</h2>
            <div className={styles.featureVersion}>
              v{feature.version} - {feature.status}
              {(hasRole(Object.values(FAMILIES_CODES), ROLES.OWNER)
                || hasGlobalOwnerRole()
                || isSolutionOwner
              ) &&
                usedIn !== "featureupgradedialog" && (
                  <i
                    className="pi pi-arrow-up-right"
                    onClick={() =>
                      navigate(
                        PATHS.FEATURES_BY_CODE_VERSION.replace(
                          ":featureCode",
                          feature.code
                        ).replace(":version", feature.version!.toString())
                      )
                    }
                    data-testid="featureCodeClick"
                  />
                )}
            </div>
            {isNewVersionAvailable && isNewVersionAvailable(feature) && (
              <>
                <div className={styles.featureVersionAvailable}>
                  New version available
                </div>
                {/* <Button
                  className={styles.previewBtn}
                  onClick={() => setShowPreview(true)}
                >
                  {isAuthorisedToEdit && isConfigurable ? "Preview to upgrade" : "Preview"}
                </Button> */}
                <div className={styles.versionCompare} onClick={() => setShowFeatureCompare(true)}>Do you know what has changed?</div>
              </>
            )}
          </div>
        </div>
        <div className={styles.dollarAndDeleteButton}>
          {chargeTypeCode && (<div className={styles.dollarLabelBox}><PricingIcon /></div>)}
          {isAuthorisedToEdit && isConfigurable && <div className={styles.actionButton}>
            <Button
              icon="pi pi-trash"
              className={`p-button-outlined p-button-secondary`}
              disabled={disableDeleteFeatureButton}
              tooltip={"Feature is associated with one or more products"}
              tooltipOptions={{
                showOnDisabled: true,
                disabled: !disableDeleteFeatureButton,
                showEvent: "click",
              }}
              onClick={() => deleteFeature && deleteFeature(feature)}
              data-testid="DeleteFeatureBtn"
            />
          </div>}
        </div>
      </div>
      <div className={styles.leftAndRightParts}>
        <div className={styles.featureCardLeftpart}>
          <div className={styles.descriptionAndUsedIn}>
            <div className={styles.featureDescription}>
              <div
                data-testid="FeatureDesc"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(feature.descriptionRichText?.toString()),
                }}
                className={styles.displayDescription}
              />
            </div>
          </div>
        </div>

        <div
          className={styles.featureCardRightpart}
          data-testid="featureAsServiceDefaultOptions"
        >
          {isAuthorisedToEdit &&
            (((usedIn === "definition" && feature.status === "draft") || usedIn === "product") &&
              isConfigurable) ? (
            <div className={styles.serviceDefaultAndDollar}>
              <div className={styles.featureCardRightpart}>
                {feature.service == true ? (
                  <FeatureAsServiceDefaultOptions
                    serviceDefaultFromParent={feature.serviceDefault}
                    saveServiceDefault={saveFeature}
                  ></FeatureAsServiceDefaultOptions>
                ) : (
                  <div className={styles.featureTypeValueLabel}>
                    {ServiceDefaultLabel[feature.serviceDefault]}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              {
                ServiceDefaultLabel[feature.serviceDefault] && (
                  <div className={styles.serviceDefaultAndDollar}>
                    <div className={`${styles.labelBox} 
                      ${feature.serviceDefault === "included" ? styles.labelBoxCore : feature.serviceDefault === "not_included" ? styles.labelBoxOptional : styles.labelBoxNonService}`}>
                      <div className={styles.label}>
                        {ServiceDefaultLabel[feature.serviceDefault]}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      <SpecificationList
        specificationParameters={specificationParameterAssociations || []}
        saveSpecificationParameters={saveSpecificationParameterConfigs}
        feature={feature}
        deleteSpecificationParam={deleteSpecification}
        addSpecificationParameter={addSpecificationParameter}
        isConfigurable={isConfigurable}
        showExcludedOptionsInViewMode={showExcludedOptionsInViewMode}
        usedIn={usedIn === "featureupgradedialog" ? "product" : usedIn}
        isAuthorisedToEdit={isAuthorisedToEdit}
        sourceComponent={sourceComponent}
      />
      {
        showFeatureCompare &&
        <FeatureVersionCompare
         onHide={() => setShowFeatureCompare(false)} 
         oldFeatureDefinition={feature}
         oldSpecificationParameterAssociations={specificationParameterAssociations}
         upgradeFeature={(newFeature:FeatureDefinition)=>upgradeFeatureVersion2(newFeature)}
         canUpgrade={isConfigurable}
         />
      }
      {
        isNewVersionAvailable &&
        (isConfigurable ? (
          <Dialog
            header="Upgrade feature ?"
            footer={upgradeFooter}
            visible={showPreview}
            onHide={() => setShowPreview(false)}
            style={{ width: "56vw" }}
          >
            {(feature.service === newActiveFeature?.service) && (feature.serviceDefault !== newActiveFeature?.serviceDefault) && (<div className={styles.optionalityChangeInfoMsg}>
              <i className="pi pi-info-circle" style={{ color: '#0b8ed2' }}></i>
              <label>
                The optionality of the feature changed in the definition. Please, be aware that it will not be reflected if upgraded.
              </label>
            </div>)}
            {
              areNewCoreSPToBeImportedAsOptionalMsgVisible() && (<div className={styles.optionalityChangeInfoMsg}>
                <i className="pi pi-info-circle" style={{ color: '#0b8ed2' }}></i>
                <label>
                  There are new core specification parameters and they will be added as optional.
                </label>
              </div>)
            }
            {newActiveFeature && <DisplayNewFeatureVersion
              subHeader="You're about to upgrade this product with the latest version of the following feature:"
              feature={newActiveFeature}
              specificationParameters={newActiveFeature.specificationParameters}
              isAuthorisedToEdit={isAuthorisedToEdit}
            />}

          </Dialog>
        ) : (
          <Dialog
            header="Preview new feature"
            footer={previewFooter}
            visible={showPreview}
            onHide={() => setShowPreview(false)}
            style={{ width: "56vw" }}
          >
            {newActiveFeature && <DisplayNewFeatureVersion
              subHeader="Go to your draft product to upgrade with this new version"
              feature={newActiveFeature}
              specificationParameters={newActiveFeature.specificationParameters}
              isAuthorisedToEdit={isAuthorisedToEdit}
            />}
          </Dialog>
        ))
      }
    </div >
  );
};
export default FeatureCard2;
