import React, { lazy, Suspense } from 'react';
import { ProductCompatibilitiesProps } from './ProductCompatibilities';

const LazyProductCompatibilities = lazy(() => import('./ProductCompatibilities'));

const ProductCompatibilities = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; } & ProductCompatibilitiesProps) => (
  <Suspense fallback={null}>
    <LazyProductCompatibilities {...props} />
  </Suspense>
);

export default ProductCompatibilities;
