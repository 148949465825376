enum PATHS {
    DEFAULTROUTE = "/",
    HOME = "home",
    ACTIVITIES = "/activities",
    HELP = "/help",
    RELEASES = "/releases",
    USERS = "/users",

    //Product paths
    PRODUCTS = "/products",
    PRODUCT_BY_CODE = "/products/:code",
    PRODUCT_VERSION = "/products/:code/version/:version",
    PRODUCT_BY_CODE_VERSION_ALL = "/products/:code/version/all",

    //Component paths
    COMPONENTS = "/components",
    COMPONENTS_BY_CODE = ":componentCode",
    EDIT_COMPONENT = "editComponent",
    EDIT_COMPONENT_ADD_FEATURE = "addFeature",

    //Feature paths
    FEATURES = "/features",
    FEATURES_BY_CODE = "/features/:featureCode",
    FEATURES_BY_CODE_VERSION = "/features/:featureCode/:version",
    CREATE_FEATURE = "createFeature",
    CREATE_SPEC_PARAM = "createSpecificationParameter/:componentCode",
    EDIT_SPEC_PARAM = "editSpecificationParameter/:componentCode",
    FEATURES_ALL_VERSIONS = "/features/:featureCode/all",

    //Solution paths
    SOLUTIONS = "/solutions",
    SOLUTION_BY_CODE_VERSION = "/solutions/:code/version/:version",
    SOLUTION_BY_CODE_VERSION_ALL = "/solutions/:code/version/all",
    SOLUTION_BY_CODE = "/solutions/:code"
    
}

export default PATHS;
