import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllPopUpValuesQuery } from "../../data/api/CatalogueApi";
import { HeaderOptions, HeaderTypes, } from "../../data/model/DataModels";
import SpinnerComponent from "../Spinner/SpinnerComponent";
import styles from "./HeaderPopUp.module.scss"


export interface HeaderPopUpProps {
	headerType: HeaderTypes;
	selectValues: HeaderOptions[];
	setHeaderOptions: Function;
	isSolution?: boolean;
}

const HeaderPopUp: FC<HeaderPopUpProps> = ({ headerType, selectValues, setHeaderOptions, isSolution = false }) => {
	const { data: allPopUpValues = [], isLoading: isLoadingallPopUpValues } = useGetAllPopUpValuesQuery(headerType);
	const [selectedOptions, setSelectedOptions] = useState(selectValues as HeaderOptions[])

	const onCategoryChange = (e: any) => {
		let _selectedCategories = [...selectedOptions];

		if (e.checked) {
			//If it is a product, then we can select only 1 Service Model
			if (!isSolution && (headerType === HeaderTypes.SERVICE_MODEL)) {
				_selectedCategories = [];
			}
			_selectedCategories.push(e.value)
		}
		else {
			_selectedCategories = _selectedCategories.filter(category => category?.code !== e?.value?.code);
		}
		setSelectedOptions(_selectedCategories);
		setHeaderOptions(_selectedCategories);
	};


	const selectAll = () => {
		setSelectedOptions(allPopUpValues);
		setHeaderOptions(allPopUpValues);
	}

	const deSelectAll = () => {
		setSelectedOptions([]);
		setHeaderOptions([]);
	}



	return (

		<div data-testid="HeaderPopUp">
			<div>
				{isLoadingallPopUpValues && <SpinnerComponent />}
				<div className={styles.headerBody}>
					{(!isLoadingallPopUpValues && allPopUpValues.length > 3) && <div className={styles.linkSection}>
						<span onClick={selectAll} > Select All</span>
						<span onClick={deSelectAll}> De-select All</span>
					</div>
					}
					<div className={styles.optionsSection}>
						{allPopUpValues?.map((category: HeaderOptions) => {
							return (
								<div key={category?.code}>
									<Checkbox inputId={category?.code} name="category" value={category} onChange={onCategoryChange} checked={selectedOptions?.some((item: HeaderOptions) => item?.code === category?.code)} />
									<label htmlFor={category?.code} className={styles.optionLabel}>
										{category?.name}
									</label>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>


	)

}

export default HeaderPopUp;
