import React, { lazy, Suspense } from 'react';
import { ShowListTypeDimensionProps } from './ShowListTypeDimension';

const LazyShowListTypeDimension = lazy(() => import('./ShowListTypeDimension'));

const ShowListTypeDimension = (props: ShowListTypeDimensionProps & JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyShowListTypeDimension {...props} />
  </Suspense>
);

export default ShowListTypeDimension;
