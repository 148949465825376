import styles from "./ProductFamilies.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useCreateNewProductMutation, useGetFamiliesQuery, useGetProductInfoListByStatusQuery } from "../../data/api/CatalogueApi";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import ErrorHandlerComponent from "../../components/ErrorHandler/ErrorHandlerComponent";
import { truncate } from "lodash";
import {
	RiArchiveDrawerLine,
	RiArchiveFill,
	RiArchiveLine,
	RiBuilding4Line,
	RiBuildingLine,
	RiLinksFill,
	RiPlaneLine,
	RiShipFill,
	RiShipLine,
	RiShoppingBag3Line,
	RiTempColdLine,
	RiTruckLine
} from "react-icons/ri";
import { InputText } from "primereact/inputtext";
import { ProductInfo, ProductType, ROLES, Status, statusDropDownList, productTypeDropDownList, productTypeFilterList, serviceModelTypeDropDownList } from "../../data/model/DataModels";
import { Button } from "primereact/button";
import CreateProductDialog from "../../components/CreateProductDialog/CreateProductDialog";
import { hasOwnerRoleForAnyProductOrFamily, hasGlobalOwnerRole, hasRole, useAnchor, hasAnyRoleForAnyProductOrFamily } from "../../authorisationService";
import PATHS from "../../Paths";
import { Sidebar } from "primereact/sidebar";
import FamilyFilter, { setSelectedFilters } from "../FamiliesView/FamilyFilter/FamilyFilter";
import { BreadCrumbContext } from "../../App";
import { ToasterContext } from "../AppLayoutView/AppLayoutView";
import { Dropdown } from "primereact/dropdown";

export const resolveFamiliesIconFn = (family: string) => {
	switch (family.toLowerCase()) {
		case "air_n_lcl": return <RiPlaneLine />;
		case "cold_chain_logistics": return <RiTempColdLine />;
		case "contract_logistics": return <RiBuilding4Line />;
		case "customs_services": return <RiArchiveDrawerLine />;
		case "ecommerce_logistics": return <RiShoppingBag3Line />;
		case "landside_transport": return <RiTruckLine />;
		case "lead_logistics": return <RiBuildingLine />;
		case "tradelens": return <RiLinksFill />;
		case "ocean_contract": return <RiShipFill />;
		case "ocean_freesale": return <RiShipLine />;
		case "ocean_vas": return <RiArchiveLine />;
		case "ocean_special_cargo": return <RiArchiveLine />;
		default: return <RiArchiveFill />;
	}
};

export const DRAFT_VIEW_PATH = "draftView";

export const familyFilterQueryParams = {
	FAMILY_FILTER_QUERY_KEY: "familynamefilter",
	PRODUCTTYPE_FILTER_QUERY_KEY: "producttypefilter",
	PRODUCT_STATUS_FILTER_QUERY_KEY: "productstatusfilter",
	SERVICEMODAL_FILTER_QUERY_KEY: "serviceNameFilter"
}

export function ProductFamilies() {

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();
	const [dialogVisible, setDialogVisible] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [productType, setProductType] = useState(productTypeDropDownList[0]);
	const { data: familyList = [], error: errorFamily, isLoading: isLoadingFamily, isFetching: isFetchingFamily } = useGetFamiliesQuery();
	const [createNewProduct, { isLoading: isLoadingCreateNewProduct }] = useCreateNewProductMutation();
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const queryfamilyFilterValue = searchParams.get(familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY);
	const familyFilters: string[] = queryfamilyFilterValue ? queryfamilyFilterValue.split(',') : [];
	const queryProductTypeFilterValue = searchParams.get(familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
	const productTypeFilters: string[] = queryProductTypeFilterValue ? queryProductTypeFilterValue.split(',') : [];
	const queryHeaderServiceModelFilterValue = searchParams.get(familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	const headerServiceModelFilters: string[] = queryHeaderServiceModelFilterValue ? queryHeaderServiceModelFilterValue.split(',') : [];
	const showDrafts: boolean = searchParams.get(DRAFT_VIEW_PATH) === "true";
	const queryProductStatusFilterValue = searchParams.get(familyFilterQueryParams.PRODUCT_STATUS_FILTER_QUERY_KEY);
	if (queryProductStatusFilterValue === null || !(hasGlobalOwnerRole() || hasAnyRoleForAnyProductOrFamily())) {
		setSelectedFilters([statusDropDownList[0].code], searchParams, setSearchParams, familyFilterQueryParams.PRODUCT_STATUS_FILTER_QUERY_KEY);
	}

	const { data: products, error: errorProducts, isLoading: isLoadingProducts, isFetching: isFetchingProducts } = useGetProductInfoListByStatusQuery(queryProductStatusFilterValue!, { skip: !queryProductStatusFilterValue });
	const hasProductOwnerRole = familyList.some(family => hasRole([family.code], ROLES.OWNER));
	const breadcrumbs = useContext(BreadCrumbContext);
	const toaster = useContext(ToasterContext);
	const { refreshAnchorToken } = useAnchor();

	useEffect(() => {
		breadcrumbs.setData([{ label: "Products", url: PATHS.PRODUCTS }]);
		if (queryProductStatusFilterValue === null) {
			setSelectedFilters([statusDropDownList[0].code], searchParams, setSearchParams, familyFilterQueryParams.PRODUCT_STATUS_FILTER_QUERY_KEY);
		}
	}, [])

	if (isLoadingProducts || isFetchingProducts || isLoadingFamily || isFetchingFamily) {
		return (<SpinnerComponent />);
	}

	if (errorFamily) {
		return (<ErrorHandlerComponent error={errorFamily} />);
	}

	if (queryProductStatusFilterValue && errorProducts) {
		toaster.showToast('error', "Failed to load '" + queryProductStatusFilterValue + " ' products. Please try again.");
	}

	// Main Render

	const filterSearchText = (product: ProductInfo): boolean => {
		return product.name.toLowerCase().includes(searchText.toLowerCase());
	}

	const filterProductType = (product: ProductInfo): boolean => {
		return productTypeFilters.length > 0 ? productTypeFilters.includes(product.productType?.toString()) : true;
	}

	const removeServiceModelFromFilter = (serviceModelCode: string) => {
		setSelectedFilters(headerServiceModelFilters?.filter((value: string) => value != serviceModelCode), searchParams, setSearchParams, familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	}

	const filterServiceModel = (product: ProductInfo): boolean => {
		const serviceModel = product.headersInfo?.serviceModel!;
		return headerServiceModelFilters.length > 0 ? serviceModel?.some(item => headerServiceModelFilters.includes(item.code.toString())) : true;
	}

	const filterStatuses = (product: ProductInfo): boolean => {
		return (queryProductStatusFilterValue === product.status) || true;
	}

	const filterFamilies = (product: ProductInfo): boolean => {
		return familyFilters.length > 0 ? familyFilters.includes(product.family.code) : true;
	}

	const goToProduct = (product: ProductInfo) => {
		navigate(`/products/${product.code}` + (product.status === Status.ACTIVE ? "" : `/version/${product.version}`));
	}

	const createProductFn = (productDTO: ProductInfo) => {
		createNewProduct(productDTO).unwrap().then(async () => {
			toaster.showToast("success", "Successfully created product");
			refreshAnchorToken();
		},
			() => {
				toaster.showToast("error", "Failed to create product");
			}
		)
		setSearchParams(searchParams);

		// turn on edit mode and
		// navigate to product after create
	}

	const removeFamilyFromFilter = (familyCode: string) => {
		setSelectedFilters(familyFilters?.filter((value: string) => value != familyCode), searchParams, setSearchParams, familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY);
	}

	const showFilterValues = () => {
		return (
			<>
				{
					familyFilters?.length > 0 &&
					familyFilters.map((familyCode: any) =>
						<span key={familyCode} >
							<span>
								Family: {familyList.find(fam => fam.code === familyCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeFamilyFromFilter(familyCode)}></span>
						</span>
					)
				}
			</>

		)
	}

	const removeProductTypeFromFilter = (productType: string) => {
		setSelectedFilters(productTypeFilters?.filter((value: string) => value != productType), searchParams, setSearchParams, familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
	}

	const showProductTypeFilterValues = () => {
		return (
			<>
				{
					productTypeFilters?.length > 0 &&
					productTypeFilters.map((productType: any) =>
						<span key={productType} >
							<span>
								Product type: {productTypeFilterList.find(prodType => prodType.type === productType)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeProductTypeFromFilter(productType)}></span>
						</span>
					)
				}
			</>

		)
	}

	const showServiceModelFilterValues = () => {
		return (
			<>
				{
					headerServiceModelFilters?.length > 0 &&
					headerServiceModelFilters.map((serviceModelCode: string) =>
						<span key={serviceModelCode} >
							<span data-testid="serviceModelFilter">
								Service Model: {serviceModelTypeDropDownList.find(service => service?.code == serviceModelCode)?.name}
							</span>
							<span className={`${'pi pi-times'} ${styles.removeFilter}`} onClick={() => removeServiceModelFromFilter(serviceModelCode)}></span>
						</span>
					)
				}
			</>

		)
	}

	const isGlobalOwner = hasGlobalOwnerRole();
	const isBPO = hasOwnerRoleForAnyProductOrFamily();

	const hasAnyRole = (productCode: String, version: Number) => {
		if (!showDrafts || isGlobalOwner || isBPO)
			return true;
		const isContibutor = hasRole([productCode + "-" + version], ROLES.CONTRIBUTOR);
		const isApprover = hasRole([productCode + "-" + version], ROLES.APPROVER);
		const isReviewer = hasRole([productCode + "-" + version], ROLES.REVIEWER);
		return isContibutor || isApprover || isReviewer;
	}

	const changeProductType = (value: any) => {
		setProductType(value);
		let selctedProductFilters: string[] = [];
		if (value.type === ProductType.PRODUCT_ADDON)
			selctedProductFilters = [ProductType.PRODUCT, ProductType.ADDON];
		else if (value.type === ProductType.PRODUCT)
			selctedProductFilters = [ProductType.PRODUCT];
		else if (value.type === ProductType.ADDON)
			selctedProductFilters = [ProductType.ADDON];
		setSelectedFilters(selctedProductFilters, searchParams, setSearchParams, familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
	}

	const changeProductStatus = (code: string) => {
		setSelectedFilters([code], searchParams, setSearchParams, familyFilterQueryParams.PRODUCT_STATUS_FILTER_QUERY_KEY);
	}



	const getFilteredProducts = () => {
		return products ? products.filter(product =>
			filterSearchText(product)
			&& filterProductType(product)
			&& filterStatuses(product)
			&& filterFamilies(product)
			&& filterServiceModel(product)
			&& hasAnyRole(product?.code, product?.version))
			: [];
	}

	const getFilterLabelWithAppliedFilterCount = () => {
		const noOfAppliedFilters = familyFilters?.length + productTypeFilters.length + headerServiceModelFilters.length;
		return `Filter${noOfAppliedFilters > 0 ? "(" + noOfAppliedFilters + ")" : ""}`;
	}

	return (
		<div className={styles.container}>
			{
				isLoadingCreateNewProduct && <SpinnerComponent />
			}
			<div className={styles.featuredProducts} data-testid='productFamilies'>
				<h1>Products</h1>
				<div>
					{hasProductOwnerRole &&
						<Button label="Add new product" icon="pi pi-plus" className="p-button-outlined p-button-secondary" onClick={() => setDialogVisible(true)} />
					}
				</div>
			</div>
			<div className={`mainDisplayCard ${styles.productsGrid}`}>
				<div className={styles.productsActions}>
					<div>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText placeholder="Search for a product" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
						</span>
						<span className={styles.productLength}>
							{getFilteredProducts().length} Products
						</span>
					</div>
					<div className={styles.productFilter}>
						<div>Show</div>
						<Dropdown className={styles.productStatusDropDown} disabled={!(hasGlobalOwnerRole() || hasAnyRoleForAnyProductOrFamily())} value={queryProductStatusFilterValue} onChange={(e) => { changeProductStatus(e.value) }} options={statusDropDownList} optionLabel="name" optionValue="code" />
						<Dropdown className={styles.productTypeDropDown} value={productType} onChange={(e) => changeProductType(e.value)} options={productTypeDropDownList} optionLabel="name"
							placeholder={productTypeDropDownList[0].type} />
						<Button label={getFilterLabelWithAppliedFilterCount()} icon={"pi pi-sliders-h"} className="p-button-outlined p-button-secondary" onClick={() => setIsFilterOpen(true)} />
					</div>
				</div>
				<div className={styles.filterValues}>
					{showFilterValues()}
					{
						familyFilters?.length > 0 &&
						<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, familyFilterQueryParams.FAMILY_FILTER_QUERY_KEY)}>Clear all</div>
					}

				</div>
				<div className={styles.filterValues}>
					{showProductTypeFilterValues()}
					{
						productTypeFilters?.length > 0 &&
						<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY)}>Clear all</div>
					}
				</div>
				<div className={styles.filterValues}>
					{showServiceModelFilterValues()}
					{
						headerServiceModelFilters?.length > 0 &&
						<div className={styles.clearAll} onClick={() => setSelectedFilters([], searchParams, setSearchParams, familyFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)}>Clear all</div>
					}
				</div>
				<div className={"p-d-flex p-flex-row p-flex-wrap "}>
					{
						// products
						// 	.filter(product => filterSearchText(product) && filterProductType(product) && filterStatuses(product) && filterFamilies(product) && hasAnyRole(product?.code, product?.version))
						getFilteredProducts()
							.map(product =>
								<div key={product.code + product.version + product.status} data-testid="productOrAddon">
									<div className={styles.card}>
										<div className={styles.productContent} onClick={() => goToProduct(product)}>

											<div className={`${"p-d-flex p-flex-row p-jc-between "} ${styles.familyAndStatus}`}>
												<span className={styles.productFamily}>{resolveFamiliesIconFn(product.family.code)} {product.family.name}</span>
												<span className={styles.productStatus}>{product.status !== "active" && product.status}</span>
											</div>

											<div className={styles.cardTitles}>
												<h2 className={styles.productTitle}>{product.name}</h2>
												{/* <div className={styles.productVersion}>{product.status !== "active" && "version " + product.version}</div> */}
											</div>

											<div className={styles.cardContent}>
												{/*<span className={styles.tagline}>{truncate(product.salesInfo.tagline, { length: 80 })}</span>*/}
												<span className={styles.teaser}>{truncate(product.salesInfo.customerNeed, { length: 80 })}</span>
											</div>

											<div className={styles.cardFooter}>
												{(product.productType === ProductType.ADDON) && <span className={styles.addonType}>Add-on</span>}
												<span className={styles.productVersion}>{product.status !== "active" && "v" + product.version}</span>
											</div>
										</div>

									</div>
								</div>
							)
					}</div>
			</div>
			{dialogVisible && <CreateProductDialog dialogVisible={dialogVisible} setDialogVisibleFn={setDialogVisible} createProductFn={createProductFn} families={familyList} />}
			<Sidebar visible={isFilterOpen} showCloseIcon={false} position="right" onHide={() => setIsFilterOpen(false)}>
				<FamilyFilter onClose={() => setIsFilterOpen(false)} familyNameFilters={familyFilters} setSelectedFilters={(selectedFilters: string[], filterType: string) => setSelectedFilters(selectedFilters, searchParams, setSearchParams, filterType)} changeProductTypeDropDown={() => changeProductTypeDropDown(searchParams, setProductType)} productTypeFilters={productTypeFilters}
					serviceModelFilters={headerServiceModelFilters} />
			</Sidebar>
		</div>
	);
}

export const changeProductTypeDropDown = (searchParams: URLSearchParams, setProductType: Function) => {
	const queryProductTypeFilterValue = searchParams.get(familyFilterQueryParams.PRODUCTTYPE_FILTER_QUERY_KEY);
	const productTypeFilters: string[] = queryProductTypeFilterValue ? queryProductTypeFilterValue.split(',') : [];
	if (productTypeFilters && productTypeFilters?.length > 1 || productTypeFilters?.length === 0)
		setProductType(productTypeDropDownList[0]);
	else if (productTypeFilters && productTypeFilters.includes(ProductType.ADDON))
		setProductType(productTypeDropDownList[2]);
	else if (productTypeFilters && productTypeFilters.includes(ProductType.PRODUCT))
		setProductType(productTypeDropDownList[1]);
}
