import React, { FC } from 'react';
import styles from './SpecificationParameterOptionality.module.scss';
import { RadioButton } from 'primereact/radiobutton';
import { SpecificationParameterDefinition } from '../../data/model/DataModels';
import { Message } from 'primereact/message';

interface SpecificationParameterOptionalityProps {
  specificationParameter: SpecificationParameterDefinition;
  optionalityChangeFn: Function;
  isSPOptionalityEnabled: boolean;
  showWarning: boolean;
  isFeatureAService?: boolean
}

const SpecificationParameterOptionality: FC<SpecificationParameterOptionalityProps> = ({ specificationParameter, optionalityChangeFn, isSPOptionalityEnabled, showWarning, isFeatureAService }) => {

  const isFeatureOptional = () => {
    return (specificationParameter.featureServiceDefault === "not_included");
  }

  return (
    <div className={styles.SpecificationParameterOptionality} data-testid="SpecificationParameterOptionality">
      <div className={styles.spTypeInnerRadioGroupRadioButtons}>
        <div>
          <div className={styles.spTypeInnerRadioGroupRadioButtons}>
            <div className={styles.spTypeRadioButton}>
              <RadioButton
                id="standardOptionality1"
                name="core"
                value="included"
                inputId="core"
                onChange={(e) => {
                  optionalityChangeFn(e.target.value);
                }}
                disabled={!isSPOptionalityEnabled || isFeatureOptional()}
                checked={specificationParameter.serviceDefault === "included"}
                data-testid="spCoreRadioButton"
              />
              <label htmlFor="core" className={styles.spTypeRadioButtonLabel}>
                Core
              </label>
            </div>

            <div className={styles.spTypeRadioButton}>
              <RadioButton
                id="optionalOptionality1"
                inputId="optional"
                name="optional"
                value="not_included"
                disabled={!isSPOptionalityEnabled || isFeatureOptional()}
                onChange={(e) => {
                  optionalityChangeFn(e.target.value);
                }}
                checked={(specificationParameter.serviceDefault === "not_included")}
                data-testid="spOptionalRadioButton"
              />
              <label htmlFor="optional" className={styles.spTypeRadioButtonLabel}>
                Optional
              </label>
            </div>
          </div>
          {
            showWarning &&
            <Message className={styles.warning}
              severity="error"
              text="A core feature needs at least one core specification parameter" />
          }
        </div>

        {
          !isFeatureAService && <>Non Service</>
        }

      </div>
    </div>
  );
}

export default SpecificationParameterOptionality;
