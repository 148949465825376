import React, { useEffect, useState } from "react";
import styles from "./AttributesList.module.scss";
import { cloneDeep, startCase } from "lodash";
import { AttributeCollection, sectionType } from "../../data/model/SharedDataInterfaces";
import "primeflex/primeflex.css";
import { InputTextarea } from "primereact/inputtextarea";
import { FieldIdentifiers, formValidation } from "../../utils/validationUtil";
import { Editor } from 'primereact/editor';
import RichTextCustomHeaderComponent, { sanitizeHTML } from "../RichTextCustomHeader/RichTextCustomHeaderComponent";

// Shows description attribute in the top and the rest in a list of icon, header, value pairs
// doesn't handle ordering ... yet

export interface AttributesListProps {
	topAttribute?: string,
	attributes: AttributeCollection,
	activeVersionAttributes: AttributeCollection,
	resolveIconStyle?: (_: string) => string,
	saveState: Function,
	section: sectionType,
	editMode?: boolean,
	isAttributesValid: Function,
	isAuthorisedToEdit: boolean
}

const AttributesList: React.FC<AttributesListProps> = ({ topAttribute = "", attributes, activeVersionAttributes, resolveIconStyle, saveState, section, editMode = false, isAttributesValid, isAuthorisedToEdit = false }) => {

	const handleChanges = (value: any, index: any) => {
		const editableAttributes = cloneDeep(attributes);
		editableAttributes[index] = value != null ? value : "";
		saveState(section, editableAttributes);
	};

	let invalidFields: string[] = [];

	useEffect(() => {
		if (invalidFields.length === 0) {
			isAttributesValid(true);
		} else {
			isAttributesValid(false);
		}
	});

	const checkFormValidity = (value: string, fieldName: string) => {
		let validityResult: any;
		if (fieldName == "description") {
			validityResult = formValidation(value, FieldIdentifiers.ATTRIBUTES_DESCRIPTION);
		} else if (fieldName == "tagline") {
			validityResult = formValidation(value, FieldIdentifiers.PRODUCT_TAGLINE);
		}
		else {
			validityResult = formValidation(value, FieldIdentifiers.ATTRIBUTES);
		}
		if (validityResult !== "") {
			if (!invalidFields.includes(fieldName)) {
				invalidFields = [...invalidFields, fieldName];
			}
		} else {
			if (invalidFields.length > 0) {
				invalidFields = invalidFields.filter(field => field !== fieldName);
			}

		}
		return validityResult;
	}



	const containsChanges = (attrIndex: any) => {
		return attributes[attrIndex]?.toString() != activeVersionAttributes[attrIndex]?.toString();
	};

	const attributeInputByType = (attrKey: string, attrValue: string) => {
		return (
			<div className={styles.attributeText}>
				<InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
					className={`${editMode ? styles.edit_mode : styles.view_mode} ${containsChanges(attrKey) ? "isChanged" : ""} ${editMode && formValidation(attrValue, FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
					value={attrValue} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, attrKey)} />
				{
					editMode && checkFormValidity(attrValue, attrKey)
				}
			</div>
		);
	}

	return (
		<div className={styles.attributesList}>
			{
				topAttribute !== "" &&
				<div>
					{
						editMode ?
							<span className={` ${styles.description}`}>
								<div className={styles.editor}>
									<Editor style={{ height: '200px' }} className={`${checkFormValidity(attributes[topAttribute].toString(), "description") != "" ? "invalid" : ""} `} headerTemplate={RichTextCustomHeaderComponent()} value={attributes["descriptionRichText"]?.toString()} onTextChange={(e) => saveState(section, { ...attributes, [topAttribute]: e.textValue, descriptionRichText: e.htmlValue })} />
									{
										checkFormValidity(attributes[topAttribute].toString(), "description")
									}
								</div>
							</span>
							:
							<div dangerouslySetInnerHTML={{ __html: sanitizeHTML(attributes["descriptionRichText"]?.toString()) }} className={styles.displayDescription} />
					}

				</div>
			}

			<div className={styles.attributes}>
				{Object.keys(attributes)
					.filter(entry => entry !== topAttribute && entry && !entry.includes("RichText"))
					.filter(entry => editMode || (attributes[entry] !== "" && attributes[entry] !== null))
					.map((entry) => {
						const attrKey = entry;
						const attrValue = attributes[entry]?.toString() || "";
						return (
							<div key={"attribute-" + attrKey} className={styles.attribute}>
								<div className={styles.attributeHeader}>
									<i className={resolveIconStyle == null ? "pi pi-info-circle" : resolveIconStyle(attrKey)} />
									<span className={styles.label}><h3>{startCase(attrKey)}</h3></span>
								</div>
								{attributeInputByType(attrKey, attrValue)}
							</div>
						);
					})}
			</div>
		</div>);
};

export default AttributesList;
