import React, { FC, useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import RichTextCustomHeaderComponent from '../RichTextCustomHeader/RichTextCustomHeaderComponent';
import {
    ConfigurableControl,
    OptionValue,
    ProductInfo,
    SpecificationParameterConfig,
    SpecificationParameterDefinition,
    SpecificationParameterType
} from '../../data/model/DataModels';
import { FieldIdentifiers, formValidation } from '../../utils/validationUtil';
import styles from './CreateOrEditSpecificationParameter.module.scss';
import specTypeOptions from "../../assets/icons/custom/spec-type-options.svg";
import specTypeCondition from "../../assets/icons/custom/spec-type-condition.svg";
import specTypeText from "../../assets/icons/custom/spec-type-text.svg";
import {
    useCreateSpecificationParameterMutation,
    useUpdateSpecificationParameterMutation
} from '../../data/api/CatalogueApi';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { ToasterContext } from '../../features/AppLayoutView/AppLayoutView';
import { useParams } from 'react-router-dom';
import SpecificationParameterConfiguration from "../SpecificationParameterConfiguration/SpecificationParameterConfiguration";
import { InputSwitch } from 'primereact/inputswitch';
import { cloneDeep } from 'lodash';
import SpecificationParameterOptionality from '../SpecificationParameterOptionality/SpecificationParameterOptionality';

export interface CreateOrEditSpecificationParameterProps {
    specificationParameter?: SpecificationParameterDefinition;
    onClose: Function;
}

const CreateOrEditSpecificationParameter: FC<CreateOrEditSpecificationParameterProps> = (
    {
        specificationParameter = { associatedProducts: [] as ProductInfo[] } as SpecificationParameterDefinition,
        onClose,
    }
) => {
    const [specificationParameterDef, setSpecificationParameterDef] = useState(specificationParameter);
    const featureService = specificationParameter?.isService;
    const [description, setDescription] = useState({
        text: specificationParameter.description || "",
        richtext: specificationParameter.descriptionRichText || ""
    });
    const isNewSpecificationParam: boolean = !specificationParameter?.code;
    const isSpecificationParameterConfigurationDisabled = specificationParameter?.associatedProducts?.length > 0;

    const [createSpecificationParam, { isLoading: isLoadingCreateSpecificationParam }] = useCreateSpecificationParameterMutation();
    const [updateSpecificationParam, { isLoading: isLoadingUpdateSpecificationParam }] = useUpdateSpecificationParameterMutation();
    const toaster = useContext(ToasterContext);
    let { version } = useParams();
    const [showWarning, setShowWarning] = useState(false);
    const totalCoreSps = 0 as number;

    let invalidFields: string[] = [];
    const specificationTypes = [SpecificationParameterType.text, SpecificationParameterType.options];

    const onDeleteOfOptionsElement = (index: number) => {
        let clonedContracting: ConfigurableControl = JSON.parse(JSON.stringify(specificationParameterDef.configurableAtContracting));
        const optionsList = specificationParameterDef.configuration.value.filter((element: any, idx: number) => idx === index ? false : true);
        if (optionsList.length === 1) {
            optionsList[0].isDefault = true;
            clonedContracting.allowMultiselect = false;

            //Exclusive check for OIPC-293
            clonedContracting.enabled = false;
            clonedContracting.choiceRequired = false;
        }
        setSpecificationParameterDef({
            ...specificationParameterDef,
            configuration: { ...specificationParameterDef.configuration, value: optionsList },
            configurableAtContracting: clonedContracting
        });
    }

    const onAddOfOptionsElement = () => {
        const newOptionsElement: OptionValue = { option: "", description: "", isDefault: false };
        if (specificationParameterDef.configuration.value && !specificationParameterDef.configuration.value.length) {
            newOptionsElement.isDefault = true;
        }
        setSpecificationParameterDef({
            ...specificationParameterDef,
            configuration: {
                ...specificationParameterDef.configuration,
                value: [...specificationParameterDef.configuration.value, newOptionsElement],
            }
        });
    }

    const onClickSpecificationType = (value: SpecificationParameterType) => {
        switch (value) {
            case SpecificationParameterType.condition:
                setSpecificationParameterDef({
                    ...specificationParameterDef,
                    configuration: {
                        ...specificationParameterDef.configuration,
                        type: SpecificationParameterType.condition,
                        value: ""
                    }
                })
                break;
            case SpecificationParameterType.options:
                setSpecificationParameterDef({
                    ...specificationParameterDef,
                    configurableAtContracting: {
                        enabled: false,
                        choiceRequired: false,
                        allowMultiselect: false
                    },
                    configuration: {
                        ...specificationParameterDef.configuration,
                        type: SpecificationParameterType.options,
                        value: [{ option: "", description: "", isDefault: true }, { option: "", description: "", isDefault: false }] as OptionValue[]
                    }
                })
                break;
            case SpecificationParameterType.text:
                setSpecificationParameterDef({
                    ...specificationParameterDef,
                    configurableAtContracting: {
                        enabled: false,
                        choiceRequired: false,
                        allowMultiselect: false
                    },
                    configuration: {
                        ...specificationParameterDef.configuration,
                        type: SpecificationParameterType.text,
                        value: ""
                    }
                })
                break;
            default:
                // set as none
                setSpecificationParameterDef({
                    ...specificationParameterDef,
                    configuration: {
                        ...specificationParameterDef.configuration,
                        type: SpecificationParameterType.none,
                        value: ""
                    }
                })
        }
    }

    const handleFormChange = (index: number, event: any) => {
        let newspecificationParameterDef = JSON.parse(JSON.stringify(specificationParameterDef));
        let data: any = [...newspecificationParameterDef.configuration.value];
        if (newspecificationParameterDef.configuration.type === 'options' && event.target.name === 'isDefault' && event.target.value) {
            data.forEach((element: OptionValue, elIndex: number) => elIndex === index ? element.isDefault = true : element.isDefault = false);
        } else {
            data[index][event.target.name] = event.target.value;
        }
        setSpecificationParameterDef({
            ...specificationParameterDef,
            configuration: { ...specificationParameterDef.configuration, value: data }
        });
    }

    const checkFormValidity = (value: string, fieldIdentifier: FieldIdentifiers, fieldName: string, isRequiredField: boolean = false, requiredFieldValidatioError: string = "") => {
        var validityResult = formValidation(value, fieldIdentifier, isRequiredField, requiredFieldValidatioError);
        if (validityResult !== "") {
            if (!invalidFields.includes(fieldName)) {
                invalidFields = [...invalidFields, fieldName];
            }
        } else if (fieldIdentifier === FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION) { // restrict duplicate options
            var confValue = specificationParameterDef.configuration.value as [];
            var filteredConf = confValue.filter(val =>
                (val["option"] + "").toLowerCase() === value.toLowerCase());
            if (!invalidFields.includes(fieldName) && filteredConf.length > 1) {
                invalidFields = [...invalidFields, fieldName];
                validityResult = (<div style={{ fontSize: "smaller", color: "red", margin: "0.25rem 0" }}>Duplicate options are not allowed</div>);
            } else {
                invalidFields = invalidFields.filter(field => field !== fieldName);
                validityResult = "";
            }
        }
        else if (fieldIdentifier === FieldIdentifiers.SPECIFICATION_PARAMETER_CONFIGURATION_OPTIONALITY) {
            if (specificationParameterDef.serviceDefault === "none") {
                invalidFields = [...invalidFields, fieldName];
                validityResult = (<div style={{ fontSize: "smaller", color: "red", margin: "0.25rem 0" }}>Please select an option</div>);
            }
        }
        else {
            if (invalidFields.length > 0) {
                invalidFields = invalidFields.filter(field => field !== fieldName);
            }
        }
        return validityResult;
    }

    const getSpecificationTypeIcons = (type: SpecificationParameterType) => {
        const selected = specificationParameterDef?.configuration?.type === type;
        const getIcons = () => {
            switch (type) {
                case SpecificationParameterType.text:
                    return <img data-testid="configTypeText" src={specTypeText} />;
                case SpecificationParameterType.options:
                    return <img data-testid="configTypeOptions" src={specTypeOptions} />;
                case SpecificationParameterType.condition:
                    return <img data-testid="configTypeCondition" src={specTypeCondition} />;
                default:
                    return <svg width="24" height="24"></svg>;
            }
        }
        return (
            <div key={type} className={`${selected && styles.selected} ${styles.specificationTypeBox}`}
                onClick={() => onClickSpecificationType(type)}>
                <div className={styles.specTypeImageDiv}>
                    {getIcons()}
                </div>
                {type === SpecificationParameterType.included ? "Inclusion" : type === SpecificationParameterType.text ? "Information" : type[0].toUpperCase() + type.slice(1)}
            </div>
        )
    }

    const textElement = () => {
        return (
            <div data-testid="textElement" className={styles.formElement}>
                <label>Specification value: </label>
                <div>
                    <InputText data-testid="textElementInputField" value={specificationParameterDef.configuration.value}
                        disabled={isSpecificationParameterConfigurationDisabled}
                        className={`${styles.textSpecificationElementInput} ${formValidation(name, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_TEXT, false, "Please enter Text value") !== "" && "invalid"}`}
                        id="textField" onChange={(e) => setSpecificationParameterDef({
                            ...specificationParameterDef,
                            configuration: { ...specificationParameterDef.configuration, value: e.target.value }
                        })} />
                    {
                        checkFormValidity(specificationParameterDef.configuration.value, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_TEXT, "textField", false, "Please enter text value")
                    }
                </div>
            </div>
        );
    }

    const optionsElement = () => {
        return (
            <>
                <div>Options: </div>
                <div data-testid="optionsElement" className={styles.optionsForm}>
                    {specificationParameterDef.configuration.value.map((element: OptionValue, index: number) => {
                        return (
                            <div data-testid={`optionsElement${index}`} key={index + "span"} className={styles.optionElement}>
                                <div className={styles.optionData}>
                                    <div className={styles.nameAndDefaultSwitch}>
                                        <div>
                                            <InputText placeholder="Name" type="text" key={index} name="option" disabled={isSpecificationParameterConfigurationDisabled}
                                                className={`p-inputtext ${styles.nameTextBox} ${formValidation(element.option, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION, false) ? "invalid" : ""}`}
                                                value={element.option} onChange={(e) => handleFormChange(index, e)} />

                                        </div>
                                        <div className={styles.defaultSwitch}>
                                            <div>Default</div>

                                            <InputSwitch data-testid={`defaultOptionsButton${index}`} checked={element.isDefault} name='isDefault' onChange={(e) => handleFormChange(index, e)} />
                                            <div className={styles.defaultInfo}>(Can be changed at product association)</div>
                                        </div>
                                    </div>
                                    {
                                        checkFormValidity(element.option, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION, "specificationOptionName" + index, true, "Please enter option value")
                                    }
                                    <div>
                                        <InputText placeholder="Description (optional)" key={index + "description"} disabled={isSpecificationParameterConfigurationDisabled}
                                            className={`p-inputtext ${styles.optionsDescription} ${formValidation(element.description, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION_DESCRIPTION, false) ? "invalid" : ""}`}
                                            name="description" id={index.toString()} value={element.description}
                                            onChange={(e) => handleFormChange(index, e)} />
                                        {
                                            checkFormValidity(element.description, FieldIdentifiers.SPECIFICATION_PARAMETER_SPEC_OPTION_DESCRIPTION, "specificationOptionDescription" + index, false)
                                        }
                                    </div>
                                </div>
                                <div className={styles.deleteButton}>
                                    <Button data-testid={`deleteOptionsButton${index}`} icon="pi pi-trash"
                                        className="p-button-outlined p-button-secondary"
                                        disabled={isSpecificationParameterConfigurationDisabled || specificationParameterDef.configuration.value.length == 1}
                                        style={{ color: "black", border: "1px solid #CFCFCF", borderRadius: "4px" }}
                                        onClick={() => onDeleteOfOptionsElement(index)} />
                                </div>
                            </div>
                        )
                    })}
                    <div>
                        <Button data-testid="addOptionsButton" icon="pi pi-plus"
                            className="p-button-outlined p-button-secondary" style={{
                                marginTop: "0.6rem",
                                color: "black",
                                border: "1px solid #CFCFCF",
                                borderRadius: "4px"
                            }}
                            disabled={isSpecificationParameterConfigurationDisabled}
                            onClick={() => onAddOfOptionsElement()} />
                    </div>
                </div>
            </>

        );
    }

    const getSpecificationElement = () => {
        switch (specificationParameterDef?.configuration?.type) {
            case SpecificationParameterType.text:
                return textElement();
            case SpecificationParameterType.options:
                return optionsElement();
            case SpecificationParameterType.condition:
                return textElement();
            default:
                return "";
        }
    }

    const onSelectOfMultiSelect = () => {
        let clonedContracting: ConfigurableControl = JSON.parse(JSON.stringify(specificationParameterDef.configurableAtContracting));
        clonedContracting.allowMultiselect = !clonedContracting.allowMultiselect;
        setSpecificationParameterDef({ ...specificationParameterDef, configurableAtContracting: clonedContracting });
    }

    const createOrUpdateSpecificationParameter = () => {
        const specificationToSave: SpecificationParameterDefinition = {
            ...specificationParameterDef,
            description: description.text,
            descriptionRichText: description.richtext,
            featureVersion: Number(version)
        }
        if (isNewSpecificationParam) {
            createSpecificationParam(specificationToSave).unwrap().then(res => {
                toaster.showToast("success", "Successfully created specification parameter");
                onClose();
            },
                (error) => {
                    toaster.showToast("error", "Failed to create specification parameter");
                    console.error(error);
                }
            );
        } else {
            updateSpecificationParam(specificationToSave).unwrap().then(res => {
                toaster.showToast("success", "Successfully updated specification parameter");

                onClose();
            },
                (error) => {
                    toaster.showToast("error", "Failed to update specification parameter");

                    console.error(error);
                }
            );
        }

    }

    const checkIfConfigurationIsOfTypeOptionAndLengthIsOne = (configuration: SpecificationParameterConfig) => {
        return configuration?.type == SpecificationParameterType.options && configuration.value.length <= 1;
    }

    const getConfigurableAtContractingOnChangeOfServiceDefault = (serviceDefault: string) => {
        if (!specificationParameter?.configurableAtContracting) {
            return;
        }

        let clonedContracting: ConfigurableControl = cloneDeep(specificationParameter?.configurableAtContracting);
        clonedContracting.enabled = false;
        clonedContracting.choiceRequired = false;
        clonedContracting.allowMultiselect = false;

        if (serviceDefault === 'included' && specificationParameter.configuration.type === 'options' && !checkIfConfigurationIsOfTypeOptionAndLengthIsOne(specificationParameter.configuration)) {
            clonedContracting.enabled = true;
            clonedContracting.choiceRequired = true;
        }

        return clonedContracting;
    }

    const onOptionalityRadioButtonClick = (serviceDefault: "included" | "not_included" | "none") => {
        if (totalCoreSps === 1 && serviceDefault === "not_included") {
            setShowWarning(true);
            return;
        }
        else {
            setShowWarning(false);
            setSpecificationParameterDef({ ...specificationParameterDef, serviceDefault: serviceDefault, configurableAtContracting: getConfigurableAtContractingOnChangeOfServiceDefault(serviceDefault) });
        }
    }

    return (
        <div className={styles.CreateOrEditSpecificationParameter} data-testid="CreateOrEditSpecificationParameter">
            {/* {previewSpecificationParameter && previewSpecificationParameterDialog()} */}
            {
                (isLoadingCreateSpecificationParam || isLoadingUpdateSpecificationParam) && <SpinnerComponent />
            }
            <h2>Specification parameter details</h2>
            <div className={styles.formElement}>
                <div>Name</div>
                <InputText
                    className={`p-inputtext ${styles.mediumWidth} ${formValidation(specificationParameterDef?.name, FieldIdentifiers.SPECIFICATION_PARAMETER_TITLE, true, "Specification parameter name is mandatory") !== "" && "invalid"}`}
                    id="specificationParameterName" value={specificationParameterDef?.name || ""}
                    onChange={(e) => setSpecificationParameterDef({ ...specificationParameterDef, name: e.target.value })}
                    placeholder="Enter specification parameter name" />
                {
                    checkFormValidity(specificationParameterDef?.name, FieldIdentifiers.SPECIFICATION_PARAMETER_TITLE, "specificationParameterName", true, "Specification parameter name is mandatory")
                }
            </div>
            <div className={styles.formElement}>
                <div>Description (optional)</div>
                <div>
                    <Editor style={{ height: '7rem', fontSize: "1rem" }}
                        placeholder="Enter specification parameter description" name="description"
                        className={`${formValidation(description.text, FieldIdentifiers.SPECIFICATION_PARAMETER_DESCRIPTION) !== "" && "invalid"} ${styles.mediumWidth}`}
                        headerTemplate={RichTextCustomHeaderComponent()} value={description.richtext?.toString()}
                        onTextChange={(e) => setDescription({ text: e.textValue, richtext: e.htmlValue || "" })} />
                    {
                        checkFormValidity(description.text, FieldIdentifiers.SPECIFICATION_PARAMETER_DESCRIPTION, "specificationParameterDescription")
                    }
                </div>
            </div>
            {
                isSpecificationParameterConfigurationDisabled &&
                <div className={styles.specificationAssociatedInfo}>
                    <i className="pi pi-info-circle" />
                    <div>Specification configuration cannot be changed as it is associated with one or multiple
                        products
                    </div>
                </div>
            }
            <div className={styles.specificationSection}>
                <div>Specification Type</div>

                <div
                    className={`${styles.specificationTypeIcons} ${isSpecificationParameterConfigurationDisabled && styles.disablePointerEvents}`}>
                    {specificationTypes.map(type => getSpecificationTypeIcons(type))}
                </div>
                {
                    specificationParameterDef?.configuration?.type !== SpecificationParameterType.none &&
                    <div className={styles.specificationTypeElement}>
                        {
                            getSpecificationElement()
                        }
                    </div>
                }
            </div>
            <div className={styles.configurableSection}>
                <div className={styles.configurationTitle}>Configuration
                    <div className={styles.configurationSubTitle}>Can be changed at product association</div>
                </div>
                <div>
                    {
                        featureService &&
                        checkFormValidity(specificationParameterDef.serviceDefault, FieldIdentifiers.SPECIFICATION_PARAMETER_CONFIGURATION_OPTIONALITY, "specificationConfigurationOptionality", true, "Please select an option")
                    }
                    {
                        featureService &&
                        <SpecificationParameterOptionality 
                            specificationParameter={specificationParameterDef}
                            optionalityChangeFn={onOptionalityRadioButtonClick}
                            isSPOptionalityEnabled={true} 
                            showWarning={showWarning}                        />
                    }
                    <SpecificationParameterConfiguration
                        specificationParameter={specificationParameterDef}
                        setSpecificationParameter={(specParam: SpecificationParameterDefinition) => setSpecificationParameterDef(specParam)}
                        // isSPOptionalityEnabled={true}
                        isFeatureAService={featureService}
                        isDraft={true}
                    />
                </div>

            </div>
            <div className={styles.alignButtonsInLine}>
                <Button className={styles.saveButton}
                    disabled={invalidFields?.length > 0 || !(specificationParameterDef?.configuration?.type)}
                    label="Save"
                    onClick={() => {
                        createOrUpdateSpecificationParameter()
                    }} />
                <Button className={`${styles.cancelButton} p-button-outlined p-button-secondary`} label="Cancel"
                    onClick={() => onClose()} />
            </div>
        </div>
    );
}

export default CreateOrEditSpecificationParameter;
