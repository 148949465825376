import React, { FC, useContext, useEffect, useState } from 'react';
import { getContractingText } from '../../../data/model/DataModels';
import styles from '../../ProductView/ProductView.module.scss'
import { ProductAndResources, useGetAllUsersForProductQuery, useGetProductByCodeQuery } from '../../../data/api/CatalogueApi';
import { startCase } from 'lodash';
import { Tag } from 'primereact/tag';
import { TabPanel, TabView } from 'primereact/tabview';
import ActivityComponent from '../../../components/Activity/ActivityComponent.lazy';
import { sectionType } from '../../../data/model/SharedDataInterfaces';
import ApplicabilitiesView from '../../ApplicabilitiesView/ApplicabilitiesView';
import SpinnerComponent from '../../../components/Spinner/SpinnerComponent';
import ComponentsView from '../../ProductView/ProductComponents/ProductComponents.lazy';
import ProductFeatures from '../../ProductView/ProductFeatures/ProductFeatures';
import AttributesList from '../../../components/AttributesList/AttributesList';
import { useLocation } from 'react-router-dom';
import { selectedComponentDefault } from '../../ProductView/ProductView';
import { FeatureFilterState, setFilters } from '../../../components/FilterPanel/featureFilterSlice';
import { filterType } from '../../../components/FilterPanel/FilterPanel';
import { useAppDispatch } from '../../../app/hooks';
import { setComponent } from '../../../data/slices/productSlice';
import { solutionTabs } from '../SolutionDetails';
import ProductOverview from '../../ProductView/ProductOverview/ProductOverview';
import { getResourceNameFromProductCodeAndVersion } from '../../../components/utils/userUtils';
import PATHS from '../../../Paths';
import { ToasterContext } from '../../AppLayoutView/AppLayoutView';
import ProductCompatibilities from '../../ProductCompatibilities/ProductCompatibilities.lazy';

interface UpgradeSolutionProductViewProps {
	subHeader: string;
	code: string;
	version: number;
}

const UpgradeSolutionProductView: FC<UpgradeSolutionProductViewProps> = ({ subHeader, code, version }) => {

	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const { data: product, isError: isErrorProduct, isLoading: isLoadingProduct } = useGetProductByCodeQuery(code);
	const { hash } = useLocation();
	const dispatch = useAppDispatch();
	const areRbacResourcesReady = (product?.rbacResourceCode && product?.rbacChildResourceCode && product?.rbacResourceCode != "" && product?.rbacChildResourceCode != '' && product?.rbacResourceCode != "error" && product?.rbacChildResourceCode != "error") || false;
	const { data: allUsers, isError: isErrorUsers, isLoading: isLoadingUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(code!, product?.version || 1), resourceCode: product?.rbacResourceCode, resourceChildCode: product?.rbacChildResourceCode } as ProductAndResources, { skip: !areRbacResourcesReady });

  const toaster = useContext(ToasterContext);

	useEffect(() => {
		const params = new URLSearchParams(hash.replace(solutionTabs[2].hash, ""));

		const filters: FeatureFilterState = {
			checkedTags: [],
			checkedControls: [],
			checkedComponents: [],
		};
		params.forEach((value, key) => {
			if (value === filterType.Tag) filters.checkedTags.push(key);
			if (value === filterType.Control) filters.checkedControls.push(key);
			if (value === filterType.Component) filters.checkedComponents.push(key);
		});
		dispatch(setFilters(filters));

		if (filters.checkedComponents?.length === 1) {
			const component = product?.components?.find(component => component.definition?.code === filters.checkedComponents[0]);
			dispatch(setComponent(component!));
		} else {
			dispatch(setComponent(selectedComponentDefault));
		}

	}, [product, hash]);

	const resolveAttributeIconFn = (key: string) => {
		switch (key.toLowerCase()) {
			case "geographiccoverage": return "pi pi-globe";
			case "customerneed": return "pi pi-users";
			case "saleschannels": return "pi pi-desktop";
			case "bookingchannels": return "pi pi-shopping-cart";
			case "targetcustomersegments": return "pi pi-briefcase";
			case "targetcustomerverticals": return "pi pi-chart-bar";
			default: return "pi pi-info-circle";
		}
	};

	if (isLoadingProduct || isLoadingUsers) {
		return (
			<SpinnerComponent />
		);
	}

	if(isErrorProduct){
		toaster.showToast("error", "Failed to load product. Please retry.")
	}
	if(isErrorUsers){
		toaster.showToast("error", "Failed to load product. Please retry.")
	}

	if (!product?.code) {
		return <></>
	}

	return (
		<div className={styles.productView} data-testid="productUpgradeDialogue">
			<div className={styles.productHeader}>
				<div className={styles.subHeader}>{subHeader}</div>
				<div className={styles.headerRow}>
					<div>
						<div className={styles.headerSection}>
							<h1 className={styles.productName}>{product.name}</h1>
						</div>
						<div className={styles.productVersion}>
							<span className="p-pr-2">Code: {product.code}, Version {product.version} ({startCase(product.status)})</span>
							<i
								className={`pi pi-arrow-up-right ${styles.goToProductUpArrowCursorPointer}`}
								onClick={() =>
									window.open(PATHS.PRODUCT_VERSION.replace(":code", product.code).replace(":version", product.version.toString()))
								}
							/>
							<span className={styles.productVersionTags}><Tag className={styles.productNameTag} severity={"info"} >{getContractingText(product.contracting)}</Tag></span>
						</div>
					</div>
				</div>
			</div>

			<div className={`mainDisplayCard ${styles.mainCardPosition}`} data-testid="mainDisplayCard">
				<TabView activeIndex={activeTabIndex} onTabChange={(e) => { setActiveTabIndex(e.index) }} data-testid="tabView">
					<TabPanel header="Overview">
						<ProductOverview productFamilyCode={product.family.code} productName={product.name} productVersion={product.version} productCode={product.code} productHeaders={product.headersInfo}
							rbacResourceCode={product.rbacResourceCode} rbacChildResourceCode={product.rbacChildResourceCode}
							shownVersionHeader={product.header} activeVersionHeader={product.header} saveStateFn={() => { }} isFormAttibutesValidFn={() => { }}
							isAuthorisedToEdit={false} allUsers={allUsers!} approversApproved={[]} approversRejected={product.approversRejected!}
							productStatus={product.status} triggerApproverStatus={0} isApprovalFlow={false}
							usedInSolutions={product.associatedSolutions} isInsideSolutionProductUpgradePopup={true}
						/>
					</TabPanel>
					<TabPanel header="Applicabilities">
						<ApplicabilitiesView familyCode={product.family.code} isScopesValid={() => { }} editMode={false} scopes={product.applicability} saveStateFn={() => { }} isAuthorisedToEdit={false} />
					</TabPanel>
					<TabPanel header="Components">
						<ComponentsView product={product} activeVersionProduct={product} saveReordering={() => { }} isAuthorisedToEdit={false} isInsideSolutionProductUpgradePopup={true} setActiveTabIndex={setActiveTabIndex} />
					</TabPanel>
					<TabPanel header="Features">
						<ProductFeatures product={product} isAuthorisedToEdit={false} isInsideSolutionProductUpgradePopup={true} />
					</TabPanel>
					<TabPanel header="Commercial Info">
						<AttributesList attributes={product.salesInfo} activeVersionAttributes={product.salesInfo} resolveIconStyle={resolveAttributeIconFn} saveState={() => { }} section={sectionType.SALES_MARKETING} editMode={false} isAttributesValid={() => { }} isAuthorisedToEdit={false} />
					</TabPanel>
					<TabPanel header="Compatibilities">
						<ProductCompatibilities product={product} />
					</TabPanel>
					<TabPanel header="Activity" data-testid="ActivityTab" >
						<ActivityComponent type={"products"} code={product.code} />
					</TabPanel>
				</TabView>
			</div>
		</div>
	)
};

export default UpgradeSolutionProductView;
