import React, { forwardRef, HTMLAttributes } from 'react';

import styles from './TreeItem.module.scss';
import { Handle } from './components';
import { UniqueIdentifier } from '@dnd-kit/core';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  id?: UniqueIdentifier
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  type: string;
  activeItemDetails: any,
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  elementStyle: any;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      childCount,
      clone,
      depth,
      type,
      activeItemDetails,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      value,
      wrapperRef,
      elementStyle,
      ...props
    },
    ref
  ) => {

    return (
      <li
        className={`${styles.Wrapper} ${clone && styles.clone} ${ghost && styles.ghost} ${indicator && styles.indicator} ${disableSelection && styles.disableSelection} ${disableInteraction && styles.disableInteraction}`}
        ref={wrapperRef}
        style={
          {
            marginTop: depth === 0 ? "0.8rem" : "none",
            '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        {...props}
        data-testid="droppableWrapper"
      >
        <div className={`${styles.TreeItem} ${styles.featureGroupType}`} ref={ref} style={{ ...style, ...elementStyle }} data-testid="draggableItem">
          <div>
            <Handle {...handleProps} />
          </div>
          <svg viewBox="0 0 20 20" width="1.2rem" style={{ fill: '#545454' }}>
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
          </svg>
          <span className={styles.Text}>{value}</span>
        </div>
      </li>
    );
  }
);
