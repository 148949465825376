import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react';

interface FilterState {
	isOpen: boolean;
	filterValue: any;
}

const initialState = {
	isOpen: false,
	filterValue: {}
} as FilterState;

const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		setIsFilterOpen(state, action: PayloadAction<boolean>) {
			state.isOpen = action.payload;
		},
		setFilterValue(state, action: PayloadAction<any>) {
			state.filterValue = action.payload;
		}
	},
})

export const { setIsFilterOpen, setFilterValue } = filterSlice.actions
export default filterSlice.reducer