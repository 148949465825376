import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {DimensionItem, LocationData, DimensionDefinition} from "../model/DataModels";
import {EnvConfig} from "../../EnvConfig";
import { getToken } from "../../authorisationService";
import { addKeysToTree, addKeysToTreeAndRemoveCities } from "../../components/ScopeComponents/ScopeHelper";


export const RefDataApi = createApi({
	reducerPath: "RefDataApi",
	baseQuery: fetchBaseQuery( {
		baseUrl: EnvConfig.CATALOGUE_BASE_URL + "/api/dimensions",
		prepareHeaders: (headers) => {
			return getToken().then(token => {
				headers.set("Authorization", `Bearer ${token}`);
				headers.set("Consumer-Key", EnvConfig.CONSUMER_KEY);
				return headers;
			});
		},
	}),
	tagTypes: ["Dimensions"],
	endpoints: (builder) => ({
		getDimensionTypes: builder.query<DimensionDefinition[], void>({
			query: () => "",
		}),
		getListTypeDimensionData: builder.query<DimensionItem[], string>({
			query: (dimensionType: string) => `?dimensionType=${dimensionType}`,
		}),
		getTreeTypeDimensionData: builder.query<LocationData[], string>({
			query: (dimensionType: string) => `?dimensionType=${dimensionType}`,
			transformResponse: (response:LocationData[], meta, arg) => response.map(node => addKeysToTree(node)),
		}),
		getCountriesData: builder.query<LocationData[], void>({
			query: () => `?dimensionType=imports`,
			transformResponse: (response:LocationData[], meta, arg) => response.map(node => addKeysToTreeAndRemoveCities(node)),
		})
	}),
});



export const {
	useGetDimensionTypesQuery,
	useGetListTypeDimensionDataQuery, useLazyGetListTypeDimensionDataQuery,
	useGetTreeTypeDimensionDataQuery, useLazyGetTreeTypeDimensionDataQuery,
	useGetCountriesDataQuery, useLazyGetCountriesDataQuery
} = RefDataApi;

