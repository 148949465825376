import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { ROLES, AnchorSubscriptionDefinition, Family } from './data/model/DataModels';
import { EnvConfig } from "./EnvConfig";
import { useEffect, useState } from 'react';

export const AUTHORITY = "https://login.microsoftonline.com/" + EnvConfig.TENANT_ID;
export const DEFAULT_SCOPE = EnvConfig.CLIENT_ID + "/.default";

// MSAL configuration
export const configuration: Configuration = {
    auth: {
        clientId: EnvConfig.CLIENT_ID,
        authority: AUTHORITY,
    },
    cache: {
        cacheLocation: "sessionStorage",
    }
};

export const pca = new PublicClientApplication(configuration);

export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


export const getToken = async (): Promise<string> => {

    const accounts = pca.getAllAccounts();
    const account = accounts[0];

    let resp: any;
    const tokenRequest = {
        scopes: [DEFAULT_SCOPE],
        account: account,
        authority: AUTHORITY
    }

    try {
        resp = await pca.acquireTokenSilent(tokenRequest);
    } catch (error: any) {
        // acquireTokenSilent failed
        if (error.name === "InteractionRequiredAuthError") {
            resp = await pca.acquireTokenRedirect(tokenRequest);
        }
    }

    return resp.accessToken;
}

export const getGraphToken = async (): Promise<string> => {

    const accounts = pca.getAllAccounts();
    const account = accounts[0];

    let resp: any;
    const tokenRequest = {
        scopes: ["https://graph.microsoft.com/.default"],
        account: account,
        authority: AUTHORITY
    }

    try {
        resp = await pca.acquireTokenSilent(tokenRequest);
    } catch (error: any) {
        // acquireTokenSilent failed
        if (error.name === "InteractionRequiredAuthError") {
            resp = await pca.acquireTokenRedirect(tokenRequest);
        }
    }

    return resp?.accessToken;
}

//Returns true when the user has the role to one of the resources passed
export const hasRole = (resources: string[], desiredRole: ROLES, resourceReadiness: boolean = true) => {
    const anchorRolesAssigned = sessionStorage.getItem('anchorRolesAssigned');
    if (!anchorRolesAssigned) {
        return false;
    }
    if (!ROLES.OWNER && !resourceReadiness) {
        //TODO: in future global owner would not have same permission as productowner. We would not need this check.
        return false;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];

    if (!roles.length) {
        if (desiredRole === ROLES.OWNER && hasGlobalOwnerRole()) {
            //TODO: in future global owner would not have same permission as productowner. REMOVE hasGlobalOwnerRole CHECK
            return true;
        }
        return false;
    }

    if (resources.length > 0) {
        return resources.some(resource => {
            let resourceParsed = EnvConfig.RBAC_ENV + resource.replace("&", "n").replace(/[^a-zA-Z0-9]/g, " ").toLowerCase();
            return roles.some((role: string) => {
                switch (desiredRole) {
                    case ROLES.CONTRIBUTOR:
                    case ROLES.OWNER:
                        //TODO: in future global owner would not have same permission as productowner. REMOVE hasGlobalOwnerRole CHECK
                        return hasGlobalOwnerRole() || role === (resourceParsed + ":" + desiredRole);
                    case ROLES.APPROVER:
                    case ROLES.REVIEWER:
                        return role === (resourceParsed + " a:" + resourceParsed + " a " + desiredRole);
                    default:
                        return false;
                }
            });
        });
    } else {
        return roles.some((role: string) => {
            switch (desiredRole) {
                case ROLES.OWNER:
                    //TODO: in future global owner would not have same permission as productowner. REMOVE hasGlobalOwnerRole CHECK
                    return hasGlobalOwnerRole() || (role.includes(desiredRole) && !role.includes("oipc"));
                case ROLES.CONTRIBUTOR:
                case ROLES.APPROVER:
                case ROLES.REVIEWER:
                    return role.includes(desiredRole);
                default:
                    return false;
            }
        });
    }

}



export const hasFeatureRole = (resources: string[], desiredRole: ROLES, resourceReadiness: boolean = true) => {
    const anchorRolesAssigned = window.sessionStorage.getItem('anchorRolesAssigned');
    if (!anchorRolesAssigned) {
        return false;
    }
    if (!ROLES.OWNER && !resourceReadiness) {
        //TODO: in future global owner would not have same permission as productowner. We would not need this check.
        return false;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];

    if (resources.length > 0) {
        return resources.some(resource => {
            let resourceParsed = EnvConfig.RBAC_ENV + resource.replace("&", "n").replace(/[^a-zA-Z0-9]/g, " ").toLowerCase();
            return roles.some((role: string) => {
                switch (desiredRole) {
                    case ROLES.CONTRIBUTOR:
                    case ROLES.OWNER:
                        //TODO: in future global owner would not have same permission as productowner. REMOVE hasGlobalOwnerRole CHECK
                        return role === (resourceParsed + ":" + desiredRole) && !hasGlobalOwnerRole();
                    case ROLES.APPROVER:
                    case ROLES.REVIEWER:
                        return role === (resourceParsed + " a:" + resourceParsed + " a " + desiredRole);
                    default:
                        return false;
                }
            });
        });
    } else {
        return roles.some((role: string) => {
            switch (desiredRole) {
                case ROLES.OWNER:
                    //TODO: in future global owner would not have same permission as productowner. REMOVE hasGlobalOwnerRole CHECK
                    return role.includes(desiredRole) && !hasGlobalOwnerRole();
                case ROLES.CONTRIBUTOR:
                case ROLES.APPROVER:
                case ROLES.REVIEWER:
                    return role.includes(desiredRole);
                default:
                    return false;
            }
        });
    }
}



export const getOwnedResources = () => {
    const anchorRolesAssigned = sessionStorage.getItem('anchorRolesAssigned');
    if (!anchorRolesAssigned) {
        return [];
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];

    return roles.filter(role => role.includes("owner")).map(role => role.split(":")[0].replace(EnvConfig.RBAC_ENV, ""));
}



//Returns the family if the BPO is from that particular family
export const userHasFamily = (family: Family) => {
    let resourceParsed = EnvConfig.RBAC_ENV + family.code.replace("&", "n").replace(/[^a-zA-Z0-9]/g, " ").toLowerCase();
    let anchorRolesAssigned = sessionStorage.getItem("anchorRolesAssigned");
    if (!anchorRolesAssigned) {
        return false;
    }
    if (hasGlobalOwnerRole()) {
        // this is because global owner should be able to create and access every family
        return true;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];
    return roles.some((role: string) => {
        let resourceAndAccess = role.split(":");
        return (resourceAndAccess[0] === resourceParsed) && (resourceAndAccess[1] === ROLES.OWNER);
    });
}

//Returns true when the user is a global owner
export const hasGlobalOwnerRole = () => {
    let anchorSubscriptions = window.sessionStorage.getItem("anchorSubscriptions");
    if (!anchorSubscriptions) {
        return false;
    }
    let subscriptions = JSON.parse(anchorSubscriptions) as AnchorSubscriptionDefinition[];
    return subscriptions.length > 0 && subscriptions.some((subscription: AnchorSubscriptionDefinition) => {
        return (subscription.subId === EnvConfig.SUBSCRIPTION_ID) && (subscription.roles === ROLES.GLOBAL_OWNER);
    });
}


//Owner of any family or any specific product
export const hasAnyRoleForAnyProductOrFamily = () => {
    let anchorRolesAssigned = window.sessionStorage.getItem("anchorRolesAssigned");
    if (!anchorRolesAssigned) {
        return false;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];
    return roles.some((role: string) => {
        let roleValue = role.split(":")[0];
        if (!roleValue) {
            // for administrator of Anchor
            return false;
        }
        switch (EnvConfig.ENVIRONMENT.toLowerCase()) {
            case "prod":
            case "spt":
                return roleValue.startsWith("f") || roleValue.startsWith("s") ? false : true;
            case "localhost":
                return roleValue.startsWith("local f") || roleValue.startsWith("local s") ? false : true;
            default:
                return roleValue.startsWith(EnvConfig.ENVIRONMENT.toLowerCase() + " f") || roleValue.startsWith(EnvConfig.ENVIRONMENT.toLowerCase() + " s") ? false : true;
        }

    });
}
export const hasAnyRoleForAnySolution = () => {
    let anchorRolesAssigned = window.sessionStorage.getItem("anchorRolesAssigned");
    if (!anchorRolesAssigned) {
        return false;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];
    return roles.some((role: string) => {
        let roleValue = role.split(":")[0];
        if (!roleValue) {
            // for administrator of Anchor
            return false;
        }
        switch (EnvConfig.ENVIRONMENT.toLowerCase()) {
            case "prod":
            case "spt":
                return roleValue.startsWith("s") ? true : false;
            case "localhost":
                return roleValue.startsWith("local s") ? true : false;
            default:
                return roleValue.startsWith(EnvConfig.ENVIRONMENT.toLowerCase() + " s") ? true : false;
        }

    });
}


//Owner of any family or any specific product
export const hasOwnerRoleForAnyProductOrFamily = () => {
    let anchorRolesAssigned = window.sessionStorage.getItem("anchorRolesAssigned");
    if (!anchorRolesAssigned) {
        return false;
    }
    let roles = JSON.parse(anchorRolesAssigned) as string[];
    return roles.some((role: string) => {
        let roleValue = role.split(":")[1];
        if (!roleValue) {
            // for administrator of Anchor
            return false;
        }
        switch (EnvConfig.ENVIRONMENT.toLowerCase()) {
            case "prod":
                return roleValue.startsWith("f") ? false : roleValue.includes(ROLES.OWNER);
            case "localhost":
                return roleValue.startsWith("local f") ? false : roleValue.includes(ROLES.OWNER);
            default:
                return roleValue.startsWith(EnvConfig.ENVIRONMENT.toLowerCase() + " f") ? false : roleValue.includes(ROLES.OWNER);
        }

    });
}

export const useAnchor = (path: string = "", skip: boolean = false) => {
    const cachedToken = sessionStorage.getItem('anchorToken');
    const [token, setToken] = useState(cachedToken ? cachedToken : "");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    const filterRoles = (roles: any) => {
        if (EnvConfig.ENVIRONMENT !== "SPT") {
            return JSON.stringify(roles.filter((role: string) => role.includes(EnvConfig.RBAC_ENV)));
        }
        return JSON.stringify(roles.filter((role: string) => !["local ", "dev ", "sit "].some(element => role.includes(element))));
    }

    const mapRoles = (resources: any) => {
        const roleArray: string[] = [];
        resources.forEach((resource: any) => {
            const name = resource.resName.toString().split("/")[0];
            resource.roles.forEach((role: any) => {
                roleArray.push(name + ":" + role.name)
            })
        })
        return roleArray;
    }

    const fetchToken = async () => {
        try {

            getToken().then(
                async azureToken => {
                    const response = await fetch(EnvConfig.USERS_ROLE_RETRIEVAL_BASE_URL + "/api/security/token/validate/anchor-rbac", {
                        headers: {
                            "Authorization": "Bearer " + azureToken
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch roles');
                    }

                    const data = await response.json();
                    // let jwtPayload = parseJwt(data.anchorToken);
                    // sessionStorage.setItem('anchorToken', data.anchorToken);
                    if(data.email){
                        sessionStorage.setItem('anchorEmail', data.email);
                    }
                    if(data.resource){
                        sessionStorage.setItem('anchorRolesAssigned', filterRoles(mapRoles(data.resource)));
                    }
                    if(data.subscriptions){
                        sessionStorage.setItem('anchorSubscriptions', JSON.stringify(data.subscriptions));
                    }
                    setToken(data.anchorToken);
                    setIsLoading(false);

                }
            )

        } catch (error) {
            setError(error);
        }


    };

    useEffect(() => {
        const cachedToken = sessionStorage.getItem('anchorToken');

        if (cachedToken) {
            setToken(cachedToken);
            setIsLoading(false);
        } else if (!skip) {
            fetchToken();
        }
    }, []);

    const checkTokenValidity = () => {
        if (!token) {
            // Token not available
            return false;
        }

        const iat = sessionStorage.getItem('anchorTokenIat');
        if (!iat) {
            return false;
        }
        if (parseInt(iat) < (Date.now() - 300000) / 1000) {
            sessionStorage.removeItem('anchorToken');
            return false;
        } else {
            return true;
        }

    };

    const refreshAnchorToken = () => {
        fetchToken();
    }

    useEffect(() => {
        if (!checkTokenValidity() && !skip) {
            fetchToken();
        }
    }, [token, skip, path]);

    return { token, isLoading, error, refreshAnchorToken };
}
