import React, { FC } from 'react';
import styles from './ProductCardMedium.module.scss';
import { ProductListing, ProductType, Status } from '../../data/model/DataModels';
import { truncate } from 'lodash';
import { Button } from 'primereact/button';
import { resolveFamiliesIconFn } from '../ProductFamiliesView/ProductFamilies';
import { ReactComponent as NonCompatibleIcon } from '../../assets/icons/maersk/mi-exclamation-octagon-solid-24px.svg';

export interface ProductCardMediumProps {
  product: ProductListing;
  onProductClick?: Function;
  deleteProduct?: Function;
  upgradeOrPreviewProduct?: Function;
  editMode?: boolean;
}

const ProductCardMedium: FC<ProductCardMediumProps> = ({ product, deleteProduct, upgradeOrPreviewProduct, editMode = true, onProductClick }) => {
  const getCardStyle = () => {
    if (product.status === Status.RETIRED) {
      return styles.retiredCard;
    }
    if (product.latestActiveVersion > product.version) {
      return styles.upgradeCard;
    }
    if (product.isCompatible === false) {
      return styles.nonCompatibleCard;
    }
  }

  const deleteIconRule = () => {
    return editMode && product.productType === ProductType.PRODUCT
  }

  return (

    <div className={`${styles.ProductCardMedium} ${getCardStyle()}`} onClick={() => onProductClick && onProductClick()} data-testid="ProductCardMedium">
      {product.latestActiveVersion > product.version && <div className={styles.statusLabel} data-testid={`newVersionBanner`}>New Version</div>}
      {product.status === Status.RETIRED && <div className={`${styles.statusLabel} ${styles.retiredStatusLabel}`}>Retired</div>}
      <div className={styles.cardContent}>

        <div className={styles.familyAndNonCompatibleIcon}>
          <div className={styles.productFamily}>{resolveFamiliesIconFn(product.family?.code || '')} {product.family?.name}</div>
          {(product.isCompatible === false && product.status !== Status.RETIRED) && <div className={styles.nonCompatibleIcon}><NonCompatibleIcon /></div>}
        </div>

        <div className={styles.title}>{product.name}</div>

        {
          product.description != "" &&
          <div className={`${styles.description}`}>
            {truncate(product.description, { length: 100, omission: "..." })}
          </div>
        }

        <div className={styles.cardFooter}>
          {product.productType === ProductType.ADDON && <div className={styles.addonType}>Add-on</div>}
          <div className={styles.productVersionAndDeleteIcon}>
            <span>v{product.version} - {product.status} {product.latestActiveVersion > product.version! ?
              <span className={styles.editSection} onClick={(e) => {
                e.stopPropagation()
                upgradeOrPreviewProduct && upgradeOrPreviewProduct()
              }}>
                {editMode ? "Upgrade to v" : "Preview v"} {product.latestActiveVersion}
              </span> : ""}
            </span>
            {deleteIconRule() && deleteProduct &&
              <span className={styles.deleteIcon}>
                <Button icon="pi pi-trash" className="p-button-outlined p-button-secondary" onClick={(e) => { e.stopPropagation(); deleteProduct() }} />
              </span>
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default ProductCardMedium;
