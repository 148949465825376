import React, { useEffect, useState } from "react";
import styles from "./DisplayCardsComponent.module.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useAppDispatch } from "../../app/hooks";
import { confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { resolveFamiliesIconFn } from "../../features/ProductFamiliesView/ProductFamilies";




export interface DisplayCardElement {
	id?: string;
	name: string;
	code: string;
	family?: { name?: string, code?: string };
	description?: string;
	tooltipElements?: string[];
	specParamElements?: string[],
	isDisabled?: boolean,
	disabledTooltip?: string;
	duplicateElement?: boolean;
	latestVersionFeatures?: string[];
}

interface DisplayCardsComponentProps {
	elementList: DisplayCardElement[];
	deletedElementList?: DisplayCardElement[];
	editMode: boolean;
	dialogView: boolean;
	action: Function
	activeVersionElements?: DisplayCardElement[];
	deleteAction?: Function;
	revertDeletedFn?: Function;
	selectedCodes?: string[];
}

const DisplayCardsComponent: React.FC<DisplayCardsComponentProps> = ({ elementList, dialogView, editMode, activeVersionElements, action, deleteAction, selectedCodes: selectedCodes = [], deletedElementList = [], revertDeletedFn }) => {
	const [featuresInTooltip, setFeaturesInTooltip] = useState<string[]>([]);
	const dispatch = useAppDispatch();
	const [searchText, setSearchText] = useState("");
	const [allComponents, setAllComponents] = useState(elementList);

	useEffect(() => {
		if (searchText !== '') {
			setAllComponents(elementList.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase())));
		}
		else {
			setAllComponents(elementList);
		}
	}, [searchText, elementList]);



	const isElementAdded = (element: DisplayCardElement): boolean => {
		return !activeVersionElements?.find(activeVersionElement => activeVersionElement.code === element.code);
	}

	interface TooltipFeaturesProps {
		tooltipElements: string[]
		isFeatures: boolean
	}

	const TooltipFeatures: React.FC<TooltipFeaturesProps> = ({ tooltipElements, isFeatures }) => {
		return (
			<div onMouseEnter={() => setFeaturesInTooltip(tooltipElements)} className={"p-mt-auto " + styles.footer}>
				<span>{tooltipElements.length + `${isFeatures ? " feature" : " specification parameter"}` + (tooltipElements.length > 1 ? "s" : "")}</span>
				<i className={"pi pi-info-circle " + (tooltipElements.length > 0 ? "tooltip " : "")} />
			</div>
		);
	}

	const TooltipComponent = () => {
		return (
			<Tooltip target=".tooltip" className="general-tooltip" autoHide={false} position="bottom">
				<div className="p-d-flex p-flex-column">
					<ul className={styles.tooltipText}>
						{featuresInTooltip.map(feature => <li key={feature}>{feature}</li>)}
					</ul>
				</div>
			</Tooltip>
		);
	}

	const confirmDelete = (event: any, elementCode: string) => {
		confirmPopup({
			target: event.currentTarget,
			message: 'Are you sure you want to proceed?',
			icon: 'pi pi-exclamation-triangle',
			accept: () => dispatch(deleteAction!(elementCode)),
			reject: () => null
		});
	}

	return (
		<div className={styles.DisplayCardsComponent}>
			<div className={styles.searchField}>
				<span className={`p-input-icon-left ${styles.saerchBar}`}>
					<i className="pi pi-search" />
					<InputText
						className={styles.inputField}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={`${"Search"}`}
						data-testid="searchComponentFilter"
						value={searchText}
					/>
				</span>
			</div>
			<div className={styles.componentGrid}>
				{allComponents?.map((element, index) => {
					return (<div key={index}>
						<div className={`${styles.component} ${(!dialogView && isElementAdded(element)) ? "added" : ""}`}>
							<div className={`${element.isDisabled ? "disabledTooltip" : ""}`}>
								<Card className={`${styles.card} ${dialogView && selectedCodes.includes(element.code) && styles.card_selected} ${element.duplicateElement ? styles.disabledBlueCard : element.isDisabled ? styles.disabledCard : styles.enabledCards}`}>
									{!dialogView && editMode &&
										<span className={styles.trash_button_position}>
											<Button icon="pi pi-trash" className="p-button-outlined" onClick={(e) => confirmDelete(e, element.code)} />
										</span>
									}
									<div data-testid={`displayCardComp${index}`} className={"p-d-flex p-flex-column " + styles.cardContent} onClick={() => action(element)}>
										{element.family && <div className={styles.productFamily}>{resolveFamiliesIconFn(element?.family?.code || '')} {element?.family?.name}</div>}
										<div className={" " + styles.title}><span data-testid={`displayCardCompName${index}`}>{element.name}</span></div>
										{
											element.description != "" &&
											<div className={styles.description}>
												{element.description}
											</div>
										}
										<div className={styles.cardFooter}>
											{element.tooltipElements && <TooltipFeatures tooltipElements={element.tooltipElements} isFeatures={true}></TooltipFeatures>}
											{element.specParamElements && <TooltipFeatures tooltipElements={element.specParamElements} isFeatures={false}></TooltipFeatures>}
											{element.duplicateElement && <div className={styles.duplicateElementLabel}>Already Added</div>}
										</div>
									</div>
								</Card>
							</div>
							<Tooltip target=".disabledTooltip" className="tooltip-no-shadow" autoHide={false} position="bottom">
								{element.disabledTooltip}
							</Tooltip>

						</div>

					</div>);
				}
				)}
				<TooltipComponent></TooltipComponent>
				{deletedElementList.map((deletedElement, index) => {
					return (<div key={index}>
						<div className={`${styles.component} " deleted`}>
							<Card className={`${styles.card} ${deletedElement.isDisabled ? styles.disabledCard : styles.enabledCards}`}>
								<span className={styles.trash_button_position}>
									<Button icon="pi pi-undo" className="p-button-outlined" onClick={(e) => revertDeletedFn!(deletedElement)} />
								</span>
								<div className={"p-d-flex p-flex-column " + styles.cardContent}>
									<div className={" " + styles.title}><span>{deletedElement.name}</span></div>
									{
										deletedElement.description != "" &&
										<div className={styles.description}>
											{deletedElement.description}
										</div>
									}
									{deletedElement.tooltipElements && <TooltipFeatures tooltipElements={deletedElement.tooltipElements} isFeatures={true}></TooltipFeatures>}
								</div>
							</Card>
						</div>
						{deletedElement.tooltipElements && <TooltipComponent></TooltipComponent>}
					</div>);
				}
				)}
			</div>
		</div>
	)
}

export default DisplayCardsComponent;
