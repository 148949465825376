import {useMsal} from '@azure/msal-react';
import React, {FC} from 'react';
import FamiliesView from '../FamiliesView/FamiliesView.lazy';
import styles from './HomeView.module.scss';

interface HomeViewComponentProps { }

const HomeView: FC<HomeViewComponentProps> = () => {
  const { accounts } = useMsal();


  return (
    <div className={styles.HomeViewComponent} data-testid="HomeViewComponent">
      <div className={styles.welcome}>
        <div className={styles.user}><h1>Product Families</h1></div>
      </div>
      <div className={styles.homeViewContent}>
        <FamiliesView />

      </div>

    </div>
  )
};

export default HomeView;
