import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Tooltip } from 'primereact/tooltip';
import React, { FC, useState } from 'react';
import { sanitizeHTML } from '../RichTextCustomHeader/RichTextCustomHeaderComponent';
import styles from './DisplayBigCards.module.scss';

export interface DisplayCardElement {
  name: string;
  code: string;
  description?: string;
  descriptionRichText?: string;
  tooltipElements?: string[];
  isDisabled?: boolean,
  disabledTooltip?: string;
  latestVersionFeatures?: string[];
}

export interface DisplayBigCardsProps {
  elementList: DisplayCardElement[];
  editMode: boolean;
  dialogView?: boolean;
  action: Function
  activeVersionElements?: DisplayCardElement[];
  deleteAction?: Function;
  isElementSelected?: Function;
  isAuthorisedToEdit: boolean;
}

const DisplayBigCards: FC<DisplayBigCardsProps> = ({ elementList, dialogView = false, editMode, activeVersionElements, action, deleteAction, isElementSelected, isAuthorisedToEdit = false }) => {

  const [featuresInTooltip, setFeaturesInTooltip] = useState<string[]>([]);

  const isElementAdded = (element: DisplayCardElement): boolean => {
    return !activeVersionElements?.find(activeVersionElement => activeVersionElement.code === element.code);
  }

  interface TooltipFeaturesProps {
    tooltipElements: string[]
  }

  const TooltipFeatures: React.FC<TooltipFeaturesProps> = ({ tooltipElements }) => {
    return (
      <div onMouseEnter={() => setFeaturesInTooltip(tooltipElements)} className={styles.featuresTooltip}>
        <span>{tooltipElements.length + " feature" + (tooltipElements.length > 1 ? "s" : "")}</span>
        <i className={"pi pi-info-circle " + (tooltipElements.length > 0 ? "tooltip " : "")} />
      </div>
    );
  }

  const TooltipComponent = () => {
    return (
      <Tooltip target=".tooltip" className="general-tooltip" autoHide={false} position="bottom">
        <div className="p-d-flex p-flex-column">
          <ul className={styles.tooltipText}>
            {featuresInTooltip.map(feature => <li key={feature}>{feature}</li>)}
          </ul>
        </div>
      </Tooltip>
    );
  }

  const confirmDelete = (event: any, elementCode: string) => {
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteAction!(elementCode),
      reject: () => null
    });
  }

  const showDescription = (element: DisplayCardElement) => {
    if (element.descriptionRichText) {
      return <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(element.descriptionRichText.toString()) }} className={styles.displayDescription} />
    }
    return <>{element.descriptionRichText}</>
  }

  return (
    <div className={`${styles.DisplayBigCards} ` + styles.componentGrid} data-testid="displayComponentBigCards">
      {elementList.map((element, index) => {
        return (<div key={index}>
          <div className={`${element.isDisabled ? "disabledTooltip" : ""}`}>
            <div data-testid={`componentCard${index}`} onClick={() => action(element)} className={`${styles.componentCard} ${(!dialogView && isElementAdded(element)) ? styles.addedHighlight : ""} ${styles.card} ${dialogView && isElementSelected!(element) && styles.card_selected} ${element.isDisabled ? styles.disabledCard : styles.enabledCards}`}>
              <div className={styles.title}>
                <span data-testid={`componentCardName${index}`}>{element.name}</span>
              </div>
              {
                element.description != "" &&
                <div className={`${styles.description} ${styles.truncateOverflow}`}>
                  {showDescription(element)}
                </div>
              }
              <div className={styles.botomElements}>
                {element.tooltipElements && <TooltipFeatures tooltipElements={element.tooltipElements}></TooltipFeatures>}
                {(element.latestVersionFeatures !== undefined && element.latestVersionFeatures.length > 0) &&
                  <div className={styles.featureUpgradeLabel}>
                    <div>{element.latestVersionFeatures.length == 1 ? "New feature available" : element.latestVersionFeatures.length + " upgrades"}</div>
                  </div>
                }
              </div>
              <div>
                {
                  !dialogView && editMode && isAuthorisedToEdit &&
                  <span className={styles.actionButtons} >
                    <Button data-testid={`componentCardDelete${index}`} icon="pi pi-trash" label='Remove' className="p-button-outlined p-button-secondary p-button-sm" onClick={(e) => confirmDelete(e, element.code)} />
                  </span>
                }
              </div>
            </div>
          </div>
          <Tooltip target=".disabledTooltip" className="tooltip-no-shadow" autoHide={false} position="bottom">
            {element.disabledTooltip}
          </Tooltip>

          {element.tooltipElements && <TooltipComponent></TooltipComponent>}
        </div>);
      }
      )}
      {/* to do: this will be done as part of new release. We need to work of displaying excluded components, features and specification parameters */}
      {/* {deletedElementList.map((deletedElement, index) => {
        return (
          <div key={index}>
            <div className={`${styles.componentCard} ${styles.deletedHighlight} ${dialogView && isElementSelected!(deletedElement) ? styles.card_selected : ""}`}>
              <div className={`${styles.title} ${styles.disabledCard}`}>
                <span>{deletedElement.name}</span>
              </div>
              {
                deletedElement.description != "" &&
                <div className={`${styles.description} ${styles.disabledCard}`}>
                  {deletedElement.description}
                </div>
              }
              <div className={styles.botomElements}>
                <div className={`${styles.disabledCard}`}>
                  {deletedElement.tooltipElements && <TooltipFeatures tooltipElements={deletedElement.tooltipElements}></TooltipFeatures>}

                </div>
                {
                  !dialogView && editMode &&
                  <span className={styles.actionButtons}>
                    <Button icon="pi pi-undo" label='Undo Delete' className="p-button-outlined p-button-secondary p-button-sm" onClick={()=>revertDeletedFn && revertDeletedFn(deletedElement)}/>
                  </span>
                }
              </div>
            </div>
          </div>
        );
      }
      )} */}
    </div>
  )
}

export default DisplayBigCards;
