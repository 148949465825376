import { ProductFamilies } from "./features/ProductFamiliesView/ProductFamilies";
import "./App.scss";
import { useLocation, useRoutes } from "react-router-dom";
import ProductView from "./features/ProductView/ProductView.lazy";
import React, { createContext, useEffect, useState } from "react";
import HelpView from "./features/HelpView/HelpView";
import ComponentsView from "./features/ComponentsView/ComponentsView.lazy";
import ROUTE from "./Paths";
import FeaturesViewComponent from "./features/FeaturesView/FeaturesView.lazy";
import ActivitiesView from "./features/ActivitiesView/ActivitiesView.lazy";
import ReleaseView from "./features/ReleaseView/ReleaseView";
import HomeView from "./features/HomeView/HomeView";
import AppLayoutView from "./features/AppLayoutView/AppLayoutView";
import FeatureDetail from "./features/FeatureDetail/FeatureDetail.lazy";
import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { Sidetab } from '@anchor-feedback/react';
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import SpinnerComponent from "./components/Spinner/SpinnerComponent";
import NotAuthenticatedView from "./components/ErrorHandler/NotAuthenticated/NotAuthenticatedView";
import { cloneDeep } from "lodash";
import { clarity } from 'react-microsoft-clarity';
import WrapperCreateOrEditSpecificationParameter
    from "./features/FeatureDetail/WrapperCreateSpecificationParameter/WrapperCreateOrEditSpecificationParameter";
import ComponentDetail from "./features/ComponentDetail/ComponentDetail.lazy";
import RoleBasedRouteGuard from "./features/RoleBasedRouteGuard/RoleBasedRouteGuard";
import UsersView from "./features/UsersView/UsersView.lazy";
import { useAnchor } from "./authorisationService";
import { SolutionsView } from "./features/SolutionsView/SolutionsView";
import SolutionDetails from "./features/SolutionDetails/SolutionDetails";
import ResourceAllVersions from "./features/SolutionDetails/ResourceAllVersions.lazy";
import { EnvConfig } from "./EnvConfig";


export interface BreadCrumb {
    label: string,
    url: string
}

export const BreadCrumbContext = createContext({ data: [] as BreadCrumb[], setData: new Function() });

const App: React.FC = (): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect);
    const { instance, accounts, inProgress } = useMsal();
    const { pathname } = useLocation();
    const { token, isLoading } = useAnchor(pathname, !isAuthenticated);

    const [crumbs, setCrumbs] = useState([] as BreadCrumb[]);

    useEffect(() => {
        clarity.init(EnvConfig.MS_CLARITY_ID);
    },[])

    useEffect(() => {
        const currentIndex: number = crumbs.map(crumb => crumb.url).indexOf(pathname);
        if (currentIndex !== -1) {
            let crumbsCopy = cloneDeep(crumbs);
            setCrumbs(crumbsCopy.slice(0, currentIndex + 1));
        }

    }, [pathname]);

    const componentRoutes = {
        path: ROUTE.COMPONENTS,
        children: [
            {
                index: true,
                element: <ComponentsView />
            },
            {
                path: ROUTE.COMPONENTS_BY_CODE,
                element: <ComponentDetail />
            },
        ]
    }

    const featureRoutes = {
        path: ROUTE.FEATURES,
        children: [
            {
                index: true,
                element: <FeaturesViewComponent />
            },
            {
                path: ROUTE.FEATURES_BY_CODE_VERSION,
                // path: ROUTE.FEATURES_BY_CODE,
                children: [
                    {
                        index: true,
                        element: <FeatureDetail />
                    },
                    {
                        path: ROUTE.CREATE_SPEC_PARAM,
                        element: <RoleBasedRouteGuard globalOwner={true} productOwner={true}><WrapperCreateOrEditSpecificationParameter /></RoleBasedRouteGuard>
                    },
                    {
                        path: ROUTE.EDIT_SPEC_PARAM,
                        element: <RoleBasedRouteGuard globalOwner={true} productOwner={true}><WrapperCreateOrEditSpecificationParameter /></RoleBasedRouteGuard>
                    }
                ]
            },

        ]
    };
    const productRoutes = {
        path: ROUTE.PRODUCTS,
        children: [
            {
                index: true,
                element: <ProductFamilies />,
            },
            {
                path: ROUTE.PRODUCT_BY_CODE_VERSION_ALL,
                children: [
                    {
                        index: true,
                        element: <ResourceAllVersions />,
                    }
                ]
            },
            {
                path: ROUTE.PRODUCT_BY_CODE,
                children: [
                    {
                        index: true,
                        element: <ProductView />,
                    },
                    {
                        path: ROUTE.PRODUCT_VERSION,
                        element: <ProductView />
                    }
                ]
            },


        ]
    };
    const solutionRoutes = {
        path: ROUTE.SOLUTIONS,
        children: [
            {
                index: true,
                element: <SolutionsView />,
            },
            {
                path: ROUTE.SOLUTION_BY_CODE_VERSION_ALL,
                children: [
                    {
                        index: true,
                        element: <ResourceAllVersions />,
                    }
                ]
            },
            {
                path: ROUTE.SOLUTION_BY_CODE_VERSION,
                children: [
                    {
                        index: true,
                        element: <SolutionDetails />,
                    }
                ]
            },
        ]
    };
    const mainRoutes = {
        path: ROUTE.DEFAULTROUTE,
        element: <AppLayoutView />,
        children: [
            {
                path: ROUTE.HOME,
                element: <HomeView />,
            },
            productRoutes,
            componentRoutes,
            featureRoutes,
            solutionRoutes,
            {
                path: ROUTE.ACTIVITIES,
                element: <RoleBasedRouteGuard globalOwner={true} productOwner={true}><ActivitiesView /></RoleBasedRouteGuard>
            },
            {
                path: ROUTE.HELP,
                element: <HelpView />
            },
            {
                path: ROUTE.RELEASES,
                element: <ReleaseView />
            },
            {
                path: ROUTE.USERS,
                element: <RoleBasedRouteGuard globalOwner={true} productOwner={false}><UsersView /></RoleBasedRouteGuard>
            },

        ],
    };

    const routing = useRoutes([mainRoutes]);

    if (isLoading) {
        return <SpinnerComponent />
    }

    return <>
        {
            isAuthenticated &&
            <>
                <BreadCrumbContext.Provider value={{ data: crumbs, setData: setCrumbs }}>
                    {routing}
                </BreadCrumbContext.Provider>
                <Sidetab id={EnvConfig["FEEDBACK_FORM_ID"]}
                    buttonText="Open Feedback"
                    email={accounts[0].username}
                    isDev={EnvConfig["ENVIRONMENT"] === "PROD" ? false: true}
                />
            </>
        }
        {inProgress !== InteractionStatus.None && <SpinnerComponent></SpinnerComponent>}
        {!isAuthenticated && inProgress === InteractionStatus.None && <NotAuthenticatedView></NotAuthenticatedView>}
    </>;
};

export default App;
