import React, { FC } from "react";
import styles from "./SpinnerComponent.module.scss";

const SpinnerComponent: FC = () => {
	return (
		<div className={styles.overlay}>
			<div className={styles.SpinnerComponent} data-testid="SpinnerComponent">
				<div>
					<span className={styles.spin}></span>
				</div>
			</div>
		</div>
	)
};

export default SpinnerComponent;