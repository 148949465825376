import { AnchorUserAssignment } from './../model/DataModels';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	AnchorResource,
	UserDefinition
} from "../model/DataModels";
import { EnvConfig } from "../../EnvConfig";
import { getToken } from "../../authorisationService";

export const SUBSCRIPTION_ID = "3db62258-fb72-4cc2-925f-dcc3c993761e";

interface UserRolesResponse {
	total_count: number,
	user_group: AnchorUserAssignment[];
}

export const UsersApi = createApi({
	reducerPath: "UsersApi",
	baseQuery: fetchBaseQuery({
		baseUrl: EnvConfig.USERS_BASE_URL,
		prepareHeaders: (headers) => {
			return getToken().then(token => {
				headers.set("Authorization", `Bearer ${token}`);
				return headers;
			});
		},
	}),
	tagTypes: [
	],
	endpoints: (builder) => ({

		//auth
		arbacAuth: builder.query<UserDefinition, void>({
			query: () => 'arbac/auth',
		}),
		getSubscriptionOwners: builder.query<AnchorUserAssignment[], void>({
			query: () => `/subscription-role/${EnvConfig.SUBSCRIPTION_ID}`,
			transformResponse:(response:{total:number, users:AnchorUserAssignment[]}): AnchorUserAssignment[] =>{
				return response.users.filter(user => user.active && user.scope_type === "subscription");
			}
		})
	}),
});

export const {
	useArbacAuthQuery,useGetSubscriptionOwnersQuery
} = UsersApi;


