import React, { FunctionComponent, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
	clearProduct,
	NewProductDTO,
	setProductContracting,
	setProductFamily,
	setProductName,
	setProductTagline,
	setProductType,
	setServiceModel
} from "../../data/slices/newProductSlice";
import { InputText } from "primereact/inputtext";
import styles from "./CreateProductDialog.module.scss";
import { Dropdown } from "primereact/dropdown";
import { Family, getContractingText, Optionality, ProductInfo, ProductType, productTypeFilterList, serviceModelTypeDropDownList } from "../../data/model/DataModels";
import { FieldIdentifiers, formValidation } from "../../utils/validationUtil";
import { userHasFamily } from "../../authorisationService";
import { ReactComponent as Flow } from '../../assets/icons/maersk/flow.svg';
import { ReactComponent as Component } from '../../assets/icons/maersk/component.svg';

const fakeFamily: Family = {
	code: "",
	name: "",
	portfolio: "Ocean",
	displayOrder: 0,
	description: ""
}
interface CreateProductProps {
	dialogVisible: boolean
	setDialogVisibleFn: Function
	createProductFn: (product: ProductInfo) => any
	families: Family[]
}

const CreateProductDialog: FunctionComponent<CreateProductProps> = ({ dialogVisible, setDialogVisibleFn, createProductFn, families }) => {
	const dispatch = useAppDispatch();
	const productDTO: NewProductDTO = useAppSelector((state) => state.newProduct);
	let invalidFields: string[] = [];
	const [isFormValid, setIsFormValid] = useState(true);
	const familiesUser = families.filter(family => userHasFamily(family));
	const [type, setType] = useState('' as ProductType);

	useEffect(() => {
		setIsFormValid(invalidFields.length == 0 && checkRequiredFieldValidation());
	})


	const convertToProductInfo = (): ProductInfo => {
		return {
			id: 0,
			lockingVersion: 0,
			code: "ignored",
			version: 1,
			name: productDTO.name,
			status: "draft",
			contracting: productDTO.contracting as Optionality,
			family: families.find(family => family.code === productDTO.family)!,
			approvalFlow: false,
			productType: type,
			header: {
				description: productDTO.tagline,
				descriptionRichText: productDTO.tagline,
				keyContacts: "",
				restriction: "",
				limitation: "",
				dependency: "",
				customerCommitments: ""
			},
			headersInfo: {
				serviceModel: productDTO.headersInfo.serviceModel ? productDTO.headersInfo.serviceModel : [],
				carrier: [],
				incoterm: [],
				contractValidity: [],
				// packagingType: [],
				attractiveness: [],
				customerType: [],
				vertical: [],
				valueProposition: [],
				loadType: [],
				direction: []
			},
			salesInfo: {
				tagline: productDTO.tagline,
				customerNeed: productDTO.customerNeed,
			}
		}
	}
	
	const submitDialog = () => {
		// build and pass product info
		createProductFn(convertToProductInfo());
		closeDialog();
	}

	const closeDialog = () => {
		//TODO: add a confirmation on discarding all changes??
		setDialogVisibleFn(false);
		dispatch(clearProduct());
	}
	const footer = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => { closeDialog() }} />
			<Button disabled={!isFormValid} label={"Create"} icon="pi pi-check" onClick={() => submitDialog()}
				tooltip={!isFormValid ? "Please enter required/valid product information" : ""} tooltipOptions={{ showOnDisabled: true }} />
		</div>
	);

	const checkFormValidity = (value: string, fieldIdentifier: FieldIdentifiers, fieldName: string) => {
		const validityResult = formValidation(value, fieldIdentifier);
		if (validityResult !== "") {
			if (!invalidFields.includes(fieldName)) {
				invalidFields = [...invalidFields, fieldName];
			}
		} else {
			if (invalidFields.length > 0) {
				invalidFields = invalidFields.filter(field => field !== fieldName);
			}

		}
		return validityResult;
	}

	const checkRequiredFieldValidation = () => {
		if (
			productDTO.productType.length > 0 &&
			(productDTO.headersInfo?.serviceModel && productDTO.headersInfo?.serviceModel?.length > 0) &&
			productDTO.name?.length > 0 &&
			productDTO.family?.length > 0 &&
			productDTO.contracting?.length > 0
		) {
			return true;
		}
		else{
			return false;
		}

	}

	const setSingleFamily = (code: string) => {
		dispatch(setProductFamily(code));
		return true;
	}

	return (
		<Dialog visible={dialogVisible} onHide={() => { closeDialog() }}
			header={"Add new product"} footer={footer}
			className={styles.createProductDialog} data-testid="addNewProductDialog">
			<div className={styles.section_block} >
				<div className={styles.input_group}>
					<div className={styles.label}>Product Type<label style={{ color: "red" }}>*</label></div>
					<div className={styles.type}>
						{ productTypeFilterList.map(prodType => {
							return 	<div className={`${styles.type_box} ${type===prodType.type ? styles.selected : ''}`} 
												onClick={() => {setType(prodType.type);dispatch(setProductType(prodType.type));}} key={prodType.type} data-testid={`productType-${prodType.type}`}>
												{ prodType.type === 'addon' ? <Component/> : <Flow/> }
												<div><b>{prodType.name}</b></div>
												<div className={styles.type_desc}>{prodType.description}</div>
											</div>
							}) 
						}
					</div>
				</div>
				<div className={styles.input_group}>
					<div className={styles.label}>Name<label style={{ color: "red" }}>*</label></div>
					<div className={styles.valueField}>
						<InputText className={checkFormValidity(productDTO.name, FieldIdentifiers.PRODUCT_TITLE, "productTitle") === "" ? "" : "p-invalid"} 
							value={productDTO.name} onChange={(e) => dispatch(setProductName(e.target.value.trimStart()))} 
							placeholder="Enter name"/>
						{checkFormValidity(productDTO.name, FieldIdentifiers.PRODUCT_TITLE, "productTitle")}
					</div>
				</div>
				<div className={styles.input_group}>
					<div className={styles.label}>Family<label style={{ color: "red" }}>*</label></div>
					<div className={styles.valueField}>
						<Dropdown className={styles.dropdown}
							optionLabel="name" 
							placeholder={familiesUser.length > 1 ? "Select a family" : familiesUser[0]?.name}
							disabled={familiesUser.length > 1 ? false : setSingleFamily(familiesUser[0]?.code)}
							options={familiesUser.map(family => ({ name: family.name, code: family.code }))}
							value={familiesUser.some(family => family.code === productDTO.family)
								? {
									name: familiesUser.find(family => family.code === productDTO.family)!.name,
									code: familiesUser.find(family => family.code === productDTO.family)!.code
								}
								: { name: fakeFamily.name, code: fakeFamily.code }
							}
							onChange={(e) => dispatch(setProductFamily(e.target.value.code))} />
					</div>
				</div>
				<div className={styles.input_group}>
					<div className={styles.label}>Type<label style={{ color: "red" }}>*</label></div>
					<div className={styles.valueField}>
						<Dropdown className={styles.dropdown}
							optionLabel="name" placeholder="Select a type"
							options={[
								{ name: getContractingText(Optionality.mandatory), code: "mandatory" },
								{ name: getContractingText(Optionality.restricted), code: "restricted" },
								{ name: getContractingText(Optionality.optional), code: "optional" }
							]}
							value={productDTO.contracting === Optionality.mandatory ? { name: getContractingText(Optionality.mandatory), code: "mandatory" }
								: productDTO.contracting === Optionality.restricted ? { name: getContractingText(Optionality.restricted), code: "restricted" }
									: productDTO.contracting === Optionality.optional ? { name: getContractingText(Optionality.optional), code: "optional" }
										: { name: "", code: "" }
							}
							onChange={(e) => dispatch(setProductContracting(e.target.value.code))} />
					</div>
				</div>
				<div className={styles.input_group}>
					<div className={styles.label}>Service Model<label style={{ color: "red" }}>*</label></div>
					<div className={styles.valueField}>
						<Dropdown className={styles.dropdown}
							optionLabel="name" placeholder="Select a service model"
							options={serviceModelTypeDropDownList}
							value={productDTO?.headersInfo.serviceModel && productDTO?.headersInfo.serviceModel.length > 0 ?
								productDTO?.headersInfo.serviceModel[0].code === serviceModelTypeDropDownList[0].code ? serviceModelTypeDropDownList[0]
								: productDTO?.headersInfo.serviceModel[0].code === serviceModelTypeDropDownList[1].code  ? serviceModelTypeDropDownList[1]
									: productDTO?.headersInfo.serviceModel[0].code === serviceModelTypeDropDownList[2].code ? serviceModelTypeDropDownList[2]
										: { name: "", code: "" } : { name: "", code: "" }
							}
							onChange={(e) => dispatch(setServiceModel(e.target.value))
							} />
					</div>
				</div>
				<div className={styles.input_group}>
					<div className={styles.label}>Short Description</div>
					<div className={styles.valueField}>
						<InputText className={checkFormValidity(productDTO.tagline, FieldIdentifiers.PRODUCT_TAGLINE, "productTagline") === "" ? "" : "p-invalid"} 
							value={productDTO.tagline} onChange={(e) => dispatch(setProductTagline(e.target.value))} placeholder="Enter description"/>
						{checkFormValidity(productDTO.tagline, FieldIdentifiers.PRODUCT_TAGLINE, "productTagline")}
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default CreateProductDialog;
