import { Button } from 'primereact/button';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasGlobalOwnerRole, hasRole } from '../../authorisationService';
import {
  FeatureDefinition,
  FAMILIES_CODES,
  ROLES,
  SpecificationParameterDefinition,
} from '../../data/model/DataModels';
import PATHS from '../../Paths';
import { sanitizeHTML } from '../RichTextCustomHeader/RichTextCustomHeaderComponent';
import styles from './FeatureCardIncremental.module.scss';
import SpecificationList from './SpecificationList/SpecificationList';
import FeatureAsServiceDefaultOptions from '../FeatureAsService/FeatureAsServiceDefaultOptions/FeatureServiceDefaultOptions';

export const ServiceDefaultLabel = {
  included: "Core Service",
  not_included: "Optional Service",
  none: "Non Service",
} as any;

export interface FeatureCardIncrementalProps {
  feature: FeatureDefinition;
  specificationParameterAssociations: SpecificationParameterDefinition[];
  deleteFeature?: Function;
  deleteSpecification?: Function;
  addSpecificationParameter?: Function;
  saveFeature?: Function;
  isConfigurable?: boolean;
  showExcludedOptionsInViewMode?: boolean;
  usedIn?: "product" | "definition" | "featureupgradedialog" | "solution";
  isNewVersionAvailable?: Function;
  saveFeatureOptionality?: Function;
  setFeatureInEditMode: Function;
  featureInEditMode: boolean;
  saveSpecificationParameters?: Function,
  onFeatureEditCancel?: Function,
  setSelectedFeatureForUpgrade?: Function
}

const FeatureCardIncremental: FC<FeatureCardIncrementalProps> = ({ feature, specificationParameterAssociations, deleteFeature, deleteSpecification, addSpecificationParameter, saveFeature, isConfigurable = false, showExcludedOptionsInViewMode = false, usedIn = "solution", isNewVersionAvailable, saveFeatureOptionality, featureInEditMode, setFeatureInEditMode, saveSpecificationParameters, onFeatureEditCancel, setSelectedFeatureForUpgrade }) => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const disableDeleteFeatureButton = feature?.associatedProducts?.length > 0 && usedIn == "definition";
  const isGlobalOwner = hasGlobalOwnerRole()
  const isFamilyOwner = hasRole(Object.values(FAMILIES_CODES), ROLES.OWNER)
  const isSolutionOwner = hasRole(['solution'], ROLES.OWNER)

  const onClickOfSave = () => {
    saveFeature && saveFeature().then(() => setEditMode(false));
    setFeatureInEditMode(false);
  }

  const onClickOfCancel = () => {
    onFeatureEditCancel && onFeatureEditCancel();
    setEditMode(false); //To be pushed to parent
  };

  const isAnyOptionalFeatureWithCoreSPTooltip = () => {
    if (!feature.serviceDefault) {
      return { disabled: false, text: undefined };
    }

    const includedSP = specificationParameterAssociations.find(
      (sp) => sp.featureRef === feature.code && sp.serviceDefault === "included"
    );

    if (feature.serviceDefault === "not_included" && includedSP) {
      return { disabled: true, text: "An optional feature can have only optional specification parameters." };
    } else if (feature.serviceDefault === "included" && !includedSP) {
      return { disabled: true, text: "A core feature must have at least one core specification parameter" };
    }

    return { disabled: false, text: undefined };
  };

  return (
    <div className={styles.FeatureCardIncremental} data-testid="FeatureCardIncremental">
      <div className={styles.header}>
        <h2 data-testid="FeatureName">
          {feature.name}
        </h2>
        <div className={styles.featureVersion}>v{feature.version} - {feature.status}</div>
        {
          (isGlobalOwner || isFamilyOwner || isSolutionOwner) && (usedIn !== "featureupgradedialog") &&
          <i className='pi pi-arrow-up-right' onClick={() => navigate(PATHS.FEATURES_BY_CODE_VERSION.replace(":featureCode", feature.code).replace(":version", feature.version!.toString()))} data-testid="featureCodeClick" />
        }
        {isNewVersionAvailable && isNewVersionAvailable(feature) && (
          <>
            <div className={styles.featureVersionAvailable}>
              New version available
            </div>
            {!editMode && isConfigurable ? (
              <Button
                className={styles.previewBtn}
                onClick={() => setSelectedFeatureForUpgrade && setSelectedFeatureForUpgrade()}
              >
                Preview to upgrade
              </Button>
            ) : (
              <Button
                className={styles.previewBtn}
                onClick={() => setSelectedFeatureForUpgrade && setSelectedFeatureForUpgrade()}
              >
                Preview
              </Button>
            )}
          </>
        )}
        {
          ((usedIn === "definition" && feature.status === 'draft') || isConfigurable) &&
          <div className={styles.actionButton}>
            {
              !editMode ?
                <div>
                  <Button icon="pi pi-pencil" className={`p-button-outlined p-button-secondary`} tooltip={featureInEditMode ? "There are unsaved changes. Please, cancel or save before editing this feature." : ""} tooltipOptions={{ position: 'left', showOnDisabled: true }}
                    onClick={() => { setEditMode(true); setFeatureInEditMode(true); }} data-testid="EditFeatureBtn" disabled={featureInEditMode} />
                </div>
                : (
                  <>
                    <Button className={`p-button-outlined p-button-primary`}
                      label="Cancel"
                      onClick={() => { onClickOfCancel(); setFeatureInEditMode(false); }} data-testid="cancelButton" />
                    <div>
                      <Button className={`p-button-outlined p-button-primary`} disabled={(isAnyOptionalFeatureWithCoreSPTooltip().disabled)} label="Save" onClick={onClickOfSave}
                        tooltip={isAnyOptionalFeatureWithCoreSPTooltip().text} tooltipOptions={{ position: 'left', showOnDisabled: true }} data-testid="featureSave" />
                    </div>
                  </>
                )
            }
            {!editMode &&
              <div>
                <Button icon="pi pi-trash" className={`p-button-outlined p-button-secondary`} disabled={disableDeleteFeatureButton || featureInEditMode}
                  tooltip={featureInEditMode ? "There are unsaved changes. Please, cancel or save before editing this feature." : disableDeleteFeatureButton ? "Feature is associated with one or more products" : ""}
                  tooltipOptions={{ showOnDisabled: true, position: 'left' }}
                  onClick={() => deleteFeature && deleteFeature(feature)} data-testid="DeleteFeatureBtn" />
              </div>}

          </div>
        }

      </div>

      <div className={styles.descriptionAndoptionality}>
        <div className={styles.featureAssociationDescription}>
          <div className={styles.descriptionAndUsedIn}>
            <div className={styles.featureDescription}>
              <div data-testid="FeatureDesc" dangerouslySetInnerHTML={{ __html: sanitizeHTML(feature.descriptionRichText?.toString()) }} className={styles.displayDescription} />
            </div>
          </div>
        </div>
        <div
          className={styles.featureCardOptionality}
          data-testid="featureAsServiceDefaultOptions"
        >
          {
            (editMode && (usedIn === "solution") &&
              isConfigurable) ? (
              <div>
                <div className={styles.featureCardRightpart}>
                  {feature.service == true ? (
                    <FeatureAsServiceDefaultOptions
                      serviceDefaultFromParent={feature.serviceDefault}
                      saveServiceDefault={(featureServiceValue: "included" | "not_included" | "none", isChangedFromCoreToOptional: boolean) => saveFeatureOptionality && saveFeatureOptionality({ ...feature, saveFeatureOptionality: featureServiceValue }, isChangedFromCoreToOptional)}
                    ></FeatureAsServiceDefaultOptions>
                  ) : (
                    <div className={styles.featureTypeValueLabel}>
                      {ServiceDefaultLabel[feature.serviceDefault]}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {!editMode &&
                  ServiceDefaultLabel[feature.serviceDefault] && (
                    <div className={styles.serviceDefaultAndDollar}>
                      <div className={`${styles.labelBox} 
                      ${feature.serviceDefault === "included" ? styles.labelBoxCore : feature.serviceDefault === "not_included" ? styles.labelBoxOptional : styles.labelBoxNonService}`}>
                        <div className={styles.label}>
                          {ServiceDefaultLabel[feature.serviceDefault]}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
      <SpecificationList
        specificationParameters={specificationParameterAssociations || []}
        saveSpecificationParameters={saveSpecificationParameters}
        feature={feature}
        deleteSpecificationParam={deleteSpecification}
        addSpecificationParameter={addSpecificationParameter}
        isConfigurable={editMode}
        showExcludedOptionsInViewMode={showExcludedOptionsInViewMode}
        usedIn={usedIn === "featureupgradedialog" || usedIn === "solution" ? "product" : usedIn}
      />
    </div>
  );
}
export default FeatureCardIncremental;
