import React, { FC } from 'react';
import { hasGlobalOwnerRole, hasRole } from '../../authorisationService';
import NotAuthenticatedView from '../../components/ErrorHandler/NotAuthenticated/NotAuthenticatedView';
import { ROLES } from '../../data/model/DataModels';
import styles from './RoleBasedRouteGuard.module.scss';

export interface RoleBasedRouteGuardProps {
  globalOwner?: boolean,
  productOwner?: boolean
}

const RoleBasedRouteGuard: FC<RoleBasedRouteGuardProps & { children?: React.ReactNode; }> = ({ children, globalOwner, productOwner }) => {
  const isGlobalOwner = hasGlobalOwnerRole();
  const hasProductOwnerRole = hasRole([], ROLES.OWNER);
  return (
    <div className={styles.RoleBasedRouteGuard} data-testid="RoleBasedRouteGuard">
      {
        ((globalOwner && isGlobalOwner) || (productOwner && hasProductOwnerRole)) ?
          children
          :
          <NotAuthenticatedView />
      }
    </div>
  );
}

export default RoleBasedRouteGuard;
