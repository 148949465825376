import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    UniqueIdentifier
} from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { FC, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom";
import { SortableTreeItem } from "./SortableTreeItem";
import { FlattenedItem, TreeItem } from "./types";
import { buildTree, getProjection } from "./utilities";

interface SortableTreeProps {
    treeItems: TreeItem[];
    returnFlattenedItems?: Function;
}


export const SortableTree: FC<SortableTreeProps> = ({ treeItems, returnFlattenedItems }) => {
    const [items, setItems] = useState<TreeItem[]>(treeItems);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [flattenedItems, setFlattenedItems] = useState<FlattenedItem[]>([]);


    useEffect(() => {
        setItems(treeItems);
    }, [treeItems]);

    useEffect(() => {
        returnFlattenedItems && returnFlattenedItems(flattenedItems);
    }, [flattenedItems]);


    const indentationWidth = 30;

    const removeChildrenOf = (
        items: FlattenedItem[],
        ids: UniqueIdentifier[]
    ) => {
        const excludeParentIds = [...ids];
        return items.filter((item) => {
            if (item.parentId && excludeParentIds.includes(item.parentId)) {
                if (item.children.length) {
                    excludeParentIds.push(item.id);
                }
                return false;
            }

            return true;
        });
    }

    const flatten = (items: TreeItem[], parentId: UniqueIdentifier | null = null, depth = 0): FlattenedItem[] => {
        return items?.reduce<FlattenedItem[]>((acc, item, index) => {
            if (Object.keys(item).includes("children")) {
                return [
                    ...acc,
                    { ...item, parentId, depth, index },
                    ...flatten(item.children, item.id, depth + 1),
                ];
            }
            else {
                return [
                    ...acc,
                    { ...item, parentId, depth, index },
                    // ...flatten(item.children, item.id, depth + 1),
                ];
            }

        }, []);
    }

    useMemo(() => {
        const flattenedTree = flatten(items);
        const collapsedItems = flattenedTree.reduce<string[]>(
            (acc, { children, collapsed, id }) =>
                collapsed && children.length ? [...acc, id as string] : acc,
            []
        );
        let parentIds: UniqueIdentifier[] = flattenedTree.filter(({ type }) => type == "parent").map(element => element.id);
        if (activeId ? parentIds.includes(activeId) : false) {
            setFlattenedItems(removeChildrenOf(flattenedTree, activeId ? [...parentIds] : collapsedItems));
        }
        else {
            setFlattenedItems(removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems));
        }
    }, [items, activeId])

    const sortedIds = useMemo(() => flattenedItems?.map((flattenedItem: FlattenedItem) => flattenedItem?.id), [flattenedItems]);

    const projected: any =
        activeId && overId
            ? getProjection(
                flattenedItems,
                activeId,
                overId,
                offsetLeft,
                indentationWidth
            )
            : null;

    const activeItem = activeId
        ? flattenedItems.find((flattenedItem: FlattenedItem) => flattenedItem?.id === activeId)
        : null;

    const handleDragMove = ({ delta }: DragMoveEvent) => {
        setOffsetLeft(delta.x);
    }

    const handleDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
        setActiveId(activeId);
        setOverId(activeId);
        const activeItem = flattenedItems.find((flattenedItem: FlattenedItem) => flattenedItem?.id === activeId);
        document.body.style.setProperty('cursor', 'grabbing');
    }

    const handleDragOver = ({ over }: DragOverEvent) => {
        setOverId(over?.id ?? null);
    }

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        resetState();
        if (projected && over) {
            const { depth, parentId } = projected;
            const clonedItems: FlattenedItem[] = JSON.parse(
                JSON.stringify(flatten(items))
            );
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
            const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);
            setItems(newItems);
        }
    }

    const handleDragCancel = () => {
        resetState();
    }

    const resetState = () => {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);

        document.body.style.setProperty('cursor', '');
    }

    return (
        <>
            {
                items.length > 0 ? (
                    <div>
                        <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart}
                            onDragMove={handleDragMove}
                            onDragOver={handleDragOver}
                            onDragEnd={handleDragEnd}
                            onDragCancel={handleDragCancel}
                            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                        >
                            <SortableContext items={sortedIds} strategy={verticalListSortingStrategy} >
                                {flattenedItems.map((item: FlattenedItem) => (
                                    <SortableTreeItem
                                        key={item.id}
                                        id={item.id}
                                        value={item.name}
                                        type={item.type}
                                        activeItemDetails={item}
                                        depth={item.id === activeId && projected ? projected.depth : item.depth}
                                        indentationWidth={indentationWidth}
                                        indicator={false}
                                        collapsed={Boolean(item.collapsed && item.children.length)}
                                        elementStyle={{ border: item.id === activeId ? "2px dashed grey" : "" }}
                                    />
                                ))}
                                {createPortal(
                                    <DragOverlay>
                                        {activeId && activeItem ? (
                                            <SortableTreeItem
                                                id={activeId}
                                                depth={activeItem.depth}
                                                type={activeItem.type}
                                                activeItemDetails={activeItem}
                                                clone
                                                value={activeItem.name}
                                                indentationWidth={indentationWidth}
                                                style={{ width: "50vw", border: "2px solid #0CA0CE", height: "3.5rem" }}
                                                elementStyle={{}}
                                            />
                                        ) : null}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </DndContext>
                    </div>
                ) : (<p>No Features added yet</p>)
            }
        </>
    )

}
