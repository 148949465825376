import { FC, useContext, useEffect, useState } from 'react';
import styles from './ProductCardView.module.scss';
import { SolutionContext } from '../../features/SolutionDetails/SolutionDetails';
import { Button } from 'primereact/button';
import { PackagedSolutionProductRelationType, ProductListing, ProductType, Status } from '../../data/model/DataModels';
import PATHS from '../../Paths';
import { resolveFamiliesIconFn } from '../../features/ProductFamiliesView/ProductFamilies';
import { cloneDeep, truncate } from 'lodash';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { Dropdown } from 'primereact/dropdown';

interface ProductCardViewProps {
   isEditable?: boolean;
   isSolutionOwner?: boolean;
   removeProduct: Function;
   previewUpgradeProduct?: Function;
   updateProduct: Function;
}

const ProductCardView: FC<ProductCardViewProps> = ({ isEditable = false, isSolutionOwner = false, removeProduct, previewUpgradeProduct, updateProduct }) => {
   const solutionState = useContext(SolutionContext);
   const solution = solutionState.solutionData;
   const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
   const [selectedOptions, setSelectedOptions] = useState<{ [associatedProductCode: string]: { relationType: string } }>({});

   if (!solution) {
      return <></>
   }
   
   useEffect(() => {
      solutionState.solutionData?.products.forEach(product => {
        setSelectedOptions(prevState => ({
          ...prevState,
          [product.code]: {relationType : product.relationType}
        }));
      });
    }, [solutionState.solutionData]);

   const goToProduct = (product: ProductListing) => {
      let url = PATHS.PRODUCT_BY_CODE.replace(":code", product.code);
      window.open(url);
   }

   const selectedTypeTemplate = (option: any) => {
      return (
         <div className={styles.customDropdownItem}>
            <span className={styles.optionName} >{option.name}</span>
            <div className={styles.optionDescription}>{option.description}</div>
         </div>
      );
   };

   const getRelationTypeNameFromCode = (relationTypeCode: string) => {
      return PackagedSolutionProductRelationType.filter(relationTypeObj => relationTypeObj.code === relationTypeCode).map(matchedObj => matchedObj.name);
   };

   const handleDropdownChange = (relationType: string, associatedProductCode: any) => {
      setSelectedOptions(prevState => ({
        ...prevState,
        [associatedProductCode]: {relationType : relationType},
      }));
      updateProduct(relationType, associatedProductCode);
    };

   return (
      <div className={styles.productCardView} data-testid="productCardView">
         {isLoadingUpdateSolution && <SpinnerComponent />}
         <div className={styles.productGrid}>
            {cloneDeep(solution?.products).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())).map((element, index) => {
               return (
                  <div key={index} data-testid="productCard">
                     <div className={styles.product} data-testid={` productCard${index + 1} `}>
                        {element.latestActiveVersion > element.version! && <div className={styles.statusLabel} data-testid={` newVersionBanner${index + 1}`}>New Version</div>}
                        {element.status === Status.RETIRED && <div className={styles.retiredStatusLabel} data-testid={` newVersionBanner${index + 1}`}>Retired</div>}
                        <div className={true ? `${styles.card} ${styles.upgradeCard}` : element.status === Status.RETIRED ? `${styles.card} ${styles.retiredCard}` : `${styles.card}`} data-testid={` listingCard${index + 1}`}>
                           {/* <div className={element.latestActiveVersion > element.version! ? `${styles.card} ${styles.upgradeCard}` : element.status === Status.RETIRED ? `${styles.card} ${styles.retiredCard}` : `${styles.card}`} data-testid={` listingCard${index + 1}`}> */}
                           <div className={styles.cardContent} onClick={() => goToProduct(element)}>
                              <div className={styles.productFamily}>{resolveFamiliesIconFn(element?.family?.code || '')} {element?.family?.name}</div>
                              <div className={`${styles.title}`}>{element.name}</div>
                              {
                                 element.description != "" &&
                                 <div className={`${styles.description}`}>
                                    {truncate(element.description, { length: 100, omission: "..." })}
                                 </div>
                              }

                           </div>
                           <div className={styles.cardFooter}>
                              {element.productType === ProductType.ADDON && <div className={styles.addonType}>Add-on</div>}
                              <div className={styles.productVersionAndDeleteIcon}>
                                 <span>v{element.version} - {element.status} {element.latestActiveVersion > element.version! ?
                                    // <span>v{element.version} - {element.status} {true ?
                                    <span className={styles.productUpgrade} onClick={(e) => previewUpgradeProduct && previewUpgradeProduct(e, element)} data-testid={` previewOrUpgradeLink${index + 1}`}>
                                       {isSolutionOwner && isEditable ? "Upgrade to v" : "Preview v"} {element.latestActiveVersion}
                                    </span> : ""}
                                 </span>
                                 {isEditable &&
                                    <span className={styles.deleteIcon}>
                                       <Button icon="pi pi-trash" className="p-button-outlined p-button-secondary" onClick={(e) => removeProduct(e, element)} aria-label={`Remove${index + 1}`} />
                                    </span>
                                 }
                              </div>
                           </div>
                           <div className={styles.selectProductType}>
                              {isEditable ?
                                 <Dropdown
                                    className={styles.relationType}
                                    value={selectedOptions[element.code]?.relationType || element.relationType}
                                    options={PackagedSolutionProductRelationType}
                                    onChange={(e) => handleDropdownChange(e.value, element.code)}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="Select Product Type"
                                    itemTemplate={selectedTypeTemplate}
                                    data-testid="ProductRelation"
                                    scrollHeight="18rem"
                                 />
                                 :
                                 <span className={styles.relationTypeViewTag}>
                                    <span className={`${styles.relationTypeViewText} ${element.relationType === 'CORE' ? `${styles.relationTypeViewTextCore}` : `${styles.relationTypeViewTextOptional}`}`}>
                                       {getRelationTypeNameFromCode(element.relationType)}
                                    </span>
                                 </span>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               );
            }
            )}
         </div>
      </div>
   )
};

export default ProductCardView;
