import { Tooltip } from 'primereact/tooltip';
import React from 'react';

const TruncatedText = ({ text, maxLength }: any) => {

  return (
    <div data-testid='truncatedText'>
      <Tooltip className='general-tooltip' target=".truncatedTextTooltip"></Tooltip>
      {
        text != undefined &&
        <div>
          {text.slice(0, maxLength)}
          {(text.length > maxLength) && <span className='truncatedTextTooltip' data-pr-tooltip={text.split(",").join(", " + "\n")} data-pr-position="bottom" style={{ cursor: "pointer" }} >...</span>}
        </div>
      }

    </div>
  );
};

export default TruncatedText;