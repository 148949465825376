import React, { lazy, Suspense } from "react";

const LazyComponentsViewComponent = lazy(() => import("./ComponentsView"));

const ComponentsView = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
	<Suspense fallback={null}>
		<LazyComponentsViewComponent {...props} />
	</Suspense>
);

export default ComponentsView;
