import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styles from './AddOnRule.module.scss';
import { CompatibilityRule, CompatibilityRuleType, DimensionType, Family, FamilyDimensionAssociation, HEADER_NAMES, HeaderTypes, ProductHeaders, Rule, Scope } from '../../data/model/DataModels';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import RuleComponent from '../RuleComponent/RuleComponent';
import { uuidv4 } from '../../components/utils/utils';
import { useGetDimensionTypesQuery, useLazyGetListTypeDimensionDataQuery } from '../../data/api/RefDataApi';
import { useGetSelectedDefaultDimensionsQuery, useLazyGetAllPopUpValuesQuery } from '../../data/api/CatalogueApi';
import { EnvConfig } from '../../EnvConfig';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { confirmDialog } from 'primereact/confirmdialog';

export interface AddOnRuleProps {
  compatibilityRule: CompatibilityRule;
  editMode: boolean;
  updateCompatibilityRule: Function;
  defaultCompatibilityRuleType: CompatibilityRuleType;
  setDefaultCompatibilityRuleType: Function;
  productApplicability: Scope[];
  productHeaders: ProductHeaders;
  productFamily: Family;
  addOrEditARule: boolean;
  setAddOrEditARule: Function;
}

const AddOnRule: FC<AddOnRuleProps> = ({ compatibilityRule, editMode, updateCompatibilityRule, defaultCompatibilityRuleType, setDefaultCompatibilityRuleType, productApplicability, productHeaders, productFamily, addOrEditARule ,setAddOrEditARule }) => {
  const { data: defaultDimensionsFamilyData = [] as FamilyDimensionAssociation[],isLoading: isLoadingDefaultDimensions } = useGetSelectedDefaultDimensionsQuery(productFamily.code, { skip: productFamily.code === "" });
  const { data: allDimensionsData, error: errorAllDimensionsData, isLoading: isLoadingAllDimensionsData } = useGetDimensionTypesQuery();
  const [localCompatibilitiyRule, setLocalCompatibilitiyRule] = useState(compatibilityRule);
  const toaster = useContext(ToasterContext);
  
  useEffect(() => {
    setLocalCompatibilitiyRule(compatibilityRule);
  }, [compatibilityRule]);

  if(errorAllDimensionsData){
    toaster.showToast('error', 'Failed to fetch dimension data');
  }

  const getDimensionName = (code: string) => {
    const dimensionDefinition = allDimensionsData?.find(dimension => dimension.code == code);
    if (dimensionDefinition) {
      return dimensionDefinition.name;
    }
    return '';
  }

  const findDimensionType = (value: string): DimensionType => {
    for (const [key, val] of Object.entries(DimensionType)) {
      if (val === value) {
        return DimensionType[key as keyof typeof DimensionType];
      }
    }
    throw new Error("Value not found in DimensionType");
  }

  const getHeaderName = (headerCode: string): string => {
    const matchedHeader = Array.from(HEADER_NAMES).find(headerItem => headerItem.code === headerCode)
    if (matchedHeader !== undefined)
      return matchedHeader.name;
    else {
      return '';
    }
  }

  const getAvailableDimensions: { name: string, code: DimensionType }[] = useMemo(() => {
    let availableDimensionTypes = new Set<string>();
    if (productApplicability.length > 0) {
      productApplicability?.forEach(applicability => {
        applicability.dimensions.forEach(dimension =>
          availableDimensionTypes.add(dimension.type))
      })
    }
    else {
      if (defaultDimensionsFamilyData.length > 0) { // If default dimension on addon family is set
        defaultDimensionsFamilyData?.forEach(defaultDimension => availableDimensionTypes.add(defaultDimension.dimensionCode));
      }
      else { // If no default dimension on addon family is set
        allDimensionsData?.forEach(dimension => availableDimensionTypes.add(dimension.code));
      }
    }
    return Array.from(availableDimensionTypes)
      .filter(dimension => !(dimension === DimensionType.ExportCountry || dimension === DimensionType.ImportCountry || dimension === DimensionType.LocationsCountry))
      .map(dimension => {
        return { name: getDimensionName(dimension), code: findDimensionType(dimension) }
      })
  }, [productApplicability, isLoadingDefaultDimensions, isLoadingAllDimensionsData]);

  const getAvailableHeaders: { name: string, code: HeaderTypes }[] = useMemo(() => {
    return Object.entries(productHeaders)
      .filter(([key, value]) => (value !== null && value.length > 0))
      .map(([key]) => {
        return { name: getHeaderName(key), code: key as HeaderTypes }
      });
  }, [productHeaders]);


  const getCompatibilityRuleTypeName = (ruleType: CompatibilityRuleType) => {
    if (ruleType === CompatibilityRuleType.MANDATORY) {
      return "Mandatory";
    }
    else if (ruleType === CompatibilityRuleType.OPT_IN) {
      return "OPT IN";
    }
    else {
      return "OPT OUT"
    }
  }

  const addRule = () => {
    const newRule = {
      name: '',
      codeUI: `RU-${uuidv4()}`,
      criteria: [
        {
          dimension: getAvailableDimensions[0].code,
          values: []
        }
      ]
    }
    let copyObject: CompatibilityRule = JSON.parse(JSON.stringify(localCompatibilitiyRule));
    copyObject.rules = [...copyObject.rules, newRule];
    setLocalCompatibilitiyRule(copyObject);
    setAddOrEditARule(true);
  }

  const deleteRule = (ruleToBeDeleted: Rule) => {
    // TODO First to be check based on code if code does not exist then based on name
    let copyObject: CompatibilityRule = JSON.parse(JSON.stringify(localCompatibilitiyRule));
    const updatedRules = copyObject.rules.filter(rule => (ruleToBeDeleted?.code ? (rule.code !== ruleToBeDeleted.code) : (rule.codeUI !== ruleToBeDeleted.codeUI)));
    copyObject.rules = updatedRules;
    setLocalCompatibilitiyRule(copyObject);
    updateCompatibilityRule(copyObject);
  }

  const updateDefaultCompatibilityRule = () => {
    const updatedCompatibilityRule = {...localCompatibilitiyRule, rules : [] };
    setDefaultCompatibilityRuleType(compatibilityRule.ruleType);
    setLocalCompatibilitiyRule(updatedCompatibilityRule);
    updateCompatibilityRule(updatedCompatibilityRule);
  }

  const confirmRuleDeletionWithDefaultChange = () => {
    confirmDialog({
      message: `This action would delete all the rules in this compatibility rule. Do you want to proceed?`,
      header: 'Change add-on default compatibility rule?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => updateDefaultCompatibilityRule()
    });
  }

  const saveRule = (ruleToSave: Rule) => {
    const updatedRules = localCompatibilitiyRule?.rules?.map(rule => {
      if ((rule.code && ruleToSave.code && rule.code === ruleToSave.code) || (rule.codeUI && ruleToSave.codeUI && rule.codeUI === ruleToSave.codeUI)) {
        return ruleToSave;
      }
      else {
        return rule;
      }
    })
    updateCompatibilityRule({ ...localCompatibilitiyRule, rules: updatedRules });
  }

  return (
    <div className={styles.AddOnRule} data-testid="AddOnRule">
      {
        (isLoadingDefaultDimensions || isLoadingAllDimensionsData) &&
        <SpinnerComponent/>
      }
      <div className={styles.title}>
        <div className={`${styles.ruleTitleTag} ${compatibilityRule.ruleType === CompatibilityRuleType.MANDATORY ? styles.mandatory : compatibilityRule.ruleType === CompatibilityRuleType.OPT_IN ? styles.optIn : styles.optOut}`}>
          {getCompatibilityRuleTypeName(compatibilityRule.ruleType)}: {defaultCompatibilityRuleType === compatibilityRule.ruleType ? "default" : compatibilityRule.rules.length!==0 ? compatibilityRule.rules.length + " rules" : "never"}
        </div>
        <div className={styles.defaultSwitch}>
          <span>Default</span>
          <InputSwitch disabled={!editMode || addOrEditARule} checked={defaultCompatibilityRuleType === compatibilityRule.ruleType || false}
            onChange={() => (defaultCompatibilityRuleType !== localCompatibilitiyRule.ruleType && localCompatibilitiyRule.rules.length > 0) ? confirmRuleDeletionWithDefaultChange() : setDefaultCompatibilityRuleType(compatibilityRule.ruleType) } />
        </div>
        {
          EnvConfig.TOGGLES["ENABLE_COMPATIBILITY_ADD_RULE"] && (editMode && !addOrEditARule && defaultCompatibilityRuleType !== compatibilityRule.ruleType) &&
          <Button label='Add Rule' className='p-button-outlined p-button-secondary' icon='pi pi-plus' onClick={() => addRule()} />
        }
      </div>
      <div className={styles.main}>
        {
          EnvConfig.TOGGLES["ENABLE_COMPATIBILITY_ADD_RULE"] && localCompatibilitiyRule?.rules?.map(rule => <RuleComponent key={rule.code} rule={rule} compatibilityRuleType={compatibilityRule.ruleType} availableDimensions={getAvailableDimensions} availableHeaders={getAvailableHeaders} 
            saveRule={(updatedRule: Rule) => saveRule(updatedRule)} deleteRule={(ruleToBeDeleted: Rule) => deleteRule(ruleToBeDeleted)} editMode={editMode} addOrEditARule={addOrEditARule} setAddOrEditARule={setAddOrEditARule}/>)
        }
        {
          defaultCompatibilityRuleType !== compatibilityRule.ruleType &&
          compatibilityRule?.rules?.length === 0 &&
          <i>Never offered as {getCompatibilityRuleTypeName(compatibilityRule.ruleType)} add-on</i>
        }
      </div>
    </div>
  );
}

export default AddOnRule;
