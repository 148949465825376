import React, { FC, useContext, useMemo, useState } from 'react';
import {
  FeatureDefinition,
  SortSpecificationParameterRequest,
  SpecificationParameterDefinition,
  SpecificationParameterType
} from '../../../data/model/DataModels';
import styles from './SpecificationList.module.scss';
import { Button } from 'primereact/button';
import { cloneDeep } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import { SortableTree } from '../../SortableTree/SortableTree';
import { FlattenedItem, TreeItem } from '../../SortableTree/types';
import { useSortFeatureSpecificationParamsMutation } from '../../../data/api/CatalogueApi';
import { ToasterContext } from '../../../features/AppLayoutView/AppLayoutView';
import ShowSpecification from "../ShowSpecification/ShowSpecification";

export interface SpecificationListProps {
  specificationParameters: SpecificationParameterDefinition[],
  feature?: FeatureDefinition,
  deleteSpecificationParam?: Function,
  createOrEditSpecificationParameter?: Function,
  addSpecificationParameter?: Function,
  saveSpecificationParameters?: Function,
  isConfigurable?: boolean,
  showExcludedOptionsInViewMode?: boolean,
  usedIn?: "product" | "definition" | "featureupgradedialog" | "featureCompare",
  isLinkedFeatureInDraft?: boolean,
  isAuthorisedToEdit: boolean,
  sourceComponent?: string,
  isFeatureSidePanel?: boolean
}

const SpecificationList: FC<SpecificationListProps> = (
  {
    specificationParameters,
    feature,
    deleteSpecificationParam,
    createOrEditSpecificationParameter,
    addSpecificationParameter,
    isConfigurable = false,
    saveSpecificationParameters,
    showExcludedOptionsInViewMode = false,
    usedIn = "definition",
    isLinkedFeatureInDraft = false,
    isAuthorisedToEdit = false,
    sourceComponent,
    isFeatureSidePanel = false
  }
) => {
  const [isSPReordering, setIsSPReordering] = useState(false);
  const [treeItems, setTreeItems] = useState<TreeItem[]>([]);
  const [flatennedItems, setFlatennedItems] = useState<FlattenedItem[]>([]);
  const [sortSpecificationParams, { isLoading: isLoadingSortSpecificationParams }] = useSortFeatureSpecificationParamsMutation();
  const toaster = useContext(ToasterContext);

  const specParamsToTreeItems = () => {
    if (feature) {
      const processSpecParams: TreeItem[] = feature.specificationParameters?.map(specParam => {
        return {
          id: specParam.code,
          name: specParam.name,
          children: [],
          type: "parent",
          isFeatureGroup: false,
          description: specParam.description,
          specification: specParam.configuration,
          code: specParam.code,
          disableEdit: specParam.associatedProducts !== null && specParam.associatedProducts?.length > 0,
          featureVersion: specParam.featureVersion,
          serviceDefault: specParam.serviceDefault,
        } as TreeItem;
      });
      setTreeItems(processSpecParams);
    } else {
      setTreeItems([]);
    }
  }

  useMemo(() => {
    specParamsToTreeItems();
  }, [feature?.specificationParameters]);

  const saveSpecification = (specificationParam: SpecificationParameterDefinition) => {
    let specificationParametersCopy = cloneDeep(specificationParameters);
    let updatingParam = specificationParametersCopy.find(element => element.code === specificationParam.code);
    let specificationOld = specificationParameters.find(element => element.code === specificationParam.code);
    if (updatingParam && specificationOld) {
      updatingParam.configuration.value = specificationParam.configuration.value;
      updatingParam.configurableAtAssociation = specificationParam.configurableAtAssociation;
      updatingParam.configurableAtBooking = specificationParam.configurableAtBooking;
      updatingParam.serviceDefault = specificationParam.serviceDefault;
      updatingParam.configurableAtContracting = {
        enabled: (feature?.service && specificationParam.configurableAtContracting?.enabled) || false,
        choiceRequired: (feature?.service && specificationParam.configurableAtContracting?.choiceRequired) || false,
        allowMultiselect: (feature?.service && specificationParam.configurableAtContracting?.allowMultiselect) || false,
      }
      if (specificationParam.configuration.type === SpecificationParameterType.options && specificationParam.configuration.value.length <= 1) {
        updatingParam.configurableAtContracting = {
          enabled: false,
          choiceRequired: false,
          allowMultiselect: false
        }
      }
      if (specificationOld.configuration.value.length < 2 && (specificationParam.configuration.type === SpecificationParameterType.options && specificationParam.configuration.value.length == 2 && specificationParam.isService)) {
        updatingParam.configurableAtContracting = {
          enabled: true,
          choiceRequired: true,
        }
      }
      saveSpecificationParameters && saveSpecificationParameters(specificationParametersCopy);
    }
  }

  const saveSpecificationParameterOptionality = (specificationParam: SpecificationParameterDefinition) => {
    let specificationParametersCopy = cloneDeep(specificationParameters);
    let updatingParam = specificationParametersCopy.find(element => element.code === specificationParam.code);
    if (updatingParam) {
      updatingParam.serviceDefault = specificationParam.serviceDefault;
    }
    saveSpecificationParameters && saveSpecificationParameters(specificationParametersCopy);
  }


  const saveSPReordering = () => {
    const flattedItemstoTree = () => {
      return {
        featureRef: feature?.code,
        featureVersion: feature?.version,
        specificationParameterOrderList: flatennedItems.map(item => item.code)
      } as SortSpecificationParameterRequest;
    }
    sortSpecificationParams(flattedItemstoTree()).unwrap().then(
      () => {
        toaster.showToast("success", "Successfully reordered");
        setIsSPReordering(false);

      },
      () => {
        toaster.showToast("error", "Failed to reorder");
      }
    )
  }

  const definitionMapping = (spec: SpecificationParameterDefinition) => {
    if ((usedIn === "product" || usedIn === "featureCompare") && spec.definition) {
      return { ...spec, description: spec.definition.description, descriptionRichText: spec.definition.descriptionRichText } as SpecificationParameterDefinition;
    }
    return spec;
  }

  return (
    <div className={styles.SpecificationList} data-testid="SpecificationList">
      <div className={styles.specificationContent}>
        <div className={styles.specificationList}>
          <div>
            <div className={styles.header}>
              <div className={styles.headerAndButton}>
                <h4 data-testid="SpecParamCount">
                  {specificationParameters?.length} specification parameter{specificationParameters?.length > 1 ? 's' : ''}
                </h4>
                <div className={styles.actionButtons}>
                  {
                    isAuthorisedToEdit && addSpecificationParameter && (usedIn == "product") && isConfigurable && feature &&
                    <>
                      <div className={`${specificationParameters.length >= feature.numberOfSpecificationParameters && ("disabledAddSP-" + feature.code)}`}>
                        <Button className={`p-button-outlined p-button-secondary`} icon="pi pi-plus" disabled={specificationParameters.length >= feature.numberOfSpecificationParameters || !isAuthorisedToEdit}
                          label="Add SP" data-testid="addSPButton" onClick={() => addSpecificationParameter && addSpecificationParameter()}
                        />
                      </div>
                      <Tooltip target={".disabledAddSP-" + feature.code} position="left" content='All specification parameters are already associated to product'
                        style={{ visibility: (specificationParameters.length >= feature.numberOfSpecificationParameters) ? 'visible' : 'hidden' }} />
                    </>
                  }
                  {
                    createOrEditSpecificationParameter && usedIn == "definition" && isLinkedFeatureInDraft &&
                    <>
                      {specificationParameters?.length > 1 &&
                        <>
                          {!isSPReordering ?
                            <Button icon="pi pi-bars" label="Reorder" className={`p-button-outlined p-button-secondary default_button`} onClick={() => { setIsSPReordering(true) }} disabled={!isAuthorisedToEdit} />
                            :
                            <>
                              <Button icon="pi pi-times" label="Cancel Reordering" className={`p-button-outlined p-button-secondary default_button`} onClick={() => { setIsSPReordering(false) }} disabled={!isAuthorisedToEdit} />
                              <Button icon="pi pi-check" label="Done Reordering" className={`p-button-outlined p-button-secondary default_button`} onClick={() => { saveSPReordering() }} disabled={!isAuthorisedToEdit} />
                            </>
                          }
                        </>
                      }
                      <Button className={`p-button-outlined p-button-secondary`} icon="pi pi-plus" disabled={isSPReordering || !isAuthorisedToEdit}
                        label="New SP" data-testid="newSPButton" onClick={() => createOrEditSpecificationParameter && createOrEditSpecificationParameter()} />
                    </>
                  }
                </div>
              </div>
              {specificationParameters?.length > 0 && <div className={styles.divider}><hr /></div>}
            </div>

          </div>

          {
            isSPReordering ?
              <SortableTree treeItems={treeItems} returnFlattenedItems={setFlatennedItems} />
              :
              specificationParameters?.map((specification, index) =>
                <ShowSpecification
                  key={specification.code}
                  associatedSpecifications={specificationParameters}
                  specification={definitionMapping(specification)}
                  index={index}
                  isConfigurable={isConfigurable}
                  isAuthorisedToEdit={isAuthorisedToEdit}
                  usedIn={usedIn}
                  feature={feature}
                  saveSpecification={saveSpecification}
                  createOrEditSpecificationParameter={createOrEditSpecificationParameter}
                  isLinkedFeatureInDraft={isLinkedFeatureInDraft}
                  deleteSpecificationParam={deleteSpecificationParam}
                  showExcludedOptionsInViewMode={showExcludedOptionsInViewMode}
                  sourceComponent={sourceComponent}
                  isFeatureSidePanel={isFeatureSidePanel}
                  saveSpecificationParameterOptionality={saveSpecificationParameterOptionality}
                   />
              )
          }
        </div>
      </div>
    </div>
  );
}

export default SpecificationList;
