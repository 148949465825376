import { cloneDeep } from 'lodash';
import React, { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb, BreadCrumbContext } from '../../App';
import PATHS from '../../Paths';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps { }

export const addCrumb = (crumb: { label: string, url: string }, breadcrumbs: any) => {
  const pathMatchedCrumbIndex = breadcrumbs.data.findIndex((element: BreadCrumb) => (crumb.url == element.url) || (crumb.url.includes('/features/') && (crumb.url.substring(0,crumb.url.lastIndexOf('/')+1) == element.url.substring(0, element.url.lastIndexOf('/')+1))));
  if (pathMatchedCrumbIndex >= 0) {
    if (breadcrumbs.data[pathMatchedCrumbIndex].label !== crumb.label) {
      let copyBreadCrumbs = cloneDeep(breadcrumbs.data);
      copyBreadCrumbs[pathMatchedCrumbIndex].label = crumb.label;
      copyBreadCrumbs.length = pathMatchedCrumbIndex + 1;
      breadcrumbs.setData(copyBreadCrumbs);
    }
  } else {
    breadcrumbs.setData([...breadcrumbs.data, { label: crumb.label, url: crumb.url }]);
  }
}

const Breadcrumbs: FC<BreadcrumbsProps> = () => {
  const home = { icon: "pi pi-home", url: PATHS.HOME };
  const breadcrumbs = useContext(BreadCrumbContext);
  const navigate = useNavigate();

  const onCrumbClick = (url: string) => {
    const index = breadcrumbs.data.findIndex(crumb => crumb.url === url);
    let crumbsCopy = cloneDeep(breadcrumbs.data);
    crumbsCopy.length = index + 1;
    breadcrumbs.setData(crumbsCopy);
    navigate(url);
  }

  return (
    <div className={styles.Breadcrumbs} data-testid="Breadcrumbs">
      <div className={styles.activeBreadCrumbLabel} onClick={() => onCrumbClick(home.url)}>Home</div>
      {
        breadcrumbs.data.map((crumb, index) =>
          <div key={index} className={styles.Breadcrumbs}>
            <div>/</div>
            <div className={index == (breadcrumbs.data.length - 1) ? styles.disabledBreadCrumbLabel : styles.activeBreadCrumbLabel} onClick={() => onCrumbClick(crumb.url)}>{crumb.label}</div>
          </div>
        )
      }
    </div>
  );
}

export default Breadcrumbs;
