import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./ProductView.module.scss";
import AttributesList from "../../components/AttributesList/AttributesList";
import {
	ProductAndResources,
	useActivateDraftProductMutation,
	useApproveRejectProductMutation,
	useCancelProductApprovalMutation,
	useCreateProductApproverMutation,
	useDeleteProductDraftMutation,
	useGetAllProductVersionsQuery,
	useGetAllUsersForProductQuery,
	useGetProductByCodeAndVersionQuery,
	useGetProductByCodeQuery,
	usePostDraftProductMutation,
	usePostProductMutation,
	usePostRoleAssignmentsMutation,
	useRetireProductMutation
} from "../../data/api/CatalogueApi";
import {
	ApprovalInfo,
	ComponentAssociation,
	emptyProductFrom,
	getContractingText,
	Optionality,
	Product,
	ResourceApproversInfo,
	ProductType,
	ROLES,
	SpecificationParameterAssociation,
	Status,
	SelectedVersion,
	ResourceVersion,
	ResourceVersions
} from "../../data/model/DataModels";
import { TabPanel, TabView } from "primereact/tabview";

import { Tag } from "primereact/tag";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import { FeatureFilterState, setFilters } from "../../components/FilterPanel/featureFilterSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { filterType } from "../../components/FilterPanel/FilterPanel";
import {
	loadEditableProduct,
	setComponent,
	setComponentReordering,
	setEditMode,
	setIsProductFormValid,
	setLocalDraftChanged,
	setShowVarianceDetails,
	updateApplicabilityInProduct,
	updateComponentAssociationInProduct,
	updateContractingInProduct,
	updateEditableProduct,
	updateEditableProductOnSave,
	updateHeaderInProduct,
	updateProductCompatibilitiesInProduct,
	updateSalesInfoInProduct,
	updateproductHeadersInProduct,
} from "../../data/slices/productSlice";
import ErrorHandlerComponent from "../../components/ErrorHandler/ErrorHandlerComponent";
import { Button } from "primereact/button";
import { capitalize, cloneDeep, startCase } from "lodash";
import ActivityComponent from "../../components/Activity/ActivityComponent.lazy";
import { confirmDialog } from "primereact/confirmdialog";
import { sectionType } from "../../data/model/SharedDataInterfaces";
import ProductOverview from "./ProductOverview/ProductOverview";
import { ToasterContext } from "../../features/AppLayoutView/AppLayoutView";
import { hasGlobalOwnerRole, hasRole, useAnchor } from "../../authorisationService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FieldIdentifiers, formValidation } from "../../utils/validationUtil";
import PATHS from "../../Paths";
import ComponentsView from "./ProductComponents/ProductComponents.lazy";
import ApplicabilitiesView from "../ApplicabilitiesView/ApplicabilitiesView.lazy";
import { dimensionIncludeValues } from "../../components/utils/dimensionData";
import { BreadCrumbContext } from "../../App";
import ProductFeatures from "./ProductFeatures/ProductFeatures";
import { addCrumb } from "../Breadcrumbs/Breadcrumbs";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import ApproversDialog from "../../components/ApproversDialog/ApproversDialog";
import { getResourceNameFromProductCodeAndVersion } from "../../components/utils/userUtils";
import { ReactComponent as RetireIcon } from "../../assets/icons/maersk/arrow-clockwise-times.svg"
import { EnvConfig } from "../../EnvConfig";
import ProductCompatibilities from "../ProductCompatibilities/ProductCompatibilities";
import ShowListElementsInPopUp from "../../components/ShowListElementsInPopUp/ShowListElementsInPopUp";
import { ReactComponent as NonCompatibleIcon } from '../../assets/icons/maersk/mi-exclamation-octagon-solid-24px.svg';


const resolveAttributeIconFn = (key: string) => {
	switch (key.toLowerCase()) {
		case "geographiccoverage": return "pi pi-globe";
		case "customerneed": return "pi pi-users";
		case "saleschannels": return "pi pi-desktop";
		case "bookingchannels": return "pi pi-shopping-cart";
		case "targetcustomersegments": return "pi pi-briefcase";
		case "targetcustomerverticals": return "pi pi-chart-bar";
		default: return "pi pi-info-circle";
	}
};

interface Validity {
	isSalesAndMarketingFormValid: boolean,
	isOverviewAttributesFormValid: boolean,
	isVarianceValid: boolean,
	isProductTitleValid: boolean,
	isScopesValid: boolean
}

export const selectedComponentDefault = {
	componentRef: "all-features",
	definition: {
		name: "All Features"
	}
} as ComponentAssociation;


// TODO: wrap TabView into reusable #hash navigatable tabview with this map and switching logic contained
export const productTabs = [
	{ label: "Overview", hash: "" },
	{ label: "Applicabilities", hash: "#applicabilities" },
	{ label: "Components", hash: "#components" },
	{ label: "Features", hash: "#features" },
	// { label: "Commercial Info", hash: "#salesinfo" },
	{ label: "Compatibilities", hash: "#compatibilities" },
	{ label: "Activity", hash: "#activity" }
];



const ProductView = () => {
	const dispatch = useAppDispatch();
	const { code, version } = useParams();
	const { hash } = useLocation();
	const navigate = useNavigate();
	const editMode: boolean = useAppSelector((state) => state.product.editMode);
	const [deleteProductDraft, { isLoading: isLoadingDeleteDraft }] = useDeleteProductDraftMutation();
	const { data: product = {} as Product, error: errorLoadingProduct, isLoading: isLoadingProduct, refetch: refetchActiveProduct, isFetching : isFetchingProduct } = version !== undefined ? useGetProductByCodeAndVersionQuery({ "code": code!, "version": version }, { skip: isLoadingDeleteDraft }) : useGetProductByCodeQuery(code!);
	const { data: allProductVersions, error: errorLoadingAllProductVersions, isLoading: isLoadingAllProductVersions } = useGetAllProductVersionsQuery(code!, { skip: (!code && !version), refetchOnMountOrArgChange: true });
	let versionDropdownList: SelectedVersion[] = [];
	let selectedVersion;
	const editableProduct = useAppSelector((state) => state.product.editableProduct);
	const isFormValid = useAppSelector((state) => state.product.isProductFormValid);
	const reorderEnabled: boolean = useAppSelector((state) => state.product.isReordering);
	const [postProduct, { isLoading: isLoadingPost }] = usePostProductMutation();
	const [createDraftProduct, { isLoading: isLoadingCreateDraftProduct }] = usePostDraftProductMutation();
	const [activateProductDraft, { isLoading: isLoadingActivateProduct }] = useActivateDraftProductMutation();

	const [approveRejectProduct, { isLoading: isLoadingApprovalInfo }] = useApproveRejectProductMutation();
	const [showFeatureUpgradeInfo, setShowFeatureUpgradeInfo] = useState(true);
	const [showDraftModeInfo, setShowDraftModeInfo] = useState(true);
	const [showDraftRejectionInfo, setShowDraftRejectionInfo] = useState(true);
	const [showHideRejectionDialog, setShowHideRejectionDialog] = useState(false);
	const [rejectionComment, setRejectionComment] = useState("");
	const [showSendForApprovalDialog, setShowSendForApprovalDialog] = useState(false);
	const [showActivateProductDialog, setShowActivateProductDialog] = useState(false);
	const [showConfirmCancelApproval, setShowConfirmCancelApproval] = useState(false);
	const [triggerApproverStatus, setTriggerApproverStatus] = useState(0);
	const areRbacResourcesReady = (product?.rbacResourceCode && product?.rbacChildResourceCode && product?.rbacResourceCode != "" && product?.rbacChildResourceCode != '' && product?.rbacResourceCode != "error" && product?.rbacChildResourceCode != "error") || false;
	const { data: allUsers, isLoading: isLoadingUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(code!, product?.version || 1), resourceCode: product?.rbacResourceCode, resourceChildCode: product?.rbacChildResourceCode } as ProductAndResources, { skip: !areRbacResourcesReady });
	const isRbacInErrorState = (product?.rbacResourceCode == "error" || product?.rbacChildResourceCode == "error") || false;
	const [cancelProductApproval, { isLoading: isLoadingCancelProductApproval }] = useCancelProductApprovalMutation();
	const [saveProductApprover, { isLoading: isLoadingSaveProductApprover }] = useCreateProductApproverMutation();
	const [retireProductApi, { isLoading: isLoadingRetireProduct }] = useRetireProductMutation();
	const isDraftChanged = useAppSelector((state) => state.product.localDraftChanged);
	const breadcrumbs = useContext(BreadCrumbContext);
	const { refreshAnchorToken } = useAnchor();
	const [showErrorMsgPopup, setShowErrorMsgPopup] = useState(false);
	const [errorMsgArr, setErrorMsgArr] = useState<string[]>([]);
	const [showNonCompatibleBanner, setShowNonCompatibleBanner] = useState(true);

	const { pathname } = useLocation();

	useEffect(() => {
		if (product.name) {
			addCrumb({ label: product.name, url: pathname }, breadcrumbs);
		}
	}, [product.name]);

	// form field validations
	const [validityState, setValidityState] = useState({
		isSalesAndMarketingFormValid: true,
		isOverviewAttributesFormValid: true,
		isVarianceValid: true,
		isProductTitleValid: true,
		isScopesValid: true
	} as Validity)

	const toaster = useContext(ToasterContext);

	useEffect(() => {
		dispatch(setIsProductFormValid(Object.values(validityState).every(value => value === true)));
	}, [validityState])

	useEffect(() => {
		const params = new URLSearchParams(hash.replace(productTabs[3].hash, ""));

		const filters: FeatureFilterState = {
			checkedTags: [],
			checkedControls: [],
			checkedComponents: [],
		};
		params.forEach((value, key) => {
			if (value === filterType.Tag) filters.checkedTags.push(key);
			if (value === filterType.Control) filters.checkedControls.push(key);
			if (value === filterType.Component) filters.checkedComponents.push(key);
		});
		dispatch(setFilters(filters));

		if (filters.checkedComponents?.length === 1) {
			const component = editMode ? editableProduct?.components.find(component => component.definition?.code === filters.checkedComponents[0]) : product?.components?.find(component => component.definition?.code === filters.checkedComponents[0]);
			dispatch(setComponent(component!));
		} else {
			dispatch(setComponent(selectedComponentDefault));
		}
		// dispatch(setFeatureGroups(product ? product.features : []));

	}, [product, hash]);

	//unmount
	useEffect(() => {
		dispatch(setEditMode(false));
	}, []);

	useMemo(() => {
		runValidationForButton();
	}, [])

	useEffect(() => {
		runValidationForButton();
	}, [editableProduct, allUsers]);


	if (isLoadingProduct || isFetchingProduct || isLoadingAllProductVersions || isLoadingPost || isLoadingDeleteDraft || isLoadingActivateProduct
		|| isLoadingCancelProductApproval || isLoadingSaveProductApprover || isLoadingCreateDraftProduct || isLoadingApprovalInfo || isLoadingRetireProduct) {
		return (
			<SpinnerComponent />
		);
	}

	if (allProductVersions && allProductVersions.resourceVersionDetailDtos?.length > 0) {
    let tempVersions: SelectedVersion[] = [];
    tempVersions = allProductVersions.resourceVersionDetailDtos.map(versionItem => ({
      ...versionItem,
      name: "Version " + versionItem.resourceVersion + " - " + capitalize(versionItem.status),
      code: versionItem.resourceVersion.toString()
    }));
    versionDropdownList = [...tempVersions, { name: "View all versions", code: ResourceVersions.AllVersion } as ResourceVersion];

    if (version === 'all')
      selectedVersion = versionDropdownList.filter(item => item.code === ResourceVersions.AllVersion)[0]?.code;
    else
      selectedVersion = versionDropdownList.filter(item => item.resourceVersion === Number(version))[0]?.code;

		if(selectedVersion === undefined)
			selectedVersion = versionDropdownList.filter(item => item.resourceVersion === Number(product.version))[0]?.code;
  }

	const anchorEmail = sessionStorage.getItem("anchorEmail") || "";

	const gotoTab = (tabIndex: number) => {
		navigate(productTabs[tabIndex].hash);
	};


	const resolveActiveTab = () => {
		const activeTab = productTabs.find(tab => tab.hash === hash.split("?")[0]);
		return activeTab ? activeTab : productTabs[0];
	};


	function getProduct() {
		let productSelected: Product = cloneDeep(editMode ? editableProduct.code ? editableProduct: product : product);
		return productSelected;
	};


	const __approversApproved = getProduct().approversApproved;
	const _approversApproved: string[] = __approversApproved ? __approversApproved : [];
	const __approversRejected = getProduct().approversRejected;
	const _approversRejected = __approversRejected ? __approversRejected : {};
	let approversRejectedKeys: string[] = [];
	if (_approversRejected) {
		approversRejectedKeys = Object.keys(_approversRejected);
	}

	const isGlobalOwner = hasGlobalOwnerRole();
	const isProductOwner = hasRole([code + "-" + getProduct().version], ROLES.OWNER, areRbacResourcesReady);
	const isContibutor = hasRole([code + "-" + getProduct().version], ROLES.CONTRIBUTOR, areRbacResourcesReady);
	const isApprover = hasRole([code + "-" + getProduct().version], ROLES.APPROVER, areRbacResourcesReady);

	const isEditNewProduct = (): boolean => editMode && editableProduct !== undefined && editableProduct.version === 1;
	const getActiveProduct = (): Product => {
		return isEditNewProduct() ? emptyProductFrom(product) : product;
	};

	const evaluateSeverity = (optionality: Optionality): "info" | "success" | "warning" | "danger" | null | undefined => {
		switch (optionality) { // Disable colors for badges as we probably want to go another way
			//   case Optionality.mandatory: return "primary";
			// case Optionality.restricted: return "warning";
			default: return 'info';
		}
	};


	const editProduct = (productDef: Product) => {
		dispatch(loadEditableProduct(productDef));
		dispatch(setEditMode(true));
	}

	const retireProduct = (productCode: string) => {
		let message = "";
		let header = "";

		if (getProduct().associatedSolutions?.length > 0 && !isUsedInActiveSolutions()) {
			message = 'This product is used in draft solutions. The draft solutions using this product will be notified that this product is no longer available after it is retired.';
			header = 'Product used in draft solutions';
		} else {
			message = 'Once retired this product will no longer be available for new opportunities and in solutions.';
			header = 'Retire Product?';
		}

		confirmDialog({
			message: message,
			header: header,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: "Retire Product",
			rejectLabel: "Cancel",
			accept: () => {
				retireProductApi(productCode).unwrap()
					.then(() => {
						toaster.showToast("success", "Product retired successfully")
					},
						(error: any) => {
							let errorMsg: string = error.data.message;
							errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to retire Product';
							toaster.showToast('error', errorMsg);
						}
					)
			}
		});
	}

	const isUsedInActiveSolutions = () => {
		return getProduct().associatedSolutions?.some(solution => solution.status == "active");
	}

	const createOrEditDraft = () => {
		createDraftProduct(getProduct()?.code).unwrap().then(
			(product) => {
				dispatch(setLocalDraftChanged(false));
				refreshAnchorToken();
				navigate(PATHS.PRODUCT_VERSION.replace(":code", getProduct().code).replace(":version", (getProduct().version + 1).toString()))
			},
			() => {
				toaster.showToast('error', 'Failed to save draft');
			});
	};

	const cancelApprovalAndEditProduct = () => {
		cancelProductApproval(code!).unwrap().then(
			(productDef) => {
				toaster.showToast('success', 'Successfully cancelled the product approval');
				dispatch(setEditMode(true));
				dispatch(updateEditableProduct({...getProduct(), lockingVersion: productDef.lockingVersion, status: productDef.status}));
			},
			() => {
				toaster.showToast('error', 'Failed to cancel the product approval');
			}
		);
		setShowConfirmCancelApproval(false);
	}

	const saveDraft = async () => {
		if (reorderEnabled) {
			confirmDialog({
				message: "You are reordering components, do you want to discard changes?",
				header: "Discard Changes",
				acceptLabel: "Discard reordering changes and Save",
				rejectLabel: "Cancel",
				icon: "pi pi-exclamation-triangle",
				accept: async () => {
					postDraft();
					dispatch(setComponentReordering(false));
				},
				reject: () => {
				}
			});
			return;
		}
		postDraft();
	};

	const postDraft = async () => {
		await postProduct(sanitizeProduct(getProduct())).unwrap().then(
			(product) => {
				if (isEditNewProduct()) refetchActiveProduct();
				toaster.showToast('success', 'Successfully saved draft');
				dispatch(updateEditableProductOnSave(product));
				// dispatch(setLocalDraftChanged(false));
				dispatch(setShowVarianceDetails(false));
			},
			(error) => {
				let errorMsg: string = error.data.message;
				errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to save draft';
				toaster.showToast('error', errorMsg);
			}
		)
	}

	const sanitizeProduct = (editableProduct: Product): Product => {
		let editProductCopy: Product = {
			...editableProduct,
			applicability: editableProduct?.applicability.map(scope => {
				return {
					...scope,
					dimensions: scope.dimensions.map(dimension => dimension.value[0] && dimension.value[0] == dimensionIncludeValues.ALL_INCLUDED ? { ...dimension, value: [dimensionIncludeValues.ALL_INCLUDED] } : dimension)
				}
			}),
			variances: editableProduct?.variances.map(variance => {
				return {
					...variance,
					dimensions: variance.dimensions.filter(dimension => dimension.value.filter((val: string) => val != "").length > 0)
				}
			}),
			compatibility: editableProduct?.compatibility
		}
		return editProductCopy;
	}

	const prepareMessage = (name: string, version: number) => {
		return version === 1 ? <span>You are about to activate Version {version} of {name}.<br />Are you sure you want to activate Version {version}?</span>
			: <span>You are about to activate Version {version} of {name} <br /> which will deprecate the current version Version {version - 1}<br />. Are you sure you want to activate Version {version}?</span>;
	}

	const confirmDraftActivation = async (name: string, version: number) => {
		confirmDialog({
			message: prepareMessage(name, version),
			header: "Activate Draft Confirmation",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Yes, Activate",
			rejectLabel: "Not Yet",
			rejectClassName: "dialog_reject",
			accept: async () => {
				await activateDraft();
			},
			reject: () => {
				return;
			}
		});
	};

	function validateCompatibilityRule() {
		var isInvalidRule = false;
		if(product.productType===ProductType.ADDON) {
			product.compatibility?.compatibilityRules.forEach(compatibilityRule => {
				if (compatibilityRule.rules.length > 0) {
					const foundInvalid = compatibilityRule.rules.some(rule => {
						if (rule.validationMsg) {
							isInvalidRule = true;
							return true;
						}
						return false;
					});
					if (foundInvalid) return;
				}
			});
		}
		return isInvalidRule;
	}

	function runValidationForButton() {

		const errorMsgs: string[] = [];
		if (allUsers ? allUsers.approvers !== null ? allUsers.approvers.length === 0 : true : true) {
			errorMsgs.push("Please add atleast one approver to proceed.");
		}
		if (!areRbacResourcesReady) {
			errorMsgs.push("Roles are not ready yet, please try again.");
		}
		if (isAnyComponentWithZeroFeature().disabled) {
			errorMsgs.push(isAnyComponentWithZeroFeature().text);
		}
		if (isAnyProductIncompatible().disabled) {
			errorMsgs.push(isAnyProductIncompatible().text);
		}
		if(validateCompatibilityRule()) {
			errorMsgs.push("Please review compatibility rule as it has invalid rules.");
		}
		setErrorMsgArr(errorMsgs);

	}

	const activateDraft = async () => {
		await activateProductDraft(getProduct().code).unwrap().then(

			() => {
				dispatch(setEditMode(false));
				navigate(PATHS.PRODUCT_BY_CODE.replace(":code", getProduct().code));
				// navigate(PATHS.PRODUCT_VERSION.replace(":code", getProduct().code).replace(":version", getProduct().version?.toString()));
				toaster.showToast('success', 'New product version activated');
			},
			() => {
				toaster.showToast('error', 'Failed to activate product draft');
			}
		)
	};

	const approveRejectAction = async (approvalInfo: ApprovalInfo) => {
		await approveRejectProduct(approvalInfo).unwrap().then(
			() => {
				approvalInfo.status === "approved" ?
					toaster.showToast('success', `You have approved the draft Version ${getProduct()?.version} of this product`)
					: toaster.showToast('success', `You have rejected the draft Version ${getProduct()?.version} of this product`);
				setShowHideRejectionDialog(false);
			},
			() => {
				approvalInfo.status === "approved" ?
					toaster.showToast('error', `Failed to approve the draft Version ${getProduct()?.version} of this product`)
					: toaster.showToast('error', `Failed to reject the draft Version ${getProduct()?.version} of this product`);
				setShowHideRejectionDialog(false);
			}
		)
	}


	const home = { icon: "pi pi-home", url: "/" };
	const activeTab = resolveActiveTab();
	const confirmDraftDeletion = async () => {
		confirmDialog({
			message: "You are about to delete the draft. This action cannot be undone. Are you sure you want to proceed?",
			header: "Delete Draft Confirmation",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Delete",
			rejectLabel: "Cancel",
			accept: async () => {
				if (product?.code) {
					deleteProductDraft(product.code).unwrap().then(() => {
						dispatch(setEditMode(false));
						refreshAnchorToken();
						navigate("/products");
						toaster.showToast('success', 'Draft deleted successfully');
					},
						() => {
							toaster.showToast('error', 'Failed to delete draft');
						}
					);

					// go back to main product asap
					navigate(pathname.split("version")[0]);
				}
			},
			reject: () => {
				return;
			}
		});
	};


	const saveState = (section: sectionType, value: any) => {
		switch (section) {
			case sectionType.HEADER:
				return dispatch(updateHeaderInProduct(value));
			case sectionType.SALES_MARKETING:
				return dispatch(updateSalesInfoInProduct(value));
			case sectionType.APPLICABILITY:
				return dispatch(updateApplicabilityInProduct(value));
			case sectionType.COMPONENT:
				return dispatch(updateComponentAssociationInProduct(value));
			case sectionType.PRODUCT_HEADERS:
				return dispatch(updateproductHeadersInProduct(value));
			case sectionType.COMPATIBILITIES:
				return dispatch(updateProductCompatibilitiesInProduct(value))
		}
	};

	const checkTitleValidity = (value: string, fieldIdentifier: FieldIdentifiers) => {
		const validityResult = formValidation(value, fieldIdentifier);
		if (validityResult !== "") {
			(validityState.isProductTitleValid) && setValidityState({ ...validityState, isProductTitleValid: false });
		} else {
			(!validityState.isProductTitleValid) && setValidityState({ ...validityState, isProductTitleValid: true });
		}
		return validityResult;
	}

	const setValidity = (isValid: boolean, key: 'isOverviewAttributesFormValid' | 'isSalesAndMarketingFormValid' | 'isProductTitleValid' | 'isVarianceValid' | 'isScopesValid') => {
		if (validityState[key] !== isValid) {
			setValidityState({ ...validityState, [key]: isValid });
		}
	}

	const isFeatureVersionAvailable = (): boolean => {
		const product = getProduct();
		const filteredFeatureCodeVersions = product?.components?.flatMap(comp => comp.upgradedFeatureCode);
		if (filteredFeatureCodeVersions?.length > 0) {
			const featureUpgradeAvailFlags = filteredFeatureCodeVersions.map(feat => {
				const matchedFeature = product.features.filter(feature => feat?.code === feature.featureRef);
				return (matchedFeature.length > 0 && (feat?.featureVersion && feat?.featureVersion > matchedFeature[0].featureVersion)) ? true : false;
			});
			return featureUpgradeAvailFlags.filter(flag => flag).length > 0 ? true : false;
		}
		else
			return false;
	}

	const getActiveApprovers = () => {
		return allUsers ? allUsers.approvers?.map(approver => approver.email) : [];
	}

	const sendForApproval = () => {
		const productApprovers: ResourceApproversInfo = {
			productCode: getProduct().code,
			version: getProduct().version,
			approvers: getActiveApprovers()
		}
		saveProductApprover(productApprovers).unwrap()
			.then(response => {
				dispatch(setEditMode(false));
				toaster.showToast('success', 'Approvers added successfully');
				refreshAnchorToken();
			},
				() => {
					toaster.showToast('error', `Failed to add approvers`);
				})

	}

	if (errorLoadingProduct) {
		return <ErrorHandlerComponent error={errorLoadingProduct} />
	}

	if (errorLoadingAllProductVersions) {
		return <ErrorHandlerComponent error={errorLoadingAllProductVersions} />
	}

	const rejectDialogFooter = (
		<div>
			<Button label="Cancel" className="p-button-outlined p-button-secondary" onClick={() => setShowHideRejectionDialog(false)} />
			<Button label="Reject" className="p-button-danger" onClick={() => approveRejectAction({ status: "rejected", comment: rejectionComment, email: anchorEmail, productCode: product?.code } as ApprovalInfo)} />
		</div>
	);

	const cancelApprovalFooter = (
		<div>
			<Button label="No" className="p-button-outlined p-button-secondary" onClick={() => setShowConfirmCancelApproval(false)} />
			<Button label="Yes, cancel approval" className="p-button-primary" onClick={() => cancelApprovalAndEditProduct()} />
		</div>
	);

	const activateProductFooter = (
		<div>
			<Button label="Not Yet" className="p-button-outlined p-button-secondary" onClick={() => setShowActivateProductDialog(false)} />
			<Button label="Yes,Activate" className="p-button-danger" onClick={() => approveRejectAction({ status: "rejected", comment: rejectionComment, email: anchorEmail, productCode: product?.code } as ApprovalInfo)} />
		</div>
	);

	const checkIfApproverTookAction = () => {
		let allApproversEmail: string[] = approversRejectedKeys;
		if (_approversApproved) {
			allApproversEmail = [...allApproversEmail, ..._approversApproved];
		}
		return allApproversEmail.includes(anchorEmail);
	}

	const confirmDraftSaveBeforeApproval = () => {
		if (errorMsgArr.length > 0) {
			runValidationForButton();
			setShowErrorMsgPopup(true);
		}
		else {
			if (isDraftChanged) {
				confirmDialog({
					message: "Do you want to save your changes before sending for approval?",
					header: "Confirmation",
					acceptLabel: "Save",
					rejectLabel: "Discard",
					icon: "pi pi-exclamation-triangle",
					accept: async () => {
						saveDraft();
						setShowSendForApprovalDialog(true);
					},
					reject: () => {
						setShowSendForApprovalDialog(true);
					}
				});
			}
			else {
				setShowSendForApprovalDialog(true);
			}
		}
	}

	const closeErrorMsgPopup = () => {
		setShowErrorMsgPopup(false);
	}

	const errorMsgPopUpFooter = (
		<div>
			<Button label="Got it" className="p-button-secondary" onClick={() => setShowErrorMsgPopup(false)} />
		</div>
	);

	const disableActivateMessage = () => {
		const featureAssociations: Record<string, SpecificationParameterAssociation[]> = {};

		for (const association of product.specificationParameters) {
			const { featureRef } = association;
			if (!featureAssociations[featureRef]) {
				featureAssociations[featureRef] = [];
			}
			featureAssociations[featureRef].push(association);
		}

		const isAnyCoreFeatureWithoutCoreSP = () => {
			if (product.features
				.filter(feature => feature.serviceDefault === "included")
				.some(feature => !featureAssociations[feature.featureRef].some(specParam => specParam.serviceDefault === "included"))) {
				return true;
			}
			return false;
		}

		const isAnyFeatureWithoutSP = () => {
			if (Object.values(featureAssociations).some(listOfSpecParamForFeature => listOfSpecParamForFeature.length === 0)) {
				return true;
			}
			return false;
		}

		if (getActiveApprovers()?.length !== getProduct()?.approversApproved?.length) {
			return `Awaiting approval from ${(getActiveApprovers()?.length - _approversApproved?.length)} approvers`;
		}
		if (isAnyComponentWithZeroFeature().disabled) {
			return isAnyComponentWithZeroFeature().text;
		}
		if (isAnyCoreFeatureWithoutCoreSP()) {
			return "One of the core type feature is missing core type specification parameter";
		}
		if (isAnyFeatureWithoutSP()) {
			return "One of the features is missing specification parameter";
		}

		if (isAnyProductIncompatible().disabled) {
			return isAnyProductIncompatible().text;
		}

		return "";
	}

	const isAnyOptionalFeatureWithCoreSPTooltip = () => {

		let associatedFeature = getProduct().features.find(feature => {
			if (feature.serviceDefault === "not_included") {
				return getProduct().specificationParameters.find(sp => {
					return feature.featureRef === sp.featureRef && sp.serviceDefault === "included";
				})
			}
			else if (feature.serviceDefault === "included") {
				return !getProduct().specificationParameters.filter(sp => sp.featureRef === feature.featureRef).some(sp => sp.serviceDefault === 'included')
			}
		});

		return associatedFeature !== undefined && associatedFeature.serviceDefault === 'not_included' ?
			{ disabled: true, text: "An optional feature can have only optional specification parameters. Please, review feature " + associatedFeature.definition.name + "." } : associatedFeature?.serviceDefault === 'included' ?
				{ disabled: true, text: "A core feature must have at least one core specification parameter. Please, review feature " + associatedFeature.definition.name + "." } :
				{ disabled: false, text: undefined };
	}

	const showSaveButton = () => {
		if (editMode) {
			if (isProductOwner || isContibutor)
				return (
					<div>
						<Button label={getProduct().lockingVersion > 0 ? "Save Changes" : "Create Draft"} disabled={!isFormValid || (getProduct().lockingVersion > 0 && isAnyOptionalFeatureWithCoreSPTooltip().disabled)} tooltip={isAnyOptionalFeatureWithCoreSPTooltip().text} tooltipOptions={{ position: 'left', event: 'both', showOnDisabled: true }} icon="pi pi-pencil" className={`general-tooltip p-button-primary`} onClick={() => saveDraft()} />
					</div>);
		}
		return <></>;
	}

	const showActionButtons = () => {
		return (
			<>
				{
					EnvConfig.TOGGLES["ENABLE_RETIREMENT"] && isGlobalOwner && getProduct().status == "active" &&
					<div>
						<Button data-testid='editButton' icon={() => <RetireIcon style={{ width: "34px", margin: "-10px auto -10px -10px" }} />} label="Retire" className="p-button-outlined p-button-secondary" onClick={() =>
							retireProduct(product.code)} disabled={!areRbacResourcesReady || isUsedInActiveSolutions()}
							tooltip={"This product cannot be retired because it is used in active solutions.Remove it from active solutions first."}
							tooltipOptions={{ showOnDisabled: true, disabled: !isUsedInActiveSolutions(), showEvent: 'click', position: 'mouse' }}
						/>
					</div>
				}
				{
					(isProductOwner || isContibutor) && getProduct().status === Status.DRAFT &&
					<Button data-testid='editButton' label="Edit" icon="pi pi-pencil" className="p-button-primary" onClick={() => editProduct(product)} disabled={!areRbacResourcesReady} />
				}
				{
					(isProductOwner || isContibutor) && getProduct().status === Status.ACTIVE &&
					<Button data-testid='editButton' label="Create/View Draft" icon="pi pi-pencil" className="p-button-primary" onClick={() => createOrEditDraft()} disabled={!areRbacResourcesReady} />
				}
				{
					(isProductOwner || isContibutor) && getProduct()?.status === Status.REVIEW &&
					<Button data-testid='editButton' label="Cancel Approval & Edit More" icon="pi pi-pencil" className="p-button-primary" onClick={() => setShowConfirmCancelApproval(true)} disabled={!areRbacResourcesReady} />
				}
				{
					isApprover && getProduct()?.status === Status.REVIEW &&
					<>
						<Button label="Reject" icon="pi pi-times-circle" disabled={checkIfApproverTookAction()} className="p-button-outlined p-button-secondary" onClick={() => setShowHideRejectionDialog(true)} />
						<Button label="Approve" icon="pi pi-thumbs-up" disabled={checkIfApproverTookAction()} className="p-button-primary" onClick={() => approveRejectAction({ status: "approved", comment: "", email: anchorEmail, productCode: product?.code } as ApprovalInfo)} />
					</>
				}
			</>
		)
	}

	function isAnyComponentWithZeroFeature() {
		const invalidComponents = getProduct()?.components?.filter(component => component.definition.numberOfFeatures === 0)
			.map(componentWithZeroFeature => componentWithZeroFeature.definition.name);

		if (invalidComponents?.length > 0) {
			return {
				disabled: true,
				text: "No features in following component(s) \nComponent Name(s): " + invalidComponents.join(', ')
			}
		}
		else {
			let featureCountMap: any = {};
			let componentCodeAndNameMap: any = {};
			getProduct()?.components?.forEach(element => {
				featureCountMap[element.componentRef] = 0;
				componentCodeAndNameMap[element.componentRef] = element.definition.name;
			});
			getProduct()?.features?.forEach(feature => featureCountMap[feature.componentRef]++);
			let componentCodesWithZeroFeatureAssociated: string[] = Object.keys(featureCountMap).filter(componentCode => featureCountMap[componentCode] === 0);
			if (componentCodesWithZeroFeatureAssociated.length > 0) {
				return {
					disabled: true,
					text: "No feature associated with product for following component(s) \nComponent Name(s): " + componentCodesWithZeroFeatureAssociated.map(code => componentCodeAndNameMap[code]).join(', ')
				}

			}
			return {
				disabled: false,
				text: ""
			}
		}
	}

    const onVersionSelect = (versionSelected: string) => {
        if (versionSelected === ResourceVersions.AllVersion)
            navigate(PATHS.PRODUCT_VERSION.replace(":code", code!).replace(":version", 'all'));
        else
            navigate(PATHS.PRODUCT_VERSION.replace(":code", code!).replace(":version", versionSelected + ""));
    }

	function isAnyProductIncompatible() {

		const product = getProduct();
		const atleastOneCompatibleProductExists = product.compatibility?.compatibleItems.some(compatibility => compatibility.isCompatible === true);
		if (product.productType === ProductType.ADDON) {
			if (!atleastOneCompatibleProductExists) {
				return {
					disabled: true,
					text: "Atleast one compatible product must exist."
				}
			}
			else if (product.compatibility?.compatibleItems.find(compatibility => compatibility.isCompatible === false)) {
				return {
					disabled: true,
					text: "Some products don’t have common applicabilities with this add-on."
				}
			}
			else if (product.compatibility?.compatibleItems.find(compatibility => compatibility.status === Status.RETIRED)) {
				return {
					disabled: true,
					text: "One or more retired products exist."
				}
			}
			return {
				disabled: false,
				text: ""
			}
		}
		else {
			return {
				disabled: false,
				text: ""
			}
		}
	}

	const isAtleastOneProductCompatible = () => {
		return (getProduct().compatibility?.compatibleItems.find(compatibility => (compatibility.isCompatible === true && compatibility.status !== Status.RETIRED)) != undefined);
	}

	return (
		<div className={styles.productView} data-testid="ProductView">
			{
				isLoadingUsers && <SpinnerComponent />
			}
			<div className={styles.productHeader}>
				<div className={styles.headerRow}>
					<div>
						<div className={styles.headerSection}>
							{editMode ?
								<>
									<div className="title">
										<InputText className={`${formValidation(getProduct().name, FieldIdentifiers.PRODUCT_TITLE) !== "" && "p-invalid"}`} value={getProduct().name} onChange={(e) => editableProduct && dispatch(updateEditableProduct({ ...editableProduct, name: e.target.value }))} placeholder="Product Name" />
										{
											checkTitleValidity(getProduct().name, FieldIdentifiers.PRODUCT_TITLE)
										}
									</div>
								</>

								:
								<h1 className={styles.productName}>{getProduct().name}</h1>
							}
							{(getProduct().productType === ProductType.ADDON) &&
								<span className={styles.addonType}>Add-on</span>
							}
							{
								!isAtleastOneProductCompatible() && (getProduct().productType === ProductType.ADDON) && <div className={styles.nonCompatibleIcon}><NonCompatibleIcon /></div>
							}
						</div>
						<div className={styles.productVersion}>
							<span className="p-pr-2">Code: {getProduct().code},
								<Dropdown className={styles.versionSelect}
												value={selectedVersion}
												options={versionDropdownList}
												optionValue='code'
												onChange={(e: any) => onVersionSelect(e.value)}
												optionLabel="name"
												data-testid="solutionVersionDropdown" />
							</span>
							{editMode
								? <Dropdown className={styles.dropdown}
									optionLabel="name" placeholder="Select a type"
									options={[
										{ name: getContractingText(Optionality.mandatory), code: "mandatory" },
										{ name: getContractingText(Optionality.restricted), code: "restricted" },
										{ name: getContractingText(Optionality.optional), code: "optional" }
									]}
									value={getProduct().contracting === Optionality.mandatory ? { name: getContractingText(Optionality.mandatory), code: "mandatory" }
										: getProduct().contracting === Optionality.restricted ? { name: getContractingText(Optionality.restricted), code: "restricted" }
											: getProduct().contracting === Optionality.optional ? { name: getContractingText(Optionality.optional), code: "optional" }
												: { name: "", code: "" }
									}
									onChange={(e) => dispatch(updateContractingInProduct(e.target.value.code))} />
								: <span className={styles.productVersionTags}><Tag className={styles.productNameTag} severity={evaluateSeverity(getProduct().contracting)} >{getContractingText(getProduct().contracting)}</Tag></span>
							}
						</div>
					</div>
					{
						<div className={editMode ? styles.actionButtons : styles.editButton}>

							{(isProductOwner) && (getProduct().status === Status.DRAFT || getProduct().status === Status.REVIEW) && <Button label="Delete Draft" icon="pi pi-trash" className="p-button-outlined  p-button-secondary" onClick={() => confirmDraftDeletion()} disabled={!(getProduct().lockingVersion > 0) || !(areRbacResourcesReady || isRbacInErrorState)} />}
							{getProduct().status === Status.DRAFT && showSaveButton()}
							{getProduct().status !== Status.RETIRED && !editMode && showActionButtons()}
							{(isProductOwner) && getProduct().status === Status.DRAFT &&
								<div>
									<Button label="Send For Approval" icon="pi pi-thumbs-up" className={` ${!editMode ? 'p-button-primary' : 'p-button-secondary'}`} onClick={() => confirmDraftSaveBeforeApproval()} />
								</div>}
							{(isProductOwner) && getProduct().status === Status.REVIEW &&
								<div>
									<Button label="Activate Product" id="activateButton" icon="pi pi-check-circle" className={'p-button-secondary'} onClick={() => confirmDraftActivation(getProduct().name, getProduct().version)} disabled={disableActivateMessage() != ""}
										tooltip={disableActivateMessage()} tooltipOptions={{ appendTo: "self", disabled: disableActivateMessage() == "", showOnDisabled: true, position: "top" }}
									/>
								</div>
							}
						</div>
					}
				</div>
				{/* <Tooltip disabled={(activateProductEnabled && getProduct().lockingVersion > 0)} target={".activate"} position="top">There is no draft version to be activated. <br /> Please save a draft first</Tooltip> */}
			</div>
			{getProduct().status === "draft" && showDraftModeInfo &&
				<div className={`${styles.ribbonBar} ${styles.productDraftVersionRibbon}`}>
					<div className={styles.ribbonInfo}>
						<div className={styles.ribbonInfoIcon}><i className="pi pi-info-circle"></i></div>
						<div>You are viewing a draft version of this {getProduct().productType === ProductType.ADDON ? 'addon' : 'product'}</div>
					</div>
					<div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowDraftModeInfo(false)}></i></div>
				</div>
			}

			{(getProduct().status === "draft") && showNonCompatibleBanner && (getProduct().productType === ProductType.ADDON) && !isAtleastOneProductCompatible() &&
				<div className={`${styles.ribbonBar} ${styles.productNonCompatibilityRibbon}`}>
					<div className={styles.ribbonInfo}>
						<div className={styles.ribbonInfoIcon}><i className="pi pi-info-circle"></i></div>
						<div>This add-on cannot be sold because all the linked products are retired or don’t have matching applicabilities</div>
					</div>
					<div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowNonCompatibleBanner(false)}></i></div>
				</div>
			}

			{isFeatureVersionAvailable() && showFeatureUpgradeInfo &&
				<div className={styles.ribbonBar} data-testid="newFeatureVersionRibbon">
					<div className={styles.ribbonInfo}>
						<div className={styles.ribbonInfoIcon}><i className="pi pi-exclamation-circle"></i></div>
						<div>New feature versions available</div>
					</div>
					<div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowFeatureUpgradeInfo(false)} data-testid="ribbonCloseIcon"></i></div>
				</div>
			}

			{getProduct().status === "draft" && approversRejectedKeys?.length !== 0 && showDraftRejectionInfo &&
				<div className={`${styles.ribbonBar} ${styles.productDraftRejectionRibbon}`}>
					<div className={styles.ribbonInfo}>
						<div className={styles.ribbonInfoIcon}><i className="pi pi-exclamation-triangle"></i></div>
						<div>Your draft has been rejected by one of the approver, <span onClick={() => setTriggerApproverStatus(triggerApproverStatus + 1)} className={styles.link}>click here</span> to view the reason</div>
					</div>
					<div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowDraftRejectionInfo(false)}></i></div>
				</div>
			}

			{getProduct().status === "retired" &&
				<div className={`${styles.ribbonBar} ${styles.productDraftRejectionRibbon}`}>
					<div className={styles.ribbonInfo}>
						<div className={styles.ribbonInfoIcon}><i className="pi pi-exclamation-triangle"></i></div>
						<div>You are viewing a retired version of this product</div>
					</div>
					{/* <div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowDraftRejectionInfo(false)}></i></div> */}
				</div>
			}

			{
				(isGlobalOwner || isProductOwner) && showSendForApprovalDialog &&
				<ApproversDialog postAssignRoleMutation={usePostRoleAssignmentsMutation}
					header={"Send for Approval"} name={getProduct().name} rbacResourceCode={getProduct().rbacResourceCode} rbacResourceChildCode={getProduct().rbacChildResourceCode} productCode={getProduct().code} productVersion={getProduct().version} roleName={ROLES.APPROVER} showParentDialog={showSendForApprovalDialog} setShowParentDialog={setShowSendForApprovalDialog} sendForApproval={sendForApproval} />
			}


			<div className={`mainDisplayCard ${styles.mainCardPosition}`} data-testid="mainDisplayCard">
				<TabView activeIndex={productTabs.indexOf(resolveActiveTab())} onTabChange={(e) => gotoTab(e.index)} data-testid="tabView">
					{/*For now TabPanel order and tabs array order must match*/}
					<TabPanel header="Overview">
						<ProductOverview productFamilyCode={getProduct().family.code} productName={getProduct().name} productVersion={getProduct().version} productCode={getProduct().code} productHeaders={getProduct().headersInfo}
							rbacResourceCode={getProduct().rbacResourceCode} rbacChildResourceCode={getProduct().rbacChildResourceCode}
							shownVersionHeader={getProduct().header} activeVersionHeader={getActiveProduct().header} saveStateFn={saveState} isFormAttibutesValidFn={(isValid: boolean) => setValidity(isValid, 'isOverviewAttributesFormValid')}
							isAuthorisedToEdit={(isGlobalOwner || isProductOwner)} allUsers={allUsers!} approversApproved={_approversApproved} approversRejected={_approversRejected}
							productStatus={getProduct().status} triggerApproverStatus={triggerApproverStatus} refetchProduct={refetchActiveProduct} isApprovalFlow={getProduct().status === Status.REVIEW} setTriggerApproverStatus={setTriggerApproverStatus} productModconStatus={getProduct().templateAvailability}
							usedInSolutions={getProduct().associatedSolutions} applicabilityScope={getProduct().applicability}
						/>
					</TabPanel>
					<TabPanel header="Applicabilities">
						<ApplicabilitiesView familyCode={getProduct().family.code} isScopesValid={(isValid: boolean) => setValidity(isValid, "isScopesValid")} editMode={editMode} scopes={getProduct().applicability} saveStateFn={saveState} isAuthorisedToEdit={(isGlobalOwner || isProductOwner)} headerInfo={getProduct().headersInfo} />
					</TabPanel>
					<TabPanel header="Components" headerClassName={isFeatureVersionAvailable() ? styles.featureUpgradeBadge : ""}>
						<ComponentsView product={getProduct()} activeVersionProduct={getActiveProduct()} saveReordering={saveState} isAuthorisedToEdit={(isGlobalOwner || isProductOwner || isContibutor)} />
					</TabPanel>
					<TabPanel header="Features" headerClassName={isFeatureVersionAvailable() ? styles.featureUpgradeBadge : ""}>
						<ProductFeatures product={getProduct()} isAuthorisedToEdit={(isGlobalOwner || isProductOwner || isContibutor)} />
					</TabPanel>
					{/*<TabPanel header="Commercial Info">*/}
					{/*	<AttributesList attributes={getProduct().salesInfo} activeVersionAttributes={getActiveProduct().salesInfo} resolveIconStyle={resolveAttributeIconFn} saveState={saveState} section={sectionType.SALES_MARKETING} editMode={editMode} isAttributesValid={(isValid: boolean) => setValidity(isValid, "isSalesAndMarketingFormValid")} isAuthorisedToEdit={isProductOwner} />*/}
					{/*</TabPanel>*/}
					<TabPanel header="Compatibilities" headerClassName={((product.productType === ProductType.ADDON) && isAnyProductIncompatible().disabled) ? styles.featureUpgradeBadge : ""}>
						<ProductCompatibilities product={getProduct()} updateCompatibilities={saveState} editMode={editMode} />
					</TabPanel>
					{
						(isGlobalOwner || isProductOwner) &&
						<TabPanel header="Activity" data-testid="ActivityTab" >
							<ActivityComponent type={"products"} code={getProduct().code} />
						</TabPanel>
					}
				</TabView>
				<Dialog visible={showHideRejectionDialog} header={"Reject Approval"} footer={rejectDialogFooter} onHide={() => setShowHideRejectionDialog(false)} style={{ width: '36vw' }}>
					<div className={styles.dialogBody} data-testid="rejectDraftProductDialog">
						<div className={styles.dialogLabel}>Brief reason for rejecting</div>
						<div><InputTextarea rows={8} cols={60} value={rejectionComment} autoResize onChange={(e) => setRejectionComment(e.target.value)} /></div>
					</div>
				</Dialog>
				<Dialog visible={showConfirmCancelApproval} header={"Cancel Approval"} footer={cancelApprovalFooter} onHide={() => setShowConfirmCancelApproval(false)} style={{ width: '36vw' }}>
					<div className={styles.cancelApproval}>Are you sure you want to cancel the approval process and edit more?</div>
				</Dialog>
				<Dialog visible={showActivateProductDialog} header={"Activate Product"} footer={activateProductFooter} onHide={() => setShowActivateProductDialog(false)} style={{ width: '36vw' }}>
					<div className={styles.dialogBody}>
						<div className={styles.dialogLabel}>Brief reason for rejecting</div>
						<div><InputTextarea rows={8} cols={60} value={rejectionComment} autoResize onChange={(e) => setRejectionComment(e.target.value)} /></div>
					</div>
				</Dialog>
			</div>
			{(showErrorMsgPopup && errorMsgArr.length > 0) && <ShowListElementsInPopUp errorMsgArr={errorMsgArr} onClose={closeErrorMsgPopup} footer={errorMsgPopUpFooter} header={"Missing Information"}></ShowListElementsInPopUp>}
		</div>
	);

};

export default ProductView;
