import { AnchorUserAssignment, approverDesignationEmailMap, FAMILIES_CODES, User } from "../../data/model/DataModels";
import { EnvConfig } from "../../EnvConfig";

export const getResourceNameFromProductCodeAndVersion = (productCode: string, version: number) => {
  return `${productCode.replaceAll("-", " ").toLowerCase()} ${version}`;
}

export const mapAnchorUserToGraphUser = (anchorUser: AnchorUserAssignment): User => {
  return {
    id: anchorUser.user_idp,
    displayName: anchorUser.name,
    mail: anchorUser.email,
  } as User
}

export const sortApproverList = (users : User[]) => {
  if(users === undefined) return [] as User[]
  const defaultApproversEmail = Object.keys(approverDesignationEmailMap);
  const defaultApprovers = users.filter(user => defaultApproversEmail?.includes(user.mail))
  const nonDefaultApprovers = users.filter(user => !defaultApproversEmail?.includes(user.mail));
  return [...defaultApprovers, ...nonDefaultApprovers];
}
