import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import SpinnerComponent from "../../../../components/Spinner/SpinnerComponent";
import {
	ComponentDefinition,
	SpecificationParameterDefinition,
} from "../../../../data/model/DataModels";
import { useGetComponentByRefQuery } from "../../../../data/api/CatalogueApi";
import styles from "./DisplaySpecificationParameterCards.module.scss";
import DisplayCardsComponent, { DisplayCardElement } from "../../../../components/DisplayCards/DisplayCardsComponent";

interface DisplaySpecificationParameterCardsProps {
	componentRef: string,
	header?: string,
	actionShowDialog: Function,
	acceptAction: Function,
	activeSpecificationParameters: string[];
	featureRef: string;
}

const DisplaySpecificationParameterCards: React.FC<DisplaySpecificationParameterCardsProps> = ({ componentRef, header = "", actionShowDialog, acceptAction, activeSpecificationParameters, featureRef }) => {

	const [specParamToAdd, setSpecParamToAdd] = useState([] as SpecificationParameterDefinition[]);
	const specParamToAddCodes = specParamToAdd.map(spec => spec.code);

	const { data: componentDetails = {} as ComponentDefinition, error: errorComponent, isLoading: isLoadingComponent } = useGetComponentByRefQuery(componentRef);
	const toast = useRef<any>();

	if (errorComponent) {
		toast?.current?.show({ severity: 'error', summary: 'Failed to fetch Specification Parameters', detail: "Please try reloading", life: 10000 });
	}

	if (isLoadingComponent) {
		return <SpinnerComponent></SpinnerComponent>;
	}

	const getSpecParamByCode = (code: string): SpecificationParameterDefinition => {
		return componentDetails.features?.flatMap(feature => feature.specificationParameters).find(specParam => specParam.code === code)!;
	}


	const selectSpecParam = (element: DisplayCardElement) => {
		let specParam = getSpecParamByCode(element.code);
		if (specParamToAdd.includes(specParam)) {
			setSpecParamToAdd(specParamToAdd.filter(specParamToAdd => specParam.code !== specParamToAdd.code))
		} else {
			setSpecParamToAdd([...specParamToAdd, specParam]);
		}
	}

	const cancelAction = () => {
		actionShowDialog(false);
	}

	const mapElementList = (): DisplayCardElement[] => {
		return componentDetails?.features.filter(feature => feature.code === featureRef).flatMap(feature => feature.specificationParameters).filter(specParam => !activeSpecificationParameters?.includes(specParam.code)).map(specParam => {
			return { code: specParam.code, name: specParam.name };
		})
	}

	const footer = (
		<div className={styles.dialogFooter}>
			<Button label="Cancel" icon="pi pi-times" onClick={() => cancelAction()} />
			<Button label="Add" data-testid="addSpecParamButton" icon="pi pi-check" onClick={() => { acceptAction(specParamToAdd); actionShowDialog(false) }} disabled={specParamToAdd.length === 0} />
		</div>
	);

	return (
		<Dialog data-testid="specParamDilogBox" footer={footer} header={header + " Specification Parameters"} visible className={styles.dialogSize} onHide={() => actionShowDialog(false)}>
			<div className={styles.dialogContent}>
				<DisplayCardsComponent action={selectSpecParam}
					selectedCodes={specParamToAddCodes}
					dialogView={true} editMode={true} elementList={mapElementList()}></DisplayCardsComponent>
			</div>
		</Dialog>
	)
}

export default DisplaySpecificationParameterCards;
