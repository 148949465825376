import { type } from "os";
import { Dialog } from "primereact/dialog";
import styles from "./AddSolutionComponentDialog.module.scss";
import { FunctionComponent, useState } from "react";
import { solutionViewComponentTypeFilterList, SolutionComponentOrigin } from "../../data/model/DataModels";
import { ReactComponent as Flow } from '../../assets/icons/maersk/flow.svg';
import {ReactComponent as GlobeComponent } from '../../assets/icons/maersk/globe.svg';
import { Button } from "primereact/button";

interface AddSolutionComponentDialogProps {
	setDialogVisibleFn: Function
}

const AddSolutionComponentDialog: FunctionComponent<AddSolutionComponentDialogProps> = ({setDialogVisibleFn }) => {

	const [componentType, setComponentType] = useState('' as SolutionComponentOrigin);

	const footer = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => setDialogVisibleFn(SolutionComponentOrigin.NONE)} />
			<Button label={"Next"} icon="pi pi-check" onClick={() => onNext()}  disabled={componentType.length === 0}
				tooltip={ "Please select component" } tooltipOptions={{ showOnDisabled: true , disabled:componentType.length !== 0}} />
		</div>
	);

	const closeDialog = () => {
		setDialogVisibleFn(SolutionComponentOrigin.NONE);
	}

	const onNext = () => {
		componentType === SolutionComponentOrigin.COMPONENT_LIBRARY ? setDialogVisibleFn(componentType) : setDialogVisibleFn(SolutionComponentOrigin.COMPONENT_FROM_PRODUCT);
	}

	return (
		<Dialog visible={true} onHide={() => closeDialog()}
			header={"Add component"} footer={footer}
			className={styles.addSolutionComponentDialog} >
			<div className={styles.sectionBlock} data-testid = "componentOriginPopUp">
				<div className={styles.inputGroup}>
					<div className={styles.type}>
						{ solutionViewComponentTypeFilterList.map(solutionViewComponentType => {
							return 	<div className={`${styles.typeBox} ${componentType===solutionViewComponentType.type ? styles.selected : ''}`} 
												onClick={() =>  {setComponentType(solutionViewComponentType.type)}} key={solutionViewComponentType.type} data-testid={`productType-${solutionViewComponentType.type}`}>
												{ solutionViewComponentType.type === SolutionComponentOrigin.COMPONENT_LIBRARY ? <GlobeComponent/> : <Flow/> }
												<div><b>{solutionViewComponentType.name}</b></div>
												<div>{solutionViewComponentType.description}</div>
												<b>{solutionViewComponentType.boldValue}</b>
											</div>
							}) 
						}
					</div>
					</div>
			</div>
		</Dialog>
	)
}

export default AddSolutionComponentDialog;