import { FC, useState } from "react";
import { ProductListing, serviceModelTypeDropDownList } from "../../data/model/DataModels";
import styles from './SolutionFilter.module.scss';
import { Checkbox } from "primereact/checkbox";
import { HeaderTypes } from "../../data/model/DataModels";
import { useGetAllPopUpValuesQuery } from "../../data/api/CatalogueApi";
import { MultiSelect } from "primereact/multiselect";



export interface SolutionFilterProps {
	onClose: Function
	setSelectedFilters: Function
	serviceNameFilters: string[];
	verticalNameFilters: string[];
	valuePropositionNameFilters: string[];
	productNameFilters: string[];
	solutionProducts: ProductListing[];
}

export const solutionFilterQueryParams = {
	SERVICEMODAL_FILTER_QUERY_KEY: "serviceNameFilter",
	VERTICAL_FILTER_QUERY_KEY: "verticalNameFilter",
	VALUEPROPOSITION_FILTER_QUERY_KEY: "valuePropositionNameFilter",
	SOLUTION_STATUS_FILTER_QUERY_KEY: "status",
	PRODUCT_FILTER_QUERY_KEY: "productFilter"
}

const SolutionFilter: FC<SolutionFilterProps> = ({ onClose, setSelectedFilters, serviceNameFilters, verticalNameFilters, valuePropositionNameFilters, productNameFilters, solutionProducts }) => {

	const { data: allVerticalValues = [], isLoading: isLoadingAllVerticalValues } = useGetAllPopUpValuesQuery(HeaderTypes.VERTICAL);
	const { data: allPropositionValues = [], isLoading: isLoadingAllPropositionsValues } = useGetAllPopUpValuesQuery(HeaderTypes.VALUE_PROPOSITION);
	const serviceModalList = serviceModelTypeDropDownList;
	const verticalFilters = allVerticalValues;
	const valuePropositionFilters = allPropositionValues;
	const [expandFilter, setExpandFilter] = useState({ serviceModal: true, vertical: true, valueProposition: true, product: true } as { serviceModal: boolean, vertical: boolean, valueProposition: boolean, product: boolean });
	const [selectedProduct, setSelectedProduct] = useState(productNameFilters);

	const toggleServiceModelFilter = () => {
		setExpandFilter({ ...expandFilter, serviceModal: !expandFilter.serviceModal });
	}

	const toggleVerticalFilter = () => {
		setExpandFilter({ ...expandFilter, vertical: !expandFilter.vertical });
	}

	const toggleValuePropositionFilter = () => {
		setExpandFilter({ ...expandFilter, valueProposition: !expandFilter.valueProposition });
	}

	const toggleProductFilter = () => {
		setExpandFilter({ ...expandFilter, product: !expandFilter.product });
	}

	const selectAllServiceModels = () => {
		setSelectedFilters(serviceModalList.map(service => service.code), solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
	}

	const deselectAllServiceModels = () => {
		setSelectedFilters([], solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY);
	}

	const selectAllVerticals = () => {
		setSelectedFilters(verticalFilters.map(vertical => vertical.code), solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY)
	}

	const deSelectAllVerticals = () => {
		setSelectedFilters([], solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY);
	}

	const selectAllValuePropositions = () => {
		setSelectedFilters(valuePropositionFilters.map(valueProposition => valueProposition.code), solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY)
	}

	const deSelectAllValuePropositions = () => {
		setSelectedFilters([], solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY);
	}


	const onCheck = (serviceModelCode: string, isChecked: boolean) => {
		if (!isChecked) {
			setSelectedFilters(serviceNameFilters.filter(serviceFilterCode => serviceFilterCode !== serviceModelCode), solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
		}
		else setSelectedFilters([...serviceNameFilters, serviceModelCode], solutionFilterQueryParams.SERVICEMODAL_FILTER_QUERY_KEY)
	}

	const onVerticalCheck = (verticalCode: string, isChecked: boolean) => {
		if (!isChecked) {
			setSelectedFilters(verticalNameFilters.filter(verticalFilterCode => verticalFilterCode !== verticalCode), solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY)
		}
		else setSelectedFilters([...verticalNameFilters, verticalCode], solutionFilterQueryParams.VERTICAL_FILTER_QUERY_KEY)
	}

	const onValuePropositionCheck = (valuePropositionCode: string, isChecked: boolean) => {
		if (!isChecked) {
			setSelectedFilters(valuePropositionNameFilters.filter(valuePropositionFilterCode => valuePropositionFilterCode !== valuePropositionCode), solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY)
		}
		else setSelectedFilters([...valuePropositionNameFilters, valuePropositionCode], solutionFilterQueryParams.VALUEPROPOSITION_FILTER_QUERY_KEY)
	}

	const onProductCheckUncheck = (productCode: string, isChecked: boolean) => {
		if (isChecked) {
			setSelectedFilters(productNameFilters.filter(code => code !== productCode), solutionFilterQueryParams.PRODUCT_FILTER_QUERY_KEY)
		}
		else {
			setSelectedFilters([...productNameFilters, productCode], solutionFilterQueryParams.PRODUCT_FILTER_QUERY_KEY)
		}
	}

	const toggleFilter = (filter: boolean) => {
		return `pi ${filter ? 'pi-angle-up' : 'pi-angle-down'} ${styles.icon}`
	}



	return (

		<div className={styles.SolutionFilter} data-testid="SolutionFilter">
			<div>
				<div className={styles.header}>
					<h2>Filters</h2>
					<div onClick={() => onClose()} className={`${"pi pi-times"} ${styles.closeIcon}`}></div>
				</div>
				<hr />
				<div className={styles.subHeader}>
					<h3>Service model</h3>
					<div onClick={() => toggleServiceModelFilter()} className={toggleFilter(expandFilter.serviceModal)} data-testid="serviceModelToggleArrow"></div>
				</div>
				<div className={` ${styles.filterSection} ${!expandFilter.serviceModal ? styles.hidden : ''}`} data-testid="serviceModelFilters">
					<div className={styles.filterActions}>
						<div className={styles.filterAction} onClick={() => selectAllServiceModels()}>Select All</div>
						<div className={styles.filterAction} onClick={() => deselectAllServiceModels()}>Deselect All</div>
					</div>
					{
						serviceModalList.map(serviceModel =>
							<div key={serviceModel.code} className={styles.checkList}>
								<span className={styles.checkbox}>
									<Checkbox inputId={serviceModel.code} checked={serviceNameFilters.includes(serviceModel.code)} onChange={(e) => onCheck(serviceModel.code, (e.checked || false))} aria-label="serviceModelFilterCheckbox" />
								</span>
								<label htmlFor={serviceModel.code}>{serviceModel.name}</label>
							</div>
						)
					}
				</div>
			</div>
			<hr />
			<div className={styles.subHeader}>
				<h3>Product</h3>
				<div onClick={() => toggleProductFilter()} className={toggleFilter(expandFilter.product)} data-testid="productToggleArrow"></div>
			</div>
			<div className={` ${styles.filterSection} ${!expandFilter.product ? styles.hidden : ''}`} data-testid="productFilters">
				<MultiSelect
					className={styles.productSearchMultiselect}
					optionLabel="name"
					optionValue="code"
					placeholder="Select product"
					value={selectedProduct}
					options={solutionProducts.sort((a, b) => a.name.localeCompare(b.name))}
					showSelectAll={false}
					onChange={(e) => {
						setSelectedProduct(e.value);
						//Below ariaSelected property is being used because "checked" property is not capturing the selection
						onProductCheckUncheck(e.selectedOption.code, (Boolean(e.originalEvent?.currentTarget?.ariaSelected === 'true') || false));
					}}
					filter
				/>
			</div>
			{
				!isLoadingAllVerticalValues &&
				<>
					<div className={styles.subHeader}>
						<h3>Vertical</h3>
						<div onClick={() => toggleVerticalFilter()} className={toggleFilter(expandFilter.vertical)} data-testid="verticalToggleArrow"></div>
					</div>
					<div className={` ${styles.filterSection} ${!expandFilter.vertical ? styles.hidden : ''}`} data-testid="verticalFilters">
						<div className={styles.filterActions}>
							<div className={styles.filterAction} onClick={() => selectAllVerticals()}>Select All</div>
							<div className={styles.filterAction} onClick={() => deSelectAllVerticals()}>Deselect All</div>
						</div>


						{
							verticalFilters.map(vertical =>
								<div key={vertical.code} className={styles.checkList}>
									<span className={styles.checkbox}>
										<Checkbox inputId={vertical.code} checked={verticalNameFilters.includes(vertical.code)} onChange={(e) => onVerticalCheck(vertical.code, (e.checked || false))} aria-label="verticalFilterCheckbox" />
									</span>
									<label htmlFor={vertical.code}>{vertical.name}</label>
								</div>
							)
						}
					</div>
				</>
			}

			{
				!isLoadingAllPropositionsValues &&
				<>
					<div className={styles.subHeader}>
						<h3>Value Proposition</h3>
						<div onClick={() => toggleValuePropositionFilter()} className={toggleFilter(expandFilter.valueProposition)} data-testid="verticalToggleArrow"></div>
					</div>
					{expandFilter.valueProposition && <div className={`${styles.filterSection}`} data-testid="verticalFilters">
						<div className={styles.filterActions}>
							<div className={styles.filterAction} onClick={() => selectAllValuePropositions()}>Select All</div>
							<div className={styles.filterAction} onClick={() => deSelectAllValuePropositions()}>Deselect All</div>
						</div>


						{
							valuePropositionFilters.map(valueProposition =>
								<div key={valueProposition.code} className={styles.checkList}>
									<span className={styles.checkbox}>
										<Checkbox inputId={valueProposition.code} checked={valuePropositionNameFilters.includes(valueProposition.code)} onChange={(e) => onValuePropositionCheck(valueProposition.code, (e.checked || false))} aria-label="valuePropositionFilterCheckbox" />
									</span>
									<label htmlFor={valueProposition.code}>{valueProposition.name}</label>
								</div>
							)
						}
					</div>
					}
				</>
			}
		</div>

	)

}

export const setSelectedFilters = (selectedFilters: string[], searchParams: URLSearchParams, setSearchParams: Function, filterType: string) => {
	if (!selectedFilters?.length) {
		searchParams?.delete(filterType)
		setSearchParams(searchParams);
		return;
	}
	searchParams.set(filterType, selectedFilters?.join(','));
	setSearchParams(searchParams);
}

export default SolutionFilter;