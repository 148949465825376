import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useMemo, useState } from "react";
import styles from "../DimensionsDialogBox/DimensionsDialogBox.module.scss";
import { HeaderDefinition, HeaderOptions, ProductHeaders } from "../../data/model/DataModels";
import { cloneDeep } from "lodash";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getHeaderIcon } from "../HeaderSection/HeaderHelper";

export interface HeaderDialogBoxProps {
	headerName: string,
	descriptionText: Function,
	applyHeaders: Function,
	selectedHeaders: string[],
	closeHeaderDialogBox: Function,
	setselectedHeaders: Function,
	enrichedProductHeaders: ProductHeaders,
	allHeadersDefinitions: HeaderDefinition[],
	defaultHeaders: string[],
	modifySelectedHeaders: Function
}

const HeaderDialogBox: FC<HeaderDialogBoxProps> = ({ headerName, descriptionText, applyHeaders, closeHeaderDialogBox, selectedHeaders, setselectedHeaders, enrichedProductHeaders, allHeadersDefinitions, defaultHeaders, modifySelectedHeaders }) => {
	const [searchInput, setSearchInput] = useState("");
	const [allHeaders, setAllHeaders] = useState([] as HeaderDefinition[]);
	const [selectedAndNewHeaders, setSelectedAndNewHeaders] = useState(enrichedProductHeaders);

	const selectedHeadersBackup = useMemo(() => {
		return selectedHeaders;
	}, []);

	useEffect(() => {
		setAllHeaders(allHeadersDefinitions.filter(data => data.name.toLowerCase().includes(searchInput.toLowerCase())));
	}, [searchInput]);

	const onStatusChange = (code: string) => {
		const selectedHeader = allHeaders.find(header => header.code === code);

		if (selectedHeader) {
			const newHeader = { [code]: [] as HeaderOptions[] } as ProductHeaders;

			if (selectedHeaders.includes(selectedHeader.code)) {
				setselectedHeaders(selectedHeaders.filter(headerCode => headerCode !== selectedHeader.code));
				const clone = cloneDeep(selectedAndNewHeaders);
				const remainingObjectEntries = Object.entries(clone).map(header => {
					return header[0] === code ? [header[0], null] : header;
				})
				const remainingObject = Object.fromEntries(remainingObjectEntries);
				setSelectedAndNewHeaders(remainingObject);
			}
			else {
				setselectedHeaders([...selectedHeaders, code]);
				setSelectedAndNewHeaders({ ...selectedAndNewHeaders, ...newHeader } as ProductHeaders);
			}
		}
	}

	const isDefaultHeader = (headerCode: string) => {
		return defaultHeaders.includes(headerCode)
	}

	const isToggleDisabled = (headerCode: string) => {
		return isDefaultHeader(headerCode);
	}

	const isSelectedHeader = (headerCode: string) => {
		return selectedHeaders.includes(headerCode);
	}

	const getExamples = (examples: string[]) => {
		let examplesCopy = cloneDeep(examples);
		if (examplesCopy.length === 1) {
			return <>{examplesCopy[0].concat(" etc.")}</>
		}
		if (examplesCopy.length >= 2) {
			examplesCopy.length = 2;
			return <>{examplesCopy.join(", ").concat(" etc.")}</>
		}

	}

	const checkHeaderDisability = (headerDef: HeaderDefinition): { isDisabled: boolean, reason: string, cardStyle: string } => {
		if (isDefaultHeader(headerDef.code)) {
			return { isDisabled: true, reason: "You cannot remove a default header", cardStyle: styles.disableDimensionThumbnail };
		}
		else if (isSelectedHeader(headerDef.code)) {
			return { isDisabled: true, reason: "", cardStyle: styles.selectedDimensionThumbnail };
		}
		return { isDisabled: false, reason: "", cardStyle: "" };
	}

	const headerCard = (headerDef: HeaderDefinition) => {
		return (
			<div data-testid={headerDef.code} key={headerDef.code} className={`${styles.dimensionCard} ${checkHeaderDisability(headerDef).cardStyle}`}>
				<div className={styles.headerAndDescription}>
					<div className={styles.header}>
						<div className={styles.imageType2} >
							{getHeaderIcon(headerDef.code)}
						</div>
						<div><h3>{headerDef.name}</h3></div>
						<div className={styles.actionButton}>
							<InputSwitch data-testid={`${headerDef.code}-switch`} className={isDefaultHeader(headerDef.code) ? styles.inputSwitchSelectedDisabled : isDefaultHeader(headerDef.code) ? styles.inputSwitch : styles.inputSwitchSelected}
								checked={isSelectedHeader(headerDef.code)}
								onChange={() => onStatusChange(headerDef.code)}
								tooltip={checkHeaderDisability(headerDef).reason}
								disabled={isToggleDisabled(headerDef.code)}
								tooltipOptions={{ showOnDisabled: true, disabled: !checkHeaderDisability(headerDef).isDisabled, showEvent: 'click', position: "left" }}
							/>
						</div>
					</div>
					<div className={styles.description}>{headerDef.description}</div>
				</div>
				<div className={styles.examples}>
					{getExamples(headerDef.examples)}
				</div>
			</div>
		);
	}

	const closeHeaderDialogueBox = () => {
		closeHeaderDialogBox();
	};

	const footer = (
		<div>
			<Button label="Cancel" className="p-button p-component p-button-outlined p-button-secondary default_button" onClick={() => {
				setselectedHeaders(selectedHeadersBackup);
				closeHeaderDialogueBox();
			}} />
			<Button label="Apply" onClick={() => {
				//setEnrichedProductHeaders && setEnrichedProductHeaders(selectedAndNewHeaders);
				applyHeaders(selectedAndNewHeaders);
			}} />
		</div>
	);

	return (
		<>
			<Dialog footer={footer} header={headerName} visible={true} onHide={() => closeHeaderDialogueBox()} >
				<div className={styles.DefaultDimensions} data-testid="headerDialog">
					{descriptionText()}
					<div>
						<span className={`p-input-icon-left leftContent ${styles.searchBar}`}>
							<i className="pi pi-search" />
							<InputText className={styles.searchBar} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Search" />
						</span>
					</div>
					<div className={styles.cardGrid}>
						{allHeaders.map((header) => {
							return headerCard(header);
						})}
					</div>
				</div>
			</Dialog>
		</>
	)
}

export default HeaderDialogBox;

