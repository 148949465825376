import React, {FC, useEffect, useState} from 'react';
import {DisplayCardElement} from '../../../../components/DisplayCards/DisplayCardsComponent';
import styles from './ComponentReorderView.module.scss';
import {closestCorners, DndContext, DragOverlay} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import SortableItem from '../../../../components/SortableItem/SortableItem';
import {MdDragIndicator} from 'react-icons/md';

export interface ComponentReorderProps {
  elementList: DisplayCardElement[];
  saveOrdering: Function;
}

const ComponentReorderView: FC<ComponentReorderProps> = ({ elementList, saveOrdering }) => {
  const [cards, setCards] = useState(elementList);
  const [activeId, setActiveId] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    saveOrdering(cards);
  }, [cards])

  const style = {
    boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.25)",
    outline: "2px solid #42B0D5",
    background: "white"
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const dragIndex = cards.indexOf(cards.find(item => item.code === active.id)!);
      const hoverIndex = cards.indexOf(cards.find(item => item.code === over.id)!);
      let newOrder = arrayMove(cards, dragIndex, hoverIndex);
      setCards(newOrder);
    }
    setActiveId(null);
    setIsDragging(false);

  }

  const getComponentCard = (component: DisplayCardElement, style: any = null) => {
    return (
      <div style={style} className={`${styles.draggableBox} ${isDragging ? styles.grabbingCursor : styles.grabCursor} sortableItem`}>
        <MdDragIndicator style={{ fontSize: "x-large" }} />
        {component.name}
      </div>
    )
  }

  const handleDragStarts = (event: any) => {
    setActiveId(event.active.id);
    setIsDragging(true);
  }

  return (
    <div className={styles.ComponentReorder} data-testid="ComponentReorder">
      <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd} onDragStart={handleDragStarts} >
        <SortableContext items={cards as any[]} strategy={verticalListSortingStrategy}>
          {cards.map(card => <SortableItem key={card.code} id={card.code} content={getComponentCard(card)} dragEnable={true} />)}
        </SortableContext>
        <DragOverlay >
          {activeId ? getComponentCard(cards.find(card => card.code === activeId)!, style) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

export default ComponentReorderView;
