import React, { FC, useState } from 'react';
import styles from './UsedInSolutions.module.scss';
import { Dropdown } from 'primereact/dropdown';
import { Status, UsedInSolution } from '../../../../data/model/DataModels';
import { RiShoppingCart2Line as Vertical } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PATHS from '../../../../Paths';




export interface usedInSolutionsProps {
	solutions: UsedInSolution[];
	isInsideSolutionProductUpgradePopup?: boolean
}

const UsedInSolutions: FC<usedInSolutionsProps> = ({ solutions, isInsideSolutionProductUpgradePopup = false }) => {
	const navigate = useNavigate();

	const featureTypeFilterLabels = [
		{ name: "All solutions", code: "all" },
		{ name: "Active only", code: Status.ACTIVE },
		{ name: "Draft only", code: Status.DRAFT },
		{ name: "Retired only", code: Status.RETIRED },
	];

	const defaultOption = featureTypeFilterLabels[0];
	const [selectdOption, setSelectedOption] = useState(defaultOption);

	const filteredSolutions = selectdOption.code === 'all' ? solutions : solutions?.filter(solution => solution.status === selectdOption.code);

	function onChangeOfStatus(e: any) {
		const selected = featureTypeFilterLabels.find(option => option.code === e.target.value) || defaultOption;
		setSelectedOption(selected);
	}

	function getSolutionByStatusPlaceholder(featureTypeFilterLabel: string): string {
		return "Show: " + featureTypeFilterLabel;
	}

	const navigateToSolution = (code: string, version: number) => {
		navigate(`/solutions/${code}/version/${version}`)
	}

	const goToSolution = (code: string, version: number) => {
		window.open(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", code).replace(":version", version.toString()))
	}

	return (
		<div className={styles.usedInSolutions} data-testid="usedIn">
			<h3>Used in</h3>
			<div className={styles.solutionByStatus} data-testid="solutionDropdown">
				<label data-testid="solutionsLabel" className={styles.solutionsLable}>{filteredSolutions?.length} solution{filteredSolutions?.length > 1 ? 's' : ''}</label>
				<Dropdown
					value={selectdOption}
					onChange={(e) => {
						onChangeOfStatus(e)
					}}
					options={featureTypeFilterLabels}
					optionLabel="name"
					optionValue="code"
					placeholder={getSolutionByStatusPlaceholder(selectdOption.name)}
					className="w-full md:w-14rem"
					data-testid="solutionStatus"
				/>
			</div>
			{
				filteredSolutions?.map((solution, index) => (
					<div key={index} className={styles.displaySolutionList} >
						<i> < Vertical /> </i> <span className={styles.pointer} onClick={() => {
							isInsideSolutionProductUpgradePopup ? goToSolution(solution.code, solution.version) : navigateToSolution(solution.code, solution.version)
						}}>{`${solution.name} v${solution.version} - ${solution.status}`}</span>
					</div>
				))
			}
		</div>
	)


}

export default UsedInSolutions;