import React, {lazy, Suspense} from 'react';
import {ComponentsViewProps} from './ProductComponents';

const LazyComponentsView = lazy(() => import('./ProductComponents'));

const ComponentsView = (props: ComponentsViewProps & JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyComponentsView {...props} />
  </Suspense>
);

export default ComponentsView;
