import { UsersApi } from './../data/api/UsersApi';
import { configureStore, ThunkAction, Action, PreloadedState, combineReducers } from "@reduxjs/toolkit";
import { CatalogueApi } from "../data/api/CatalogueApi";
import featureFilterReducer from "../components/FilterPanel/featureFilterSlice";
import productReducer from "../data/slices/productSlice";
import filterReducer from "../data/slices/filterSlice";
import newProductReducer from "../data/slices/newProductSlice";
import { RefDataApi } from "../data/api/RefDataApi";
import { GraphApi } from '../data/api/GraphApi';

const rootReducer = combineReducers({
	// API reducers
	[CatalogueApi.reducerPath]: CatalogueApi.reducer,
	[RefDataApi.reducerPath]: RefDataApi.reducer,
	[UsersApi.reducerPath]: UsersApi.reducer,
	[GraphApi.reducerPath]: GraphApi.reducer,
	// Domain reducers
	featureFilter: featureFilterReducer,
	product: productReducer,
	filter: filterReducer,
	newProduct: newProductReducer
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(
				CatalogueApi.middleware,
				RefDataApi.middleware,
				UsersApi.middleware,
				GraphApi.middleware
			),
		preloadedState
	});
}
//helper function for test only
// export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
// 	return {...store, preloadedState: preloadedState};
// }

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

