import { cloneDeep } from 'lodash';
import { Editor } from 'primereact/editor';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RichTextCustomHeaderComponent, { sanitizeHTML } from '../../../../components/RichTextCustomHeader/RichTextCustomHeaderComponent';
import SpinnerComponent from '../../../../components/Spinner/SpinnerComponent';
import { getResourceNameFromProductCodeAndVersion, mapAnchorUserToGraphUser, sortApproverList } from '../../../../components/utils/userUtils';
import { ProductAndResources, ProductQuery, useGetAllRolesForProductQuery, useGetAllUsersForProductQuery, useGetProductByCodeAndVersionForGettingResourceCodeQuery, usePostRetryGettingUsersForProductMutation } from '../../../../data/api/CatalogueApi';
import { AllUserData, ProductHeaders, ROLES, Status, User } from '../../../../data/model/DataModels';
import { AttributeCollection, sectionType } from '../../../../data/model/SharedDataInterfaces';
import { updateResourceCodes } from '../../../../data/slices/productSlice';
import { FieldIdentifiers, formValidation } from '../../../../utils/validationUtil';
import styles from './OverviewForm.module.scss';
import { hasGlobalOwnerRole, hasRole, useAnchor } from '../../../../authorisationService';
import { ToasterContext } from '../../../AppLayoutView/AppLayoutView';
import UserListForRoleV2 from '../../../UserListForRoleV2/UserListForRoleV2';




export interface OverviewFormProps {
  formFields: AttributeCollection,
  activeVersionFormFields?: AttributeCollection,
  saveState: Function,
  section: sectionType,
  editMode?: boolean,
  isFormValid: Function,
  productName: string,
  productVersion: number,
  productFamilyCode: string,
  productCode: string,
  productStatus: string,
  rbacResourceCode: string,
  rbacResourceChildCode: string,
  isAuthorisedToEdit: boolean,
  allUsers: AllUserData,
  approversApproved: string[],
  approversRejected: { [key: string]: string },
  triggerApproverStatus?: number,
  setTriggerApproverStatus?: Function,
  refetchProduct?: Function,
  isApprovalFlow: boolean,
  productHeaders: ProductHeaders,
  isInsideSolutionProductUpgradePopup?: boolean
}

interface DescriptionField {
  description: string,
  descriptionRichText: string
}

const OverviewForm: FC<OverviewFormProps> = ({ productName, productVersion, productHeaders, productCode, productFamilyCode, rbacResourceCode, rbacResourceChildCode, formFields, activeVersionFormFields, saveState, section, editMode = false, isFormValid, isAuthorisedToEdit = false, approversApproved, approversRejected, productStatus, triggerApproverStatus = 0, setTriggerApproverStatus, refetchProduct, isApprovalFlow = false, isInsideSolutionProductUpgradePopup }) => {
  const dispatch = useDispatch();
  const areRbacResourcesReady = (rbacResourceCode && rbacResourceChildCode && rbacResourceCode != "" && rbacResourceChildCode != '' && rbacResourceCode != "error" && rbacResourceChildCode != "error") || false;
  const isRbacInErrorState = (rbacResourceCode == "error" || rbacResourceChildCode == "error") || false;
  const isGlobalOwner = hasGlobalOwnerRole();
  const isProductOwner = hasRole([productCode + "-" + productVersion], ROLES.OWNER);


  const { data: allRoles, isLoading: isLoadingRoles, isError: isErrorRoles } = useGetAllRolesForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(productCode, productVersion), resourceCode: rbacResourceCode, resourceChildCode: rbacResourceChildCode } as ProductAndResources, { skip: !areRbacResourcesReady });
  const { data: productForResourceCode, isLoading: isLoadingProductForResourceCode } = useGetProductByCodeAndVersionForGettingResourceCodeQuery({ code: productCode, version: productVersion.toString() } as ProductQuery, { pollingInterval: 5000, skip: areRbacResourcesReady || isRbacInErrorState });
  const { data: allUsers, isLoading: isLoadingUsers, isError: isErrorUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(productCode, productVersion), resourceCode: rbacResourceCode, resourceChildCode: rbacResourceChildCode } as ProductAndResources, { skip: !areRbacResourcesReady });
  const [isMounted, setIsMounted] = useState(false);
  const [description, setDescription] = useState({ description: '', descriptionRichText: '' });
  const [postRetryGettingUsersForProduct, { isLoading: isLoadingRetryGettingUsersForProduct }] = usePostRetryGettingUsersForProductMutation();
  const { refreshAnchorToken } = useAnchor();



  const handleChanges = (value: any, index: any) => {
    const editableAttributes = cloneDeep(formFields);
    editableAttributes[index] = value != null ? value : "";
    saveState(section, editableAttributes);
  };

  let invalidFields: string[] = [];

  const toaster = useContext(ToasterContext);

  useEffect(() => {
    if (productForResourceCode?.rbacResourceCode && productForResourceCode?.rbacChildResourceCode) {
      refreshAnchorToken();
      refetchProduct && refetchProduct();
      dispatch(updateResourceCodes(productForResourceCode));
    }
  }, [productForResourceCode])


  useEffect(() => {
    if (invalidFields.length === 0) {
      isFormValid(true);
    } else {
      isFormValid(false);
    }
  });

  useEffect(() => {
    if (isMounted === false)
      setIsMounted(true);
  }, [])

  useEffect(() => {
    if (isMounted) {
      const editableAttributes = cloneDeep(formFields);
      editableAttributes["description"] = description.description;
      editableAttributes["descriptionRichText"] = description.descriptionRichText;
      saveState(section, editableAttributes);
    }
  }, [description]);

  if (isErrorRoles) {
    toaster.showToast('error', `Failed to fetch Roles for this product. Please refresh.`);
  }
  if (isErrorUsers) {
    toaster.showToast('error', `Failed to fetch Users for this product. Please refresh.`);
  }

  const checkFormValidity = (value: string, fieldName: string) => {
    let validityResult: any;
    if (fieldName == "description") {
      validityResult = formValidation(value, FieldIdentifiers.ATTRIBUTES_DESCRIPTION);
    } else {
      validityResult = formValidation(value, FieldIdentifiers.ATTRIBUTES);
    }
    if (validityResult !== "") {
      if (!invalidFields.includes(fieldName)) {
        invalidFields = [...invalidFields, fieldName];
      }
    } else {
      if (invalidFields.length > 0) {
        invalidFields = invalidFields.filter(field => field !== fieldName);
      }

    }
    return validityResult;
  }

  const checkIfStringIsNotUndefinedNullEmpty = (inputString: string) => {
    if (inputString === "" || inputString === undefined || inputString === null) {
      return false;
    }
    return true;
  }


  const updateDescription = (textValue: string, htmlValue: string) => {
    setDescription({ description: textValue, descriptionRichText: htmlValue });
  }


  const containsChanges = (attrIndex: string) => {
    if (activeVersionFormFields) {
      return formFields[attrIndex]?.toString() != activeVersionFormFields[attrIndex]?.toString()
    }
    return true;
  };

  const getRoleId = (roleName: ROLES): string => {
    if (allRoles) {
      return allRoles[roleName]?.roles_id;
    }
    return "";
  }

  const retryCreatingAnchorResources = () => {
    postRetryGettingUsersForProduct(productCode);
  }



  const rbacResourceReadinessBanner = () => {
    if (isRbacInErrorState) {
      return (
        <div className={styles.redRibbon}>
          <i className={`pi pi-times-circle ${styles.icon}`} />
          <div>
            We failed to retrieve the users.
            {
              (hasGlobalOwnerRole() || hasRole([productCode + "-" + (productVersion - 1)], ROLES.OWNER)) &&
              <>
                &nbsp;<span onClick={() => retryCreatingAnchorResources()} className={styles.link}>Click here</span> to try again.
              </>

            }
          </div>

        </div>
      );
    } else if (!areRbacResourcesReady) {
      return (
        <div className={styles.yellowRibbon}>
          <span className={styles.spin} />
          We are currently setting up the role based access for you.
        </div>
      );
    } else {
      return <></>
    }
  }

  const sortApprovers = (users: User[]) => {
    const sortApprovers = sortApproverList(users)
    return sortApprovers ? sortApprovers : [];
  }


  return (
    <div className={styles.OverviewForm} data-testid="OverviewForm">
      {
        (isLoadingProductForResourceCode || isLoadingRoles || isLoadingUsers || isLoadingRetryGettingUsersForProduct) && <SpinnerComponent />
      }
      <div className={styles.formFieldList} data-testid="FormFields">
        <div>
          {
            editMode ?
              <span className={` ${styles.description}`}>
                <div className={`${styles.editor} ${containsChanges('description') ? "isChanged" : ""}`}>
                  <Editor readOnly={!isAuthorisedToEdit} style={{ height: '200px' }} className={`${checkFormValidity(formFields['description'].toString(), "description") != "" ? "invalid" : ""} `} headerTemplate={RichTextCustomHeaderComponent()} value={formFields['descriptionRichText'].toString()} onTextChange={(e) => updateDescription(e.textValue, e.htmlValue !== null ? e.htmlValue : "")} />
                  {
                    checkFormValidity(formFields['description'].toString(), "description")
                  }
                </div>
              </span>
              :
              <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(formFields["descriptionRichText"]?.toString()) }} className={styles.displayDescription}>

              </div>
          }

        </div>
        {
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Key Contacts</h3>
              </span>
            </div>
            <div className={styles.keyContactsUserLists}>
              {rbacResourceReadinessBanner()}
              <UserListForRoleV2 allUsers={allUsers} displayRoleName='Owner' canEdit={isGlobalOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isInsideSolutionProductUpgradePopup} resourceCode={rbacResourceCode} resourceChildCode={rbacResourceChildCode} roleName={ROLES.OWNER} roleId={getRoleId(ROLES.OWNER)} userList={allUsers?.owners?.map(owner => mapAnchorUserToGraphUser(owner))} productFamilyCode={productFamilyCode} productName={productName} productVersion={productVersion} productCode={productCode} editMode={editMode} />
              {
                (productStatus === Status.DRAFT || productStatus === Status.REVIEW) &&
                <>
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Contributors' canEdit={isProductOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={rbacResourceCode} resourceChildCode={rbacResourceChildCode} roleName={ROLES.CONTRIBUTOR} roleId={getRoleId(ROLES.CONTRIBUTOR)} userList={allUsers?.contributors?.map(contributor => mapAnchorUserToGraphUser(contributor))} productFamilyCode={productFamilyCode} productName={productName} productVersion={productVersion} productCode={productCode} editMode={editMode} />
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Reviewers' canEdit={isProductOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={rbacResourceCode} resourceChildCode={rbacResourceChildCode} roleName={ROLES.REVIEWER} roleId={getRoleId(ROLES.REVIEWER)} userList={allUsers?.reviewers?.map(reviewer => mapAnchorUserToGraphUser(reviewer))} productFamilyCode={productFamilyCode} productName={productName} productVersion={productVersion} productCode={productCode} editMode={editMode} />
                  <UserListForRoleV2 allUsers={allUsers} displayRoleName='Approvers' canEdit={isProductOwner && areRbacResourcesReady && !isLoadingRoles && !isLoadingUsers && !isErrorRoles && !isErrorUsers && !isApprovalFlow} resourceCode={rbacResourceCode} resourceChildCode={rbacResourceChildCode} roleName={ROLES.APPROVER} roleId={getRoleId(ROLES.APPROVER)} userList={sortApprovers(allUsers ? allUsers?.approvers?.map(approver => mapAnchorUserToGraphUser(approver)) : [])} productFamilyCode={productFamilyCode} productName={productName} productVersion={productVersion} productCode={productCode} editMode={editMode} approversApproved={approversApproved} approversRejected={approversRejected} triggerApproverStatus={triggerApproverStatus} setTriggerApproverStatus={setTriggerApproverStatus} />
                </>
              }
            </div>

          </div>
        }
        {
          ((!editMode && checkIfStringIsNotUndefinedNullEmpty(formFields['restriction']?.toString())) || editMode) &&
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Restriction</h3>
              </span>
            </div>
            <div className={styles.fieldTextValue}>
              {
                editMode ?
                  <>
                    <InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
                      className={`${containsChanges('restriction') ? "isChanged" : ""} ${styles.inputField} ${formValidation('restriction', FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
                      value={formFields['restriction']?.toString()} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, 'restriction')} />
                    {checkFormValidity(formFields['restriction']?.toString(), 'restriction')}
                  </>
                  :
                  <>
                    {formFields['restriction']?.toString()}
                  </>
              }

            </div>
          </div>
        }
        {
          ((!editMode && checkIfStringIsNotUndefinedNullEmpty(formFields['limitation']?.toString())) || editMode) &&
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Limitation</h3>
              </span>
            </div>
            <div className={styles.fieldTextValue}>
              {
                editMode ?
                  <>
                    <InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
                      className={`${containsChanges('limitation') ? "isChanged" : ""} ${styles.inputField} ${formValidation('limitation', FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
                      value={formFields['limitation']?.toString()} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, 'limitation')} />
                    {checkFormValidity(formFields['limitation']?.toString(), 'limitation')}
                  </>
                  :
                  <>
                    {formFields['limitation']?.toString()}
                  </>
              }

            </div>
          </div>
        }

        {
          ((!editMode && checkIfStringIsNotUndefinedNullEmpty(formFields['dependency']?.toString())) || editMode) &&
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Dependency</h3>
              </span>
            </div>
            <div className={styles.fieldTextValue}>
              {
                editMode ?
                  <>
                    <InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
                      className={`${containsChanges('dependency') ? "isChanged" : ""} ${styles.inputField} ${formValidation('dependency', FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
                      value={formFields['dependency']?.toString()} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, 'dependency')} />
                    {checkFormValidity(formFields['dependency']?.toString(), 'dependency')}
                  </>
                  :
                  <>
                    {formFields['dependency']?.toString()}
                  </>
              }

            </div>
          </div>
        }
        {
          ((!editMode && checkIfStringIsNotUndefinedNullEmpty(formFields['customerCommitments']?.toString())) || editMode) &&
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <span className={styles.label}>
                <h3>Customer Commitments</h3>
              </span>
            </div>
            <div className={styles.fieldTextValue}>
              {
                editMode ?
                  <>
                    <InputTextarea readOnly={!isAuthorisedToEdit} rows={2} cols={80}
                      className={`${containsChanges('customerCommitments') ? "isChanged" : ""} ${styles.inputField} ${formValidation('customerCommitments', FieldIdentifiers.ATTRIBUTES) !== "" && "p-invalid"}`}
                      value={formFields['customerCommitments']?.toString()} autoResize disabled={!editMode} onChange={(e) => handleChanges(e.target.value, 'customerCommitments')} />
                    {checkFormValidity(formFields['customerCommitments']?.toString(), 'customerCommitments')}
                  </>
                  :
                  <>
                    {formFields['customerCommitments']?.toString()}
                  </>
              }

            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default OverviewForm;
