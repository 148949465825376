import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface FeatureFilterState {
    checkedTags: string[],
    checkedControls: string[],
    checkedComponents: string[]
}

// Define the initial state using that type
const initialState: FeatureFilterState = {
	checkedTags: [],
	checkedControls: [],
	checkedComponents: [],
};

export const featureFilterSlice = createSlice({
	name: "featureFilter",
	initialState,
	reducers: {

		addCheckedTag: (state, action: PayloadAction<string>) => {
			state.checkedTags.push(action.payload);
		},
		removeCheckedTag: (state, action: PayloadAction<string>) => {
			state.checkedTags.splice(state.checkedTags.indexOf(action.payload), 1);
		},
		addCheckedControl: (state, action: PayloadAction<string>) => {
			state.checkedControls.push(action.payload);
		},
		removeCheckedControl: (state, action: PayloadAction<string>) => {
			state.checkedControls.splice(state.checkedControls.indexOf(action.payload), 1);
		},
		addCheckedComponent: (state, action: PayloadAction<string>) => {
			state.checkedComponents.push(action.payload);
		},
		removeCheckedComponent: (state, action: PayloadAction<string>) => {
			state.checkedComponents.splice(state.checkedComponents.indexOf(action.payload), 1);
		},
		clearCheckedComponents: (state) => {
			state.checkedComponents = [];
		},
		clearAllFilters: (state) => {
			state.checkedTags = [];
			state.checkedComponents = [];
			state.checkedControls = [];
		},
		setFilters: (state, action: PayloadAction<FeatureFilterState>) => {
			state.checkedTags = action.payload.checkedTags;
			state.checkedComponents = action.payload.checkedComponents;
			state.checkedControls = action.payload.checkedControls;
		}

	}
});

export const { addCheckedTag, addCheckedControl, removeCheckedControl, removeCheckedTag, addCheckedComponent, removeCheckedComponent, clearAllFilters, clearCheckedComponents, setFilters } = featureFilterSlice.actions;

export default featureFilterSlice.reducer;