import React, { lazy, Suspense } from 'react';
import { FeatureDetailProps } from './FeatureDetail';

const LazyFeatureDetail = lazy(() => import('./FeatureDetail'));

const FeatureDetail = (props: FeatureDetailProps & JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyFeatureDetail {...props} />
  </Suspense>
);

export default FeatureDetail;
