import {
  ComponentAssociation,
  ConfigurableControl,
  FeatureAssociation,
  FeatureDefinition,
  Product,
  ProductInfo,
  SpecificationParameterAssociation,
  SpecificationParameterDefinition,
} from "../../../data/model/DataModels";
import styles from "./ProductFeatures.module.scss";
import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addSpecificationParameterInProduct,
  deleteFeatureInProduct,
  deleteSpecificationParameterInProduct,
  updateAllSpecificationParameterInProduct,
  updateFeatureInProduct,
} from "../../../data/slices/productSlice";
import { FeatureFilterState } from "../../../components/FilterPanel/featureFilterSlice";
import ProductFeaturesViewHeader from "./ProductFeaturesViewHeader/ProductFeaturesViewHeader";
import DisplaySpecificationParameterCards from "./DisplaySpecificationParameterCards/DisplaySpecificationParameterCards";
import FeatureCard2, { ServiceDefaultLabel } from "../../../components/FeatureCard2/FeatureCard2";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { cloneDeep } from "lodash";
interface ProductFeaturesProps {
  product: Product;
  isAuthorisedToEdit: boolean;
  isInsideSolutionProductUpgradePopup?: boolean;
}

// const applyFiltersToSpecificationParameters = (allProductSpecificationParameters: SpecificationParameterAssociation[], filtersApplied: FeatureFilterState): SpecificationParameterAssociation[] => {
// 	// mapping data for filtering
// 	let confAtContractFilters: string[] = [];
// 	let selectAtBookingFilters: string[] = [];

// 	if (filtersApplied.checkedControls.length > 0) {
// 		confAtContractFilters = filtersApplied.checkedControls.filter(checkedControl => checkedControl.includes(controls[0].key));
// 		selectAtBookingFilters = filtersApplied.checkedControls.filter(checkedControl => checkedControl.includes(controls[1].key));
// 	}

// 	// filtering logic
// 	const filteredSpecificationParameters = allProductSpecificationParameters?.filter(specificationParameter => {

// 		//filtering on tags
// 		if (filtersApplied.checkedTags.length > 0) {
// 			if (specificationParameter?.definition?.tags == null ||
// 				!filtersApplied.checkedTags.every(tag => specificationParameter.definition?.tags?.includes(tag))
// 			) {
// 				return false;
// 			}
// 		}

// 		//filter on configurableAtContracting
// 		if (confAtContractFilters.length > 0) {
// 			if (specificationParameter.configuration.configurableAtContracting === null ||
// 				!confAtContractFilters.includes(controls[0].key + "-" + specificationParameter.configuration.configurableAtContracting
// 				)
// 			) {
// 				return false;
// 			}
// 		}

// 		//filter on selectableAtBooking
// 		if (selectAtBookingFilters.length > 0) {
// 			if (specificationParameter.configuration.selectableAtBooking === null ||
// 				!selectAtBookingFilters.includes(controls[1].key + "-" + specificationParameter.configuration.selectableAtBooking!)
// 			) {
// 				return false;
// 			}
// 		}

// 		//filtering on components
// 		if (filtersApplied.checkedComponents.length > 0 &&
// 			!filtersApplied.checkedComponents.includes(specificationParameter.definition.componentRef)
// 		) {
// 			return false;
// 		}

// 		return true;

// 	});

// 	return filteredSpecificationParameters;
// };

const ProductFeatures: FC<ProductFeaturesProps> = ({
  product,
  isAuthorisedToEdit = false,
  isInsideSolutionProductUpgradePopup = false
}) => {
  const component: ComponentAssociation = useAppSelector(
    (state) => state.product.selectedComponent
  );
  const filtersApplied = useAppSelector((state) => state.featureFilter);
  const dispatch = useAppDispatch();
  const editMode: boolean = useAppSelector((state) => state.product.editMode);
  const features = product.features;
  const [showSpecificationParamModal, setShowSpecificationParamModal] =
    useState(false);
  const [selectedFeature, setSelectedFeature] = useState(
    {} as FeatureDefinition
  );

  const deleteFeature = (featureCode: string) => {
    dispatch(deleteFeatureInProduct(featureCode));
  };

  const confirmRemoveSpecificationParameter = (
    specification: SpecificationParameterDefinition
  ) => {
    confirmDialog({
      message: `Are you sure you want to remove specification parameter ${specification.name} from this product?`,
      header: "Remove specification parameter?",
      icon: "pi pi-exclamation-triangle",
      accept: () =>
        dispatch(deleteSpecificationParameterInProduct(specification.code)),
    });
  };

  const onClickOfAddSpecParam = (feature: FeatureDefinition) => {
    setShowSpecificationParamModal(true);
    setSelectedFeature(feature);
  };

  const disableAddSpecificationButton = (
    featureDefinition: FeatureDefinition
  ) => {
    let associatedNumberOfSpecParams = product.specificationParameters.filter(
      (specParam) => specParam.featureRef === featureDefinition.code
    ).length;
    return (
      associatedNumberOfSpecParams >=
      featureDefinition.numberOfSpecificationParameters
    );
  };

  const [featureTypeFilterValue, setFeatureTypeFilterValue] = useState("all");

  const displayFeature = (feature: FeatureAssociation, index: number) => {
    return (
      <div data-testid={`feature${index}`} key={index}>
        <div
          className={styles.feature_group_name}
          style={{ marginBottom: "25px" }}
          data-testid={`featurename${index}`}
        >
          <div className={styles.featureNameVersion}>
            <div>{feature?.definition?.name}</div>
            <div className={styles.featureVersion}>v1</div>
            <div className={styles.featureVersionAvailable}>
              New version available
            </div>
            <div>
              <Button className={styles.previewBtn}>Preview</Button>
            </div>
          </div>
          <div>
            {editMode ? (
              <>
                <span style={{ float: "right" }}>
                  <Button
                    disabled={disableAddSpecificationButton(
                      feature.definition!
                    )}
                    icon="pi pi-plus"
                    data-testid={`addSpecParamButton-${feature.featureRef}`}
                    className="p-button-outlined p-button-secondary"
                    onClick={() => onClickOfAddSpecParam(feature.definition!)}
                    label="Add Specification Parameter"
                    style={{ marginRight: "10px" }}
                    tooltip={
                      "All specification parameters are already associated with product"
                    }
                    tooltipOptions={{
                      showOnDisabled: true,
                      disabled: !disableAddSpecificationButton(
                        feature.definition!
                      ),
                      showEvent: "click",
                      position: "left",
                      className: "general-tooltip",
                    }}
                  />
                  <Button
                    icon="pi pi-trash"
                    data-testid={`deleteFeatureButton${index}`}
                    className="p-button-outlined p-button-secondary"
                  />
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>{feature?.definition?.description}</div>
      </div>
    );
  };

  const getFeaturesForSelectedComp = (component: ComponentAssociation) => {
    if (filtersApplied && filtersApplied.checkedComponents.length > 0)
      return features?.filter(({ componentRef }) =>
        filtersApplied.checkedComponents.includes(componentRef)
      );
    else
      return component.componentRef === "all-features"
        ? features
        : features?.filter(
          ({ componentRef }) => componentRef === component.componentRef
        );
  };

  const isFeatureVersionAvailable = (feature: FeatureAssociation): boolean => {
    const filteredComponent = product.components.filter(
      (comp) => comp.componentRef === feature.componentRef
    );
    if (filteredComponent) {
      const newFeatureVersion = filteredComponent.flatMap((comp) =>
        comp.upgradedFeatureCode?.filter(
          (featureCodeAndVersion) =>
            featureCodeAndVersion.code === feature.featureRef
        )
      );
      const latestVersionAvailable = newFeatureVersion[0]?.featureVersion;
      return latestVersionAvailable &&
        latestVersionAvailable > feature.featureVersion
        ? true
        : false;
    }
    return false;
  };

  const convertSpecParamDefinitionToAssociation = (
    specParam: SpecificationParameterDefinition
  ): SpecificationParameterAssociation => {
    return {
      featureRef: specParam.featureRef,
      componentRef: specParam.componentRef,
      specificationParamRef: specParam.code,
      configuration: specParam.configuration,
      productId: product.id,
      definition: specParam.definition,
      configurableAtContracting: specParam.configurableAtContracting,
      configurableAtBooking: specParam.configurableAtBooking,
      featureVersion: specParam?.featureVersion,
      serviceDefault: specParam?.serviceDefault
    } as SpecificationParameterAssociation;
  };

  const newFeatureToUpdateSp = (newFeature: FeatureDefinition, existingFeature: FeatureDefinition) => {
    const updatedSPs = newFeature.specificationParameters.map(newFeatureSP => {
      const existingFeatureSP = existingFeature.specificationParameters.find(existingSP => newFeatureSP.code === existingSP.code);
      const updatedServiceDefault = existingFeatureSP?.serviceDefault ?? newFeatureSP.serviceDefault;
      return { ...newFeatureSP, serviceDefault: updatedServiceDefault };
    });
    return { ...newFeature, specificationParameters: updatedSPs };
  };

  const upgradeToNewFeature = (
    existingFeature: FeatureDefinition,
    newFeature: FeatureDefinition
  ) => {

    const updateFeatureDefinition = (newFeature.service != existingFeature.service) ? newFeature : newFeatureToUpdateSp(newFeature, existingFeature);

    const newUpgradedFeature = {
      productId: product.code,
      featureRef: existingFeature.code,
      componentRef: existingFeature.componentRef,
      definition: updateFeatureDefinition,
      featureVersion: newFeature.version,
      service: (newFeature.service != existingFeature.service) ? newFeature.service : existingFeature.service,
      serviceDefault: (newFeature.service != existingFeature.service) ? newFeature.serviceDefault : existingFeature.serviceDefault
    } as FeatureAssociation;
    let upgradedFeatures = features.map((feature) =>
      feature.featureRef === existingFeature.code ? newUpgradedFeature : feature
    );
    const existingSpecParamToBeDeleted =
      existingFeature.specificationParameters.map((specParam) =>
        convertSpecParamDefinitionToAssociation(specParam)
      );

    const newSpecParamToBeAdded = newFeature.specificationParameters.map((specParam) => {
      const existingFeatureSP = product.specificationParameters.find(existingSP =>
        existingSP.specificationParamRef === specParam.code && existingSP.featureRef === specParam.featureRef
      );
      let updatedSpecParam = {};
      if (newFeature.service != existingFeature.service) {
        updatedSpecParam = specParam;
      } else {
        const areNewSPCoreAndExistingFeatureOptional = (existingFeatureSP == undefined) && (specParam.serviceDefault === "included") && (existingFeature.serviceDefault == "not_included");
        if (areNewSPCoreAndExistingFeatureOptional) {
          updatedSpecParam = {
            ...specParam,
            serviceDefault: "not_included",
          };
        } else {
          updatedSpecParam = {
            ...specParam,
            serviceDefault: existingFeatureSP?.serviceDefault ?? specParam.serviceDefault,
          };
        }
      }
      const convertedSpecParam = convertSpecParamDefinitionToAssociation(updatedSpecParam as SpecificationParameterDefinition);
      return convertedSpecParam;
    });


    const updatedSpecificationparameters =
      product.specificationParameters.filter(
        (mainSp) =>
          !existingSpecParamToBeDeleted.filter(
            (spToDelete) =>
              spToDelete.specificationParamRef ===
              mainSp.specificationParamRef &&
              mainSp.featureRef === spToDelete.featureRef
          ).length
      );
    dispatch(updateFeatureInProduct(upgradedFeatures));
    dispatch(
      updateAllSpecificationParameterInProduct([
        ...updatedSpecificationparameters,
        ...newSpecParamToBeAdded,
      ])
    );
  };

  const addSpecificationParameter = (
    specificationParameters: SpecificationParameterDefinition[]
  ) => {
    let specparamAssociations: SpecificationParameterAssociation[] =
      specificationParameters.map((specParam) => {
        return {
          featureRef: specParam.featureRef,
          componentRef: specParam.componentRef,
          specificationParamRef: specParam.code,
          configuration: specParam.configuration,
          productId: product.id,
          definition: specParam,
          configurableAtContracting: specParam.configurableAtContracting,
          configurableAtBooking: specParam.configurableAtBooking,
          featureVersion: selectedFeature.version,
          serviceDefault: specParam.serviceDefault
        } as SpecificationParameterAssociation;
      });
    dispatch(addSpecificationParameterInProduct(specparamAssociations));
  };

  const getAssociatedSpecParamsOfFeature = (feature: FeatureDefinition) => {
    return product.specificationParameters
      .filter((specParam) => specParam.featureRef === feature.code)
      .map((specParam) => specParam.specificationParamRef);
  };

  const saveSpecificationParameterConfig = (
    specParams: SpecificationParameterDefinition[]
  ) => {
    let updatedList = product.specificationParameters.map((param) => {
      let updatedValue = specParams.find(
        (spec) => spec.code === param.specificationParamRef
      );
      if (updatedValue) {
        return {
          ...param,
          configurableAtBooking: updatedValue.configurableAtBooking,
          configurableAtContracting: updatedValue.configurableAtContracting,
          configurableAtAssociation: updatedValue.configurableAtAssociation,
          serviceDefault: updatedValue.serviceDefault,
          configuration: {
            ...param.configuration,
            value: updatedValue.configuration.value,
          },
        };
      }
      return param;
    });
    dispatch(updateAllSpecificationParameterInProduct(updatedList));
  };

  const saveFeatureConfig = (featureParam: FeatureDefinition, isChangedFromCoreToOptional: boolean) => {
    let updatedList = product.features.map((param) => {
      let updatedValue = featureParam.code === param.featureRef;
      if (updatedValue) {
        return {
          ...param,
          serviceDefault: featureParam.serviceDefault,
        };
      }
      return param;
    });
    dispatch(updateFeatureInProduct(updatedList));

    if (isChangedFromCoreToOptional) {
      const updatedSPs = product.specificationParameters.map((sp) => {
        let updatedSP = sp.featureRef === featureParam.code;
        if (updatedSP) {
          let config: ConfigurableControl = cloneDeep(sp.configurableAtContracting!);
          config.enabled = false;
          config.allowMultiselect = false;
          config.choiceRequired = false;
          return {
            ...sp,

            serviceDefault: "not_included",
            configurableAtContracting: config
          } as SpecificationParameterAssociation;
        }
        return sp;
      });
      dispatch(updateAllSpecificationParameterInProduct(updatedSPs));
    }
  };

  const confirmRemoveFeature = (feature: FeatureAssociation) => {
    confirmDialog({
      message: `Are you sure you want to remove feature ${feature.definition.name} from this product?`,
      header: "Remove feature?",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteFeature && deleteFeature(feature.featureRef),
    });
  };



  const showFeature = (feature: FeatureAssociation) => {
    const associatedSpecParams = product.specificationParameters
      .filter((specParam) => specParam.featureRef === feature.featureRef)
      .map((specParam) => {
        return {
          ...specParam,
          code: specParam.specificationParamRef,
          name: specParam.definition.name,
          featureServiceDefault: feature.serviceDefault
        };
      }) as unknown as SpecificationParameterDefinition[];
    return (
      <FeatureCard2
        key={feature.featureRef}
        feature={{
          ...feature.definition,
          associatedProducts: [{ code: product.code }] as ProductInfo[],
          service: feature.service,
          serviceDefault: feature.serviceDefault,
          specificationParameters: product.specificationParameters
            .filter((specParam) => specParam.featureRef === feature.featureRef)
            .map((specParam) => { return { ...specParam.definition, featureServiceDefault: feature.serviceDefault } }),
        }}
        saveSpecificationParameterConfigs={saveSpecificationParameterConfig}
        saveFeatureConfigs={saveFeatureConfig}
        specificationParameterAssociations={associatedSpecParams}
        addSpecificationParameter={() =>
          onClickOfAddSpecParam(feature.definition!)
        }
        deleteFeature={() => confirmRemoveFeature(feature)}
        deleteSpecification={(
          specification: SpecificationParameterDefinition
        ) => confirmRemoveSpecificationParameter(specification)}
        isConfigurable={editMode}
        usedIn={"product"}
        isNewVersionAvailable={() => isFeatureVersionAvailable(feature)}
        upgradeToNewFeature={upgradeToNewFeature}
        isAuthorisedToEdit={isAuthorisedToEdit}
        chargeTypeCode={feature.chargeTypeCode}
      />
    );
  };

  return (
    <div
      className={styles.ProductFeatures}
      data-testid="productSpecificationsComponent"
    >
      <ProductFeaturesViewHeader
        product={product}
        isAuthorisedToEdit={isAuthorisedToEdit}
        setFeatureTypeFilterValue={setFeatureTypeFilterValue}
        isInsideSolutionProductUpgradePopup={isInsideSolutionProductUpgradePopup}
      />

      {getFeaturesForSelectedComp(component)
        .filter((feature) => (featureTypeFilterValue == "all" || (feature.serviceDefault === featureTypeFilterValue))
        )
        .map((feature) => showFeature(feature))}

      {showSpecificationParamModal && (
        <DisplaySpecificationParameterCards
          header={selectedFeature.name}
          actionShowDialog={setShowSpecificationParamModal}
          activeSpecificationParameters={getAssociatedSpecParamsOfFeature(
            selectedFeature
          )}
          componentRef={selectedFeature.componentRef}
          featureRef={selectedFeature.code}
          acceptAction={(specParams: SpecificationParameterDefinition[]) =>
            addSpecificationParameter(specParams)
          }
        />
      )}
    </div>
  );
};

export default ProductFeatures;
