import { FC, useContext, useEffect, useState } from 'react';
import styles from './RuleComponentReadView.module.scss';
import { DimensionItem, DimensionType, HeaderTypes, LocationData, Rule } from '../../data/model/DataModels';
import { ESMap } from 'typescript';
import { ReactComponent as InvalidRuleIcon } from '../../assets/icons/maersk/mi-exclamation-triangle-solid-24px.svg';
import { useGetCountriesDataQuery, useLazyGetListTypeDimensionDataQuery } from '../../data/api/RefDataApi';
import { useLazyGetAllPopUpValuesQuery } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';

export interface RuleComponentReadViewProps {
  rule: Rule;
  allAvailableDimensions: { name: string, code: DimensionType | HeaderTypes }[];
}

const RuleComponentReadView: FC<RuleComponentReadViewProps> = ({ rule, allAvailableDimensions }) => {
  const [dimensionValuesMaster, setDimensionValuesMaster] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getAllValueOfListTypeDimension, { isLoading: isLoadingAllValueOfListTypeDimension }] = useLazyGetListTypeDimensionDataQuery();
  const [getAllValuesOfHeaderTypeDimension, { isLoading: isLoadingAllValuesOfHeaderTypeDimension }] = useLazyGetAllPopUpValuesQuery();
  const toaster = useContext(ToasterContext);

  const isLocationDimension = (criterionKey: DimensionType | HeaderTypes) => {
    return criterionKey == DimensionType.Export
      || criterionKey == DimensionType.Import
      || criterionKey == DimensionType.Locations
  }

  const { data: countries = [], error: errorCountriesData, isLoading: isLoadingCountriesData } = useGetCountriesDataQuery(undefined, { skip: !rule.criteria.some(criterion => isLocationDimension(criterion.dimension)) });


  const isHeaderType = (key: any): key is HeaderTypes => {
    return Object.values(HeaderTypes).includes(key);
  };

  const transformList = (listTypeDimensionData: DimensionItem[]): { name: string, code: string }[] => {
    var dimensionValues: { name: string, code: string }[] = [];
    listTypeDimensionData.forEach(dimensionItem => {
      if (dimensionItem?.children !== undefined) {
        dimensionItem?.children
          .forEach(innerDimensionItem => dimensionValues.push({ name: dimensionItem.name + '-' + innerDimensionItem.name, code: innerDimensionItem.code }));
      }
      else {
        dimensionValues.push({ name: dimensionItem.name, code: dimensionItem.code });
      }
    })
    return dimensionValues;
  }

  const getCountryNameMapping = (locations: LocationData[]) => {
    let countryData: Record<string, string> = {};
    locations.forEach(location => {
      if (location.type != "Country") {
        countryData = { ...countryData, ...getCountryNameMapping(location.children) };
      } else {
        countryData[location.code] = location.name;
      }
    });
    return countryData;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let dimensionValuesData: Record<string, string> = dimensionValuesMaster;
      for (const criterion of rule.criteria) {
        if (!dimensionValuesData[criterion.dimension]) {
          if (isHeaderType(criterion.dimension)) {
            const res = await getAllValuesOfHeaderTypeDimension(criterion.dimension);
            if (res.data) {
              res.data.forEach(keyValue => dimensionValuesData[keyValue.code] = keyValue.name);
            }
          }
          else if (isLocationDimension(criterion.dimension)) {
            dimensionValuesData = {...dimensionValuesData, ...getCountryNameMapping(countries)}
          }
          else {
            const res = await getAllValueOfListTypeDimension(criterion.dimension);
            if (res.data) {
              transformList(res.data).forEach(keyValue => dimensionValuesData[keyValue.code] = keyValue.name);
            }
          }
        }
      }
      setDimensionValuesMaster(dimensionValuesData);
      setIsLoading(false);
    };

    fetchData();
  }, [rule.criteria, isLoadingCountriesData]);

  if (errorCountriesData) {
    toaster.showToast('error', 'Failed to fetch location values');
  }

  const getMapFromValidationMsg = (rule: Rule): ESMap<string, string[]> => {
    const map = new Map();
    if(rule?.criteriaValidationResults) {
      if(rule?.validationMsg!==null){
        map.set(rule.name, [rule?.validationMsg]);
      }
      else {
        rule?.criteriaValidationResults.forEach(dimensionAndMissingValue => {
            map.set(dimensionAndMissingValue.dimensionType, dimensionAndMissingValue.missingValues);
        })
      }
    }
    return map;
  }

  const getDimensionValue = (code: string) => {
    const valueName = dimensionValuesMaster[code];
    if (valueName) {
      return valueName;
    }
    return code;
  };

  const mapToDimensionName = (dimensionCode: string) => {
    const matchedDimension = allAvailableDimensions.find(dimension => dimension.code === dimensionCode);
    return matchedDimension?.name;
  }

  return (
    <div className={styles.RuleComponentReadView} data-testid="RuleComponentReadView">
      {(isLoading || isLoadingCountriesData || isLoadingAllValueOfListTypeDimension || isLoadingAllValuesOfHeaderTypeDimension) &&
        <SpinnerComponent />
      }
      <div className={styles.ruleSection}>
        <div className={styles.ruleName}>
          {getMapFromValidationMsg(rule).size > 0 ? <span className={styles.invalidRuleIcon} data-testid="invalidRuleIcon"><InvalidRuleIcon /></span> : ""} 
          <span className={`${getMapFromValidationMsg(rule).size > 0 ? styles.invalidRuleHighlight : ''}`}>{rule.name}:</span>
        </div>
        {rule.criteria.map((criterion, criteriaIndex) => {
          return <div className={styles.ruleCriteria} key={criterion.dimension}>
            {criteriaIndex >= 1 && <span className={styles.italic}>AND </span>}
            <span className={`${styles.criteriaName} ${styles.italic}`}>
              {mapToDimensionName(criterion.dimension) + " include "}
            </span>
            {criterion.values.map((val, index) => {
              return getMapFromValidationMsg(rule).get(criterion.dimension)?.find(mapVal => mapVal === val)
                ? <span key={index} className={styles.criteriaValueStrikedOff}>{getDimensionValue(val)}; </span>
                : <span key={index} className={styles.criteriaValue}>{getDimensionValue(val)}; </span>
            })
            }
          </div>
        })
        }
      </div>
    </div>
  )
};

export default RuleComponentReadView;
