import React, { createContext, FunctionComponent, useEffect, useRef, useState } from "react";
import { hasGlobalOwnerRole, hasRole } from "../../authorisationService";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { usePostProductMutation } from "../../data/api/CatalogueApi";
import { useMsal } from "@azure/msal-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ROUTE from "../../Paths";
import PATHS from "../../Paths";
import { Toast } from "primereact/toast";
import { setComponentReordering, setEditMode } from "../../data/slices/productSlice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import SideBarNavigation from "../../components/SideBarNavigation/SideBarNavigation";
import userIcon from "../../assets/icons/maersk/user-circle.svg";
import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import "./AppLayoutView.scss";
import { FAMILIES, ROLES } from "../../data/model/DataModels";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

export interface MenuItem {
  text: string;
  path: PATHS;
  icon: string;
  isSelected: boolean
}

export const ToasterContext = createContext({ showToast: new Function() });

const allMenu: MenuItem[] = [
  {
    text: "Home",
    path: ROUTE.HOME,
    icon: "pi pi-home",
    isSelected: true
  },
  {
    text: "Solutions",
    path: ROUTE.SOLUTIONS,
    icon: "pi pi-shopping-cart",
    isSelected: false
  },
  {
    text: "Products",
    path: ROUTE.PRODUCTS,
    icon: "pi pi-globe",
    isSelected: false
  },
  {
    text: "Components",
    path: ROUTE.COMPONENTS,
    icon: "pi pi-sitemap",
    isSelected: false
  },
  {
    text: "Features",
    path: ROUTE.FEATURES,
    icon: "pi pi-list",
    isSelected: false
  },
  {
    text: "Activities",
    path: ROUTE.ACTIVITIES,
    icon: "pi pi-book",
    isSelected: false
  },
  {
    text: "Users",
    path: ROUTE.USERS,
    icon: "pi pi-users",
    isSelected: false
  },
  { // TODO: MVP Add help back in once we have something
    text: "Help",
    path: ROUTE.HELP,
    icon: "pi pi-question-circle",
    isSelected: false
  },
  { // TODO: MVP Add help back in once we have something
    text: "Release",
    path: ROUTE.RELEASES,
    icon: "pi pi-globe",
    isSelected: false
  }
];


const AppLayoutView: FunctionComponent = () => {

  const editMode: boolean = useAppSelector((state) => state.product.editMode);
  const [postProduct, { isLoading: isLoadingPost }] = usePostProductMutation();
  let editableProduct = useAppSelector((state) => state.product.editableProduct);
  const isProductFormValid = useAppSelector((state) => state.product.isProductFormValid);
  let draftChanged = useAppSelector((state) => state.product.localDraftChanged);
  const reorderEnabled: boolean = useAppSelector((state) => state.product.isReordering);
  const isGlobalOwner = hasGlobalOwnerRole();
  const hasProductOwnerRole = hasRole(Object.keys(FAMILIES), ROLES.OWNER);


  const { accounts } = useMsal();


  const dispatch = useAppDispatch();
  const location = useLocation();


  let menu: MenuItem[] = allMenu;

  //filter header navigation tabs based on roles
  if (!hasProductOwnerRole && !isGlobalOwner) {
    menu = allMenu.filter(menuItem =>
      ![ROUTE.COMPONENTS, ROUTE.FEATURES, ROUTE.ACTIVITIES].includes(menuItem.path)
    )
  }

  if (!isGlobalOwner) {
    menu = allMenu.filter(menuItem =>
      ![ROUTE.USERS, ROUTE.ACTIVITIES].includes(menuItem.path)
    )
  }

  if (location.pathname !== ROUTE.DEFAULTROUTE) {
    menu = menu.map(item => { return { ...item, isSelected: location.pathname.includes(item.path) ? true : false }; });
  }

  const initialState = { menuItems: menu };

  const navigate = useNavigate();

  const [state, setstate] = useState(initialState);


  const selectedMenu = state.menuItems.find(item => item.isSelected === true);
  if (selectedMenu && location.pathname !== ROUTE.DEFAULTROUTE && !location.pathname.includes(selectedMenu.path)) {
    setstate({ ...state, menuItems: state.menuItems.map(item => { return { ...item, isSelected: location.pathname.includes(item.path) ? true : false }; }) });
  }


  useEffect(() => {

    const selectedMenu = state.menuItems.find(item => item.isSelected == true);

    if (selectedMenu && location.pathname === ROUTE.DEFAULTROUTE) {
      navigate(selectedMenu.path);
    }
  }, []);


  const onMenuSelection = (menuItem: MenuItem) => {
    if (editMode) {
      confirmExitEditMode();
    } else {
      const menuItems = state.menuItems.map(item => { return { ...item, isSelected: item.text == menuItem.text ? true : false }; });
      setstate({ ...state, menuItems });
      navigate(menuItem.path);
    }
  };

  const toast = useRef<any>();

  const showToast = (severityValue: string, summaryValue: string, detailValue?: string) => {
    toast?.current?.show({ severity: severityValue, summary: summaryValue, detail: detailValue, life: 5000 });
  }

  const confirmExitEditMode = async () => {

    if (!draftChanged && !reorderEnabled) {
      dispatch(setEditMode(false));
      return;
    }
    if (reorderEnabled) {
      confirmDialog({
        message: "You are reordering components, do you want to discard changes?",
        header: "Discard Changes",
        acceptLabel: "Discard Changes",
        rejectLabel: "Cancel",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          dispatch(setComponentReordering(false));
          dispatch(setEditMode(false));
        },
        reject: () => {
        }
      });
    }
    else if (isProductFormValid) {
      confirmDialog({
        message: "Do you want to save your changes?",
        header: "Confirmation",
        acceptLabel: "Save",
        rejectLabel: "Discard",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          postProduct(editableProduct!).then(
            () => {
              showToast("success", "Successfully saved draft product.")
              dispatch(setEditMode(false));
            },
            error => {
              showToast("error", "Failed to save draft product.")
            }
          )
        },
        reject: () => {
          dispatch(setEditMode(false));
        }
      });
    } else {
      confirmDialog({
        message: "You have validation errors on inputs, Do you want to review and change?",
        header: "Confirmation",
        acceptLabel: "Go back and Review",
        rejectLabel: "Discard",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
        },
        reject: () => {
          dispatch(setEditMode(false));
        }
      });
    }

  };

  return (
    <div className="app">
      <ToasterContext.Provider value={{ showToast }}>
        <Toast ref={toast} />
        {
          isLoadingPost &&
          <SpinnerComponent />
        }
        <div className="sidebarCollapsed">
          <SideBarNavigation menuItems={state.menuItems} onItemSelection={(item: MenuItem) => onMenuSelection(item)} />
        </div>
        <div className="appBody">
          <header className="app-header">
            <div className="app-title">Product Catalogue</div>
            <div>
              <sup>Internal View</sup>
            </div>
            <div className="userInfo">
              <div className={"userName"}>{accounts[0].name}</div>
              <div>
                <img style={{ height: "45px", width: "45px" }} src={userIcon} />
              </div>

            </div>
          </header>
          <div className="appDisplayArea">
            <div className={location.pathname.includes(PATHS.HELP) ? "" : editMode ? "edit_frame" : "normal_frame"}>
              <div>
                {editMode && <div className={"notch"}><Button label="Edit Mode" iconPos="right" icon="pi pi-times" onClick={() => confirmExitEditMode()} /></div>}
                <div className={location.pathname.includes(PATHS.HELP) || location.pathname.includes(PATHS.HOME) ? "" : "app-content"}>
                  {!(location.pathname.includes(PATHS.HOME) || location.pathname.includes(PATHS.HELP) || location.pathname.includes(PATHS.RELEASES)) && <Breadcrumbs />}
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>


        <ConfirmDialog />
        <ConfirmPopup />
      </ToasterContext.Provider>

    </div>
  );
};

export default AppLayoutView;
