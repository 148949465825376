import DOMPurify from "dompurify";
import { FC } from "react";

const RichTextCustomHeaderComponent: FC<void> = () => {
	return (
		<>
			<span className="ql-formats">
				<button className="ql-bold" aria-label="Bold"></button>
				<button className="ql-italic" aria-label="Italic"></button>
				<button className="ql-underline" aria-label="Underline"></button>
			</span>
			<span className="ql-formats">
				<button type="button" className="ql-list" value="ordered" aria-label="Ordered List" />
				<button type="button" className="ql-list" value="bullet" aria-label="Unordered List" />
			</span>
			{/* <span className="ql-formats">
				<select className="ql-color"></select>
				<select className="ql-background"></select>
			</span> */}
			<span className="ql-formats">
				<button className="ql-link" aria-label="Insert Link"></button>
			</span>
		</>

	);
}

export const sanitizeHTML = (htmlString: string) => {
	return DOMPurify.sanitize(htmlString);
}

export default RichTextCustomHeaderComponent;