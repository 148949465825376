import { FC, useEffect, useMemo, useState } from "react";
import styles from "./HeaderView.module.scss";
import { Dialog } from "primereact/dialog";
import HeaderPopUp from "../HeaderPopUp/HeaderPopUp";
import { Header, HeaderAttribute, HeaderDefinition, HeaderOptions, HeaderTypes, ProductHeaders, DimensionType, Scope } from '../../data/model/DataModels';
import { Tag } from "primereact/tag";
import { getIconColor, getHeaderIcon } from './HeaderHelper'
import { Button } from "primereact/button";
import { hasGlobalOwnerRole } from "../../authorisationService";
import HeaderDialogBox from "../HeaderDialogueBox/HeaderDialogueBox";
import { cloneDeep } from "lodash";
import { useGetSelectedDefaultDimensionsQuery } from "../../data/api/CatalogueApi";


export interface HeaderViewProps {
	productHeaders: ProductHeaders;
	editMode: boolean;
	saveHeaders: Function,
	headerList: Header[],
	allHeadersDefinitions: HeaderDefinition[],
	defaultHeaders: string[],
	familyCode?: string,
	applicabilityScope?: Scope[],
	isSolution?: boolean
}

const HeaderView: FC<HeaderViewProps> = ({ productHeaders, editMode, saveHeaders, headerList, familyCode = '', applicabilityScope = [], allHeadersDefinitions, defaultHeaders, isSolution }) => {
	const [multiListDialog, setMultiListDialog] = useState(false);
	const [headerType, setHeaderType] = useState<HeaderTypes>(HeaderTypes.SERVICE_MODEL);
	const [selectValue, setSelectValue] = useState([] as Header[]);
	const [headerPopUp, setHeaderPopUp] = useState('');
	const [headerOptions, setHeaderOptions] = useState([] as HeaderOptions[]);
	const [showHeaderDialog, setShowHeaderDialog] = useState(false);
	const [enrichedProductHeaders, setEnrichedProductHeaders] = useState(productHeaders);
	const previouslySavedHeaderNames = Object.entries(enrichedProductHeaders).filter(header => (header[1] !== null)).map(entry => entry[0]);
	const [selectedHeaders, setselectedHeaders] = useState(previouslySavedHeaderNames);
	const { data: defaultDimensionsFamilyData } = useGetSelectedDefaultDimensionsQuery(familyCode, { skip: familyCode === "" });
	const isGlobalOwner = hasGlobalOwnerRole();

	useEffect( () => {
		setEnrichedProductHeaders(productHeaders);
	},[productHeaders])

	const handleOnClickOfEdit = (header: HeaderAttribute) => {
		setMultiListDialog(true);
		setHeaderType(header.type);
		setSelectValue(header.value);
		setHeaderPopUp(header.name);
	}

	const handleDelete = (selectedHeaderCode: string) => {
		setselectedHeaders(selectedHeaders.filter(headerCode => headerCode !== selectedHeaderCode));
		const clone = cloneDeep(enrichedProductHeaders);
		const remainingObjectEntries = Object.entries(clone).map(header => header[0] === selectedHeaderCode ? [header[0], null] : header);
		const remainingObject = Object.fromEntries(remainingObjectEntries);
		setEnrichedProductHeaders && setEnrichedProductHeaders(remainingObject as ProductHeaders);
		saveHeaders(remainingObject);
	}

	const getproductHeader = (headerObject: Header) => {
		const matchedHeaderValue = enrichedProductHeaders[headerObject.code];
		return matchedHeaderValue == null ? undefined : { name: headerObject.name, value: matchedHeaderValue };
	}

	const getheaderDefinition = (headerObject: Header) => {
		if (enrichedProductHeaders) {
			const header = getproductHeader(headerObject);
			if (header) {
				return header;
			}
			else return undefined;
		}
		return;
	}


	const constructheaderTile = () => {
		return headerList.map((header: Header) => headerTile(header));
	}

	const checkDirectionHeaderRule: string = useMemo(() => {
		let validationMsg = "";
		if (headerType === HeaderTypes.DIRECTION) {
			let availableDimensions: string[];
			if (applicabilityScope.length > 0) {
				availableDimensions = Array.from(new Set(applicabilityScope.flatMap(appScope => appScope.dimensions).map(dimension => dimension.type)));
			}
			else {
				availableDimensions = defaultDimensionsFamilyData ? defaultDimensionsFamilyData.map(dimension => dimension.dimensionCode) : [];
			}

			if (availableDimensions.length > 0) {
				const selectedHeaders: string[] = headerOptions.length > 0 ? headerOptions.map(header => header.code) : [];
				if (availableDimensions.includes(DimensionType.Import) && !availableDimensions.includes(DimensionType.Export) && selectedHeaders.includes('origin')) {
					validationMsg = "Destination dimension in applicability already exists.\nRemove destination dimension first and proceed to save.";
				}
				else if (availableDimensions.includes(DimensionType.Export) && !availableDimensions.includes(DimensionType.Import) && selectedHeaders.includes('destination')) {
					validationMsg = "Origin dimension in applicability already exists.\nRemove origin dimension first and proceed to save.";
				}
			}
		}
		return validationMsg;
	}, [headerType, headerOptions])

	const save = () => {
		const newlyAddedHeaderValues = { [headerType]: headerOptions } as ProductHeaders;
		setEnrichedProductHeaders && setEnrichedProductHeaders({ ...enrichedProductHeaders, ...newlyAddedHeaderValues });
		saveHeaders(newlyAddedHeaderValues);
		setMultiListDialog(false);
		setHeaderOptions([]);
	}

	const disableSaveButtonWithoutMessage = () => {
		return (headerType === HeaderTypes.SERVICE_MODEL && headerOptions.length === 0) ? true : false;
	}

	const footerContent = (
		<div>
			<Button label="Cancel" className="p-button-outlined p-button-secondary" onClick={() => {setMultiListDialog(false);setHeaderOptions([])}} />
			<Button label="Save" className="p-button-primary" onClick={() => save()} disabled={(checkDirectionHeaderRule !== "") || disableSaveButtonWithoutMessage()} tooltip={checkDirectionHeaderRule} tooltipOptions={{ showOnDisabled: true, position: "bottom" }} />
		</div>
	)

	const accessEditIcon = (headerAttribute: HeaderAttribute) => {
		if (editMode && (headerAttribute.type !== HeaderTypes.SERVICE_MODEL)) {
			return true
		}
		else if (editMode && (headerAttribute.type === HeaderTypes.SERVICE_MODEL && isGlobalOwner)) {
			return true;
		}
		else return false;
	}

	const isDefaultHeader = (headerCode: string) => {
		return defaultHeaders.includes(headerCode)
	}

	const headerTile = (header: Header) => {
		const headerRef = getheaderDefinition(header)!;
		const headerAttribute: HeaderAttribute | undefined = headerRef ? {
			type: header.code,
			name: headerRef?.name,
			value: headerRef?.value || [],
			icon: getHeaderIcon(header.code)
		} : undefined;
		return (headerAttribute &&
			<div key={header.code} data-testid="HeaderFields">
				<div className={styles.icons}>
					<div className={styles.type} >{headerAttribute.name}
						{accessEditIcon(headerAttribute) && <span data-testid={`${header.code}-PencilIcon`} className={styles.pencilIcon} onClick={(e) => handleOnClickOfEdit(headerAttribute)}><i className={`pi pi-pencil ${styles.pencilIconFont}`}></i></span>}
						{editMode && !isDefaultHeader(headerAttribute.type) && <span data-testid={`${header.code}-TrashIcon`} className={styles.trashIcon} onClick={() => handleDelete(headerAttribute.type)}><i className={`pi pi-trash ${styles.pencilIconFont}`}></i></span>}
					</div>
					<div className={styles.headerIcon}>
						{headerAttribute?.icon}
					</div>
				</div>
				{
					!headerAttribute?.value.length ? <div className={styles.notDefined}>Not Defined</div> :
						headerAttribute?.value?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((attribute: Header) => {
							return (
								<Tag key={attribute.code} id="headerTag" value={attribute?.name} rounded className={`${styles.tag} ${getIconColor(headerAttribute?.type)}`}></Tag>
							)
						})
				}
				<p className={styles.headerBorder}></p>
			</div>
		)
	}

	const closeHeaderDilogBox = () => {
		setShowHeaderDialog(false);
	}

	const getDescriptionText = () => {
		return (
			<div>Please include/exclude header you need:</div>
		)
	}

	const addHeader = (enrichedProductHeaders: ProductHeaders) => {
		setShowHeaderDialog(false);
		setEnrichedProductHeaders && setEnrichedProductHeaders(enrichedProductHeaders);
		saveHeaders(enrichedProductHeaders as Header[]);
	}

	const displayHeaderDialogBox = () => {
		setShowHeaderDialog(true);
	}

	const areAllHeadersAdded = () => {
		return headerList.length === selectedHeaders.length;
	}

	return (
		<div>
			<div className={styles.headerView} data-testid="headerViewList">
				<div className={styles.headerContainer}>
					<div className={styles.headeritems}>
						{/* <div>
						<div className={styles.symbol}>
							<div className={styles.headerWidth}>
								<div className={styles.type}>Type</div>
								<Tag value="Solution" rounded className={`${styles.tag} ${styles.carrier}`}></Tag>
							</div>
							<div>
								<Type />
							</div>
						</div>
						<p className={styles.headerBorder}></p>
					</div> */}
						<div >
							<div className={styles.headerWidth} data-testid="HeaderTile">
								{
									constructheaderTile()
								}
							</div>
						</div>
					</div>
				</div>
			{ multiListDialog &&
				<Dialog visible header={headerPopUp} onHide={() => {
					setMultiListDialog(false);
				}} style={{ width: '32vw', height: '45vh' }} footer={footerContent}>
					<HeaderPopUp headerType={headerType} selectValues={selectValue}
						setHeaderOptions={setHeaderOptions} isSolution={isSolution}></HeaderPopUp>
				</Dialog>
			}

				{/*
			
				MIGHT REQUIRE IN FUTURE

			<Dialog visible={singleList} header='Service Model' onHide={() => {
				setSingleList(false);
			}} style={{ width: '25vw', height: '35vh' }}>
				<ServiceModel></ServiceModel>
			</Dialog> 
			
			*/}
			</div>
			{editMode &&
				<div className={styles.alignCenter}>
					<Button
						label="Add Header"
						icon="pi pi-plus"
						className={`p-button-outlined p-button-secondary`}
						onClick={() => displayHeaderDialogBox()}
						tooltip={"All Headers have already been added"}
						disabled={areAllHeadersAdded()}
						tooltipOptions={{ showOnDisabled: true, disabled: !areAllHeadersAdded(), position: "bottom" }}
					/>
				</div>
			}
			{
				showHeaderDialog && <HeaderDialogBox headerName={"Add Headers"} descriptionText={getDescriptionText} selectedHeaders={selectedHeaders}
					applyHeaders={addHeader} closeHeaderDialogBox={() => closeHeaderDilogBox()} setselectedHeaders={setselectedHeaders}
					enrichedProductHeaders={enrichedProductHeaders} allHeadersDefinitions={allHeadersDefinitions} modifySelectedHeaders={() => { }}
					defaultHeaders={defaultHeaders} />
			}
		</div>
	)

}

export default HeaderView;