import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';
import styles from './SolutionFeatureAssociations.module.scss';
import { ConfigurableControl, FeatureAssociation, FeatureAssociationRequest, FeatureDefinition, ProductInfo, SolutionDefinition, SolutionDefnitionRequest, SpecificationParameterAssociation, SpecificationParameterAssociationRequest, SpecificationParameterDefinition, Status } from '../../data/model/DataModels';
import { SolutionContext, constructBaseSolutionRequest, solutionTabs } from '../SolutionDetails/SolutionDetails';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import SolutionFeatureAssociationsHeader from '../SolutionFeatureAssociationsHeader/SolutionFeatureAssociationsHeader';
import { filterType } from '../../components/FilterPanel/FilterPanel';
import { useLocation } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import FeatureCardIncremental from '../../components/FeatureCardIncremental/FeatureCardIncremental';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import DisplaySpecificationParameterCards from '../ProductView/ProductFeatures/DisplaySpecificationParameterCards/DisplaySpecificationParameterCards';
import { cloneDeep } from 'lodash';
import FeatureUpgradeDialog from '../../components/FeatureUpgradeDialog/FeatureUpgradeDialog';

export interface SolutionFeatureAssociationsProps {
  refetchSolution: Function,
  isEditable: boolean,
  featureInEditMode: boolean,
  setFeatureInEditMode: Function
}

interface ClonedSolutionContextInterface {
  solutionData?: SolutionDefinition;
  setSolutionData: Dispatch<SetStateAction<SolutionDefinition | undefined>>
}

export const ClonedSolutionContext = createContext<ClonedSolutionContextInterface>({ setSolutionData: () => { } });

const SolutionFeatureAssociations: FC<SolutionFeatureAssociationsProps> = ({ refetchSolution, isEditable, featureInEditMode, setFeatureInEditMode }) => {

  const solutionState = useContext(SolutionContext);
  const [clonedSolutionState, setClonedSolutionState] = useState(solutionState.solutionData);
  const solution = clonedSolutionState;
  const isDraftSolution = solution?.status === Status.DRAFT;
  let selectedComponentCodes: string[] = [];

  const [showSpecificationParamModal, setShowSpecificationParamModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({} as FeatureDefinition);
  const [selectedFeatureForUpgrade, setSelectedFeatureForUpgrade] = useState<FeatureAssociation>();

  const { hash } = useLocation();


  const params = new URLSearchParams(hash.replace(solutionTabs[4].hash, ""));


  if (params.getAll(filterType.Component).length > 0) {
    selectedComponentCodes = params.getAll(filterType.Component);
  }



  const toaster = useContext(ToasterContext);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();

  if (!solution) {
    return <></>
  }

  const getFeaturesForSelectedComp = () => {
    return selectedComponentCodes.length === 0 ? solution.features : solution.features?.filter(({ componentRef }) => selectedComponentCodes.includes(componentRef));
  }

  const [featureTypeFilterValue, setFeatureTypeFilterValue] = useState("all");

  const deleteFeature = (featureCode: string) => {
    if (!solutionState.solutionData) {
      return;
    }

    const featureAssociations: FeatureAssociationRequest[] = solutionState.solutionData.features.filter(feature => feature.featureRef !== featureCode);
    const specificationParameterAssociations: SpecificationParameterAssociationRequest[] = solutionState.solutionData.specificationParameters.filter(specificationParameter => specificationParameter.featureRef !== featureCode);

    const solutionBaseRequest = constructBaseSolutionRequest(solutionState.solutionData);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: featureAssociations,
      specificationParameters: specificationParameterAssociations
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solutionState.solutionData as SolutionDefinition,
            lockingVersion: response.lockingVersion,
            features: response.features,
            specificationParameters: response.specificationParameters
          }
        );
        setClonedSolutionState({
          ...solutionState.solutionData as SolutionDefinition,
          lockingVersion: response.lockingVersion,
          features: response.features,
          specificationParameters: response.specificationParameters
        });
        toaster.showToast('success', 'Successfully deleted feature');
      },
      () => {
        toaster.showToast('error', 'Failed to delete feature');
      }
    );
  };

  const confirmRemoveFeature = (feature: FeatureAssociation) => {
    confirmDialog({
      message: `Are you sure you want to remove feature ${feature.definition.name} from this solution?`,
      header: 'Remove feature?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteFeature(feature.featureRef)
    });
  }

  const deleteSpecificationParameterInSolution = (specCode: string) => {
    if (!solution) {
      return;
    }

    const specificationParameterAssociations: SpecificationParameterAssociation[] = solution.specificationParameters.filter(specificationParameter => specificationParameter.specificationParamRef !== specCode);
    setClonedSolutionState({ ...solution, specificationParameters: specificationParameterAssociations });
  }

  const confirmRemoveSpecificationParameter = (specification: SpecificationParameterDefinition) => {
    confirmDialog({
      message: `Are you sure you want to remove specification parameter ${specification.name} from this solution?`,
      header: 'Remove specification parameter?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteSpecificationParameterInSolution(specification.code)
    });
  }

  const saveFeature = () => {
    if (!solutionState?.solutionData) {
      return;
    }

    const solutionBaseRequest = constructBaseSolutionRequest(solutionState?.solutionData);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      specificationParameters: solution.specificationParameters,
      features: solution.features
    };

    return updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solutionState?.solutionData as SolutionDefinition,
            lockingVersion: response.lockingVersion,
            specificationParameters: response.specificationParameters,
            features: response.features
          }
        );

        setClonedSolutionState({
          ...solutionState?.solutionData as SolutionDefinition,
          lockingVersion: response.lockingVersion,
          specificationParameters: response.specificationParameters,
          features: response.features
        });

        const responseUpgradedFeatures = response.components.flatMap((component) => component.upgradedFeatureCode);
        const solutionUpgradedFeatures = solutionState?.solutionData?.components.flatMap((component) => component.upgradedFeatureCode);

        if (solutionUpgradedFeatures && responseUpgradedFeatures.length !== solutionUpgradedFeatures.length) {
          refetchSolution();
        }

        toaster.showToast('success', 'Successfully updated feature');
      },
      () => {
        toaster.showToast('error', 'Failed to update feature');
      }
    );
  }



  const saveFeatureOptionality = (featureParam: FeatureDefinition, isChangedFromCoreToOptional: boolean) => {
    let updatedFeature = solution.features.map((param) => {
      let updatedValue = featureParam.code === param.featureRef;
      if (updatedValue) {
        return {
          ...param,
          serviceDefault: featureParam.serviceDefault,
        };
      }
      return param;
    });
    let updatedSPs;
    if (isChangedFromCoreToOptional) {
      updatedSPs = solution.specificationParameters.map((sp) => {
        let updatedSP = sp.featureRef === featureParam.code;
        if (updatedSP) {
          let config: ConfigurableControl = cloneDeep(sp.configurableAtContracting!);
          config.enabled = false;
          config.allowMultiselect = false;
          config.choiceRequired = false;
          return {
            ...sp,

            serviceDefault: "not_included",
            configurableAtContracting: config
          } as SpecificationParameterAssociation;
        }
        return sp;
      });
    }
    else updatedSPs = solution.specificationParameters.map((sp) => {
      let updatedSp = sp.featureRef === featureParam.code
      if (updatedSp) {
        return {
          ...sp,
          serviceDefault: sp.serviceDefault
        } as SpecificationParameterAssociation
      }
      return sp;
    });
    setClonedSolutionState({
      ...solution,
      features: updatedFeature,
      specificationParameters: updatedSPs
    });
  };


  const onClickOfAddSpecParam = (feature: FeatureDefinition) => {
    setShowSpecificationParamModal(true);
    setSelectedFeature(feature);
  }

  const isFeatureVersionAvailable = (feature: FeatureAssociation): boolean => {
    const filteredComponent = solution.components.filter(comp => comp.componentRef === feature.componentRef);
    if (filteredComponent) {
      const newFeatureVersion = filteredComponent.flatMap(comp => comp.upgradedFeatureCode?.filter(featureCodeAndVersion => featureCodeAndVersion.code === feature.featureRef));
      const latestVersionAvailable = newFeatureVersion[0]?.featureVersion;
      return latestVersionAvailable && (latestVersionAvailable > feature.featureVersion) ? true : false;
    }
    return false;
  }

  const convertSpecParamDefinitionToAssociation = (specParam: SpecificationParameterDefinition, previousSPAssociationId?: number): SpecificationParameterAssociation => {
    const updateAssociation: SpecificationParameterAssociation =  {
      featureRef: specParam.featureRef,
      componentRef: specParam.componentRef,
      specificationParamRef: specParam.code,
      configuration: specParam.configuration,
      productId: solution.id,
      definition: specParam,
      configurableAtContracting: specParam.configurableAtContracting,
      configurableAtBooking: specParam.configurableAtBooking,
      featureVersion: specParam.featureVersion,
      serviceDefault: specParam?.serviceDefault,
    } as SpecificationParameterAssociation
    if(previousSPAssociationId){
      updateAssociation.id = previousSPAssociationId; 
    }
    return updateAssociation;
  }


  const newFeatureToUpdateSp = (newFeature: FeatureDefinition, existingFeature: FeatureDefinition) => {
    const updatedSPs = newFeature.specificationParameters.map(newFeatureSP => {
      const existingFeatureSP = existingFeature.specificationParameters.find(existingSP => newFeatureSP.code === existingSP.code);
      const updatedServiceDefault = existingFeatureSP?.serviceDefault ?? newFeatureSP.serviceDefault;
      return { ...newFeatureSP, serviceDefault: updatedServiceDefault };
    });
    return { ...newFeature, specificationParameters: updatedSPs };
  };

  const upgradeToNewFeature = (
    existingFeature: FeatureDefinition,
    newFeature: FeatureDefinition
  ) => {

    const updateFeatureDefinition = (newFeature.service != existingFeature.service) ? newFeature : newFeatureToUpdateSp(newFeature, existingFeature);

    const newUpgradedFeature = {
      productId: solution.code,
      featureRef: existingFeature.code,
      componentRef: existingFeature.componentRef,
      definition: updateFeatureDefinition,
      featureVersion: newFeature.version,
      service: (newFeature.service != existingFeature.service) ? newFeature.service : existingFeature.service,
      serviceDefault: (newFeature.service != existingFeature.service) ? newFeature.serviceDefault : existingFeature.serviceDefault
    } as FeatureAssociation;

    let upgradedFeatures = solution.features.map((feature) =>
      feature.featureRef === existingFeature.code ? newUpgradedFeature : feature
    );
    
    const existingSpecParamToBeDeleted =
      existingFeature.specificationParameters.map((specParam) =>
        convertSpecParamDefinitionToAssociation(specParam)
      );

    const newSpecParamToBeAdded = newFeature.specificationParameters.map((specParam) => {
      const existingFeatureSP = solution.specificationParameters.find(existingSP =>
        existingSP.specificationParamRef === specParam.code
      );
      let newSpecParamDefinition: SpecificationParameterDefinition = {...specParam};
      if (newFeature.service == existingFeature.service){
        const areNewSPCoreAndExistingFeatureOptional = (existingFeatureSP == undefined) && (specParam.serviceDefault === "included") && (existingFeature.serviceDefault == "not_included");
        if (areNewSPCoreAndExistingFeatureOptional) {
          newSpecParamDefinition.serviceDefault = "not_included";
        } else {
          newSpecParamDefinition.serviceDefault = existingFeatureSP?.serviceDefault ?? specParam.serviceDefault
        }
      }
      const convertedSpecParam = convertSpecParamDefinitionToAssociation(newSpecParamDefinition, existingFeatureSP?.id);
      return convertedSpecParam;
    });


    const updatedSpecificationparameters =
      solution.specificationParameters.filter(
        (mainSp) =>
          !existingSpecParamToBeDeleted.filter(
            (spToDelete) =>
              spToDelete.specificationParamRef ===
              mainSp.specificationParamRef &&
              mainSp.featureRef === spToDelete.featureRef
          ).length
      );

    setClonedSolutionState({
      ...solution,
      features: upgradedFeatures,
      specificationParameters: [...updatedSpecificationparameters, ...newSpecParamToBeAdded]
    });
  };

  const showFeature = (feature: FeatureAssociation) => {
    const associatedSpecParams = solution.specificationParameters
      .filter((specParam) => specParam.featureRef === feature.featureRef)
      .map((specParam) => {
        return {
          ...specParam.definition,
          ...specParam,
          code: specParam.specificationParamRef,
          name: specParam.definition.name,
          featureServiceDefault: feature.serviceDefault
        };
      }) as SpecificationParameterDefinition[]; 
      
      return (
        <FeatureCardIncremental key={feature.featureRef}
          feature={
            {
              ...feature.definition,
              service: feature.service,
              associatedProducts: [{ code: solution.code }] as ProductInfo[],
              serviceDefault: feature.serviceDefault,
              specificationParameters: associatedSpecParams,
            }
          }
          saveFeature={saveFeature}
          specificationParameterAssociations={associatedSpecParams}
          addSpecificationParameter={() => onClickOfAddSpecParam(feature.definition!)}
          deleteFeature={() => confirmRemoveFeature(feature)}
          deleteSpecification={(specification: SpecificationParameterDefinition) => confirmRemoveSpecificationParameter(specification)}
          isConfigurable={isEditable}
          usedIn={"solution"}
          isNewVersionAvailable={() => isFeatureVersionAvailable(feature)}
          saveFeatureOptionality={saveFeatureOptionality}
          setFeatureInEditMode={setFeatureInEditMode}
          featureInEditMode={featureInEditMode}
          setSelectedFeatureForUpgrade={() => setSelectedFeatureForUpgrade(feature)}
        />
      );
  }

  const getAssociatedSpecParamsOfFeature = (feature: FeatureDefinition) => {
    return solution.specificationParameters.filter(specParam => specParam.featureRef === feature.code).map(specParam => specParam.specificationParamRef);
  }

  const addSpecificationParameter = useCallback(
    (specParams: SpecificationParameterDefinition[]) => {
      if (!solution) {
        return;
      }

      const mapSpecParamDefinitionToAssociation = (): SpecificationParameterAssociation[] => {
        return specParams.map(specParam => {
          const selectedFeatureAssociation = solution.features.find(feature => feature.featureRef === specParam.featureRef);
          return {
            featureRef: specParam.featureRef,
            featureVersion: specParam.featureVersion,
            componentRef: specParam.componentRef,
            definition: specParam,
            specificationParamRef: specParam.code,
            configuration: specParam.configuration,
            configurableAtContracting: specParam.configurableAtContracting,
            configurableAtBooking: specParam.configurableAtBooking,
            note: "",
            serviceDefault: selectedFeatureAssociation && selectedFeatureAssociation.serviceDefault === 'not_included' ? 'not_included' : specParam.serviceDefault
          } as SpecificationParameterAssociation
        })
      }

      setClonedSolutionState({
        ...solution,
        specificationParameters: [...solution.specificationParameters, ...mapSpecParamDefinitionToAssociation()]
      });
    },
    [solution.specificationParameters],
  )

  const getSpecificationParameterDefinitions = (featureAssociation : FeatureAssociation) => {
    return solution.specificationParameters.filter(specParam => specParam.featureRef === featureAssociation.featureRef).map(spAssociation => {
      return {
        ...spAssociation
      } as unknown as SpecificationParameterDefinition
    })
  }

  return (
    <div className={styles.SolutionFeatureAssociations} data-testid="SolutionFeatureAssociations">
      <ClonedSolutionContext.Provider value={{ solutionData: clonedSolutionState, setSolutionData: setClonedSolutionState }}>
        {isLoadingUpdateSolution && <SpinnerComponent />}
        <SolutionFeatureAssociationsHeader isEditable={isEditable && !featureInEditMode} setFeatureTypeFilterValue={setFeatureTypeFilterValue} />
        {
          getFeaturesForSelectedComp()
            .filter((feature) => (featureTypeFilterValue == "all" || (feature.serviceDefault === featureTypeFilterValue))
            ).map(feature =>
              showFeature(feature)
            )
        }
        {
          showSpecificationParamModal &&
          <DisplaySpecificationParameterCards header={selectedFeature.name} actionShowDialog={() => setShowSpecificationParamModal(false)} activeSpecificationParameters={getAssociatedSpecParamsOfFeature(selectedFeature)} componentRef={selectedFeature.componentRef} featureRef={selectedFeature.code} acceptAction={(specParams: SpecificationParameterDefinition[]) => addSpecificationParameter(specParams)} />
        }
        {
        selectedFeatureForUpgrade !== undefined  && 
        <FeatureUpgradeDialog onHide={() => setSelectedFeatureForUpgrade(undefined)} feature={selectedFeatureForUpgrade} specificationParameters={getSpecificationParameterDefinitions(selectedFeatureForUpgrade)} isEditable={isEditable}
          upgradeToNewFeature={(existingFeature: FeatureDefinition, newFeature: FeatureDefinition) => upgradeToNewFeature && upgradeToNewFeature(existingFeature, newFeature)}/>
      }
      </ClonedSolutionContext.Provider>
    </div>
  );
}

export default SolutionFeatureAssociations;
